import React from 'react'
import { observable, action, reaction, computed, decorate } from 'mobx'
import { observer } from 'mobx-react'
import { ago, noFirstSpace, getVersionString } from '../utils'
import Input from '../components/Input'
import NewVersionModal from '../components/modals/NewVersion'
import WarningModal from '../components/modals/Warning'
import EasterEggs from '../components/EasterEggs'
// Key code strings
const METAKEY_STRING =
  navigator.platform === 'MacIntel' ? String.fromCharCode(8984) : String.fromCharCode(8963)
const SHIFTKEY_STRING = String.fromCharCode(8679)
const RIGHT_ARROW_STRING = String.fromCharCode(9654) // Open arrow
const DELETEKEY_STRING = String.fromCharCode(9003) // Delete
const DESELECT_STRING = String.fromCharCode(9099) // Deselect All
const ZOOMPLUS_STRING = String.fromCharCode(9547) // Zoom In (plus)
const ZOOMMINUS_STRING = String.fromCharCode(9473) // Zoom Out (minus)

class ToolbarComponent extends React.Component {
  constructor(props) {
    super(props)
    this.newVersionModal = null
    this.revertModal = false
    this.selectedIndex = null
    this.selectedInnerMenuIndex = null
    this.isShowMenu = false
    this.isShowMenuClick = false
    this.routeEMModal = false
    this.exportModal = false
  }
  get isStructure() {
    const { app } = this.props
    return app.route.current === 'Structure'
  }
  get isLayout() {
    const { app } = this.props
    return app.route.current === 'Layout'
  }
  get canDelete() {
    const { layout: layoutState, structure: structureState } = this.props.app.state
    if (this.isStructure) return structureState.selections.hasAny
    if (this.isLayout) return layoutState.selections.hasAny
    return false
  }
  get canDeselect() {
    const { layout: layoutState, structure: structureState } = this.props.app.state
    if (this.isStructure) return structureState.selections.hasAny
    if (this.isLayout) return layoutState.selections.hasAny
    return false
  }
  get canCopy() {
    const { layout: layoutState, structure: structureState } = this.props.app.state
    if (this.isStructure) return structureState.selections.hasAny
    if (this.isLayout) return layoutState.selections.hasAny
    return false
  }
  get canDuplicate() {
    const { layout: layoutState, structure: structureState } = this.props.app.state
    if (this.isStructure) return structureState.selections.canDuplicate
    if (this.isLayout) return layoutState.selections.canDuplicate
    return false
  }
  get canZoomIn() {
    const { layout: layoutState, structure: structureState } = this.props.app.state
    if (this.isStructure) return structureState.camera.canZoomIn
    if (this.isLayout) return layoutState.camera.canZoomIn
    return false
  }
  get canZoomOut() {
    const { layout: layoutState, structure: structureState } = this.props.app.state
    if (this.isStructure) return structureState.camera.canZoomOut
    if (this.isLayout) return layoutState.camera.canZoomOut
    return false
  }
  get canUndo() {
    const { history } = this.props.app
    //if (this.isLayout) return history.canUndo
    return history.canUndo
  }
  get canRedo() {
    const { history } = this.props.app
    //if (this.isLayout) return history.canRedo
    return history.canRedo
  }
  get canPreviewFromSequence() {
    const { structure: structureState } = this.props.app.state
    return structureState.selections.isSingleSequence
  }
  get hasAssetSelection() {
    const { layout: layoutState } = this.props.app.state

    return layoutState.selections.assets.length > 0
  }

  // File Menu
  openEM = () => {
    const {
      app: { route },
      app
    } = this.props
    const em = app.state.editionmanager
    this.routeEMModal = true
  }
  routeToEM = async () => {
    const {
      app: { route },
      app
    } = this.props
    const em = app.state.editionmanager
    if (app.edition.version.isUnversioned) await em.showHomepage()
    else await em.showEditionDetails(app.edition.objectId)
    route.goTo('EditionManager')
    app.resetState()
  }
  save = () => {
    const { app } = this.props
    const globalState = app.state.global
    if (noFirstSpace(app.edition.name).length) app.saveEdition()
    else app.state.global.showAlert('error', `Edition name cannot be blank or only spaces.`)
  }
  revert = () => {
    const { app } = this.props
    const em = app.state.editionmanager
    em.revertEdition(app.edition).then(this.closeRevertModal)
  }
  discard = () => {
    const {
      app,
      app: { route }
    } = this.props
    const em = app.state.editionmanager
    em.discardEdition(app.edition).then(async () => {
      await em.showHomepage()
      route.goTo('EditionManager')
      app.resetState()
    })
  }
  toggleNewVersionModal = () => {
    this.newVersionModal = !this.newVersionModal
  }
  toggleRevertModal = () => {
    this.revertModal = !this.revertModal
  }
  saveVersion = (comments, versionType, assetSetId, hideModal) => {
    const { app } = this.props
    const globalState = app.state.global
    if (noFirstSpace(app.edition.name).length)
      app.saveVersion(comments, versionType, assetSetId).then(hideModal)
    else app.state.global.showAlert('error', `Edition name cannot be blank or only spaces.`)
  }
  duplicateEdition = () => {
    const { app } = this.props
    app.duplicateEdition()
  }
  closeSequence = () => {
    const {
      app: { route }
    } = this.props
    route.goTo('Structure')
  }
  // Edit Menu
  undo = () => {
    const { app } = this.props
    if (this.canUndo) app.history.undo()
  }
  redo = () => {
    const { app } = this.props
    if (this.canRedo) app.history.redo()
  }
  cut = (e) => {
    if (!this.canCopy) return
    const { app } = this.props
    app.cut(e, app.route)
  }
  cutSelected = () => {
    document.execCommand('cut')
  }
  copy = (e) => {
    if (!this.canCopy) return
    const { app } = this.props
    app.copy(e, app.route)
  }
  copySelected = () => {
    document.execCommand('copy')
  }
  paste = (e) => {
    const { app } = this.props
    app.paste(e, app.route)
  }
  duplicateSelected = () => {
    const { layout: layoutState, structure: structureState } = this.props.app.state
    if (!this.canDuplicate) return
    if (this.isStructure) structureState.selections.duplicateSelected()
    if (this.isLayout) layoutState.selections.duplicateSelected()
  }
  deleteSelected = () => {
    const { layout: layoutState, structure: structureState } = this.props.app.state
    if (!this.canDelete) return
    if (this.isStructure) structureState.selections.deleteSelected()
    if (this.isLayout) layoutState.selections.deleteSelected()
  }
  deselect = () => {
    const { layout: layoutState, structure: structureState } = this.props.app.state
    if (!this.canDeselect) return
    if (this.isStructure) structureState.selections.clearSelection()
    if (this.isLayout) layoutState.selections.clearAllSelections()
  }
  // View Menu
  togglePlay = () => {
    const { layout: layoutState } = this.props.app.state
    if (this.isLayout) layoutState.player.togglePlayback()
  }
  zoomIn = () => {
    const { layout: layoutState, structure: structureState } = this.props.app.state
    if (!this.canZoomIn) return
    if (this.isStructure) structureState.camera.zoomIn()
    if (this.isLayout) layoutState.camera.zoomIn()
  }
  zoomOut = () => {
    const { layout: layoutState, structure: structureState } = this.props.app.state
    if (!this.canZoomOut) return
    if (this.isStructure) structureState.camera.zoomOut()
    if (this.isLayout) layoutState.camera.zoomOut()
  }
  resetZoom = () => {
    const { layout: layoutState, structure: structureState } = this.props.app.state
    if (this.isStructure) structureState.camera.zoomTo(3)
    if (this.isLayout) layoutState.viewport.resetCameraZoom()
  }
  resetCamera = () => {
    const { layout: layoutState, structure: structureState } = this.props.app.state
    if (this.isStructure) structureState.camera.moveTo(0, 0)
    if (this.isLayout) layoutState.viewport.resetCameraCenter()
  }
  togglePanel = (name) => {
    const { ui } = this.props.app.state
    ui.collapseState.toggleCollapse(name)
  }
  toggleOutline = () => {
    const { layout: layoutState } = this.props.app.state
    if (this.isLayout) layoutState.viewport.toggleAssetOutline()
  }
  // Preview Menu
  preview = (e) => {
    const { app } = this.props
    if (e && e.preventDefault) e.preventDefault() // Disable browser print dialog
    app.history.ignore(() => app.saveEditionForPreview())
  }
  previewFromSequence = () => {
    const { app } = this.props
    const { structure: structureState } = app.state
    if (this.canPreviewFromSequence) {
      const previewFrom = structureState.selections.first
      // Make sure undo/redo ignores this operation
      app.history.ignore(() => app.saveEditionForPreview(previewFrom))
    }
  }
  exportWordDoc = () => {
    const app = this.props.app
    const toWord = this.props.editionToWord
    const edition = app.edition

    toWord(edition)
  }
  exportHtml = () => {
    const app = this.props.app
    const toHtml = this.props.editionToHtml
    const edition = app.edition
    const lang = app.getLanguageCode() === "ES" ? "es" : "en"
    toHtml(edition, lang)
  }
  exportCaptions = () => {
    const app = this.props.app
    const edition = app.edition
    const lang = app.getLanguageCode() === "ES" ? "es" : "en"
    const toTTML = this.props.editionToTTML

    toTTML(edition, lang)
      .catch(err => 
        app.throwError(err.message))
  }

  alignHLeft = () => {
    const { selections } = this.props.app.state.layout

    selections.alignXMin()
  }
  alignHCenter = () => {
    const { selections } = this.props.app.state.layout

    selections.alignXCenter()
  }
  alignHRight = () => {
    const { selections } = this.props.app.state.layout

    selections.alignXMax()
  }
  alignVTop = () => {
    const { selections } = this.props.app.state.layout

    selections.alignYMin()
  }
  alignVCenter = () => {
    const { selections } = this.props.app.state.layout

    selections.alignYCenter()
  }
  alignVBottom = () => {
    const { selections } = this.props.app.state.layout

    selections.alignYMax()
  }
  distributeSpaceX = () => {
    const { selections } = this.props.app.state.layout

    selections.distributeX()
  }
  distributeSpaceY = () => {
    const { selections } = this.props.app.state.layout

    selections.distributeY()
  }
  // Key map (key sequence/combo to event & function)
  hotKeyMap = [
    ['mod+o', 'keydown', this.openEM],
    ['mod+s', 'keydown', this.save],
    ['mod+shift+s', 'keydown', this.toggleNewVersionModal],
    //['mod+e',          'keydown', this.exportScript   ],
    ['mod+b', 'keydown', this.closeSequence],
    ['mod+p', 'keydown', this.preview],
    ['mod+shift+p', 'keydown', this.previewFromSequence],
    ['mod+z', 'keydown', this.undo],
    ['mod+shift+z', 'keydown', this.redo],
    ['mod+h', 'keydown', this.togglePanel.bind(this, 'Scripting')]
  ]
  // Keyboard Shortcuts for File Menu
  handleHotkeys(keyboard) {
    const { hotKeyMap } = this
    for (let i = 0, binding; (binding = hotKeyMap[i]); i++) {
      const [hotkey, evt, fn] = binding
      keyboard.bind(hotkey, fn, evt)
    }
  }
  disposeHotkeys(keyboard) {
    const { hotKeyMap } = this
    for (let i = 0, binding; (binding = hotKeyMap[i]); i++) {
      const [hotkey, evt, fn] = binding
      keyboard.unbind(hotkey, evt)
    }
  }
  componentDidMount() {
    const { app } = this.props
    document.addEventListener('cut', this.cut)
    document.addEventListener('copy', this.copy)
    document.addEventListener('paste', this.paste)
    document.addEventListener('click', this.hideSubmenu)
    this.handleHotkeys(app.signals.keyboard)
    app.state.global.startSaveTimer()
  }
  componentWillUnmount() {
    const { app } = this.props
    document.removeEventListener('cut', this.cut)
    document.removeEventListener('copy', this.copy)
    document.removeEventListener('paste', this.paste)
    document.removeEventListener('click', this.hideSubmenu)
    this.disposeHotkeys(app.signals.keyboard)
    app.state.global.stopSaveTimer()
  }
  hideSubmenu = () => {
    if (!this.isShowMenu) {
      this.isShowMenuClick = false
      this.selectedIndex = null
      this.selectedInnerMenuIndex = null
    }
  }
  showSubmenu = (index) => {
    this.isShowMenu = true
    this.selectedIndex = this.isShowMenuClick ? index : null
  }
  onSubmenuClick = (index) => {
    this.isShowMenuClick = true
    this.showSubmenu(index)
  }
  showInnerSubmenu = (index) => {
    this.isShowMenu = true
    this.selectedInnerMenuIndex = index
  }
  onMouseLeaveHandler = () => {
    this.isShowMenu = false
  }
  onSubmenuMenuHide = () => {
    this.selectedInnerMenuIndex = null
  }
  closeRevertModal = () => {
    this.revertModal = null
  }
  closeRouteEMModal = () => {
    this.routeEMModal = null
  }
  render() {
    const {
      isStructure,
      isLayout,
      canDelete,
      canDeselect,
      canCopy,
      canDuplicate,
      canZoomIn,
      canZoomOut,
      canUndo,
      canRedo,
      canPreviewFromSequence,
      openEM,
      save,
      duplicateEdition,
      closeSequence,
      toggleNewVersionModal,
      undo,
      redo,
      cutSelected,
      copySelected,
      duplicateSelected,
      deleteSelected,
      deselect,
      togglePlay,
      zoomIn,
      zoomOut,
      resetZoom,
      resetCamera,
      toggleOutline,
      preview,
      previewFromSequence,
      showSubmenu,
      onSubmenuClick,
      showInnerSubmenu,
      toggleRevertModal,
      hasAssetSelection
    } = this
    const { app } = this.props
    const { state } = app
    const { global: globalState } = state
    const { save: saveState } = globalState
    const { collapseState } = state.ui
    const updateName = (val) => app.edition.updateProp('name', val)
    // Panel toggles
    const toggleWidgets = () => this.togglePanel('Widgets')
    const toggleVariables = () => this.togglePanel('Variables')
    const toggleMedia = () => this.togglePanel('Media')
    const toggleActions = () => this.togglePanel('Actions')
    const toggleScripting = () => this.togglePanel('Scripting')
    const toggleDetails = () => this.togglePanel('Details')
    const toggleHierarchy = () => this.togglePanel('Hierarchy')
    const revertOrDiscard = app.edition.version.isUnversioned
      ? 'Discard Edition'
      : 'Revert to last version'
    // New Version Modal
    const getNewVersionModal = () => {
      if (this.newVersionModal) {
        return (
          <NewVersionModal
            hideModal={toggleNewVersionModal}
            saveVersion={this.saveVersion}
            version={app.edition.version}
            app={app}
          />
        )
      } else {
        return null
      }
    }
    const getRevertModal = () => {
      if (this.revertModal && !app.edition.version.isUnversioned) {
        return (
          <WarningModal
            cancel={this.closeRevertModal}
            continue={this.revert}
            warning={`This will delete all personal version saves for this edition and revert to version ${getVersionString(
              app.edition.version
            )}.`}
          />
        )
      } else if (this.revertModal && app.edition.version.isUnversioned) {
        return (
          <WarningModal
            cancel={this.closeRevertModal}
            continue={this.discard}
            warning={
              'This will discard the current edition and return you to the Edition Manager. This cannot be undone.'
            }
          />
        )
      } else {
        return null
      }
    }
    const getRouteEMModal = () => {
      if (this.routeEMModal) {
        return (
          <WarningModal
            cancel={this.closeRouteEMModal}
            continue={this.routeToEM}
            warning={`Unsaved changes will be lost!`}
          />
        )
      } else {
        return null
      }
    }

    const saveMessage =
      saveState.status === 'error' ? (
        <span className="status" title={saveState.message}>
          <em>Cannot Save File</em>
        </span>
      ) : (
        <span className="status" title={saveState.timestamp}>
          Last save:{' '}
          <em>
            {saveState.timestamp && saveState.lastChecked ? ago(saveState.timestamp) : 'Never'}
          </em>
        </span>
      )

    return (
      <div id="toolbar">
        <EasterEggs app={app} />
        <div className="toolbar-inner">
          <div className="brand">
            <i className="sprite toolbar jellyfish" />
          </div>
          <ul className={`file-menu`}>
            <li
              className={this.selectedIndex == '1' ? 'menu-item active' : 'menu-item '}
              onClick={() => onSubmenuClick('1')}
              onMouseOver={() => showSubmenu('1')}
              onMouseLeave={this.onMouseLeaveHandler}
              data-qa-hook="menu.file">
              File
              <ul className={this.selectedIndex == '1' ? 'sub-menu show-menu' : 'sub-menu '}>
                <li className="menu-item" onClick={openEM} data-qa-hook="menu.editionManager">
                  Edition Manager
                  <span className="shortcut">{METAKEY_STRING}O</span>
                </li>
                <li className="menu-item" onClick={save} data-qa-hook="menu.save">
                  Save
                  <span className="shortcut">{METAKEY_STRING}S</span>
                </li>
                <li className="menu-item" onClick={toggleNewVersionModal} data-qa-hook="menu.saveANewVersion">
                  Save a new version
                  <span className="shortcut">
                    {SHIFTKEY_STRING}
                    {METAKEY_STRING}S
                  </span>
                 </li>
                <li className="menu-item" onClick={duplicateEdition} data-qa-hook="menu.duplicateEdition">
                  Duplicate Edition
                </li>
                <li className="menu-item" onClick={toggleRevertModal} data-qa-hook="menu.revertDiscard">
                  {revertOrDiscard}
                  <span className="shortcut">{METAKEY_STRING}R</span>
                </li>
                <li className="menu-divider" />
                <li className="menu-item" onClick={() => onSubmenuClick("1.1")} onMouseOver={() => showInnerSubmenu("1.1")} onMouseLeave={this.onSubmenuMenuHide} data-qa-hook="menu.exportAs">
                  Export as...
                  <ul className={this.selectedInnerMenuIndex === "1.1" ? "sub-menu show-menu" : "sub-menu"} onMouseLeave={this.onSubmenuMenuHide}>
                    <li className="menu-item" onClick={this.exportHtml} data-qa-hook="menu.exportHTML">
                      HTML
                    </li>
                    <li className="menu-item" onClick={this.exportCaptions} data-qa-hook="menu.exportCaptions">
                      TTML
                    </li>
                    <li className="menu-item" onClick={this.exportWordDoc} data-qa-hook="menu.exportWord">
                      Word
                    </li>
                  </ul>
                </li>
                <li className={`menu-divider ${isLayout ? '' : 'hidden'}`} />
                <li className={`menu-item ${isLayout ? '' : 'hidden'}`} onClick={closeSequence} data-qa-hook="menu.closeSequence">
                  Close Sequence
                  <span className="shortcut">{METAKEY_STRING}B</span>
                </li>
              </ul>
            </li>
            <li
              className={this.selectedIndex == '2' ? 'menu-item active' : 'menu-item '}
              onClick={() => onSubmenuClick('2')}
              onMouseOver={() => showSubmenu('2')}
              onMouseLeave={this.onMouseLeaveHandler} data-qa-hook="menu.edit">
              Edit
              <ul className={this.selectedIndex == '2' ? 'sub-menu show-menu' : 'sub-menu '}>
                <li className={`menu-item ${canUndo ? '' : 'disabled'}`} onClick={undo} data-qa-hook="menu.undo">
                  Undo
                  <span className="shortcut">{METAKEY_STRING}Z</span>
                </li>
                <li className={`menu-item ${canRedo ? '' : 'disabled'}`} onClick={redo} data-qa-hook="menu.redo">
                  Redo
                  <span className="shortcut">
                    {SHIFTKEY_STRING}
                    {METAKEY_STRING}Z
                  </span>
                </li>
                <li className={`menu-item ${canCopy ? '' : 'disabled'}`} onClick={cutSelected} data-qa-hook="menu.cut">
                  Cut
                  <span className="shortcut">{METAKEY_STRING}X</span>
                </li>
                <li className={`menu-item ${canCopy ? '' : 'disabled'}`} onClick={copySelected} data-qa-hook="menu.copy">
                  Copy
                  <span className="shortcut">{METAKEY_STRING}C</span>
                </li>
                <li className={`menu-divider`} />
                <li
                  className={`menu-item ${canDuplicate ? '' : 'disabled'}`}
                  onClick={duplicateSelected} data-qa-hook="menu.duplicate">
                  Duplicate
                  <span className="shortcut">{METAKEY_STRING}D</span>
                </li>
                <li className={`menu-item ${canDelete ? '' : 'disabled'}`} onClick={deleteSelected} data-qa-hook="menu.delete">
                  Delete
                  <span className="shortcut">{DELETEKEY_STRING}</span>
                </li>
                <li className={`menu-item ${canDeselect ? '' : 'disabled'}`} onClick={deselect} data-qa-hook="menu.deselectAll">
                  Deselect All
                  <span className="shortcut">{DESELECT_STRING}</span>
                </li>
              </ul>
            </li>
            <li
              className={this.selectedIndex == '3' ? 'menu-item active' : 'menu-item '}
              onClick={() => onSubmenuClick('3')}
              onMouseOver={() => showSubmenu('3')}
              onMouseLeave={this.onMouseLeaveHandler} data-qa-hook="menu.selection">
              Selection
              <ul className={this.selectedIndex == '3' ?  'sub-menu show-menu' : 'sub-menu '}>
                <li
                  className={`menu-item ${hasAssetSelection ? '' : 'disabled'}`}
                  onClick={this.alignHLeft}
                  data-qa-hook="menu.align-horizontal-left">
                  Align Horizontal Left
                </li>
                <li
                  className={`menu-item ${hasAssetSelection ? '' : 'disabled'}`}
                  onClick={this.alignHCenter}
                  data-qa-hook="align-horizontal-center">
                  Align Horizontal Center
                </li>
                <li
                  className={`menu-item ${hasAssetSelection ? '' : 'disabled'}`}
                  onClick={this.alignHRight}
                  data-qa-hook="align-horizontal-right">
                  Align Horizontal Right
                </li>
                <li
                  className={`menu-item ${hasAssetSelection ? '' : 'disabled'}`}
                  onClick={this.alignVTop}
                  data-qa-hook="align-vertical-top">
                  Align Vertical Top
                </li>
                <li
                  className={`menu-item ${hasAssetSelection ? '' : 'disabled'}`}
                  onClick={this.alignVCenter}
                  data-qa-hook="align-vertical-center">
                  Align Vertical Center
                </li>
                <li
                  className={`menu-item ${hasAssetSelection ? '' : 'disabled'}`}
                  onClick={this.alignVBottom}
                  data-qa-hook="align-vertical-bottom">
                  Align Vertical Bottom
                </li>
                <li
                  className={`menu-item ${hasAssetSelection ? '' : 'disabled'}`}
                  onClick={this.distributeSpaceX}
                  data-qa-hook="distribute-horizontal">
                  Distribute Horizontal Space
                </li>
                <li
                  className={`menu-item ${hasAssetSelection ? '' : 'disabled'}`}
                  onClick={this.distributeSpaceY}
                  data-qa-hook="distribute-vertical">
                  Distribute Vertical Space
                </li>
              </ul>
            </li>
            <li
              className={this.selectedIndex == '4' ? 'menu-item active' : 'menu-item '}
              onClick={() => onSubmenuClick('4')}
              onMouseOver={() => showSubmenu('4')}
              onMouseLeave={this.onMouseLeaveHandler} data-qa-hook="menu.view">
              View
              <ul className={this.selectedIndex == '4' ? 'sub-menu show-menu' : 'sub-menu '}>
                <li className={`menu-item ${isLayout ? '' : 'hidden'}`} onClick={closeSequence} data-qa-hook="menu.structureView">
                  Structure View
                  <span className="shortcut">{METAKEY_STRING}B</span>
                </li>
                <li className={`menu-divider ${isLayout ? '' : 'hidden'}`} />
                <li className={`menu-item ${canZoomIn ? '' : 'disabled'}`} onClick={zoomIn} data-qa-hook="menu.zoomIn">
                  Zoom In
                  <span className="shortcut">{ZOOMPLUS_STRING}</span>
                </li>
                <li className={`menu-item ${canZoomOut ? '' : 'disabled'}`} onClick={zoomOut} data-qa-hook="menu.zoomOut">
                  Zoom Out
                  <span className="shortcut">{ZOOMMINUS_STRING}</span>
                </li>
                <li className="menu-item" onClick={resetZoom} data-qa-hook="menu.resetZoom">
                  Reset Zoom
                  <span className="shortcut">{METAKEY_STRING}0</span>
                </li>
                <li className="menu-item" onClick={resetCamera} data-qa-hook="menu.recenterCamera">
                  Recenter Camera
                  <span className="shortcut">{METAKEY_STRING}1</span>
                </li>
                <li className="menu-divider" />
                <li className={`menu-item ${isLayout ? '' : 'hidden'}`} onClick={toggleOutline} data-qa-hook="menu.toggleAssetOutlines">
                  Toggle Asset Outlines
                  <span className="shortcut">{METAKEY_STRING}\</span>
                </li>
                <li
                  className="menu-item"
                  onClick={() => onSubmenuClick('4.1')}
                  onMouseOver={() => showInnerSubmenu('4.1')}
                  onMouseLeave={this.onSubmenuMenuHide} data-qa-hook="menu.showHidePanels">
                  Show / Hide Panels
                  <span className="shortcut">
                    <small>{RIGHT_ARROW_STRING}</small>
                  </span>
                  <ul
                    className={
                      this.selectedInnerMenuIndex == '4.1' ? 'sub-menu show-menu' : 'sub-menu '
                    }
                    onMouseLeave={this.onSubmenuMenuHide}>
                    <li className={'menu-item'} onClick={toggleWidgets} data-qa-hook="menu.widgetsPanel">
                      <div className="toggle-check">
                        <span className="checkmark">{collapseState.panels.Widgets ? '' : '✓'}</span>
                        <span className="label">Widgets</span>
                      </div>
                    </li>
                    <li className={'menu-item'} onClick={toggleVariables} data-qa-hook="menu.variablesPanel">
                      <div className="toggle-check">
                        <span className="checkmark">
                          {collapseState.panels.Variables ? '' : '✓'}
                        </span>
                        <span className="label">Variables</span>
                      </div>
                    </li>
                    <li className={'menu-item'} onClick={toggleMedia} data-qa-hook="menu.mediaPanel">
                      <div className="toggle-check">
                        <span className="checkmark">{collapseState.panels.Media ? '' : '✓'}</span>
                        <span className="label">Media</span>
                      </div>
                    </li>
                    <li className={'menu-item'} onClick={toggleActions} data-qa-hook="menu.ActionsPanel">
                      <div className="toggle-check">
                        <span className="checkmark">{collapseState.panels.Actions ? '' : '✓'}</span>
                        <span className="label">Actions</span>
                      </div>
                    </li>
                    <li className={'menu-item'} onClick={toggleScripting} data-qa-hook="menu.scriptingPanel">
                      <div className="toggle-check">
                        <span className="checkmark">
                          {collapseState.panels.Scripting ? '' : '✓'}
                        </span>
                        <span className="label">Scripting</span>
                      </div>
                    </li>
                    <li className={'menu-item'} onClick={toggleHierarchy} data-qa-hook="menu.hierarchyPanel">
                      <div className="toggle-check">
                        <span className="checkmark">
                          {collapseState.panels.Hierarchy ? '' : '✓'}
                        </span>
                        <span className="label">Hierarchy</span>
                      </div>
                    </li>
                    <li className={'menu-item'} onClick={toggleDetails} data-qa-hook="menu.detailsPanel">
                      <div className="toggle-check">
                        <span className="checkmark">{collapseState.panels.Details ? '' : '✓'}</span>
                        <span className="label">Details</span>
                      </div>
                    </li>
                  </ul>
                </li>
                <li className={`menu-divider ${isLayout ? '' : 'hidden'}`} />
                <li className={`menu-item ${isLayout ? '' : 'hidden'}`} onClick={togglePlay} data-qa-hook="menu.playPause">
                  Play/Pause
                  <span className="shortcut">Space</span>
                </li>
              </ul>
            </li>
            <li
              className={this.selectedIndex == '5' ? 'menu-item active' : 'menu-item '}
              onClick={() => onSubmenuClick('5')}
              onMouseOver={() => showSubmenu('5')}
              onMouseLeave={this.onMouseLeaveHandler} data-qa-hook="menu.preview">
              Preview
              <ul className={this.selectedIndex == '5' ? 'sub-menu show-menu' : 'sub-menu '}>
                <li className={`menu-item`} onClick={preview} data-qa-hook="menu.previewInEplayer">
                  Preview in ePlayer
                  <span className="shortcut">{METAKEY_STRING}P</span>
                </li>
                {canPreviewFromSequence && (
                  <li className={`menu-item`} onClick={previewFromSequence} data-qa-hook="menu.previewFromSequence">
                    Preview from Sequence
                    <span className="shortcut">
                      {SHIFTKEY_STRING}
                      {METAKEY_STRING}P
                    </span>
                  </li>
                )}
              </ul>
            </li>
            <li
              className={this.selectedIndex == '6' ? 'menu-item active' : 'menu-item '}
              onClick={() => onSubmenuClick('6')}
              onMouseOver={() => showSubmenu('6')}
              onMouseLeave={this.onMouseLeaveHandler} data-qa-hook="menu.help">
              Help
              <ul className={this.selectedIndex == '6' ? 'sub-menu show-menu' : 'sub-menu '}>
                <li className="menu-item">
                  <a href="https://confluence.ce.wolterskluwer.io/display/JELLYREQ/Jellyfish+Documentation" target="_blank" data-qa-hook="menu.jellyfishUserGuide">
                    Jellyfish User Guide
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href="https://jira.ce.wolterskluwer.io/secure/RapidBoard.jspa?rapidView=612&projectKey=ED&view=planning.nodetail&issueLimit=100"
                    target="_blank" data-qa-hook="menu.requestSupport">
                    Request Support
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <div className="edition">
            <div className="edition-name">
              <i className="edition-icon" />
              <Input
                type="text"
                placeholder="Edition Name"
                value={app.edition.name}
                onSave={updateName}
              />
            </div>
            <div className="save-state">
              <button type="button" className="save-button" onClick={save} data-qa-hook="button.ToolbarSave">
                <i className={`indicator ${saveState.status}`} />
                <i className="sprite toolbar save" />
                {saveMessage}
              </button>
            </div>|
            <button
              className={`widgets-button ${collapseState.panels.Widgets ? '' : 'active'}`}
              onClick={toggleWidgets} data-qa-hook="button.widgetsToggle">
              Widgets <i className="widgets-menu" />
            </button>
          </div>
        </div>
        {getNewVersionModal()}
        {getRevertModal()}
        {getRouteEMModal()}
      </div>
    )
  }
}

decorate(ToolbarComponent, {
  newVersionModal: observable,
  revertModal: observable,
  selectedIndex: observable,
  selectedInnerMenuIndex: observable,
  isShowMenu: observable,
  isShowMenuClick: observable,
  routeEMModal: observable,
  exportModal: observable,
  isStructure: computed,
  isLayout: computed,
  canDelete: computed,
  canDeselect: computed,
  canCopy: computed,
  canDuplicate: computed,
  canZoomIn: computed,
  canZoomOut: computed,
  canUndo: computed,
  canRedo: computed,
  canPreviewFromSequence: computed,
  hasAssetSelection: computed,
  handleHotkeys: action.bound,
  disposeHotkeys: action.bound,
})

const Toolbar = observer(ToolbarComponent)

export default Toolbar
