import React from 'react'
import ModalWrapper from '../../containers/ModalWrapper'
import NewVersion from '../NewVersion'

export default class NewVersionModal extends React.Component {
  constructor(props) {
    super(props)
  }
  saveVersion = (comments, versionType, assetSetId) => {
    this.props.saveVersion(comments, versionType, assetSetId, this.props.hideModal)
  }
  render() {
    return (
      <div>
        <ModalWrapper
          title="Create New Version"
          hideModal={this.props.hideModal}
          width="700px"
          height="auto"
          modaltype="popup">
          <NewVersion
            saveVersion={this.saveVersion}
            version={this.props.version}
            app={this.props.app}
          />
        </ModalWrapper>
      </div>
    )
  }
}
