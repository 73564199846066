import { types, getParent, getSnapshot } from 'mobx-state-tree'
import { AudioRow } from 'eplayer-core'
import Expandable from './Expandable'
import RawAudio from './Media/RawAudio'
import { setProp } from './utils'

// TODO: is this even used any more?
const AudioRowCurrent = types.model('AudioRowCurrent', {
	active: types.boolean,
	progress: types.number,
})

// TODO: is this even used any more?
const AudioRowScriptState = types.model('AudioRowScriptState', {
	expanded: types.boolean,
	showInfo: types.boolean,
	showAlerts: types.boolean,
})

const AudioRowVar = types.model('AudioRowVar', {
	value: types.string,
	build: types.string,
	style: types.string,
})

const AudioRowTimelineState = types
	.model('AudioRowTimelineState', {
		muted: types.boolean,
		showText: types.boolean,
		locked: types.boolean,
	})
	.actions(self => {
		function toggleProp(prop) {
			self[prop] = !self[prop]
		}
		return {
			toggleProp,
		}
	})
  
const AudioRowDetailsState = types.model('AudioRowDetailsState', {
	properties: Expandable,
	media: Expandable,
})

const model = types
	.model('AudioRow', {
		Type: types.literal('AudioRow'),
		objectId: types.identifier,
		name: types.string,
		// MediaOptions
		audioMedia: types.maybeNull(types.reference(RawAudio)),
		startTime: types.number,
		variables: types.map(AudioRowVar),
		current: AudioRowCurrent,
		scriptState: AudioRowScriptState,
		timelineState: AudioRowTimelineState,
		detailsState: AudioRowDetailsState,
	})
	.views(self => ({
		get parent() {
			return getParent(self, 2)
		},
	}))
	.actions(self => {
		function updateProp(prop, value) {
			const r = self
			setProp(prop, value, r)
		}
		function setAudioMedia(media) {
			const r = self
			r.audioMedia = media
		}
		function setTemplateVariable(key, value) {
			const r = self
			r.variables[key] = value
		}
		function duplicate(sequence) {
			const r = self
			sequence = sequence || r.parent
			sequence.addAudioRow(clone())
		}
		function clone() {
			return AudioRow.clone(getSnapshot(self))
		}
		return {
			updateProp,
			setAudioMedia,
			setTemplateVariable,
			duplicate,
			clone,
		}
	})
export default model
