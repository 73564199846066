import { types } from 'mobx-state-tree'
import { Option } from 'eplayer-core'
import Expression from './Expression'
import RawText from './RawText'
const model = types
	.model('TextOption', {
		Type: types.literal('TextOption'),
		objectId: types.identifier,
		condition: Expression,
		value: RawText,
	})
	.actions(self => {
		function updateCondition(value) {
			const o = self
			o.condition.string = value
			// Re-compile the expression (some of this should probably just be computed)
			Option.Expression.compile(o.condition)
		}
		function updateTextValue(value) {
			const o = self
			o.value.updateValue(value)
		}
		function updateAltValue(value) {
			const o = self
			o.value.updateAlt(value)
		}
		return {
			updateCondition,
			updateTextValue,
			updateAltValue,
		}
	})
export default model
