import React from 'react'
import ModalWrapper from '../../containers/ModalWrapper'

export default class WarningModal extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div>
        <ModalWrapper
          title="Warning"
          hideModal={this.props.cancel}
          width="600px"
          height="auto"
          modaltype="popup">
          <div className="warning-wrap">
            <label>{this.props.warning}</label>
            <label>Continue?</label>
            <div className="buttons">
              <button className="left-option" onClick={this.props.continue}>
                Ok
              </button>
              <button className="right-option" onClick={this.props.cancel}>
                Cancel
              </button>
            </div>
          </div>
        </ModalWrapper>
      </div>
    )
  }
}
