import React from 'react'
import { observable } from 'mobx'
import { decorate } from 'mobx'
import { observer } from 'mobx-react'
import { MENU_HIDE_DELAY } from '../../constants'

class ScriptMenuComponent extends React.Component {
  constructor(props) {
    super(props)
    this.menuShown = false
    this.styles = { top: 0, bottom: 'auto' }
    this.timeout = null
  }
  toggleMenu = () => {
    this.menuShown = !this.menuShown
    let objectTop = this.refs.menu.getBoundingClientRect().top
    let windowHeight = window.innerHeight
    this.styles =
      objectTop / windowHeight > 0.65 ? { top: 'auto', bottom: 0 } : { top: 0, bottom: 'auto' }
  }
  setDelayedHide = () => {
    this.timeout = window.setTimeout(() => (this.menuShown = false), MENU_HIDE_DELAY)
  }
  cancelDelayedHide = () => {
    if (this.timeout) window.clearTimeout(this.timeout)
  }
  render() {
    const { children } = this.props
    const menuProps = {
      ref: 'menu',
      className: `script-menu ${this.menuShown ? 'shown' : ''}`,
      onMouseUp: this.toggleMenu,
      onMouseLeave: this.setDelayedHide,
      onMouseEnter: this.cancelDelayedHide
    }
    return (
      <div {...menuProps}>
        <div className={`popover ${this.menuShown ? 'shown' : ''}`} style={this.styles}>
          {children}
        </div>
      </div>
    )
  }
}

decorate(ScriptMenuComponent, {
  menuShown: observable,
  styles: observable,
})

const ScriptMenu = observer(ScriptMenuComponent)

export default ScriptMenu
