import { observable, action, computed, decorate } from 'mobx'

const perf = performance

export default class Clock {
  constructor() {
    this.last = 0
    this.next = perf.now()
  }
  get dT() {
    return this.next - this.last
  }
  tick() {
    this.last = this.next
    this.next = perf.now()
  }
  pause() {
    this.last = this.next = perf.now()
  }
}

decorate(Clock, {
  last: observable,
  next: observable,
  dT: computed,
  tick: action.bound,
  pausticke: action.bound,
})
