import React from 'react'
import { observer } from 'mobx-react'
import ContextMenu from '../../components/ContextMenu'
import { TIMELINE_EASING_OPTIONS } from '../../constants'

const KEYFRAME_CONTEXT_MENU_OFFSET = 10

const Keyframe = observer(class Keyframe extends React.Component {
  dragStartX = 0
  contextMenu = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const { keyframe, isLocked, timelineState } = this.props
    if (isLocked) return
    timelineState.easingContextMenu.show(e, keyframe)
  }
  mouseDown = (e) => {
    e.stopPropagation()
    // TODO: EAT-2547: should we check `e.button` here so that it doesn't conflict with `contextMenu`?
    const { keyframe, isLocked, isSelected, selectKeyframe, timelineState } = this.props
    if (isLocked) return
    if (!isSelected) selectKeyframe(keyframe, e.shiftKey)
    // `ownerDocument` because element may not be in same window as code
    const NODE = e.target.ownerDocument //document.getElementById("timeline")
    if(e.shiftKey){
      this.keyframesSorted = this.getKeyFrames()
    }
    NODE.addEventListener('mousemove', this.mouseMove, false)
    NODE.addEventListener('mouseup', this.mouseUp, false)
    this.dragStartX = e.clientX
    timelineState.dragTransaction.start(keyframe.objectId)
    this.oldX = e.pageX
  }

  mouseMove = (e) => {
    const { frameWidth, timelineState, keyframe } = this.props
    const diff = e.clientX - this.dragStartX + frameWidth / 2
    const frameDiff = Math.floor(diff / frameWidth)
    if (e.shiftKey) {
      const frameRange = this.oldX < e.pageX ? 10 : -10
      const frameValue = keyframe.frame + frameDiff
      const range = [frameValue, frameValue + frameRange].sort((a, b) => a - b)
      if (this.keyframesSorted) {
        const filteredFrames = this.keyframesSorted.filter((s) => s >= range[0] && s <= range[1])
        if (filteredFrames.length > 0) {
          const frameToSet =
            this.oldX < e.pageX ? filteredFrames[0] : filteredFrames[filteredFrames.length - 1]
          if (Math.abs(frameToSet - keyframe.frame) <= 10) {
            timelineState.dragTransaction.move(frameToSet - keyframe.frame)
          }
        }
      }
    } else {
      timelineState.dragTransaction.move(frameDiff)
    }
    this.oldX = e.pageX
  }

  mouseUp = (e) => {
    const { timelineState } = this.props
    // `ownerDocument` because element may not be in same window as code
    const NODE = e.target.ownerDocument //document.getElementById("timeline")
    NODE.removeEventListener('mousemove', this.mouseMove, false)
    NODE.removeEventListener('mouseup', this.mouseUp, false)
    timelineState.dragTransaction.end()
  }

  getKeyFrames() {
    const { stage } = this.props
    var keyframes = []

    for (let asset of stage.children.slice(0).reverse()) {
      const propList = asset.timelineState.properties || []
      for (let property of propList) {
        let keyframeArray = asset[property + 'Keyframes'] || []
        for (let keyframe of keyframeArray) {
          keyframes.push(keyframe.frame)
        }
      }
    }

    return keyframes.sort((a, b) => a - b)
  }

  render() {
    const { x, frameWidth, isSelected, isDragging, keyframe, timelineState } = this.props
    const { easingContextMenu } = timelineState

    // diamond = 14x14
    // slash = 10/10
    // hit marker = 14/20
    // center our elements in the middle of the frame
    const xDiamond = x + frameWidth / 2 - 7
    const xSquare = x + frameWidth / 2 - 5
    const xHit = x + frameWidth / 2 - 7
    const xCircle = x + frameWidth / 2

    const isStep = keyframe.fn === 'step'
    const isLinear = keyframe.fn === 'linear'
    const isOther = !isStep && !isLinear

    let keyframeRectClass = 'keyframe'
    if (isSelected) keyframeRectClass += ' selected'
    if (isDragging) keyframeRectClass += ' dragging'
    let hitMarkerClass = 'keyframe-hit-area'
    if (isSelected) hitMarkerClass += ' selected'
    if (isDragging) hitMarkerClass += ' dragging'

    const color = isSelected ? '#03FF94' : '#FFFFFF'

    const showEasingContextMenu =
      easingContextMenu.visible && easingContextMenu.keyframe === keyframe
    const getEasingContextMenu = showEasingContextMenu ? (
      <ContextMenu
        className={`contextMenu-wrap`}
        root={timelineState.timelineEl}
        hide={easingContextMenu.hide}
        offset={KEYFRAME_CONTEXT_MENU_OFFSET}
        clickX={easingContextMenu.clickX}
        clickY={easingContextMenu.clickY}>
        <ul className="contextMenu">
          {TIMELINE_EASING_OPTIONS.map((easeOption) => (
            <li
              key={easeOption.value}
              className={'contextMenu-option'}
              onMouseDown={() => easingContextMenu.setEasing(easeOption.value)}>
              <div className="toggle-check">
                <span className="checkmark">{keyframe.fn === easeOption.value ? '✓' : ''}</span>
                <span className="label">{easeOption.label}</span>
              </div>
            </li>
          ))}
        </ul>
      </ContextMenu>
    ) : null

    // Default
    return (
      <React.Fragment>
        <g onMouseDown={this.mouseDown} onContextMenu={this.contextMenu}>
          {isStep ? (
            <rect
              x={0}
              y={0}
              width={10}
              height={10}
              fill={color}
              className={keyframeRectClass}
              stroke="#212528"
              style={{ transform: `translate(${xSquare}px, 5px)`, pointerEvents: 'none' }}
            />
          ) : (
            <polygon
              points="0,7 7,14 14,7 7,0"
              fill={color}
              className={keyframeRectClass}
              stroke="#212528"
              style={{ transform: `translate(${xDiamond}px, 3px)`, pointerEvents: 'none' }}
            />
          )}
          <rect
            x={0}
            y={0}
            width={14}
            height={20}
            fill="#FFFFFF"
            fillOpacity="0"
            stroke="none"
            className={hitMarkerClass}
            style={{ transform: `translate(${xHit}px, 0)` }}
          />
          {isOther && (
            <circle
              r={3}
              fill="#1c1c1c"
              style={{ transform: `translate(${xCircle}px, 10px)`, pointerEvents: 'none' }}
            />
          )}
        </g>
        {getEasingContextMenu}
      </React.Fragment>
    )
  }
})

export default Keyframe
