import React from 'react'
import { observer } from 'mobx-react'

const ContentEditable = observer(class ContentEditable extends React.Component {
  constructor(props) {
    super(props)
    this.htmlEl = null
    this.lastHtml = null
    this.emitChange = this.emitChange.bind(this)
    this.focus = this.focus.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  render() {
    var { tagName, html, onChange, onSave, ...props } = this.props
    return React.createElement(
      tagName || 'div',
      {
        ...props,
        ref: (el) => (this.htmlEl = el),
        onKeyDown: this.emitChange,
        onBlur: this.onBlur,
        className: `content-editable ${this.props.disabled ? 'disabled' : 'enabled'}`,
        contentEditable: !this.props.disabled,
        dangerouslySetInnerHTML: { __html: html },
        placeholder: this.props.placeholder
      },
      this.props.children
    )
  }

  shouldComponentUpdate(nextProps) {
    // We need not rerender if the change of props simply reflects the user's
    // edits. Rerendering in this case would make the cursor/caret jump.
    return (
      // Rerender if there is no element yet... (somehow?)
      !this.htmlEl ||
      // ...or if html really changed... (programmatically, not by user edit)
      (nextProps.html !== this.htmlEl.textContent && nextProps.html !== this.props.html) ||
      // ...or if editing is enabled or disabled.
      this.props.disabled !== nextProps.disabled
    )
  }

  componentDidMount() {
    if (!this.props.disabled) {
      this.focus()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.htmlEl && this.props.html !== this.htmlEl.textContent) {
      // Perhaps React (whose VDOM gets outdated because we often prevent
      // rerendering) did not update the DOM. So we update it manually now.
      this.htmlEl.textContent = this.props.html
    }
    if (!this.props.disabled && prevProps.disabled) {
      this.focus()
    }
  }

  emitChange(evt) {
    evt.stopPropagation()
    if (!this.htmlEl) return
    var html = this.htmlEl.textContent
    // on Enter or Tab key
    if (evt.keyCode === 9 || evt.keyCode === 13) {
      if (this.props.onSave && html !== this.lastHtml) {
        // evt.target = { value: html };
        this.props.onSave(html)
      }
    }
    if (this.props.onKeyDown) this.props.onKeyDown(evt)
    this.lastHtml = html
  }

  focus(evt) {
    if (!this.htmlEl) return
    this.htmlEl.focus()
    // // Puts cursor at the end of the text value
    // let range = document.createRange()
    // range.selectNodeContents(this.htmlEl)
    // range.collapse(false)
    // let selection = window.getSelection()
    // selection.removeAllRanges()
    // selection.addRange(range)
  }

  onBlur(evt) {
    this.emitChange(evt)
    var html = this.htmlEl.textContent
    if (this.props.onBlur) this.props.onBlur(html)
  }
})

export default ContentEditable
