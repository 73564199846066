import { observable, computed, action, autorun, extendObservable, toJS } from 'mobx'
import { getParent } from "mobx-state-tree"
import { getSnapshot } from "mobx-state-tree"
import { AABB, Vec3, Mat4, Asset, Keyframe as KeyframeFactory } from 'eplayer-core'
import { utils  } from "eplayer-core"
import { VideoRow as VideoRowFactory } from "eplayer-core"
import { AudioRow as AudioRowFactory } from "eplayer-core"
import Sprite from '../../../models/Asset/Sprite'
import Video from '../../../models/Asset/Video'
import VideoRow from '../../../models/VideoRow'
import AudioRow from '../../../models/AudioRow'
import * as AssetModels from '../../../models/Asset'
import Embed from '../../../models/Asset/Embed'
import { add, subtract, set, negate, copy as copyVec3, transformMat4 } from 'gl-vec3'
import {
  identity,
  invert,
  scale,
  translate,
  translateX,
  translateY,
  rotateX,
  rotateY,
  rotateZ,
  multiply,
  copy as copyMat4
} from 'gl-mat4'
import { radiansBetween, clone } from '../../../utils'
import {
  LAYOUT_MOUSE_MODES as MODE,
  MOUSE_BUTTON,
  CONTROL_SIZE,
  VIEWPORT_MINHEIGHT
} from '../../../constants'

const msToFrame = utils.msToFrame

// Cached refs
let mouseLocalNow = Vec3(0, 0, 0)
let mouseLocalLast = Vec3(0, 0, 0)
let mouseLocalDown = Vec3(0, 0, 0)
let mouseLocalDelta = Vec3(0, 0, 0)
let cachedScale = Vec3(1, 1, 1)
let cachedScaleKfs = []

function getCollisions(collisions, aabb, renderable) {
  const isSingleSelect = aabb.ul[0] === aabb.lr[0] && aabb.ul[1] === aabb.lr[1]
  const isActive = renderable.active
  const isLocked = renderable.baseAsset.timelineState.locked
  const isSelectable = ("Video" !== renderable.Type) && (isActive && !isLocked)
  const thisAABB = renderable.aabb
  const children = renderable.children
  const childrenEnd = children.length
  const doesCollide = isSelectable && thisAABB && AABB.collidesWithAABB(aabb, thisAABB)
  if (doesCollide) {
    // Note: storing the asset instead of the renderable (because selections.renderables is READ-ONLY)
    collisions.push(renderable.baseAsset)
    // If multi-select, we stop walking the tree branch at the top-most selectable node (EAT-1979)
    if (isSingleSelect) {
      // If single-select, continue on down the tree
      for (let i = 0, child; i < childrenEnd; i++) {
        child = children[i]
        getCollisions(collisions, aabb, child)
      }
    }
  } else {
    // If no collisions, continue on down the tree
    for (let i = 0, child; i < childrenEnd; i++) {
      child = children[i]
      getCollisions(collisions, aabb, child)
    }
  }
}

function mouseWithin(mouse, renderable) {
  const isActive = renderable.active
  const isLocked = renderable.baseAsset.timelineState.locked
  const isSelectable = isActive && !isLocked
  const thisAABB = renderable.aabb
  const children = renderable.children
  const childrenEnd = children.length

  const doesCollide =
    isSelectable &&
    thisAABB &&
    AABB.pointCollidesWithAABB(mouse, thisAABB)

  let target = doesCollide ?
    renderable.baseAsset :
    null

  if (!target) {
    // Continue on down the tree
    for (let i = 0, child; i < childrenEnd; i++) {
      child = children[i]
      target = mouseWithin(mouse, child)
      
      if (target) {
        break
      }
    }
  }

  return target
}

export default function ViewportState(storage, history, camera, player, selections, renderable, global) {
  return observable({
    camera: camera,
    player: player,
    selections: selections,
    renderable: renderable,
    el: null,
    size: {
      width: 0,
      height: 0
    },
    position: {
      left: 0,
      top: 0
    },
    mouse: {
      downAt: Vec3(0, 0, 0),
      nowAt: Vec3(0, 0, 0),
      lastAt: Vec3(0, 0, 0),
      get moveDelta() {
        let delta = Vec3(0, 0, 0)
        subtract(delta, this.nowAt, this.lastAt)
        return delta
      },
      get dragDelta() {
        let delta = Vec3(0, 0, 0)
        subtract(delta, this.nowAt, this.downAt)
        return delta
      },
      get worldDownAt() {
        return transformMat4(Vec3(0, 0, 0), this.downAt, camera.inverseViewMatrix)
      },
      get worldNowAt() {
        return transformMat4(Vec3(0, 0, 0), this.nowAt, camera.inverseViewMatrix)
      },
      get worldLastAt() {
        return transformMat4(Vec3(0, 0, 0), this.lastAt, camera.inverseViewMatrix)
      }
    },
    cameraInitialized: false,
    readyToPan: false,
    mode: MODE.INACTIVE,
    activeAABB: '',
    outlineAssets: storage.has('outlineAssets')
      ? storage.get('outlineAssets', 'boolean')
      : false,
    // Computeds
    get isTranslating() {
      return this.mode === MODE.TRANSLATING
    },
    get isRotating() {
      return this.mode === MODE.ROTATING
    },
    get isScaling() {
      return this.mode === MODE.SCALING
    },
    get isSelecting() {
      return this.mode === MODE.SELECTING
    },
    get isDragSelecting() {
      return this.mode === MODE.DRAG_SELECTING
    },
    get isActive() {
      return this.mode === MODE.ACTIVE
    },
    get isInactive() {
      return this.mode === MODE.INACTIVE
    },
    get isPanning() {
      return this.mode === MODE.PANNING
    },
    get relControlSize() {
      return CONTROL_SIZE / this.camera.zoom
    },
    get dragRect() {
      let rect = AABB('dragRect')
      AABB.fitTo(rect, this.mouse.worldDownAt, this.mouse.worldNowAt)
      return rect
    },
    get selectionRects() {
      let rects = []
      this.selections.renderables.forEach((selection) => {
        if (selection.aabb) rects.push(selection.aabb)
      })
      return rects
    },
    get selectionRect() {
      // This and the other computeds that use it are currently only used for single-selected Assets
      if (this.selectionRects.length !== 1) return AABB('selectionRect')
      return this.selectionRects[0]
    },
    get selectionCenter() {
      return Vec3(
        this.selectionRect.ul[0] + this.selectionWidth / 2,
        this.selectionRect.ul[1] + this.selectionHeight / 2,
        0
      )
    },
    get selectionWidth() {
      return this.selectionRect.lr[0] - this.selectionRect.ul[0]
    },
    get selectionHeight() {
      return this.selectionRect.lr[1] - this.selectionRect.ul[1]
    },
    get selectionUpperLeft() {
      let rect = AABB('selectionUpperLeft')
      AABB.place(
        rect,
        this.relControlSize,
        this.relControlSize,
        this.selectionRect.ul[0],
        this.selectionRect.ul[1]
      )
      return rect
    },
    get selectionUpperRight() {
      let rect = AABB('selectionUpperRight')
      AABB.place(
        rect,
        this.relControlSize,
        this.relControlSize,
        this.selectionRect.lr[0],
        this.selectionRect.ul[1]
      )
      return rect
    },
    get selectionLowerRight() {
      let rect = AABB('selectionLowerRight')
      AABB.place(
        rect,
        this.relControlSize,
        this.relControlSize,
        this.selectionRect.lr[0],
        this.selectionRect.lr[1]
      )
      return rect
    },
    get selectionLowerLeft() {
      let rect = AABB('selectionLowerLeft')
      AABB.place(
        rect,
        this.relControlSize,
        this.relControlSize,
        this.selectionRect.ul[0],
        this.selectionRect.lr[1]
      )
      return rect
    },
    get selectionTop() {
      let rect = AABB('selectionTop')
      const w = this.selectionWidth - this.relControlSize
      const cx = this.selectionRect.ul[0] + this.selectionWidth / 2
      AABB.place(rect, w, this.relControlSize, cx, this.selectionRect.ul[1])
      return rect
    },
    get selectionBottom() {
      let rect = AABB('selectionBottom')
      const w = this.selectionWidth - this.relControlSize
      const cx = this.selectionRect.lr[0] - this.selectionWidth / 2
      AABB.place(rect, w, this.relControlSize, cx, this.selectionRect.lr[1])
      return rect
    },
    get selectionLeft() {
      let rect = AABB('selectionLeft')
      const h = this.selectionHeight - this.relControlSize
      const cy = this.selectionRect.ul[1] + this.selectionHeight / 2
      AABB.place(rect, this.relControlSize, h, this.selectionRect.ul[0], cy)
      return rect
    },
    get selectionRight() {
      let rect = AABB('selectionRight')
      const h = this.selectionHeight - this.relControlSize
      const cy = this.selectionRect.lr[1] - this.selectionHeight / 2
      AABB.place(rect, this.relControlSize, h, this.selectionRect.lr[0], cy)
      return rect
    },
    // Rotation anchor
    get selectionRotation() {
      let rect = AABB('selectionRotation')
      AABB.place(
        rect,
        this.relControlSize,
        this.relControlSize,
        this.rotationAnchorCenter[0],
        this.rotationAnchorCenter[1]
      )
      return rect
    },
    get rotationAnchorCenter() {
      let c = Vec3(0, 0, 0)
      const selections = this.selections.renderables
      if (!selections.length) return c
      // When rotating, rotation aabb follows mouse
      if (this.isRotating) {
        copyVec3(c, this.mouse.worldNowAt)
      } else {
        const wm = selections[0].worldMatrix
        set(c, selections[0].width / 2, -0.3 * selections[0].height, 0)
        transformMat4(c, c, wm)
      }
      return c
    },
    // Actions
    readyPanning: function() {
      this.readyToPan = true
    },
    donePanning: function() {
      this.readyToPan = false
    },
    toggleAssetOutline: function() {
      this.outlineAssets = !this.outlineAssets
      storage.set('outlineAssets', 'boolean', this.outlineAssets)
    },
    setViewportEl: function(el) {
      this.el = el
    },
    setViewportSize: function(w, h) {
      this.size.width = w
      this.size.height = h
    },
    setViewportPosition: function(l, t) {
      this.position.left = l
      this.position.top = t
    },
    resize: function() {
      const { el, setViewportSize, setViewportPosition, resetCameraCenter, resetCameraZoom } = this
      // Access necessary DOM props and set state
      if (!el) return
      const w = el.clientWidth
      const h = Math.max(el.clientHeight, VIEWPORT_MINHEIGHT) // TODO: we can probably just handle this in the CSS
      const bcr = el.getBoundingClientRect()
      setViewportSize(w, h)
      setViewportPosition(bcr.left, bcr.top)
      // zoom and center camera against stage/viewport (just once on initialization)
      if (!this.cameraInitialized) {
        this.cameraInitialized = true
        resetCameraCenter()
        resetCameraZoom()
      }
    },
    resetCameraCenter: function() {
      const { camera, size } = this
      const vcx = size.width / 2 - camera.viewport[0] / 2
      const vcy = size.height / 2 - camera.viewport[1] / 2
      camera.moveTo(vcx, vcy)
    },
    resetCameraZoom: function() {
      const { camera, size } = this
      // Figure out which axis we should scale from
      const vz = Math.min(size.width / camera.viewport[0], size.height / camera.viewport[1])
      camera.zoomTo(vz * 0.8)
    },
    setMode: function(mode) {
      this.mode = mode
    },
    viewportActive: function() {
      this.mode = MODE.ACTIVE
    },
    viewportInactive: function() {
      this.mode = MODE.INACTIVE
    },
    viewportTranslating: function() {
      this.mode = MODE.TRANSLATING
    },
    viewportScaling: function() {
      this.mode = MODE.SCALING
    },
    viewportRotating: function() {
      this.mode = MODE.ROTATING
    },
    viewportPanning: function() {
      this.mode = MODE.PANNING
    },
    setActiveAABB: function(string) {
      this.activeAABB = string
    },
    onDrop: function(evt, assets) {
      const { el, position, mouse, renderable, selections } = this
      const { player } = this
      const { current: stage } = renderable

      if (!el) return

      set(mouse.nowAt, evt.clientX - position.left, evt.clientY - position.top, 0)

      const worldNowAt = mouse.worldNowAt
      const isMedia = ['RawHTML', 'RawAudio', 'RawVideo', 'RawSprite'].includes(assets[0].Type)
      if (!isMedia) {
        for (let i = 0, asset; i < assets.length; i++) {
        asset = assets[i]
          // Only allow ListItem assets to be dropped *inside* List assets.
        if (asset.Type === 'ListItem') {
          const target = mouseWithin(mouse.worldNowAt, stage)
          if (target && target.Type === 'List') {
            target.addChild(asset)
            selections.setAssetSelection(asset, false)
          }
          continue
        } else if (asset.Type.match("Template")) {
          const t = asset
          const src = getSnapshot(asset)
          const destType = src.Type.split("Template")[0]
          const destProps = Asset[destType].withDefaults(src.properties)

          asset = AssetModels[destType].create(destProps)

          t.link(asset)
        }
        // Set Position and add
        asset.updateVec('position', worldNowAt)
        stage.asset.addChild(asset)
        selections.setAssetSelection(asset, false)
        }
      } else {
        const unloaded = assets.filter((a) => a.src && player.cache.getAsset(a.src) === undefined)
        unloaded.forEach(a => player.cache.addResource(a))
        global.setLoading(true)
        player.cache.load(...unloaded.map(a => a.src))
          .then(() => {
            for (let i = 0, asset; i < assets.length; i++) {
              asset = assets[i]
              // Handle Raw Media drop from Media Panel
              if ('RawSprite' === asset.Type) {
                asset = Sprite.create(
                  Asset.Sprite.withDefaults({
                    name: asset.fileName,
                    spriteMedia: asset,
                    width: asset.width / asset.columnCount,
                    height: asset.height / asset.rowCount
                  })
                )
              }
              else if ('RawVideo' === asset.Type) {
                const stageHasVideo = stage.children.length > 0 && "Video" === stage.children[0].Type
                const canAddVideoAsset = !stageHasVideo
                const vRow = VideoRow.create(VideoRowFactory())
                vRow.setVideoMedia(asset)

                if (player.activeSequence.videoRows.length) {
                  const vRows = player.activeSequence.videoRows
                  const prevRow = vRows[vRows.length - 1]
                  const startTime = prevRow.startTime + Math.ceil(msToFrame(player.fps, prevRow.videoMedia.durationMs))

                  vRow.updateProp("startTime", startTime)
                }

                if (canAddVideoAsset) {
                  const vAsset = Video.create(Asset.Video())
                  stage.asset.addChild(vAsset)
                }

                player.activeSequence.addVideoRow(vRow)
                continue
              }
              else if ('RawHTML' === asset.Type) {
                const rawMedia = asset
                asset = Embed.create(
                  Asset.Embed.withDefaults({
                    name: rawMedia.fileName,
                    canvasMedia: asset,
                    width: rawMedia.animationWidth,
                    height: rawMedia.animationHeight
                  })
                )
              } else if ('RawAudio' === asset.Type) {
                const aRow = AudioRow.create(AudioRowFactory())
                aRow.setAudioMedia(asset)
                if (player.activeSequence.audioRows.length) {
                  const aRows = player.activeSequence.audioRows
                  const prevRow = aRows[aRows.length - 1]
                  const startTime = prevRow.startTime + Math.ceil(msToFrame(player.fps, prevRow.audioMedia.durationMs))
                  aRow.updateProp("startTime", startTime)
                }
                player.activeSequence.addAudioRow(aRow)
                continue
              }

              // Set Position and add
              asset.updateVec('position', worldNowAt)
              stage.asset.addChild(asset)
              selections.setAssetSelection(asset, false)
            }
            global.setLoading(false)
          })
      }
    },
    onMouseDown: function(evt) {
      evt.stopPropagation()
      const {
        camera,
        position,
        mouse,
        mode,
        renderable,
        selections,
        selectionRects,
        selectionRect,
        selectionUpperLeft,
        selectionUpperRight,
        selectionLowerRight,
        selectionLowerLeft,
        selectionTop,
        selectionRight,
        selectionBottom,
        selectionLeft,
        selectionRotation
      } = this
      const { current: stage } = renderable

      copyVec3(mouse.lastAt, mouse.nowAt)
      set(mouse.nowAt, evt.clientX - position.left, evt.clientY - position.top, 0)
      copyVec3(mouse.downAt, mouse.nowAt)

      if (MOUSE_BUTTON.RIGHT === evt.button) {
        this.viewportPanning()
      } else if (MOUSE_BUTTON.LEFT === evt.button) {
        let cd = []
        getCollisions(cd, this.dragRect, stage) // Note: dragRect is dereferenced here because it's computed based off the mouse nowAt/downAt set above
        // if (this.isActive && selections.renderables.length === 1) {
        if (this.isActive && cd.length === 1) {
          const r = selections.renderables[0]
          const selection = AABB.pointCollidesWithAABB(mouse.worldDownAt, selectionRect)
          const upperLeft = AABB.pointCollidesWithAABB(mouse.worldDownAt, selectionUpperLeft)
          const upperRight = AABB.pointCollidesWithAABB(mouse.worldDownAt, selectionUpperRight)
          const lowerLeft = AABB.pointCollidesWithAABB(mouse.worldDownAt, selectionLowerLeft)
          const lowerRight = AABB.pointCollidesWithAABB(mouse.worldDownAt, selectionLowerRight)
          const top = AABB.pointCollidesWithAABB(mouse.worldDownAt, selectionTop)
          const right = AABB.pointCollidesWithAABB(mouse.worldDownAt, selectionRight)
          const bottom = AABB.pointCollidesWithAABB(mouse.worldDownAt, selectionBottom)
          const left = AABB.pointCollidesWithAABB(mouse.worldDownAt, selectionLeft)
          const rotation = AABB.pointCollidesWithAABB(mouse.worldDownAt, selectionRotation)
          if (upperLeft) {
            this.startScaling('upperLeft', r)
            return
          }
          if (upperRight) {
            this.startScaling('upperRight', r)
            return
          }
          if (lowerLeft) {
            this.startScaling('lowerLeft', r)
            return
          }
          if (lowerRight) {
            this.startScaling('lowerRight', r)
            return
          }
          if (top) {
            this.startScaling('top', r)
            return
          }
          if (right) {
            this.startScaling('right', r)
            return
          }
          if (bottom) {
            this.startScaling('bottom', r)
            return
          }
          if (left) {
            this.startScaling('left', r)
            return
          }
          if (rotation) {
            this.startRotating()
            return
          }
          if (selection) {
            if (evt.shiftKey) {
              this.startSelecting(selections.assets.slice(), evt.shiftKey)
              return
            } else this.startTranslating()
            return
          }
          if (cd.length) {
            this.startSelecting(cd, evt.shiftKey)
            return
          } else {
            this.setMode(MODE.DRAG_SELECTING)
          }
        } else {
          const selection = AABB.pointCollidesWithAABBGroup(mouse.worldDownAt, selectionRects)
          if (selection) {
            if (evt.shiftKey) {
              this.startSelecting(selections.assets.slice(), evt.shiftKey)
              return
            } else this.startTranslating()
            return
          }
          if (cd.length) {
            this.startSelecting(cd, evt.shiftKey)
            return
          } else {
            this.setMode(MODE.DRAG_SELECTING)
          }
        }
      }
    },
    onMouseMove: function(evt) {
      evt.stopPropagation()
      const { camera, position, mouse } = this

      if (this.isInactive) return

      copyVec3(mouse.lastAt, mouse.nowAt)
      set(mouse.nowAt, evt.clientX - position.left, evt.clientY - position.top, 0)

      if (this.isSelecting) this.startTranslating()
      else if (this.isTranslating) this.translateSelectionsByMouse()
      else if (this.isScaling) this.scaleSelectionsByMouse()
      else if (this.isRotating) this.rotateSelectionsByMouse(mouse.worldLastAt)
      else if (this.isPanning) camera.pan(evt.nativeEvent.movementX, evt.nativeEvent.movementY)
    },
    onMouseUp: function(evt) {
      evt.stopPropagation()
      const { position, mouse, selections, renderable } = this
      const { current: stage } = renderable

      copyVec3(mouse.lastAt, mouse.nowAt)
      set(mouse.nowAt, evt.clientX - position.left, evt.clientY - position.top, 0)

      if (this.isDragSelecting) {
        let collisions = []
        getCollisions(collisions, this.dragRect, stage)
        if (collisions.length) {
          let newSelections = collisions.slice()
          newSelections.forEach((collision) => {
            selections.setAssetSelection(collision, true)
          })
        } else {
          selections.clearAllSelections()
        }
      }

      if (this.isTranslating) this.translateSelectionsByMouse()
      else if (this.isScaling) this.scaleSelectionsByMouse()
      else if (this.isRotating) this.rotateSelectionsByMouse(mouse.worldLastAt)

      // Turn 'snapshotting' back on and set to final destination
      history.stopGroup()

      this.setMode(MODE.ACTIVE)
    },
    onMouseEnter: function(evt) {
      this.setMode(MODE.ACTIVE)
    },
    onMouseLeave: function(evt) {
      this.setMode(MODE.INACTIVE)
    },
    onMouseOver: function(evt) {
      const { position, mouse } = this
      // EAT-2087: if the react-color control is open while mousing into the Viewport, onMouseEnter won't fire
      if (this.isInactive) {
        // Make sure to set the mouse coordinates and ignore lastAt in case onMouseMove has not fired
        set(mouse.nowAt, evt.clientX - position.left, evt.clientY - position.top, 0)
        this.setMode(MODE.ACTIVE)
      }
    },
    onWheel: function(evt) {
      evt.preventDefault()
      const dX = evt.deltaX
      const dY = evt.deltaY
      this.camera.pan(-dX, -dY)
    },
    startSelecting: function(collisions, shiftKey) {
      const { selections } = this
      this.setMode(MODE.SELECTING)
      if (collisions.length) {
        selections.setAssetSelection(collisions[collisions.length - 1], shiftKey) // select the "top-most" asset collision
      }
    },
    startTranslating: function() {
      // This is not 'snapshotted' since it'd just generate noise
      history.startGroup()
      this.setMode(MODE.TRANSLATING)
      // Call this right away because this now occurs onMouseMove
      this.translateSelectionsByMouse()
    },
    startScaling: function(aabb, renderable) {
      // This is not 'snapshotted' since it'd just generate noise
      history.startGroup()
      copyVec3(cachedScale, this.player.animateMode ? renderable.scale : renderable.baseAsset.scale)
      cachedScaleKfs = renderable.baseAsset.scaleKeyframes.map((kf) => clone(kf.value)) // Create an array of the cached keyframe values (i.e. [[2,2,1], [.5,.5,1], [1,1,1]])
      this.setActiveAABB(aabb)
      this.setMode(MODE.SCALING)
    },
    startRotating: function() {
      // This is not 'snapshotted' since it'd just generate noise
      history.startGroup()
      // Rotate on mouse down to handle "jump"
      this.rotateSelectionsByMouse(this.rotationAnchorCenter)
      this.setMode(MODE.ROTATING)
    },
    translateSelectionsByVec: function(selections, vec) {
      const end = selections.length
      for (let i = 0; i < end; i++) {
        const renderable = selections[i]
        // Execute
        this.translateSelection(renderable, vec)
      }
    },
    translateSelectionsByMouse: function() {
      const { mouse, selections } = this
      const end = selections.renderables.length
      for (let i = 0; i < end; i++) {
        const renderable = selections.renderables[i]
        // Transform mouse space
        transformMat4(
          mouseLocalLast,
          mouse.worldLastAt,
          invert(Mat4(), renderable.parent.worldMatrix)
        )
        transformMat4(
          mouseLocalNow,
          mouse.worldNowAt,
          invert(Mat4(), renderable.parent.worldMatrix)
        )
        subtract(mouseLocalDelta, mouseLocalNow, mouseLocalLast)
        // Execute
        this.translateSelection(renderable, mouseLocalDelta)
      }
    },
    translateSelection: function(renderable, delta) {
      const { player } = this
      const asset = renderable.baseAsset
      if (player.animateMode) {
        // Insert the keyframe
        const kfValue = add(Vec3(0, 0, 0), renderable.position, delta)
        const kf = KeyframeFactory.Keyframe({ frame: player.frame, fn: 'linear', value: kfValue })
        asset.addKeyframe('position', kf)
      } else {
        // Update source asset
        asset.updateVec('position', add(Vec3(0, 0, 0), asset.position, delta))
        // Update keyframes
        const kfs = asset.positionKeyframes
        const kfEnd = kfs.length
        for (let j = 0, kf; j < kfEnd; j++) {
          kf = kfs[j]
          kf.updateValue(add(Vec3(0, 0, 0), kf.value, delta))
        }
      }
    },
    scaleSelectionsByMouse: function() {
      const { mouse, selections } = this
      const renderable = selections.renderables[0]
      // Transform mouse space
      transformMat4(mouseLocalDown, mouse.downAt, renderable.parent.worldMatrix)
      transformMat4(mouseLocalNow, mouse.nowAt, renderable.parent.worldMatrix)
      subtract(mouseLocalDelta, mouseLocalNow, mouseLocalDown)
      // Execute
      this.scaleSelection(renderable, mouseLocalDelta)
    },
    scaleSelection: function(renderable, delta) {
      const { activeAABB, player } = this
      const asset = renderable.baseAsset
      const originScale = cachedScale
      const originKfs = cachedScaleKfs

      const basis = player.animateMode ? renderable : asset
      let localDelta, dimension, scaleX, scaleY
      if (activeAABB === 'left') {
        localDelta = -delta[0]
        dimension = basis.width
        scaleX = originScale[0] + localDelta / dimension
        scaleY = originScale[1]
      } else if (activeAABB === 'right') {
        localDelta = delta[0]
        dimension = basis.width
        scaleX = originScale[0] + localDelta / dimension
        scaleY = originScale[1]
      } else if (activeAABB === 'top') {
        localDelta = -delta[1]
        dimension = basis.height
        scaleY = originScale[1] + localDelta / dimension
        scaleX = originScale[0]
      } else if (activeAABB === 'bottom') {
        localDelta = delta[1]
        dimension = basis.height
        scaleY = originScale[1] + localDelta / dimension
        scaleX = originScale[0]
      } else if (activeAABB === 'upperLeft') {
        localDelta = (-delta[0] + -delta[1]) / 2
        dimension = Math.min(basis.width, basis.height)
        scaleX = originScale[0] + localDelta / dimension
        scaleY = originScale[1] + localDelta / dimension
      } else if (activeAABB === 'upperRight') {
        localDelta = (delta[0] + -delta[1]) / 2
        dimension = Math.min(basis.width, basis.height)
        scaleX = originScale[0] + localDelta / dimension
        scaleY = originScale[1] + localDelta / dimension
      } else if (activeAABB === 'lowerRight') {
        localDelta = (delta[0] + delta[1]) / 2
        dimension = Math.min(basis.width, basis.height)
        scaleX = originScale[0] + localDelta / dimension
        scaleY = originScale[1] + localDelta / dimension
      } else if (activeAABB === 'lowerLeft') {
        localDelta = (-delta[0] + delta[1]) / 2
        dimension = Math.min(basis.width, basis.height)
        scaleX = originScale[0] + localDelta / dimension
        scaleY = originScale[1] + localDelta / dimension
      }

      if (player.animateMode) {
        // Insert the keyframe
        const kfValue = Vec3(scaleX, scaleY, 1)
        const kf = KeyframeFactory.Keyframe({ frame: player.frame, fn: 'linear', value: kfValue })
        asset.addKeyframe('scale', kf)
      } else {
        // Update source asset
        asset.updateVec('scale', Vec3(scaleX, scaleY, 1))
        // Update keyframes
        const kfs = asset.scaleKeyframes
        const kfEnd = kfs.length
        for (let j = 0, kf, kfXValue, kfYValue; j < kfEnd; j++) {
          kf = kfs[j]
          kfXValue = asset.scale[0] * (originKfs[j][0] / originScale[0])
          kfYValue = asset.scale[1] * (originKfs[j][1] / originScale[1])
          kf.updateValue(Vec3(kfXValue, kfYValue, 1))
        }
      }
    },
    rotateSelectionsByMouse: function(lastCenter) {
      const { mouse, selections, selectionCenter } = this
      const renderable = selections.renderables[0]
      // Calculate rotation from asset center instead of mouseWorld center
      const lastFromCenter = subtract(Vec3(0, 0, 0), selectionCenter, lastCenter)
      const fromCenter = subtract(Vec3(0, 0, 0), selectionCenter, mouse.worldNowAt)
      const diff = radiansBetween(lastFromCenter, fromCenter)
      // Execute
      this.rotateSelection(renderable, diff)
    },
    rotateSelection: function(renderable, delta) {
      const { player } = this
      const asset = renderable.baseAsset
      if (player.animateMode) {
        // Insert the keyframe
        const kfValue = Vec3(0, 0, renderable.rotation[2] + delta)
        const kf = KeyframeFactory.Keyframe({ frame: player.frame, fn: 'linear', value: kfValue })
        asset.addKeyframe('rotation', kf)
      } else {
        // Update source asset
        asset.updateVec('rotation', Vec3(0, 0, asset.rotation[2] + delta))
        // Update keyframes
        const kfs = asset.rotationKeyframes
        const kfEnd = kfs.length
        for (let j = 0, kf; j < kfEnd; j++) {
          kf = kfs[j]
          kf.updateValue(Vec3(0, 0, kf.value[2] + delta))
        }
      }
    }
  }, {
    el: observable.ref,
    readyPanning: action.bound,
    donePanning: action.bound,
    toggleAssetOutline: action.bound,
    setViewportEl: action.bound,
    setViewportSize: action.bound,
    setViewportPosition: action.bound,
    resize: action.bound,
    resetCameraCenter: action.bound,
    resetCameraZoom: action.bound,
    setMode: action.bound,
    viewportActive: action.bound,
    viewportInactive: action.bound,
    viewportTranslating: action.bound,
    viewportScaling: action.bound,
    viewportRotating: action.bound,
    viewportPanning: action.bound,
    setActiveAABB: action.bound,
    onDrop: action.bound,
    onMouseDown: action.bound,
    onMouseMove: action.bound,
    onMouseUp: action.bound,
    onMouseEnter: action.bound,
    onMouseLeave: action.bound,
    onMouseOver: action.bound,
    onWheel: action.bound,
    startSelecting: action.bound,
    startTranslating: action.bound,
    startScaling: action.bound,
    startRotating: action.bound,
    translateSelectionsByVec: action.bound,
    translateSelectionsByMouse: action.bound,
    translateSelection: action.bound,
    scaleSelectionsByMouse: action.bound,
    scaleSelection: action.bound,
    rotateSelectionsByMouse: action.bound,
    rotateSelection: action.bound
  })
}
