import React from 'react'
import { observer } from 'mobx-react'
import Transition from 'react-transition-group/Transition'

const Fade = observer(({ in: inProp, children }) => {
  return (
    <Transition in={inProp} timeout={375} mountOnEnter={true} unmountOnExit={true}>
      {(status) => React.cloneElement(React.Children.only(children), { className: `squish-${status}` })}
    </Transition>
  )
})

export default Fade
