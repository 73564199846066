import { types } from 'mobx-state-tree'
import { Option as OptionFactory } from 'eplayer-core'
import RawHTML from '../Media/RawHTML'
import Option from '../Option'
import Keyframe from '../Keyframe'
import { EmbedTemplate } from '../Template'
import { Fill, Layerable, Rectangle, Stroke, Transformable, Visible, UI } from './shared/props'
import assetActions from './shared/actions'
import assetViews from './shared/views'
import assetChildTypes from './shared/childTypes'

const AssetType = 'Embed'

const model = types
  .model(AssetType, {
    // Entity
    Type: types.literal(AssetType),
    objectId: types.identifier,
    // Asset
    name: types.string,
    children: types.late(assetChildTypes),
    // MediaOptions
    canvasMedia: types.maybeNull(types.reference(RawHTML)),
    canvasMediaOptions: types.late(() => types.array(Option)),
    canvasFrame: types.number,
    canvasKeyframes: types.array(Keyframe),
    template: types.maybeNull(types.reference(EmbedTemplate)),
    ...Layerable,
    ...Fill,
    ...Stroke,
    ...Rectangle,
    ...Visible,
    ...Transformable,
    ...UI,
  })
  .views(assetViews)
  .actions(self => {
    function addMediaOption(prop, option) {
      const a = self
      option = option || Option.create(OptionFactory.Option())
      a['canvasMediaOptions'].push(option)
    }
    function removeMediaOption(option) {
      const a = self
      a['canvasMediaOptions'].remove(option)
    }
    return {
      ...assetActions(self),
      addMediaOption,
      removeMediaOption,
    }
  })
export default model
