import React from 'react'
import { Statement } from 'eplayer-core'
import CodeEditor from '../CodeEditor'
import { ActionsTakeover } from '../../panels/Actions'
import { Takeover } from '../index'

export default class CodeEditorTakeover extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      codeEditorValue: props.statement ? props.statement.string : '',
      codeEditorError: false,
      codeEditorErrorMsg: ''
    }
    this.setValue = this.setValue.bind(this)
    this.onOk = this.onOk.bind(this)
    this.keyPress = this.keyPress.bind(this)
  }
  setValue(value) {
    this.setState({ codeEditorValue: value })
  }
  onOk() {
    var s = Statement('', this.state.codeEditorValue)
    Statement.compile(s)
    this.setState({ codeEditorError: !s.compiled })
    this.setState({ codeEditorErrorMsg: s.compileError })
    if (s.compiled) {
      this.props.onOk(this.state.codeEditorValue)
      this.props.hideTakeover()
    }
  }
  keyPress(e) {
    if (e.which == 27) {
      this.props.hideTakeover()
    }
  }
  render() {
    const { app, variables, assets } = this.props
    const { codeEditorError, codeEditorErrorMsg } = this.state

    return (
      <div onKeyDown={this.keyPress}>
        <Takeover style={{ width: '90%', bottom: '10%' }}>
          <ActionsTakeover
            title={this.props.title || 'Code Editor'}
            hideTakeover={this.props.hideTakeover}
            onOk={this.onOk}
            app={app}>
            <CodeEditor
              placeholder={`Write some code here please!`}
              value={this.props.statement ? this.props.statement.string : ''}
              onChange={this.setValue}
              error={codeEditorError}
              message={codeEditorErrorMsg}
              variables={variables}
              assets={assets}
            />
          </ActionsTakeover>
        </Takeover>
      </div>
    )
  }
}
