import { observable, action } from 'mobx'

export default function FileList(app) {
  return observable({
    editions: [],
    selection: null,
    searching: false,
    search: '',
    page: 1,
    sort: app.storage.has('sortEditions') ? app.storage.get('sortEditions', 'string') : 'updated',
    // Computeds
    get sortedEditions() {
      if (this.editions.length && this.sort) {
        const { sort } = this
        if (sort === 'updated') {
          return this.editions.sort(function(a, b) {
            var keyA = new Date(a.lastUpdated),
              keyB = new Date(b.lastUpdated)
            if (keyA < keyB) return 1
            if (keyA > keyB) return -1
            return 0
          })
        } else if (sort === 'name') {
          return this.editions.sort(function(a, b) {
            var keyA = a.name || 'Untitled Edition',
              keyB = b.name || 'Untitled Edition'
            // Compare the 2 dates
            if (keyA < keyB) return -1
            if (keyA > keyB) return 1
            ;(keyA = new Date(a.lastUpdated)), (keyB = new Date(b.lastUpdated))
            // Compare the 2 dates
            if (keyA < keyB) return 1
            if (keyA > keyB) return -1

            return 0
          })
        }
      }
      return this.editions
    },
    get filteredEditions() {
      if (this.searching && this.search) {
        const regex = new RegExp(this.escapeRegExp(this.search), 'i')
        return this.sortedEditions.filter((edition) => {
          const name = edition.name || 'Untitled Edition'
          if (name.match(regex)) return true
          else return false
        })
      } else {
        return this.sortedEditions
      }
    },
    escapeRegExp(text) {
      return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
    },
    // Actions
    setEditionList: function(editionList) {
      this.editions.replace(editionList)
    },
    setSelection: function(selection) {
      this.selection = selection
    },
    clearSelection: function() {
      this.selection = null
    },
    setPage: function(page) {
      this.page = page
    },
    resetState: function() {
      // this.editions.clear()
      this.clearSelection()
      this.setPage(1)
      this.resetSearch()
      this.searching = false
    },
    toggleSearching: function() {
      this.searching = !this.searching
    },
    setSearch: function(query) {
      this.search = query
    },
    resetSearch: function() {
      this.search = ''
    },
    setSort: function(key) {
      this.sort = key
      app.storage.set('sortEditions', 'string', key)
    }
  }, {
    editions: observable.shallow,
    selection: observable.ref,
    setEditionList: action.bound,
    setSelection: action.bound,
    clearSelection: action.bound,
    setPage: action.bound,
    resetState: action.bound,
    toggleSearching: action.bound,
    setSearch: action.bound,
    resetSearch: action.bound,
    setSort: action.bound
  })
}
