import { types, getSnapshot, getParent, getRoot } from 'mobx-state-tree'
import { BaseContent, Option as OptionFactory } from 'eplayer-core'
import vec3 from 'gl-vec3'
import Sequence from './Sequence'
import Statement from '../Statement'
import Option from '../Option'
import { setProp } from '../utils'

const DecisionNodeCurrent = types.model('DecisionNodeCurrent', {
	position: types.array(types.number),
	radius: types.number,
	localMatrix: types.array(types.number),
	worldMatrix: types.array(types.number),
})

const model = types
	.model('DecisionNode', {
		Type: types.literal('DecisionNode'),
		objectId: types.identifier,
		name: types.string,
		description: types.string,
		next: types.late(() =>
			types.maybeNull(types.reference(types.union(Sequence, model)))
		),
		connections: types.late(() => types.array(Option)),
		onEnter: types.maybeNull(Statement),
		current: DecisionNodeCurrent,
	})
	.views(self => ({
		get parent() {
			return getParent(self, 2)
		},
	}))
	.actions(self => {
		function updateProp(prop, value) {
			const dN = self
			setProp(prop, value, dN)
		}
		function setNext(value = null) {
			self.next = value
		}
		function addConnection(option) {
			const dN = self
			option = option || Option.create(OptionFactory.Option())
			dN.connections.push(option)
		}
		function removeConnection(option) {
			const dN = self
			dN.connections.remove(option)
		}
		function duplicate(parent) {
			const dN = self
			parent = parent || dN.parent
			let clone = dN.clone()
			parent.addDecisionNode(clone)
		}
		function clone() {
			const dN = self
			let copy = getSnapshot(dN)
			// run graph clone to setup new UUIDs and such
			copy = BaseContent.DecisionNode.clone(copy)
			// Clear decision node connections while maintaining conditions
			copy.next = null
			for (let i = 0; i < copy.connections.length; i++) {
				let connection = copy.connections[i]
				connection.value = null
			}
			return copy
		}
		// Dragging DecisionNode in Structure Viewport
		function translate(delta) {
			const dN = self
			vec3.add(dN.current.position, dN.current.position, delta)
		}
		// Dragging a DecisionNode onto another DecisionNode in Structure Hierarchy
		function moveContent(node) {
			const edition = getRoot(self)
			const dN = self
			const newParent = dN.parent
			const newPeers = newParent['decisionNodes']
			const i = newPeers.indexOf(dN)
			edition.detachNode(node)
			newParent.addDecisionNodeAtIndex(i, node)
		}
		return {
			updateProp,
			setNext,
			addConnection,
			removeConnection,
			duplicate,
			clone,
			translate,
			moveContent,
		}
	})
export default model
