import { deserialize } from 'eplayer-core'
import { csvToEdition, templates as templateEd, modernTemplates } from 'polyp'
import { read as xlsxRead, utils as xlsxUtils } from 'xlsx'
import { action } from 'mobx'
import { observable } from 'mobx'
import { toJS } from 'mobx'
import { readBinaryString } from '../actions/io'
import { noFirstSpace } from '../utils'
import { updateRemoteMedia } from '../actions/remote'

export default function ConversionProc(app) {
  return observable({
    aborted: false,
    errors: [],
    editionName: null,
    lang: 'en',
    profile: "Old",
    xls: {
      name: '',
      sheet: null
    },
    audio: {
      pending: [],
      failed: [],
      completed: [],
      get ready() {
        const pending = this.pending.length
        const complete = this.completed.length
        const failed = this.failed.length

        if (!pending) return false
        else if (complete + failed !== pending) return false
        else if (failed) return false
        else return true
      },
      get uploadfinished() {
        const pending = this.pending.length
        const complete = this.completed.length
        const failed = this.failed.length
        if (!pending) return false
        else if (complete + failed !== pending) return false
        else return true
      },
      get status() {
        const pending = this.pending.length
        const completed = this.completed.length
        const failed = this.failed.length
        if (pending && completed + failed !== pending) return 'inprogress'
        else if (pending && completed === pending) return 'success'
        else if (pending && completed + failed === pending && failed > 0) return 'failed'
        else return null
      }
    },
    canvas: {
      pending: [],
      failed: [],
      completed: [],
      get ready() {
        const pending = this.pending.length
        const complete = this.completed.length
        const failed = this.failed.length

        if (!pending) return false
        else if (complete + failed !== pending) return false
        else if (failed) return false
        else return true
      },
      get uploadfinished() {
        const pending = this.pending.length
        const complete = this.completed.length
        const failed = this.failed.length
        if (!pending) return false
        else if (complete + failed !== pending) return false
        else return true
      },
      get status() {
        const pending = this.pending.length
        const completed = this.completed.length
        const failed = this.failed.length
        if (pending && completed + failed !== pending) return 'inprogress'
        else if (pending && completed === pending) return 'success'
        else if (pending && completed + failed === pending && failed > 0) return 'failed'
        else return null
      }
    },
    get ready() {
      const hasXLS = this.xls.sheet != null
      const audioReady = this.audio.ready
      const canvasReady = this.canvas.ready
      const hasName = this.editionName != null && this.editionName != ''
      if (!hasXLS) return false
      else if (!audioReady) return false
      else if (!canvasReady) return false
      else if (!hasName) return false
      else return true
    },
    readXLS: function(file) {
      const toWorkbook = (bin) => xlsxRead(bin, { type: 'binary' })
      const assignXLS = action((wkbk) => {
        if (!this.aborted) this.xls.sheet = wkbk.Sheets.script
      })
      const failure = action((err) => {
        if (!this.aborted) this.errors.push(err)
      })

      this.xls.name = file.name

      readBinaryString(file)
        .then(toWorkbook, failure)
        .then(assignXLS, failure)
    },
    readAudio: async function(fileList) {
      const { state: { layout: { player } } } = app
      const { audio: { completed } } = this
      const { audio: { pending } } = this
      const { audio: { failed } } = this
      const { errors } = this
      fileList = Array.from(fileList)

      errors.clear()
      failed.clear()
      completed.clear()
      pending.replace(fileList)

      for await (const [ _, result ] of updateRemoteMedia(player.cache, ...fileList)) {
        if (this.aborted) break
        if (result instanceof Error) {
          failed.push(result)
          errors.push(result)
        } else {
          completed.push(result)
        }
      }
    },
    readCanvas: async function(fileList) {
      const { state: { layout: { player } } } = app
      const { canvas: { completed } } = this
      const { canvas: { pending } } = this
      const { canvas: { failed } } = this
      const { errors } = this
      fileList = Array.from(fileList)

      errors.clear()
      failed.clear()
      completed.clear()
      pending.replace(fileList)

      for await (const [ _, result ] of updateRemoteMedia(player.cache, ...fileList)) {
        if (this.aborted) break
        if (result instanceof Error) {
          failed.push(result)
          errors.push(result)
        } else {
          completed.push(result)
        }
      }
    },
    abort: function() {
      this.aborted = true
      this.reset()
    },
    reset: function() {
      const { audio, canvas } = this
      this.xls.name = ''
      this.xls.sheet = null
      this.errors.clear()
      this.resetCanvas()
      this.resetAudio()
    },
    resetCanvas: function() {
      const { canvas } = this
      canvas.completed.clear()
      canvas.failed.clear()
      canvas.pending.clear()
    },
    resetAudio: function() {
      const { audio } = this
      audio.completed.clear()
      audio.failed.clear()
      audio.pending.clear()
    },
    resetXLS: function() {
      this.xls.name = ''
      this.xls.sheet = null
    },
    start: function(route) {
      const e = deserialize(templateEd.message)
      const audioMap = this.audio.completed.reduce(
        (dict, rawAudio) => ((dict[rawAudio.fileName] = toJS(rawAudio)), dict),
        {}
      )
      const canvasMap = this.canvas.completed.reduce(
        (dict, rawHTML) => ((dict[rawHTML.fileName] = toJS(rawHTML)), dict),
        {}
      )
      const spriteMap = e.spriteMedia.reduce((dict, rawSprite) => {
        dict[rawSprite.fileName] = rawSprite
        return dict
      }, {})
      const templates = e.chapters.reduce((dict, ch) => {
        const ss = ch.sequences
        let i = ss.length,
          s
        while ((s = ss[--i])) dict[s.name] = toJS(s)
        return dict
      }, modernTemplates)
      const lang = this.lang
      const isNewProfile = "New" === this.profile ? true : false
      const csv = xlsxUtils.sheet_to_csv(toJS(this.xls.sheet))
      const result = csvToEdition(audioMap, canvasMap, spriteMap, templates, csv, lang, isNewProfile)
      result.name = this.editionName
      if (Array.isArray(result)) {
        this.errors.push(...result)
        this.resetAudio()
        this.resetCanvas()
      } else {
        this.reset()
        app.readEdition(result)
      }
    },
    changeLanguage: function(lang) {
      this.lang = lang
    },
    changeProfile: function(profile) {
      this.profile = profile
    },
    updateEditionName: function(val) {
      this.editionName = noFirstSpace(val)
    }
  }, {
    readXLS: action.bound,
    readAudio: action.bound,
    readCanvas: action.bound,
    abort: action.bound,
    reset: action.bound,
    resetCanvas: action.bound,
    resetAudio: action.bound,
    resetXLS: action.bound,
    start: action.bound,
    changeLanguage: action.bound,
    updateEditionName: action.bound
  })
}
