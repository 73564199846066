import React from 'react'
import { observer } from 'mobx-react'
import vec3 from 'gl-vec3'
import mat from 'gl-mat4'
import { Asset, AABB, Vec3, Mat4, RGBA, utils } from 'eplayer-core'
import {
  ONDROP_WHITELIST,
  CONTROL_SIZE,
  SELECTION_CONTROL_SIZE,
  SELECTION_COLOR,
  CORNER_ANCHOR_COLOR,
  ROTATE_ANCHOR_COLOR,
  EDGE_ANCHOR_COLOR
} from '../constants'

const { append } = utils

//cached values
const ulCam = Vec3(0, 0, 0)
const lrCam = Vec3(0, 0, 0)
const p1Cam = Vec3(0, 0, 0)
const p2Cam = Vec3(0, 0, 0)

const ScaleAnchor = observer(({ camera, color, aabb, name }) => {
  return React.cloneElement(AABB.render(camera, color, aabb), { className: name })
})

const RotationAnchor = observer(({ camera, color, aabb, name }) => {
  vec3.transformMat4(ulCam, aabb.ul, camera.viewMatrix)
  vec3.transformMat4(lrCam, aabb.lr, camera.viewMatrix)
  const l = ulCam[0] + 'px'
  const t = ulCam[1] + 'px'
  const w = lrCam[0] - ulCam[0]
  const h = lrCam[1] - ulCam[1]

  return React.createElement('div', {
    key: `anchor ${name}`,
    className: `anchor ${name}`,
    style: {
      position: 'absolute',
      boxSizing: 'border-box',
      border: '1px solid ' + RGBA.toString(ROTATE_ANCHOR_COLOR),
      borderRadius: '50%',
      width: w,
      height: h,
      zIndex: 10000,
      transform: 'translate3d(' + l + ', ' + t + ', 0px)',
      fontSize: '8em'
    }
  })
})

const RotationAxis = observer(({ camera, p1, p2, name }) => {
  vec3.transformMat4(p1Cam, p1, camera.viewMatrix)
  vec3.transformMat4(p2Cam, p2, camera.viewMatrix)
  const l = Math.min(p1Cam[0], p2Cam[0]) + 'px'
  const t = Math.min(p1Cam[1], p2Cam[1]) + 'px'
  const xDiff = p1Cam[0] - p2Cam[0]
  const yDiff = p1Cam[1] - p2Cam[1]
  const w = Math.abs(xDiff)
  const h = Math.abs(yDiff)

  const x1 = 0
  const x2 = w
  const y1 = (xDiff < 0 && yDiff < 0) || (xDiff >= 0 && yDiff >= 0) ? 0 : h
  const y2 = y1 === 0 ? h : 0

  return React.createElement(
    'svg',
    {
      key: `${name}`,
      className: `${name}`,
      style: {
        position: 'absolute',
        boxSizing: 'border-box',
        width: w,
        height: h,
        zIndex: 10000,
        transform: 'translate3d(' + l + ', ' + t + ', 0px)'
      }
    },
    React.createElement('line', {
      x1: x1,
      y1: y1,
      x2: x2,
      y2: y2,
      strokeWidth: '1',
      stroke: RGBA.toString(ROTATE_ANCHOR_COLOR)
    })
  )
})

const RotationIndicator = observer(({ camera, radians, aabb, name }) => {
  vec3.transformMat4(ulCam, aabb.ul, camera.viewMatrix)
  vec3.transformMat4(lrCam, aabb.lr, camera.viewMatrix)
  const aabbW = lrCam[0] - ulCam[0]
  const aabbH = lrCam[1] - ulCam[1]
  const l = ulCam[0] + aabbW + 'px'
  const t = ulCam[1] + aabbH + 'px'

  const degrees = radians * 180 / Math.PI
  const degreesLabel = degrees.toFixed() + '°'

  return React.createElement(
    'div',
    {
      key: `${name}`,
      className: `${name}`,
      style: {
        position: 'absolute',
        boxSizing: 'border-box',
        width: 'auto',
        height: 'auto',
        zIndex: 10000,
        transform: 'translate3d(' + l + ', ' + t + ', 0px)',
        fontSize: '12px',
        textAlign: 'center',
        color: '#fff',
        backgroundColor: 'rgba(35, 37, 41, .75)',
        padding: '2px 5px'
      }
    },
    degreesLabel
  )
})

const LayoutViewport = observer(class LayoutViewport extends React.Component {
  exit = () => {
    const { route, state } = this.props
    route.goTo('Structure')
  }
  onDragStart = (e) => {
    e.preventDefault()
  }
  onDragOver = (e) => {
    e.preventDefault()
  }
  onContextMenu = (e) => {
    e.preventDefault()
  }
  // TODO: should eventually move this completely into viewport state when we have access to Global state
  onDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const { state } = this.props
    const { global: globalState, layout: layoutState } = state
    const droppedItems = globalState.dropped()
    const droppedFrom = e.dataTransfer.getData('text/panel')
    const isValidDrop = ONDROP_WHITELIST['LayoutViewport'].includes(droppedFrom)
    if (!isValidDrop) {
      globalState.showAlert(
        'warn',
        `Cannot drop items from the ${droppedFrom} panel onto the LayoutViewport panel.`
      )
      return
    }
    layoutState.viewport.onDrop(e, droppedItems)
  }
  componentDidMount() {
    const { state } = this.props
    const { layout: layoutState } = state
    // Initialize Size and Position
    layoutState.viewport.resize()
  }
  render() {
    const { state, signals } = this.props
    const { layout: layoutState, timeline: timelineState } = state
    const { camera, clock, player, selections, renderable, viewport: viewportState } = layoutState

    const { activeSequence: sequence } = player
    const { current: stage } = renderable
    const { assets: assetSelections, renderables: renderableSelections } = selections
    const {
      outlineAssets,
      dragRect,
      selectionRects,
      selectionUpperLeft,
      selectionUpperRight,
      selectionLowerRight,
      selectionLowerLeft,
      selectionTop,
      selectionRight,
      selectionBottom,
      selectionLeft,
      selectionRotation,
      selectionCenter,
      rotationAnchorCenter
    } = viewportState
    const {
      origin: [oX, oY],
      position: [cX, cY],
      zoom: z
    } = camera

    const scene = []
    const ui = []

    const props = {
      id: 'viewport',
      ['data-qa-hook']: 'ui.layout-viewport',
      className: `viewport layer ${outlineAssets ? 'outline-assets' : ''}`,
      ref: viewportState.setViewportEl,
      onWheel: viewportState.onWheel,
      onMouseMove: viewportState.onMouseMove,
      onMouseDown: viewportState.onMouseDown,
      onMouseUp: viewportState.onMouseUp,
      onMouseEnter: viewportState.onMouseEnter,
      onMouseLeave: viewportState.onMouseLeave,
      onMouseOver: viewportState.onMouseOver,
      onDragStart: this.onDragStart,
      onDragOver: this.onDragOver,
      onDrop: this.onDrop,
      onContextMenu: this.onContextMenu
    }

    const cameraProps = {
      id: 'camera',
      key: 'camera',
      ref: 'cameraEl',
      style: {
        position: 'relative',
        width: stage.width,
        height: stage.height,
        transformOrigin: `${oX * 100}% ${oY * 100}%`,
        transform: `translate3d(${cX}px, ${cY}px, 0px) scale3d(${z}, ${z}, 1)`
      }
    }
    /*
    // rulerProps currently unused - for future guide rulers
    const rulerProps = {
      style: {
        position: 'relative',
        width: '1970px',
        height: '1130px',
        transformOrigin: `${oX*100}% ${oY*100}%`,
        transform: `translate3d(${cX}px, ${cY}px, 0px) scale3d(${z}, ${z}, 1)`
      }
    }
    */

    if (selectionRects.length) {
      selectionRects.forEach((selectionRect) => {
        append(ui, AABB.render(camera, SELECTION_COLOR, selectionRect))
      })
      if (selectionRects.length === 1 && assetSelections[0].Type !== 'Stage') {
        append(
          ui,
          <ScaleAnchor
            camera={camera}
            color={CORNER_ANCHOR_COLOR}
            key={`upperLeft`}
            name={`upperLeft`}
            aabb={selectionUpperLeft}
          />
        )
        append(
          ui,
          <ScaleAnchor
            camera={camera}
            color={CORNER_ANCHOR_COLOR}
            key={`upperRight`}
            name={`upperRight`}
            aabb={selectionUpperRight}
          />
        )
        append(
          ui,
          <ScaleAnchor
            camera={camera}
            color={CORNER_ANCHOR_COLOR}
            key={`lowerRight`}
            name={`lowerRight`}
            aabb={selectionLowerRight}
          />
        )
        append(
          ui,
          <ScaleAnchor
            camera={camera}
            color={CORNER_ANCHOR_COLOR}
            key={`lowerLeft`}
            name={`lowerLeft`}
            aabb={selectionLowerLeft}
          />
        )
        append(
          ui,
          <ScaleAnchor
            camera={camera}
            color={EDGE_ANCHOR_COLOR}
            key={`top`}
            name={`top`}
            aabb={selectionTop}
          />
        )
        append(
          ui,
          <ScaleAnchor
            camera={camera}
            color={EDGE_ANCHOR_COLOR}
            key={`right`}
            name={`right`}
            aabb={selectionRight}
          />
        )
        append(
          ui,
          <ScaleAnchor
            camera={camera}
            color={EDGE_ANCHOR_COLOR}
            key={`bottom`}
            name={`bottom`}
            aabb={selectionBottom}
          />
        )
        append(
          ui,
          <ScaleAnchor
            camera={camera}
            color={EDGE_ANCHOR_COLOR}
            key={`left`}
            name={`left`}
            aabb={selectionLeft}
          />
        )
        append(
          ui,
          <RotationAnchor camera={camera} key={`rotate`} name={`rotate`} aabb={selectionRotation} />
        )
        if (viewportState.isRotating) {
          const selected = renderableSelections[0]
          const radians = selected.rotation[2]
          append(
            ui,
            <RotationAxis
              camera={camera}
              key={`rotate axis`}
              name={`rotate axis`}
              p1={selectionCenter}
              p2={rotationAnchorCenter}
            />
          )
          append(
            ui,
            <RotationIndicator
              camera={camera}
              key={`rotate indicator`}
              name={`rotate indicator`}
              radians={radians}
              aabb={selectionRotation}
            />
          )
        }
      }
    }

    if (viewportState.isDragSelecting) {
      append(ui, AABB.render(camera, SELECTION_COLOR, dragRect))
    }

    append(scene, Asset.render(stage, clock.dT))

    return (
      <div {...props}>
        {ui}
        <div {...cameraProps}>{scene}</div>
      </div>
    )
  }
})

export default LayoutViewport
