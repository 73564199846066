import React from 'react'
import DOM from 'react-dom'
import { observable } from 'mobx'
import { observer } from 'mobx-react'

const ContextMenu = observer(class ContextMenu extends React.Component {
  constructor(props) {
    super(props)
    // Create a div that we'll render the menu into.
    this.el = document.createElement('div')
    this.el.className = props.className
  }
  componentDidMount() {
    const { root, hide } = this.props
    // Append the element into the DOM on mount.
    root.appendChild(this.el)
    this.onShow()
    this.registerHandlers()
  }
  componentWillUnmount() {
    const { root, hide } = this.props
    // Remove the element from the DOM when we unmount.
    root.removeChild(this.el)
    this.onHide()
    this.unregisterHandlers()
  }
  registerHandlers = () => {
    document.addEventListener('mousedown', this.handleClickOutside, true)
    document.addEventListener('scroll', this.hideMenu, true) // turn on useCapture because scroll events don't bubble
    document.addEventListener('contextmenu', this.hideMenu)
    document.addEventListener('keyup', this.handleKeyUp)
    window.addEventListener('resize', this.hideMenu)
  }
  unregisterHandlers = () => {
    document.removeEventListener('mousedown', this.handleClickOutside, true)
    document.removeEventListener('scroll', this.hideMenu, true) // turn on useCapture because scroll events don't bubble
    document.removeEventListener('contextmenu', this.hideMenu)
    document.removeEventListener('keyup', this.handleKeyUp)
    window.removeEventListener('resize', this.hideMenu)
  }
  hideMenu = () => {
    const { hide } = this.props
    hide()
  }
  onShow = () => {
    const { root, clickX, clickY, offset } = this.props
    const screenW = window.innerWidth
    const screenH = window.innerHeight
    const rootRect = root.getBoundingClientRect()
    const rootX = rootRect.left
    const rootY = rootRect.top
    const menuW = this.el.offsetWidth
    const menuH = this.el.offsetHeight

    const top = screenH - clickY > menuW
    const right = screenW - clickX > menuH
    const bottom = !top
    const left = !right

    const menuX = left ? clickX - rootX - menuW - offset : clickX - rootX + offset
    const menuY = bottom ? clickY - rootY - menuH - offset : clickY - rootY + offset

    this.el.style.top = `${menuY}px`
    this.el.style.left = `${menuX}px`
  }
  onHide = () => {}
  handleClickOutside = (e) => {
    if (!this.el.contains(e.target)) this.hideMenu()
  }
  handleKeyUp = (e) => {
    if (e.keyCode === 27) this.hideMenu()
  }
  render() {
    // Use a portal to render the children into the element
    return DOM.createPortal(
      // Any valid React child: JSX, strings, arrays, etc.
      this.props.children,
      // A DOM element
      this.el
    )
  }
})

export default ContextMenu
