import { types } from 'mobx-state-tree'
import { Option } from 'eplayer-core'
import Expression from './Expression'
import DecisionNode from './BaseContent/DecisionNode'
import Sequence from './BaseContent/Sequence'
import RawSprite from './Media/RawSprite'
import RawAudio from './Media/RawAudio'
import RawVideo from './Media/RawVideo'
import RawHTML from './Media/RawHTML'

const model = types
	.model('Option', {
		Type: types.literal('Option'),
		objectId: types.identifier,
		condition: Expression,
		// Option default is that its a reference as value
		value: types.late(() =>
			types.maybeNull(
				types.reference(
					types.union(
						Sequence,
						DecisionNode,
						RawSprite,
						RawAudio,
						RawHTML,
						RawVideo
					)
				)
			)
		),
	})
	.actions(self => {
		function updateCondition(value) {
			const o = self
			o.condition.string = value
			// Re-compile the expression (some of this should probably just be computed)
			Option.Expression.compile(o.condition)
		}
		function updateValue(value) {
			const o = self
			o.value = value
		}
		return {
			updateCondition,
			updateValue,
		}
	})
export default model
