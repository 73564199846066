import { observable, action } from 'mobx'

export default function EditionVersionHistory() {
  return observable({
    results: [],
    // (should searching and loaded be mutually exclusive?)
    searching: false, // bugstatus
    loaded: false, // resultsstatus
    get isIdle() {
      return !this.searching && !this.loaded
    },
    get isSearching() {
      return this.searching && !this.loaded
    },
    get isLoaded() {
      return !this.searching && this.loaded
    },
    // Actions
    setResults: function(editionList) {
      this.results.replace(editionList)
    },
    addResults: function(editionList) {
      this.results.concat(editionList)
    },
    clearResults: function() {
      this.results.clear()
    },
    setIdle: function() {
      this.searching = false
      this.loaded = false
    },
    setSearching: function() {
      this.searching = true
      this.loaded = false
    },
    setLoaded: function() {
      this.searching = false
      this.loaded = true
    }
  }, {
    results: observable.shallow,
    setResults: action.bound,
    addResults: action.bound,
    clearResults: action.bound,
    setIdle: action.bound,
    setSearching: action.bound,
    setLoaded: action.bound
  })
}
