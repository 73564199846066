import { types } from 'mobx-state-tree'
import Sequence from './Sequence'
import DecisionNode from './DecisionNode'

const StartNodeCurrent = types.model('StartNodeCurrent', {
	position: types.array(types.number),
	radius: types.number,
	localMatrix: types.array(types.number),
	worldMatrix: types.array(types.number),
})

const model = types
	.model('StartNode', {
		Type: types.literal('StartNode'),
		objectId: types.identifier,
		next: types.late(() =>
			types.maybeNull(types.reference(types.union(Sequence, DecisionNode)))
		),
		current: StartNodeCurrent,
	})
	.actions(self => {
		function setNext(value = null) {
			self.next = value
		}
		return {
			setNext,
		}
	})
export default model
