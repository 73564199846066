import React from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { decorate } from 'mobx'
import vec3 from 'gl-vec3'
import { BaseContent, Vec3 } from 'eplayer-core'
import { ONDROP_WHITELIST, TOOLTIP_MIN_HEIGHT } from '../constants'

class StructureViewportComponent extends React.Component {
  constructor(props) {
    super(props)
    this.showTooltip = false
    this.tooltipText = ''
    this.positionTop = 0
    this.positionLeft = 0
    this.currentSequence = ''
  }
  onDragStart = (e) => {
    e.preventDefault()
  }
  onDragOver = (e) => {
    e.preventDefault()
  }
  onDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const { edition, state } = this.props
    const { global: globalState, structure: structureState } = state
    const droppedItems = globalState.dropped()
    const droppedFrom = e.dataTransfer.getData('text/panel')
    const isValidDrop = ONDROP_WHITELIST['StructureViewport'].includes(droppedFrom)
    if (!isValidDrop) {
      globalState.showAlert(
        'warn',
        `Cannot drop items from the ${droppedFrom} panel onto the StructureViewport panel.`
      )
      return
    }
    structureState.viewport.onDrop(e, edition, droppedItems)
  }
  onMouseMove = (e) => {
    const { edition, state } = this.props
    this.showOrHideTooltip(e);
    state.structure.viewport.onMouseMove(e, edition)
  }
  onMouseDown = (e) => {
    const { edition, state } = this.props
    state.structure.viewport.onMouseDown(e, edition)
  }
  onMouseUp = (e) => {
    const { edition, state } = this.props
    state.structure.viewport.onMouseUp(e, edition)
  }
  onContextMenu = (e) => e.preventDefault()

  onDoubleClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const { route, state: { layout, structure: { selections } }, edition: { variables } } = this.props
    const { nodes: [ selected, ] } = selections
    if (selected && "Sequence" === selected.Type) {
      layout.setup(variables, selected)
      route.goTo("Layout")
    }
  }
  
  onMouseWheel = (e) => {
    const { state } = this.props
    const { structure: structureState } = state
    const { viewport: viewportState } = structureState
    viewportState.onWheel(e)
    this.hideTooltip()
  }

  showOrHideTooltip = (e) => {
    if(e.target.classList.contains("tooltip")){
      return;
    }
    if (e.target.classList.contains("Sequence") && !e.target.classList.contains("Label")) {
     const sequenceId =  e.target.id.replace('circle_','label_').replace('circledot_','label_').replace('label_','');
     const { state } = this.props
     const { structure: structureState } = state
     const {  viewport: viewportState } = structureState
     const sequence = viewportState.sequenceRef[sequenceId];
     if(sequence && sequence.circle && sequence.text){
       const   target =sequence.circle;
       const  textNode =sequence.text;
       
     if(target && textNode &&  textNode.innerHTML ){
        const { state } = this.props
        const { structure: structureState } = state
        const { viewport: viewportState } = structureState
        let position = target.getBoundingClientRect()
        this.tooltipText = textNode.innerHTML
        this.positionLeft = position.left + position.width
        this.positionTop = position.top -  viewportState.position.top + position.height / 2 - TOOLTIP_MIN_HEIGHT
        this.showTooltip = true
        const dotNode =sequence.dot;
        if(dotNode){
          this.currentSequence  = sequenceId;
          sequence.showToolTip = true
        }
     }else {
      this.hideTooltip()
     }
    }else {
      this.hideTooltip()
    }
  }
  else {
    this.hideTooltip()
  }
  }

  hideTooltip(){
    if (this.showTooltip) {
      this.showTooltip = false
      if(this.currentSequence){
        const { state } = this.props
        const { structure: structureState } = state
        const {  viewport: viewportState } = structureState
        const sequence = viewportState.sequenceRef[this.currentSequence];
          if(sequence){
            sequence.showToolTip = false; 
          }
      }
    }
  }

   componentDidMount() {
    const { state } = this.props
    const { structure: structureState } = state
    // Initialize Size and Position
    structureState.viewport.resize()
   
  }

  render() {
    const { edition, state } = this.props
    const { structure: structureState } = state
    const { camera, selections, viewport: viewportState } = structureState

    const { nodes: nodeSelections } = selections

    const props = {
      id: 'viewport',
      className: 'viewport layer',
      ref: viewportState.setViewportEl,
      onWheel: this.onMouseWheel,
      onMouseMove: this.onMouseMove,
      onMouseDown: this.onMouseDown,
      onMouseUp: this.onMouseUp,
      onMouseEnter: viewportState.onMouseEnter,
      onMouseLeave: viewportState.onMouseLeave,
      onDragStart: this.onDragStart,
      onDragOver: this.onDragOver,
      onDrop: this.onDrop,
      onContextMenu: this.onContextMenu,
      onDoubleClick: this.onDoubleClick
    }

    return (
      <div className="viewPortContainer" {...props}>
        <svg width="100%" height="100%">
          {BaseContent.render(camera, edition, [], nodeSelections, viewportState)}
        </svg>
        {this.showTooltip && (
          <div className="tooltip" style={{ left: this.positionLeft, top: this.positionTop }}>
            {this.tooltipText}
          </div>
        )}
      </div>
    )
  }
}

decorate(StructureViewportComponent, {
  showTooltip: observable,
  tooltipText: observable,
  positionTop: observable,
  positionLeft: observable,
  currentSequence: observable,
})

const StructureViewport = observer(StructureViewportComponent)

export default StructureViewport
