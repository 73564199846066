import React from 'react'
import { observer } from 'mobx-react'

const AudioLabels = observer(({ rows, timelineState, layoutState }) => {
  const rowLabels = rows.map((row, index) => (
    <AudioLabel
      key={row.objectId}
      row={row}
      timelineState={timelineState}
      layoutState={layoutState}
    />
  ))
  return <div className="audio-labels inset">{rowLabels}</div>
})

const AudioLabel = observer(class AudioLabel extends React.Component {
  select = (e) => {
    e.stopPropagation()
    const { row, timelineState, layoutState } = this.props
    const { selections } = layoutState
    const isSelected = selections.audioRows.includes(row)
    const isLocked = timelineState.audioLocked || row.timelineState.locked
    if (isLocked) return
    if (!isSelected && !e.shiftKey) {
      selections.clearActionSelections()
      selections.clearAssetSelections()
      selections.clearKeyframeSelections()
      selections.clearVideoSelections()
    }
    selections.setAudioSelection(row, e.shiftKey)
  }
  toggleLabels = (e) => {
    const { row } = this.props
    row.timelineState.toggleProp('showText')
  }
  toggleMute = (e) => {
    const { row } = this.props
    row.timelineState.toggleProp('muted')
  }
  toggleLock = (e) => {
    const { row, layoutState } = this.props
    layoutState.selections.deselectAudio(row)
    row.timelineState.toggleProp('locked')
  }
  duplicate = () => {
    const { row } = this.props
    row.duplicate()
  }
  render() {
    const { row, timelineState, layoutState } = this.props
    const { audioRows: selections } = layoutState.selections

    let name = row.name
    if (!name && row.audioMedia) {
      name = row.audioMedia.fileName
    }
    if (!name && !row.audioMedia) {
      name = 'Empty Audio'
    }

    const isSelected = selections.includes(row)
    const isTextShown = row.timelineState.showText || timelineState.showText
    const isMuted = row.timelineState.muted || timelineState.muteAll
    const isLocked = row.timelineState.locked || timelineState.audioLocked

    return (
      <div className={`audio-label ${isSelected ? 'selected' : ''}`}>
        <button className="indicator" disabled={true}>
          <i className="sprite timeline caret" />
        </button>
        <div className="asset-name" onMouseUp={this.select}>
          <span className="truncate">{name}</span>
        </div>
        <button
          type="button"
          title="Toggle Row Labels"
          onClick={this.toggleLabels}
          className={`action ${isTextShown ? 'active' : ''}`}>
          <i className={`sprite timeline ${isTextShown ? 'on' : 'off'}`} />
        </button>
        <button
          type="button"
          title="Mute"
          onClick={this.toggleMute}
          className={`action ${isMuted ? 'active' : ''}`}>
          <i className={`sprite timeline ${isMuted ? 'on' : 'off'}`} />
        </button>
        <button
          type="button"
          title="Lock"
          onClick={this.toggleLock}
          className={`action ${isLocked ? 'active' : ''}`}>
          <i className={`sprite timeline ${isLocked ? 'on' : 'off'}`} />
        </button>
        <button type="button" title="Duplicate" onClick={this.duplicate} className="action">
          <i className="sprite timeline off active-on" />
        </button>
      </div>
    )
  }
})

export default AudioLabels
