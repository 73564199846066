import { types, getSnapshot, getParent, getRoot } from 'mobx-state-tree'
import {
  BaseContent,
  AudioRow as AudioRowFactory,
  Action as ActionFactory,
  Statement as StatementFactory,
} from 'eplayer-core'
import vec3 from 'gl-vec3'
import DecisionNode from './DecisionNode'
import Action from '../Action'
import AudioRow from '../AudioRow'
import VideoRow from '../VideoRow'
import Stage from '../Asset/Stage'
import Statement from '../Statement'
import { setProp, strCompare, walkTree } from '../utils'
import { SEQUENCE_OFFSET_Y, MIN_SEQUENCE_DURATION, MAX_SEQUENCE_DURATION, KEYFRAME_PROPS } from '../constants'

const SequenceCurrent = types.model('SequenceCurrent', {
  position: types.array(types.number),
  radius: types.number,
  localMatrix: types.array(types.number),
  worldMatrix: types.array(types.number),
})

const SequenceScriptState = types
  .model('SequenceScriptState', {
    expanded: types.boolean,
    showInfo: types.boolean,
    showConnections: types.boolean,
    showAlerts: types.boolean,
  })
  .actions(self => {
    function toggleProp(prop) {
      self[prop] = !self[prop]
    }
    return {
      toggleProp,
    }
  })

const sequenceAttributes = [
  'programCompletion',
  'menu'
]

const model = types
  .model('Sequence', {
    Type: types.literal('Sequence'),
    objectId: types.identifier,
    name: types.string,
    description: types.string,
    next: types.late(() =>
      types.maybeNull(types.reference(types.union(DecisionNode, model)))
    ),
    duration: types.union(types.number, types.string),
    actions: types.array(Action),
    audioRows: types.array(AudioRow),
    videoRows: types.array(VideoRow),
    stage: Stage,
    onEnter: types.maybeNull(Statement),
    onExit: types.maybeNull(Statement),
    current: SequenceCurrent,
    scriptState: SequenceScriptState,
    isMenu: types.boolean,
    isProgramCompletion: types.boolean,
  })
  .views(self => ({
    get sortedActions() {
      return self.actions.sort(function(a, b) {
        return a.frame > b.frame || strCompare(a.name, b.name)
      })
    },

    get parent() {
      return getParent(self, 2)
    },
  }))
  .actions(self => {
    function updateProp(prop, value) {
      setProp(prop, value, self)
    }

    function setDuration(newDuration) {
      newDuration = Math.max(newDuration, MIN_SEQUENCE_DURATION) // floor of 1
      newDuration = Math.min(newDuration, MAX_SEQUENCE_DURATION) // ceiling

      self.duration =  newDuration
    }

    function setNext(value = null) {
      self.next = value
    }

    function addAudioRow(row) {
      self.audioRows.push(row)
    }

    function addAudioRowAtIndex(p, row) {
      self.audioRows.splice(p, 0, row)
    }

    // Script Panel dropdown insert Row
    function insertAudioRow(position) {
      const s = self
      position = position === undefined ? s.audioRows.length : position
      var row = AudioRow.create(AudioRowFactory())
      s.addAudioRowAtIndex(position, row)
      return row
    }

    // Dragging a Row in Script Panel
    function moveAudioRow(row, position) {
      const edition = getRoot(self)
      edition.detachNode(row)
      self.addAudioRowAtIndex(position, row)
    }

    // Script Panel dropdown remove Row
    function removeAudioRow(row) {
      self.audioRows.remove(row)
    }

    function addVideoRow(row) {
      self.videoRows.push(row)
    }

    function addVideoRowAtIndex(p, row) {
      self.videoRows.splice(p, 0, row)
    }

    // Script Panel dropdown insert Row
    function insertVideoRow(position) {
      const s = self
      position = position === undefined ? s.videoRows.length : position
      var row = VideoRow.create(VideoRowFactory())
      s.addVideoRowAtIndex(position, row)
      return row
    }

    // Dragging a Row in Script Panel
    function moveVideoRow(row, position) {
      const edition = getRoot(self)
      edition.detachNode(row)
      self.addVideoRowAtIndex(position, row)
    }

    // Script Panel dropdown remove Row
    function removeVideoRow(row) {
      self.videoRows.remove(row)
    }

    function insertAction() {
      self.actions.push(Action.create(ActionFactory()))
    }

    function removeAction(action) {
      self.actions.remove(action)
    }

    function updateEvent(event, value) {
      const props = { args: '', string: value }

      self[event] = Statement.create({
        ...StatementFactory(),
        ...props,
      })
    }

    function duplicate(chapter) {
      chapter = chapter || self.parent
      // TODO Might break with template
      let clone = self.clone()
      // set clone's default positioning
      clone.current.position[1] = self.current.position[1] + SEQUENCE_OFFSET_Y
      chapter.addSequence(clone)
    }

    function paste(obj, isSameDomain) {
      // Re-clone on paste for refreshed UUIDs
      if (obj.Type === 'DecisionNode')
        self.parent.addDecisionNode(BaseContent.DecisionNode.clone(obj))
      // Clear Media from certain asset types if domains do not match
      if (obj.Type === 'Sequence') {
        isSameDomain
          ? self.parent.addSequence(BaseContent.Sequence.clone(obj))
          : self.parent.addSequence(BaseContent.Sequence.cloneWithoutMedia(obj))
      }
    }

    function clone() {
      // run graph clone to setup new UUIDs and such
      // Note: Sequence graph clone removes .next connections
      const s = BaseContent.Sequence.clone(getSnapshot(self))
      return s
    }

    // Dragging Sequence in Structure Viewport
    function translate(delta) {
      vec3.add(self.current.position, self.current.position, delta)
    }

    // Dragging a Sequence onto another Sequence in Structure Hierarchy
    function moveContent(node) {
      const edition = getRoot(self)
      const newParent = self.parent
      const newPeers = newParent['sequences']
      const i = newPeers.indexOf(self)
      edition.detachNode(node)
      newParent.addSequenceAtIndex(i, node)
    }

    function timelineShift(frameAt, modifierFn) {
      const s = self
      // Shift trailing Actions by 1
      for (let i = 0, l = s.actions.length; i < l; i++) {
        const action = s.actions[i]
        if (action.frame <= frameAt) continue
        action.updateProp('frame', modifierFn(action.frame))
      }
      // Shift trailing Audio by 1
      for (let i = 0, l = s.audioRows.length; i < l; i++) {
        const row = s.audioRows[i]
        if (row.startTime <= frameAt) continue
        row.updateProp('startTime', modifierFn(row.startTime))
      }
      // Shift trailing Keyframes by 1
      walkTree((n) => {
        // Loop through each of the keyframe properties
        for (let i = 0, l = KEYFRAME_PROPS.length; i < l; i++) {
          const keyframeProp = KEYFRAME_PROPS[i]
          // Check for non-shared keyframe props
          if (n.hasOwnProperty(keyframeProp)) {
            const kfs = n[keyframeProp]
            // Loop through each of the keyframe properties keyframes
            for (let j = 0, l2 = kfs.length; j < l2; j++) {
              const kf = kfs[j]
              if (kf.frame <= frameAt) continue
              kf.updateFrame(modifierFn(kf.frame))
            }
          }
        }
      }, s.stage)
    }

    function insertFrame(frameAt) {
      const increment = (prevFrame) => ++prevFrame
      let newDuration = parseInt(self.duration) + 1
      self.setDuration(newDuration)
      self.timelineShift(frameAt, increment)
    }

    function removeFrame(frameAt) {
      const decrement = (prevFrame) => --prevFrame
      let newDuration = parseInt(self.duration) - 1
      self.setDuration(newDuration)
      self.timelineShift(frameAt, decrement)
    }

    function toggleAttribute(attrName) {
      if (!sequenceAttributes.includes(attrName)) return
      if (sequenceAttributes[0] === attrName) self.isProgramCompletion = !self.isProgramCompletion
      else if (sequenceAttributes[1] === attrName) self.isMenu = !self.isMenu
    }

    return {
      updateProp,
      setDuration,
      setNext,
      addAudioRow,
      addAudioRowAtIndex,
      insertAudioRow,
      moveAudioRow,
      removeAudioRow,
      addVideoRow,
      addVideoRowAtIndex,
      insertVideoRow,
      moveVideoRow,
      removeVideoRow,
      insertAction,
      removeAction,
      updateEvent,
      duplicate,
      paste,
      clone,
      translate,
      moveContent,
      timelineShift,
      insertFrame,
      removeFrame,
      toggleAttribute,
    }
  })

export default model
