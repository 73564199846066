import { types } from 'mobx-state-tree'
import { Option as OptionFactory } from 'eplayer-core'
import RawSprite from '../Media/RawSprite'
import Option from '../Option'
import Keyframe from '../Keyframe'
import { SpriteTemplate } from '../Template'
import { Layerable, Rectangle, Stroke, Transformable, Visible, UI } from './shared/props'
import assetActions from './shared/actions'
import assetViews from './shared/views'
import assetChildTypes from './shared/childTypes'

const AssetType = 'Sprite'

const model = types
  .model(AssetType, {
    // Entity
    Type: types.literal(AssetType),
    objectId: types.identifier,
    // Asset
    name: types.string,
    children: types.late(assetChildTypes),
    alt: types.string,
    // MediaOptions
    spriteMedia: types.maybeNull(types.reference(RawSprite)),
    spriteMediaOptions: types.late(() => types.array(Option)),
    frame: types.number,
    frameKeyframes: types.array(Keyframe),
    template: types.maybeNull(types.reference(SpriteTemplate)),
    ...Layerable,
    ...Stroke,
    ...Rectangle,
    ...Visible,
    ...Transformable,
    ...UI,
  })
  .views(assetViews)
  .actions(self => {
    function addMediaOption(option) {
      const a = self
      option = option || Option.create(OptionFactory.Option())
      a['spriteMediaOptions'].push(option)
    }
    function removeMediaOption(option) {
      const a = self
      a['spriteMediaOptions'].remove(option)
    }
    return {
      ...assetActions(self),
      addMediaOption,
      removeMediaOption,
    }
  })
export default model
