import { types } from 'mobx-state-tree'
import { ListTemplate } from '../Template'
import { Fill, Layerable, Rectangle, Stroke, Transformable, Visible, UI } from './shared/props'
import { Asset } from 'eplayer-core'
import assetActions from './shared/actions'
import assetViews from './shared/views'
import assetChildTypes from './shared/childTypes'

const AssetType = 'List'

const model = types
  .model(AssetType, {
    // Entity
    Type: types.literal(AssetType),
    objectId: types.identifier,
    // Asset
    name: types.string,
    children: types.late(assetChildTypes),
    listStyleType: types.string,
    listStylePosition: types.string,
    template: types.maybeNull(types.reference(ListTemplate)),
    ...Layerable,
    ...Fill,
    ...Stroke,
    ...Rectangle,
    ...Visible,
    ...Transformable,
    ...UI
  })
  .views(assetViews)
  .actions(self => {
    return {
      ...assetActions(self),
      addChild(nextChild) {
        if (nextChild == null) {
          const ListItem = require('./ListItem')
          nextChild = ListItem.default.create(Asset.ListItem.withDefaults({ name: 'Untitled List Item' }))
        }

        if (nextChild.Type !== 'ListItem') return
        else self.children.push(nextChild)
      },
      acceptsDrop() {
        return false
      }
    }
  })
export default model
