import { types } from 'mobx-state-tree'
import RGBA from './RGBA'
import Expression from './Expression'

const model = types.model('BackgroundColorOption', {
	Type: types.literal('BackgroundColorOption'),
	objectId: types.identifier,
	condition: Expression,
	value: RGBA,
})

export default model
