import React from 'react'
import { decorate, observable } from 'mobx'
import { observer } from 'mobx-react'
import { getTimeStamp } from '../../utils'

const COLUMNS_PER_PAGE = 4
const LIST_ITEM_HEIGHT = 26

const EditionListItem = observer(({ ed, isSelected, selectEdition, loadSelectedEdition }) => {
  const displayName = ed.name || 'Untitled Edition'
  const timestamp = getTimeStamp(ed.lastUpdated)
  const onClick = () => selectEdition(ed)
  return (
    <li>
      <button
        title={displayName}
        className={`single-edition ${isSelected ? 'selected' : ''}`}
        onClick={onClick}
        onDoubleClick={loadSelectedEdition}>
        <span className="title">{displayName}</span>
        <span className="updated" title={`at ${timestamp[1]}`}>
          {timestamp[0]}
        </span>
      </button>
    </li>
  )
})

const EditionListPagination = observer(({ numPages, currentPage, goToPage }) => {
  if (numPages <= 1) return null
  let controls = []
  for (let i = 0; i < numPages; i++) {
    const normalizedPage = i + 1
    const onClick = () => goToPage(normalizedPage)
    controls.push(
      <button
        className={currentPage === normalizedPage ? 'active' : ''}
        onClick={onClick}
        title={`Page ${normalizedPage}`}
      />
    )
  }
  return <div className="pages">{controls}</div>
})

class LoaderPanelComponent extends React.Component {
  setListEl = (el) => {
    if (!el) return
    this.listEl = el
    this.listElSize.width = el.clientWidth
    this.listElSize.height = el.clientHeight
  }
  setSearchEl = (el) => {
    if (!el) return
    this.searchEl = el
  }
  keyPress = (e) => {
    if (e.which == 27) {
      this.goBack()
    }
  }
  goBack = () => {
    const { app } = this.props
    const em = app.state.editionmanager
    em.showHomepage()
  }
  setFocus = (el) => {
    if (!el) return
    // Focus the UI for keyboard control
    el.focus()
  }
  constructor(props) {
    super(props)
    // DOM props
    this.listEl = null
    this.searchEl = null
    this.listElSize = {
      width: 0,
      height: 0
    }
  }
  render() {
    const { app } = this.props
    const fileList = app.state.editionmanager.legacyFileList

    // DOM dimensions
    const height = this.listElSize.height
    const width = this.listElSize.width

    // Pagination
    const perColumn = Math.floor(height / LIST_ITEM_HEIGHT)
    const perPage = perColumn * COLUMNS_PER_PAGE
    const numPages = perPage ? Math.ceil(fileList.filteredEditions.length / perPage) : 0

    const loadSelectedEdition = (_) => {
      if (fileList.selection) {
        app.loadEdition(fileList.selection.objectId)
      }
    }

    const updateSearch = (e) => {
      fileList.setSearch(e.target.value)
    }

    const openSearch = (_) => {
      fileList.resetSearch()
      fileList.toggleSearching()
      this.searchEl.focus()
    }

    const closeSearch = (_) => {
      fileList.resetSearch()
      fileList.toggleSearching()
    }

    const sortByName = (_) => fileList.setSort('name')
    const sortByUpdated = (_) => fileList.setSort('updated')

    const editionList = fileList.filteredEditions.map((ed) => {
      const isSelected = fileList.selection === ed
      return (
        <EditionListItem
          key={ed.objectId}
          ed={ed}
          isSelected={isSelected}
          selectEdition={fileList.setSelection}
          loadSelectedEdition={loadSelectedEdition}
        />
      )
    })

    const loadButtonParams = {
      type: 'button',
      onClick: loadSelectedEdition,
      disabled: !fileList.selection,
      className: 'load-button'
    }

    return (
      <div id="loader" ref={this.setFocus} tabIndex="0" onKeyDown={this.keyPress}>
        <div className={`header ${fileList.searching ? 'searching' : ''}`}>
          <h1>Open</h1>
          <button type="button" className="search" onClick={openSearch}>
            <i className="sprite conversion search" />
          </button>
          <input
            type="text"
            ref={this.setSearchEl}
            value={fileList.search}
            onChange={updateSearch}
            className="search-field"
            placeholder="Edition Search"
          />
          <button
            type="button"
            className="close-search"
            disabled={!fileList.searching}
            onClick={closeSearch}>
            <i className="sprite conversion close" />
          </button>
        </div>
        <div className="editions" ref={this.setListEl}>
          <ul
            className="wrapper"
            style={{ transform: `translateX(-${width * fileList.page - width}px)` }}>
            {editionList}
          </ul>
        </div>
        <EditionListPagination
          numPages={numPages}
          currentPage={fileList.page}
          goToPage={fileList.setPage}
        />
        <div className="actions">
          <button {...loadButtonParams}>Open File</button>
          <button type="button" className="cancel-button" onClick={this.goBack}>
            Cancel or <span className="faux-key">ESC</span>
          </button>
        </div>
        <div className="sort">
          Sort by&nbsp;
          <button
            className={`inline ${fileList.sort === 'name' ? 'active' : 'inactive'}`}
            onClick={sortByName}>
            Name
          </button>
          &nbsp;|&nbsp;
          <button
            className={`inline ${fileList.sort === 'updated' ? 'active' : 'inactive'}`}
            onClick={sortByUpdated}>
            Updated
          </button>
        </div>
      </div>
    )
  }
}

decorate(LoaderPanelComponent, {
  listEl: observable.ref,
  searchEl: observable.ref,
  listElSize: observable,
})

const LoaderPanel = observer(LoaderPanelComponent)

export default LoaderPanel
