import React from 'react'
import { observer } from 'mobx-react'

function initializeFileInput(multiple, accept) {
  const input = document.createElement('input')
  input.type = 'file'
  input.multiple = multiple ? true : undefined // boolean attribute
  input.accept = accept || '.jpg,.png,.svg,.mp3,.mp4,.js'
  return input
}

const XLSInput = observer(class XLSInput extends React.Component {
  componentDidMount() {
    const { conversion } = this.props
    this.input = initializeFileInput(false, '.xls, .xlsx')
    this.input.onchange = this.read.bind(this, conversion)
  }
  read(conversion, e) {
    conversion.readXLS(e.target.files[0])
    this.input = initializeFileInput(false, '.xls, .xlsx')
    this.input.onchange = this.read.bind(this, conversion)
  }
  render() {
    const onClick = (e) => this.input.click()
    return (
      <button type="button" className="choose" onClick={onClick} title="Choose File" data-qa-hook="import.button.chooseXLSfile">
        Choose File
      </button>
    )
  }
})

const AudioInput = observer(class AudioInput extends React.Component {
  componentDidMount() {
    const { conversion } = this.props
    this.input = initializeFileInput(true, '.mp3')
    this.input.onchange = this.upload.bind(this, conversion)
  }
  upload(conversion, e) {
    conversion.readAudio(e.target.files)
    this.input = initializeFileInput(true, '.mp3')
    this.input.onchange = this.upload.bind(this, conversion)
  }
  render() {
    const { conversion } = this.props
    const onClick = (e) => this.input.click()
    return (
      <button
        data-qa-hook="import.button.chooseFilesAudio"
        type="button"
        className="choose"
        onClick={onClick}
        title="Choose Files"
        disabled={conversion.audio.status ? true : false}>
        Choose Files
      </button>
    )
  }
})

const CanvasInput = observer(class CanvasInput extends React.Component {
  componentDidMount() {
    const { conversion } = this.props
    this.input = initializeFileInput(true, '.js')
    this.input.onchange = this.upload.bind(this, conversion)
  }
  upload(conversion, e) {
    conversion.readCanvas(e.target.files)
    this.input = initializeFileInput(true, '.js')
    this.input.onchange = this.upload.bind(this, conversion)
  }
  render() {
    const { conversion } = this.props
    const onClick = (e) => this.input.click()
    return (
      <button
        data-qa-hook="import.button.chooseFilesMedia"
        type="button"
        className="choose"
        onClick={onClick}
        title="Choose Files"
        disabled={conversion.canvas.status ? true : false}>
        Choose Files
      </button>
    )
  }
})

const ProfileSelect = observer(class ProfileSelect extends React.Component {
  render() {
    const { conversion } = this.props
    return (
      <select value={conversion.profile} onChange={(e) => conversion.changeProfile(e.target.value)} data-qa-hook="import.select.profile">
        <option value="New" data-qa-hook="import.selectProfile.option.new">New</option>
        <option value="Old" data-qa-hook="import.selectProfile.option.old">Old</option>
      </select>
    )
  }
})

const LangSelect = observer(class LangSelect extends React.Component {
  render() {
    const conversion = this.props.conversion
    return (
      <select value={conversion.lang} onChange={(e) => conversion.changeLanguage(e.target.value)} data-qa-hook="import.select.language">
        <option value="en" data-qa-hook="import.selectLang.option.en">EN</option>
        <option value="es" data-qa-hook="import.selectLang.option.es">ES</option>
      </select>
    )
  }
})

const EditionTitle = observer(class EditionTitle extends React.Component {
  render() {
    const conversion = this.props.conversion
    return (
      <input
        type="text"
        data-qa-hook="import.input.editiontitle"
        maxLength={128}
        value={conversion.editionName}
        onChange={(e) => conversion.updateEditionName(e.target.value)}
      />
    )
  }
})

const AudioProgress = observer(class AudioProgress extends React.Component {
  render() {
    const { audio } = this.props.conversion
    if (audio.status === 'inprogress') {
      return (
        <p data-qa-hook="import.uploadAudio.result">
          Progress: {audio.completed.length} of {audio.pending.length} Audio Files
        </p>
      )
    } else if (audio.status === 'success') {
      return (
        <p data-qa-hook="import.uploadAudio.result">
          Completed upload of {audio.completed.length} of {audio.pending.length} Audio Files
        </p>
      )
    } else if (audio.status === 'failed') {
      return <p data-qa-hook="import.uploadAudio.result">Upload Failed due to {audio.failed.length} errors.</p>
    } else return null
  }
})

const CanvasProgress = observer(class CanvasProgress extends React.Component {
  render() {
    const { canvas } = this.props.conversion
    if (canvas.status === 'inprogress') {
      return (
        <p data-qa-hook="import.uploadMedia.result">
          Progress: {canvas.completed.length} of {canvas.pending.length} Animation Files
        </p>
      )
    } else if (canvas.status === 'success') {
      return (
        <p data-qa-hook="import.uploadMedia.result">
          Completed upload of {canvas.completed.length} of {canvas.pending.length} Animation Files
        </p>
      )
    } else if (canvas.status === 'failed') {
      return <p data-qa-hook="import.uploadMedia.result">Upload Failed due to {canvas.failed.length} errors.</p>
    } else return null
  }
})

const ImportPanel = observer(class ImportPanel extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { app, conversion } = this.props
    const audioCompleted = conversion.audio.completed
    const canvasCompleted = conversion.canvas.completed
    const audioFailed = conversion.audio.failed
    const canvasFailed = conversion.canvas.failed
    const conversionReady = !conversion.ready
    const audioReset = () => conversion.resetAudio()
    const canvasReset = () => conversion.resetCanvas()
    const errors = conversion.errors
    const removeXLS = () => conversion.resetXLS()
    const back = () => {
      app.route.goBack()
      conversion.reset()
    }

    const audioList = audioCompleted.map((c, key) => (
      <li key={key}>
        <i className="sprite conversion audio" /> {c.fileName}
      </li>
    ))
    const audioFailList = audioFailed.map((c, key) => (
      <li key={key}>
        <i className="sprite conversion audio" /> {c.name}
      </li>
    ))
    const canvasList = canvasCompleted.map((c, key) => (
      <li key={key}>
        <i className="sprite conversion document" /> {c.fileName}
      </li>
    ))
    const canvasFailList = canvasFailed.map((c, key) => (
      <li key={key}>
        <i className="sprite conversion document" /> {c.name}
      </li>
    ))
    const errorList = errors.map((error, index) => (
      <li key={index} className="message">
        {error.message}
      </li>
    ))

    const conversionButtonParams = {
      type: 'button',
      onClick: (_) => {
        conversion.start(app.route)
      },
      disabled: conversionReady,
      className: 'conversion-button'
    }

    return (
      <div id="import">
        <div className={`header`}>
          <h1>Import</h1>
          <p>
            Notice: Currently this import tool is limited to uploading audio and animation files as
            a single batch. You must upload all of your files at once for each type.<br />
            <br />We have made recent changes to make this process easier. See the help
            documentation on Confluence for more information.
          </p>
        </div>
        <div className="container">
          <div className="steps">
            <div className="step">
              <p className="step-text">1. Name your new edition:</p>
              <EditionTitle conversion={conversion} />
              <p className={`file-name ${conversion.editionName ? 'has-file' : ''}`}>{`${
                conversion.editionName
                  ? ''
                  : 'An edition name is required. Maximum of 128 characters.'
              }`}</p>
            </div>
            <div className="step">
              <p className="step-text">2. Select an XLS file:</p>
              <XLSInput conversion={conversion} />
              <p className={`file-name ${conversion.xls.sheet ? 'has-file' : ''}`}>
                <i className="sprite conversion document" />{' '}
                {`${conversion.xls.sheet ? conversion.xls.name : 'No XLS file selected'}`}{' '}
                <button type="button" onClick={removeXLS}>
                  <i className="sprite conversion remove" />
                </button>
              </p>
            </div>
            <div className="step">
              <p className="step-text">3. Select Profile</p>
              <ProfileSelect conversion={conversion} />
            </div>
            <div className="step">
              <p className="step-text">4. Select language:</p>
              <LangSelect conversion={conversion} />
            </div>
            <div className="step">
              <p className="step-text">5. Select all audio files:</p>
              <AudioInput conversion={conversion} />
              <span
                className={`clear-button ${conversion.audio.uploadfinished ? '' : 'hidden'}`}
                onClick={audioReset}>
                Clear audio uploads
              </span>
              <AudioProgress conversion={conversion} />
              {conversion.audio.completed.length > 0 && (
                <div className="upload-success">
                  <h5>Completed:</h5>
                  <ol className="file-list">{audioList}</ol>
                </div>
              )}
              {conversion.audio.failed.length > 0 && (
                <div className="upload-failed">
                  <h5>Failed:</h5>
                  <ol className="file-list">{audioFailList}</ol>
                </div>
              )}
            </div>
            <div className="step">
              <p className="step-text">6. Select all animation files:</p>
              <CanvasInput conversion={conversion} />
              <span
                className={`clear-button ${conversion.canvas.uploadfinished ? '' : 'hidden'}`}
                onClick={canvasReset}>
                Clear animation uploads
              </span>
              <CanvasProgress conversion={conversion} />
              {conversion.canvas.completed.length > 0 && (
                <div className="upload-success">
                  <h5>Completed:</h5>
                  <ol className="file-list">{canvasList}</ol>
                </div>
              )}
              {conversion.canvas.failed.length > 0 && (
                <div className="upload-failed">
                  <h5>Failed:</h5>
                  <ol className="file-list">{canvasFailList}</ol>
                </div>
              )}
            </div>
            <div className="step row">
              <button {...conversionButtonParams} data-qa-hook="import.button.beginConversion">Begin Conversion</button>
              <button type="button" className="cancel-button" onClick={back} data-qa-hook="import.button.beginConversion">
                Cancel or <span className="faux-key">ESC</span>
              </button>
            </div>
          </div>
          <div className={`errors ${errorList.length > 0 ? 'visible' : 'hidden'}`}>
            <h4>Unable to convert script due to the following {errorList.length} errors - </h4>
            <ul>{errorList}</ul>
          </div>
        </div>
      </div>
    )
  }
})

export default ImportPanel
