import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { MAX_SEQUENCE_DURATION } from '../models/constants'

const maxSequence = MAX_SEQUENCE_DURATION;
const DEFAULT_WIDTH = 1
const SIZER_STYLE = {
  position: 'absolute',
  top: 0,
  left: 0,
  visibility: 'hidden',
  height: 0,
  overflow: 'scroll',
  whiteSpace: 'pre'
}

const Input = observer(class Input extends React.Component {
  constructor(props) {
    super(props)

    this.defaultValue = props.type === 'number' ? 0 : ''
    this.state = {
      focused: false,
      inputWidth: DEFAULT_WIDTH,
      value: props.value ? String(props.value) : this.defaultValue
    }

    this.onKeyDown = this.onKeyDown.bind(this)
    this.onKeyUp = this.onKeyUp.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.setInputWidth = this.setInputWidth.bind(this)
  }
  onKeyDown(e) {
    const { onSave, name, comparative } = this.props
    e.stopPropagation()
    // Save on enter or tab
    this.noNegativeFrames(name, e)
    if (e.keyCode === 13) {
      this.validateField(name, this, onSave, comparative)
    }
  }
  onKeyUp(e) {
    e.stopPropagation()
  }
  onChange(e) {
    const { value } = e.target
    this.setState({ value })
  }
  onFocus(e) {
    this.setState({ focused: true })
  }
  onBlur(e) {
    const { onSave, disableOnBlur } = this.props
    this.setState({ focused: false })
    // Save on blur
    if (!disableOnBlur) {
      onSave(this.state.value)
    }
  }
  validateField(fieldName, element, onSave, comparison) {
    if (fieldName === undefined){ // Field has no title, so save changes
      onSave(element.state.value)
    }
    else if (fieldName === 'durationControl' || fieldName === 'currentFrame') {
      if (element.state.value >= comparison) { // duration over max
        element.setState( {value: comparison})
        onSave(element.state.value)
      }
      else if (element.state.value <= 1 || element.state.value === '' || element.state.value === null || element.state.value === undefined) { // duration under 1
        element.setState( {value: 1})
        onSave(element.state.value)
      }
      else { // Any other duration
        onSave(parseInt(element.state.value))
      }
    }
    else { // Field has title, but no conditions, so just save
      onSave(value)
    }
  }

  parseGhostValue(fieldName, ghostValue){
    if (fieldName === 'durationControl' || fieldName === 'currentFrame'){
      return parseInt(ghostValue)
    } else {
      return ghostValue
    }
  }

  noNegativeFrames(fieldName, e){
    if (fieldName === 'currentFrame') {
      if (!((e.keyCode > 95 && e.keyCode < 106)
          || (e.keyCode > 47 && e.keyCode < 58)
          || e.keyCode == 8)) {
        return false;
      }
    }
  }

  setInputWidth() {
    if (this.mounted && this.ghost) {
      const newInputWidth = this.ghost.scrollWidth + 2
      if (newInputWidth !== this.state.inputWidth) {
        this.setState({
          inputWidth: newInputWidth
        })
      }
    }
  }
  getTextField() {
    const {
      title,
      placeholder,
      className,
      onSave,
      pattern,
      maxLength,
      type,
      disabled,
      min,
      max,
      step,
      tabIndex,
      autoName,
    } = this.props
    const { inputWidth, value } = this.state
    const textfieldClass = className ? `text-field ${className}` : 'text-field'
    const textfieldStyles = {
      width: inputWidth,
      boxSizing: 'content-box',
      textOverflow: 'ellipsis'
    }
    return (
      <input
        type={type}
        pattern={pattern}
        maxLength={maxLength}
        disabled={disabled}
        min={min}
        max={max}
        step={step}
        tabIndex={tabIndex}
        className={textfieldClass}
        title={title || value}
        placeholder={placeholder}
        value={value}
        style={textfieldStyles}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onKeyUp={this.onKeyUp}
        onKeyDown={this.onKeyDown}
        data-qa-hook={autoName}
      />
    )
  }
  getGhostField() {
    const { placeholder, name} = this.props
    const { value } = this.state
    let ghostValue = value && value.length || value && name ? value : placeholder // Condition added to support duration control override
    ghostValue = this.parseGhostValue(name, ghostValue)
    return (
      <div style={SIZER_STYLE} ref={(c) => (this.ghost = c)} aria-hidden="true">
        {ghostValue}
      </div>
    )
  }
  componentDidMount() {
    this.mounted = true
    this.setInputWidth()
  }
  componentDidUpdate() {
    this.setInputWidth()
  }
  componentWillUnmount() {
    this.mounted = false
  }
  componentWillReceiveProps(newProps) {
    if (newProps.value !== this.props.value) {
      this.setState({ value: String(newProps.value) })
    }
  }
  render() {
    return (
      <div className="input-wrap">
        {this.getTextField()}
        {this.getGhostField()}
      </div>
    )
  }
})

Input.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  step: PropTypes.number,
  tabIndex: PropTypes.number
}

export default Input
