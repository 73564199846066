import React from 'react'
import { observer } from 'mobx-react'
import ScriptInspector from '../panels/Script'
import Media, { BatchDeleteModal, UploadFileModal } from '../panels/Media'
import { TemplateUpdatesModal } from '../panels/Media'
import Widgets from '../panels/StructureWidgets'
import Variables from '../panels/Variables'
import Actions from '../panels/Actions'
import StructureHierarchy from '../panels/StructureHierarchy'
import StructureDetails from '../panels/StructureDetails'
import StructureViewport from '../panels/StructureViewport'
import { requestAnimationFrameThrottle } from '../utils'
import { Card, WidgetsCard } from '../components'

const StructureUI = observer(class StructureUI extends React.Component {
  resize = () => {
    const { app } = this.props
    const { state } = app
    state.ui.resizeWindow()
    state.structure.viewport.resize()
  }
  setFocus = (el) => {
    if (!el) return
    // Focus the UI for keyboard control
    el.focus()
  }

  collapsePanel = (name) => {
    const { collapseState } = this.props.app.state.ui
    collapseState.toggleCollapse(name)
  }
  componentDidMount() {
    const { app } = this.props
    const { state } = app
    // Handle window/viewport sizing
    requestAnimationFrameThrottle('resize', 'optimizedResize')
    window.addEventListener('optimizedResize', this.resize)
    this.resize()
    state.structure.enter()
  }
  componentWillUnmount() {
    const { app } = this.props
    const { state } = app
    window.removeEventListener('optimizedResize', this.resize)
    state.structure.exit()
  }

  closeUploadModal = () => {
    const { app } = this.props
    const { state } = app
    const {  media: mediaState } = state
      mediaState.hideUploadModal();
  }

  render() {
    const { app } = this.props
    const { signals, edition, versionDetails, state, route } = app
    const { variables } = edition
    const { ui, global: globalState, layout: layoutState, media: mediaState, script: scriptState } = state
    const { activeCard } = ui

    // column
    const { leftColumn, scriptColumn, viewportColumn, rightColumn } = ui
    const { activeIndex } = leftColumn

    // cards
    const [widgetsCard, mediaCard, variablesCard, actionsCard] = leftColumn.cards
    const [scriptCard] = scriptColumn.cards
    const [viewportCard] = viewportColumn.cards
    const [hierarchyCard, detailsCard] = rightColumn.cards
    const { setActiveCard, setActiveTab } = ui
    const onClickCard = (card) => (e) => (e.stopPropagation(), setActiveCard(card))

    let leftColumnContent = null
    let rightColumnContent = null
    let scriptColumnContent = null

    // for expand/collapse and panel resize
    const { collapseState } = state.ui
    const variableCardProps = {
      activeCard: activeCard,
      card: variablesCard,
      onClick: onClickCard(variablesCard),
      name: 'Variables',
      collapsed: collapseState.panels.Variables,
      height: ui.verticalPanelHeightStructure,
      innerHeight: ui.verticalPanelHeightStructureInner,
      collapsePanel: this.collapsePanel
    }
    const actionsCardProps = {
      activeCard: activeCard,
      card: actionsCard,
      onClick: onClickCard(actionsCard),
      name: 'Actions',
      collapsed: collapseState.panels.Actions,
      height: ui.verticalPanelHeightStructure,
      innerHeight: ui.verticalPanelHeightStructureInner,
      collapsePanel: this.collapsePanel
    }
    const detailsCardProps = {
      activeCard: activeCard,
      card: detailsCard,
      onClick: onClickCard(detailsCard),
      name: 'Details',
      collapsed: collapseState.panels.Details,
      height: ui.verticalPanelHeightStructure,
      innerHeight: ui.verticalPanelHeightStructureInner,
      collapsePanel: this.collapsePanel
    }
    const mediaCardProps = {
      activeCard: activeCard,
      card: mediaCard,
      onClick: onClickCard(mediaCard),
      name: 'Media',
      collapsed: collapseState.panels.Media,
      height: ui.verticalPanelHeightStructure,
      innerHeight: ui.verticalPanelHeightStructureInner,
      collapsePanel: this.collapsePanel
    }
    const widgetsCardProps = {
      activeCard: activeCard,
      card: widgetsCard,
      onClick: onClickCard(widgetsCard),
      name: 'Widgets',
      collapsed: collapseState.panels.Widgets,
      collapsePanel: this.collapsePanel
    }
    const hierarchyCardProps = {
      activeCard: activeCard,
      card: hierarchyCard,
      onClick: onClickCard(hierarchyCard),
      name: 'Hierarchy',
      collapsed: collapseState.panels.Hierarchy,
      height: ui.verticalPanelHeightStructure,
      innerHeight: ui.verticalPanelHeightStructureInner,
      collapsePanel: this.collapsePanel
    }
    const viewportCardProps = {
      activeCard: activeCard,
      card: viewportCard,
      onClick: onClickCard(viewportCard),
      height: ui.verticalPanelHeightStructure,
      innerHeight: ui.verticalPanelHeightStructureInner
    }
    const scriptCardProps = {
      activeCard: activeCard,
      card: scriptCard,
      onClick: onClickCard(scriptCard),
      name: 'Scripting',
      collapsed: collapseState.panels.Scripting,
      height: ui.verticalPanelHeightStructure,
      innerHeight: ui.verticalPanelHeightStructureInner,
      collapsePanel: this.collapsePanel
    }
    const goToSequenceId = id => {
      layoutState.setup(variables, id)
      route.goTo("Layout")
    }
    return (
      <div id="interface" ref={this.setFocus} tabIndex="0">
        <div
          className="top-row"
          style={{
            width: '100%',
            ...collapseState.panels.Widgets
              ? { display: 'none' }
              : { width: '100%' }
          }}>
          <WidgetsCard {...widgetsCardProps}>
            <Widgets globalState={globalState} />
          </WidgetsCard>
        </div>
        <div
          className="column left"
          style={
            collapseState.panels.Media ? { display: 'none' } : { width: '220px', display: 'block' }
          }>
          <Card {...mediaCardProps}>
            <Media globalState={globalState} previewTemplateOnClick={goToSequenceId} mediaState={mediaState} edition={edition} />
          </Card>
        </div>
        { mediaState.showUploadPopup && <UploadFileModal cancel={this.closeUploadModal} mediaState={mediaState} /> }
        { mediaState.showBatchDelete && <BatchDeleteModal mediaState={mediaState}/> }
        { mediaState.showTemplateUpdates &&
          <TemplateUpdatesModal onDone={mediaState.endDialog}>{
            mediaState.sessionTemplateUpdates.map(([text, id], index) => {
              const vDom =
                <div className="file-selection-row" key={"file-row-" + index} >
                  <div data-qa-hook={`template-update-${index}`} className="file-name template-update" onClick={ () => { mediaState.endDialog(); goToSequenceId(id) } }>{text}</div>
                </div>

              return vDom
            })
          }</TemplateUpdatesModal> }
        <div
          className="column left"
          style={
            collapseState.panels.Variables
              ? { display: 'none' }
              : { width: '220px', display: 'block' }
          }>
          <Card {...variableCardProps}>
            <Variables edition={edition} player={layoutState.player}/>
          </Card>
        </div>
        <div
          className={ui.codeEditing ? `column left zpush` : `column left`}
          style={
            collapseState.panels.Actions
              ? { display: 'none' }
              : { width: '221px', display: 'block' }
          }>
          <Card {...actionsCardProps}>
            <Actions state={state} vars={edition.variables} ui={ui} />
          </Card>
        </div>
        <div
          className={`column script left`}
          style={
            collapseState.panels.Scripting
              ? { display: 'none' }
              : { width: scriptState.extended ? 400 : 400, display: 'block' }
          }>
          <Card {...scriptCardProps}>
            <ScriptInspector state={state} edition={edition} route={route} />
          </Card>
        </div>
        <div className="column free">
          <div className="row">
            <Card {...viewportCardProps}>
              <StructureViewport state={state} edition={edition} signals={signals} route={route}/>
            </Card>
          </div>
        </div>
        <div
          className="column right"
          style={
            collapseState.panels.Details
              ? { display: 'none' }
              : { width: '220px', display: 'block' }
          }>
          <Card {...detailsCardProps}>
            <StructureDetails state={state} edition={edition} versionDetails={versionDetails} />
          </Card>
        </div>
        <div
          className="column right"
          style={
            collapseState.panels.Hierarchy
              ? { display: 'none' }
              : { width: '220px', display: 'block' }
          }>
          <Card {...hierarchyCardProps}>
            <StructureHierarchy
              state={state}
              edition={edition}
              route={route}
              variables={variables}
            />
          </Card>
        </div>
      </div>
    )
  }
})

export default StructureUI
