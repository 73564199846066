import { observable, action, computed } from 'mobx'
import format from 'date-fns/format'
import addYears from 'date-fns/add_years'
import subDays from 'date-fns/sub_days'

export default function AdvisorLink(link) {
  let linkExp = link.linkExpiration
  if (linkExp) {
    const dateParts = linkExp.split('-')
    const date = new Date(dateParts[0], parseInt(dateParts[1]) - 1, dateParts[2]) //  'YYYY-MM-DD');
    linkExp = format(date, 'MM/DD/YYYY') // since form server side its coming as YYYY-MM-DD format.
  }
  return observable({
    id: link.id,
    editionId: link.editionId,
    editionName: link.editionName,
    editionVersion: link.editionVersion,
    advisor: link.advisor,
    link: link.link,
    token: link.token,
    notes: link.notes,
    linkExpiration: linkExp,
    linkInputExpiration: linkExp,
    created: link.created,
    createdBy: link.createdBy,
    lastUpdated: link.lastUpdated,
    lastUpdatedBy: link.lastUpdatedBy,
    setLinkExpiration: function(linkExpiration) {
      this.linkExpiration = linkExpiration
      this.linkInputExpiration = linkExpiration
    },
    setLinkInputExpiration: function(linkExpiration) {
      this.linkInputExpiration = linkExpiration
    },
    get isValid() {
      return (
        this.isExpirationDateComplete &&
        this.isExpirationDateFormatValid &&
        this.isExpirationDateAWeekPast &&
        this.isExpirationDateYearLater
      )
    },
    get isExpirationDateComplete() {
      if (!this.linkInputExpiration) return false
      return this.linkInputExpiration.indexOf('_') === -1
    },
    get isExpirationDateFormatValid() {
      if (!this.linkInputExpiration) return false
      const dateParts = this.linkInputExpiration.split('/')
      const year = parseInt(dateParts[2])
      const month = parseInt(dateParts[0])
      const day = parseInt(dateParts[1])
      if (year <= 0 || month <= 0 || day <= 0 || month > 12 || day > 31) {
        return false
      }
      return true
    },
    get isExpirationDateAWeekPast() {
      if (!this.linkInputExpiration) return false
      const dateParts = this.linkInputExpiration.split('/')
      const year = parseInt(dateParts[2])
      const month = parseInt(dateParts[0])
      const day = parseInt(dateParts[1])
      const today = new Date()
      const selectedDate = new Date(year, month - 1, day)
      if (selectedDate < subDays(today, 7)) {
        return false
      }
      return true
    },
    get isExpirationDateYearLater() {
      if (!this.linkInputExpiration) return false
      const dateParts = this.linkInputExpiration.split('/')
      const year = parseInt(dateParts[2])
      const month = parseInt(dateParts[0])
      const day = parseInt(dateParts[1])
      const today = new Date()
      const selectedDate = new Date(year, month - 1, day)
      const later = addYears(today, 1)
      if (later < selectedDate) {
        return false
      }
      return true
    },
    get isExpired() {
      if (this.linkExpiration) {
        const dateParts = this.linkExpiration.split('/')
        const date = new Date(dateParts[2], parseInt(dateParts[0]) - 1, dateParts[1])
        const today = new Date()
        return !(date >= new Date(today.getFullYear(), today.getMonth(), today.getDate()))
      }
      return false
    }
  }, {
    setLinkExpiration: action.bound,
    setLinkInputExpiration: action.bound
  })
}
