import React from 'react'
import { observer } from 'mobx-react'
import TransitionGroup from 'react-transition-group/TransitionGroup'
import CSSTransition from 'react-transition-group/CSSTransition'

const AlertItem = observer(({ alert, index }) => {
  return <div className={`toolbar-alert toolbar-alert-${index} ${alert.type}`} data-qa-hook={`app.alert.${alert.type}`}>{alert.message}</div>
})

const Alerts = observer(({ alerts }) => {
  return (
    <TransitionGroup component="div" className="toolbar-alerts">
      {alerts.map((alert, i) => (
        <CSSTransition key={alert.id} classNames="flyIn" timeout={{ enter: 300, exit: 240 }}>
          <AlertItem alert={alert} index={i} />
        </CSSTransition>
      ))}
    </TransitionGroup>
  )
})

export default Alerts
