import React from 'react'
import { observer } from 'mobx-react'

export const Pending = observer(() => {
  const dom =
    <svg viewBox="0 0 800 800" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'translateZ(0)' }}>
      <circle
        className='pending'
        cx="400"
        cy="400"
        fill="none"
        r="200"
        strokeWidth="50"
        stroke="#50E9BA"
        strokeDasharray="700 1400"
        strokeLinecap="round"
        strokeDashoffset="0"
      />
    </svg>

  return dom
})

