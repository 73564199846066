import { types } from 'mobx-state-tree'
import Expression from '../Expression'
import { RadioTemplate } from '../Template'
import { Fill, Layerable, Rectangle, Stroke, Transformable, Visible, UI, LimitedStateful as Stateful } from './shared/props'
import assetActions from './shared/actions'
import assetViews from './shared/views'
import assetChildTypes from './shared/childTypes'

const AssetType = 'Radio'

const model = types
  .model(AssetType, {
    // Entity
    Type: types.literal(AssetType),
    objectId: types.identifier,
    // Asset
    name: types.string,
    children: types.late(assetChildTypes),
    value: Expression,
    disabled: Expression,
    checked: Expression,
    template: types.maybeNull(types.reference(RadioTemplate)),
    ...Layerable,
    ...Fill,
    ...Stroke,
    ...Rectangle,
    ...Visible,
    ...Transformable,
    ...UI,
    ...Stateful
  })
  .views(assetViews)
  .actions(self => {
    return {
      ...assetActions(self),
    }
  })
export default model
