import { observable, action, computed, decorate } from 'mobx'
import { onSnapshot, applySnapshot, getSnapshot } from 'mobx-state-tree'

export default class History {
  constructor() {
    this.recording = false // supress recording history when replaying (this is not observable on purpose because it should not be transactional)
    this.grouping = false // ability to group snapshots and only record the final state
    this.max = 21 // max number of snapshots (accounts for initial base snapshot)
    this.disposer = null
    this.cursor = -1
    this.snapshots = []
    this.groupSnapshots = []
  }
  get canUndo() {
    return this.cursor > 0
  }
  get canRedo() {
    return this.cursor < this.snapshots.length - 1
  }
  add(s) {
    if (!this.recording) return
    if (this.grouping) {
      this.groupSnapshots.push(s)
    } else {
      this.snapshots.splice(this.cursor + 1) // clear redo stack
      this.snapshots.push(s)
      if (this.snapshots.length > this.max) this.truncate()
      this.cursor = this.snapshots.length - 1
    }
  }
  clear() {
    if (typeof this.disposer === 'function') this.disposer()
    this.cursor = -1
    this.snapshots.clear()
  }
  truncate() {
    this.snapshots.shift()
  }
  record() {
    this.recording = true
  }
  pause() {
    this.recording = false
  }
  undo() {
    if (this.canUndo) {
      this.cursor--
      this.ignore(this.snapshots[this.cursor].replay)
    }
  }
  redo() {
    if (this.canRedo) {
      this.cursor++
      this.ignore(this.snapshots[this.cursor].replay)
    }
  }
  ignore(fn) {
    this.pause()
    if (fn && typeof fn === 'function') fn.call()
    this.record()
  }
  startGroup() {
    this.grouping = true
  }
  stopGroup() {
    this.grouping = false
    // Make sure snapshots were generated in the group (i.e. a single-click operating in the Viewport)
    if (this.groupSnapshots.length) {
      this.add(this.groupSnapshots[this.groupSnapshots.length - 1]) // Record the final snapshot in the group
    }
    this.groupSnapshots.clear()
  }
  // initialization
  snapshot(store, routeStore, selectionStoreLayout, selectionStoreStructure, pre, post) {
    this.clear()
    this.record()
    // Set up history tracking
    this.disposer = onSnapshot(store, (s) => {
      let snapshotData = {
        data: s,
        routeData: routeStore.toJS, // Serialize and store route state
        selectionDataLayout: selectionStoreLayout.toJS, // Serialize and store selection state
        selectionDataStructure: selectionStoreStructure.toJS, // Serialize and store selection state
      }
      this.add({
        replay() {
          if (pre && typeof pre === 'function') pre.call()
          applySnapshot(store, snapshotData.data) // restore the store from the serialized data
          routeStore.fromJS(snapshotData.routeData) // restore route
          selectionStoreLayout.fromJS(snapshotData.selectionDataLayout) // restore layout selections
          selectionStoreStructure.fromJS(snapshotData.selectionDataStructure) // restore structure selections
          if (post && typeof post === 'function') post.call()
        }
      })
    })
    // add initial snapshot
    let snapshotData = {
      data: getSnapshot(store),
      routeData: routeStore.toJS, // Serialize and store route state
      selectionDataLayout: selectionStoreLayout.toJS, // Serialize and store selection state
      selectionDataStructure: selectionStoreStructure.toJS, // Serialize and store selection state
    }
    this.add({
      replay() {
        if (pre && typeof pre === 'function') pre.call()
        applySnapshot(store, snapshotData.data) // restore the store from the serialized data
        routeStore.fromJS(snapshotData.routeData) // restore route
        selectionStoreLayout.fromJS(snapshotData.selectionDataLayout) // restore layout selections
        selectionStoreStructure.fromJS(snapshotData.selectionDataStructure) // restore structure selections
        if (post && typeof post === 'function') post.call()
      }
    })
  }
}

decorate(History, {
  cursor: observable,
  snapshots: observable.shallow,
  groupSnapshots: observable.shallow,
  canUndo: computed,
  canRedo: computed,
  add: action.bound,
  clear: action.bound,
  truncate: action.bound,
})
