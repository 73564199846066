import { Component } from "react"
import { createPortal } from "react-dom"
import { UUID } from "eplayer-core"

export default class NewWindow extends Component {
  constructor() {
    super()

    this.state = {
      container: null,
      windowName: UUID(),
      windowFeatures: `menubar=no,toolbar=no,location=no,personalbar=no,status=no,resizable=yes,scrollbars=yes,height=400`
    }

    this.windowTimer = null
    this.handleClose = this.handleClose.bind(this)

  }

  handleClose() {
    const { container } = this.state

    if (container && container.closed) {
      const { onClose } = this.props

      return onClose && onClose()
    }

    this.windowTimer = requestAnimationFrame(this.handleClose)
  }

  componentWillUnmount() {
    const { container } = this.state
    const { windowTimer } = this

    windowTimer && cancelAnimationFrame(windowTimer)
    container && container.close()
  }

  componentDidMount() {
    if (!this.state.container) {
      const { windowName } = this.state
      const { windowFeatures } = this.state

      const headElems = Array.from(window.document.head.children)

      const headHtml =
        headElems.reduce((htmlStr, { outerHTML }) =>
          `${htmlStr}\n${outerHTML}`,
          "")

      const html =
        `<!DOCTYPE html><html lang="en-us"><head>${headHtml}</head><body></body>`

      const newWindow = window.open("", windowName, windowFeatures)

      newWindow.document.open()

      newWindow.document.write(html)
      
      newWindow.document.close()

      this.windowTimer = requestAnimationFrame(this.handleClose)

      newWindow.addEventListener("load", () =>
        this.setState({ container: newWindow }))
    }
  }

  render() {
    const { container } = this.state
    const { children } = this.props

    return container && createPortal(children, container.document.body) || null
  }
}
