import { assert } from './utils'

export class Visibility {
  observer = null
  targetMap = new WeakMap

  static fromWindow({ IntersectionObserver }) {
    return new Visibility(IntersectionObserver)
  }

  constructor(IntersectionObserver) {
    this.observer = new IntersectionObserver(records => {
      records.forEach(({ isIntersecting, target, }) => {
        const { targetMap } = this
        const handler = targetMap.get(target)
        if (!handler) this.dispose(target)
        else handler(isIntersecting)
      })
    })
  }

  dispose(elem) {
    this.observer.unobserve(elem)
    this.targetMap.delete(elem)
  }

  observe(elem, onChangeVisibility) {
    const { observer } = this
    const { targetMap } = this
    assert(observer != null, "No IntersectionObserver initialized")
    targetMap.set(elem, onChangeVisibility)
    observer.observe(elem)
  }
}
