import React from 'react'
import { observer } from 'mobx-react'

const MasterActionsTimeline = observer(({ actions, width, ...rest }) => {
  const actionMarkers = actions.map((action, index) => (
    <ActionMarker key={action.objectId} action={action} {...rest} />
  ))
  return (
    <div className="actions-timeline">
      <div className="grid">
        <svg width={`${width}`} height="19" style={{ shapeRendering: 'optimizeSpeed' }}>
          <g>{actionMarkers}</g>
        </svg>
      </div>
    </div>
  )
})

const ActionMarker = observer(class ActionMarker extends React.Component {
  dragStartX = 0
  select = (e) => {
    e.stopPropagation()
    const { action, layoutState } = this.props
    const { selections } = layoutState
    const isSelected = selections.actions.includes(action)
    if (!isSelected && !e.shiftKey) {
      selections.clearAllSelections()
    }
    selections.setActionSelection(action, e.shiftKey)
  }
  mouseDown = (e) => {
    const { action, timelineState } = this.props
    const NODE = e.target.ownerDocument // document.getElementById("timeline")
    NODE.addEventListener('mousemove', this.mouseMove, false)
    NODE.addEventListener('mouseup', this.mouseUp, false)
    this.dragStartX = e.clientX
    timelineState.dragTransaction.start(action.objectId)
  }
  mouseMove = (e) => {
    const { frameWidth, offset, timelineState } = this.props
    const diff = e.clientX - this.dragStartX + frameWidth / 2
    const frameDiff = Math.floor(diff / frameWidth)
    timelineState.dragTransaction.move(frameDiff)
  }
  mouseUp = (e) => {
    const { timelineState } = this.props
    const NODE = e.target.ownerDocument // document.getElementById("timeline")
    NODE.removeEventListener('mousemove', this.mouseMove, false)
    NODE.removeEventListener('mouseup', this.mouseUp, false)
    timelineState.dragTransaction.end()
  }
  render() {
    const { action, timelineState, layoutState, frames, frameWidth, offset } = this.props
    const { actions: selections } = layoutState.selections

    const isSelected = selections.includes(action)
    const isDragging = action.objectId === timelineState.dragTransaction.object

    let keyframeRectClass = 'keyframe'
    if (isSelected) keyframeRectClass += ' selected'
    if (isDragging) keyframeRectClass += ' dragging'
    let hitMarkerClass = 'keyframe-hit-area'
    if (isSelected) hitMarkerClass += ' selected'
    if (isDragging) hitMarkerClass += ' dragging'

    const color = isSelected ? '#03FF94' : '#FFFFFF'

    const dragOffset = isSelected ? timelineState.dragTransaction.offset : 0
    const x = (action.frame + dragOffset - offset) * frameWidth

    // diamond = 14x14
    // hit marker = 14/20
    // center our elements in the middle of the frame
    const xDiamond = x + frameWidth / 2 - 7
    const xHit = x + frameWidth / 2 - 7
    const xText = x + frameWidth / 2 + 7

    return (
      <g>
        <polygon
          points="0,7 7,14 14,7 7,0"
          fill={color}
          className={keyframeRectClass}
          stroke="#212528"
          style={{ transform: `translate(${xDiamond}px, 3px)`, pointerEvents: 'none' }}
        />
        <rect
          x={0}
          y={0}
          width={14}
          height={20}
          fill="#FFFFFF"
          fillOpacity="0"
          stroke="none"
          className={hitMarkerClass}
          style={{ transform: `translate(${xHit}px, 0)` }}
          onMouseUp={this.select}
          onMouseDown={this.mouseDown}
        />
        <text
          x={0}
          y={0}
          fontFamily="Roboto"
          fontSize="10"
          fill={color}
          textAnchor="start"
          alignmentBaseline="before-edge"
          style={{ transform: `translate(${xText}px, 4px)`, pointerEvents: 'none' }}>
          {action.name}
        </text>
      </g>
    )
  }
})

export default MasterActionsTimeline
