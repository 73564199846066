import React from 'react'
import { observer } from 'mobx-react'
import { Keyframe } from 'eplayer-core'
import Input from '../../components/Input'
import Select from 'react-select'
import { toMMSS } from '../../utils'
import { FPS } from '../../constants'
import { MAX_SEQUENCE_DURATION } from '../../models/constants'

const Controls = observer(class Controls extends React.Component {
  goToBeginning = () => {
    const { layoutState } = this.props
    layoutState.player.pause()
    layoutState.player.setFrame(1)
  }
  goToEnd = () => {
    const { layoutState, duration } = this.props
    layoutState.player.pause()
    layoutState.player.setFrame(duration)
  }
  previousFrame = () => {
    const { layoutState, duration } = this.props
    const newFrame = layoutState.player.frame - 1 < 1 ? duration : layoutState.player.frame - 1 // loop to end
    layoutState.player.pause()
    layoutState.player.setFrame(newFrame)
  }
  nextFrame = () => {
    const { layoutState, duration } = this.props
    const newFrame = layoutState.player.frame + 1 > duration ? 1 : layoutState.player.frame + 1 // loop back to 1
    layoutState.player.pause()
    layoutState.player.setFrame(newFrame)
  }

  checkDuration = (e) => { // Validate Duration is not beyond maximum
    const {globalState, setSequenceDuration} = this.props
    if (e >= MAX_SEQUENCE_DURATION)  {
      globalState.showAlert('error', `Sequences are limited to ${MAX_SEQUENCE_DURATION} frames.`)
    }
    setSequenceDuration(parseInt(e))
  }

    checkFrame = (e) => { // Validate frame
        const {globalState, duration, layoutState} = this.props
        const { player } = layoutState
        if (e > duration)  {
            globalState.showAlert('error', `Invalid frame. Moving to end of the sequence.`)
        } else if (e <= 0 || !parseInt(e)) {
            globalState.showAlert('error', `Invalid frame. Moving to beginning of the sequence.`)
        }
        player.setFrame(parseInt(e))
    }

  render() {
    const { layoutState, duration, setSequenceDuration, timelineState } = this.props
    const { player } = layoutState
    const { playing, frame } = player
    return (
      <div className="timeline-controls-left">
        <div className="play-controls">
          <span className="controls-timecode" style={{ display: 'none' }}>
            {toMMSS(frame / FPS)}
          </span>
          <button
            type="button"
            title="Go to Beginning"
            onClick={this.goToBeginning}
            className="secondary-control">
            <i className="control go-to-beginning" />
          </button>
          <button
            type="button"
            title="Rewind"
            onClick={this.previousFrame}
            style={{ display: 'none' }}>
            <i className="control rewind" />
          </button>
          <button type="button" title={playing ? 'Pause' : 'Play'} onClick={player.togglePlayback}>
            <i className={playing ? 'control pause' : 'control play'} />
          </button>
          <button type="button" title="Animate Mode" onClick={player.toggleAnimateMode}>
            <i className="control animate" />
          </button>
          <button
            type="button"
            title="Fast-Forward"
            onClick={this.nextFrame}
            style={{ display: 'none' }}>
            <i className="control fast-forward" />
          </button>
          <button
            type="button"
            title="Go to End"
            onClick={this.goToEnd}
            className="secondary-control">
            <i className="control go-to-end" />
          </button>
          <span className="controls-timecode-frame">
            <Input
              name="currentFrame"
              type="number"
              className="current"
              min={1}
              value={Math.floor(frame)}
              comparative={duration}
              onSave={e => this.checkFrame(e)}
              title="Current Frame"
            />
            <span className="slash">/</span>
            <Input
              name="durationControl"
              type="number"
              className="duration"
              min={1}
              comparative={MAX_SEQUENCE_DURATION}
              value={duration}
              onSave={e => this.checkDuration(e)}
              title="Sequence Duration"
            />
          </span>
        </div>
      </div>
    )
  }
})

export default Controls
