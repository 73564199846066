import React from 'react'
import { observable } from 'mobx'
import { decorate } from 'mobx'
import { observer } from 'mobx-react'

const NameEditor = observer(class NameEditor extends React.Component {
  constructor(props) {
    super(props)
    this.editableEl = null
  }
  setEditableEl = (el) => {
    const { defaultValue } = this.props
    if (!el) return
    this.editableEl = el
    el.focus()
    el.setSelectionRange(0, defaultValue.length)
  }
  onBlur = (e) => {
    const { defaultValue, onSave, hide } = this.props
    const newValue = e.target.value
    if (defaultValue !== newValue) onSave(newValue)
    hide()
  }
  onKeyDown = (e) => {
    const { defaultValue, onSave, hide } = this.props
    const newValue = e.target.value
    const isTab = e.keyCode === 9
    const isEnter = e.keyCode === 13
    // Tab or Enter (technically tab will fire blur ¯\_(ツ)_/¯)
    if (isTab || isEnter) {
      e.preventDefault()
      e.stopPropagation()
      if (defaultValue !== newValue) onSave(newValue)
      hide()
    }
  }
  // Escape is bound to keyup in the rest of the app
  onKeyUp = (e) => {
    const { defaultValue, hide } = this.props
    const isEscape = e.keyCode === 27
    if (isEscape) {
      e.preventDefault()
      e.stopPropagation()
      if (this.editableEl) {
        // onSave(defaultValue) // this would trigger an entry in undo/redo...
        this.editableEl.value = defaultValue
        hide()
      }
    }
  }
  render() {
    const { className, defaultValue } = this.props
    return (
      <input
        type="text"
        className={className}
        defaultValue={defaultValue}
        ref={this.setEditableEl}
        onBlur={this.onBlur}
        onKeyDown={this.onKeyDown}
        onKeyUp={this.onKeyUp}
      />
    )
  }
})

decorate(NameEditor, {
  editableEl: observable.ref,
})

export default NameEditor
