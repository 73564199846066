import { types } from 'mobx-state-tree'
import { Fill, Layerable, Rectangle, Stroke, Transformable, Visible, UI } from './shared/props'
import assetActions from './shared/actions'
import assetViews from './shared/views'

const AssetType = 'MenuGroup'

const model = types
  .model(AssetType, {
    // Entity
    Type: types.literal(AssetType),
    objectId: types.identifier,
    // Asset
    name: types.string,
    labeledBy: types.string,
    children: types.late(() => types.array(require('./MenuGroupItem').default)),
    ...Layerable,
    ...Fill,
    ...Stroke,
    ...Rectangle,
    ...Visible,
    ...Transformable,
    ...UI,
  })
  .views(assetViews)
  .actions(self => {
    return {
      ...assetActions(self),
      addChild(asset) {
        if (asset.Type !== 'MenuGroupItem') return
        else if (self.children.length === 8) return
        else self.children.push(asset)
      },
      acceptsDrop() {
        return false
      },
    }
  })

export default model
