import React from 'react'
import { observer } from 'mobx-react'
import { TIMELINE_LABEL_WIDTH, TIMELINE_PLAYHEAD_WIDTH } from '../../constants'

const Playhead = observer(class Playhead extends React.Component {
  constructor(props) {
    super(props)
    
    this.ref = React.createRef()
  }
  mouseDown = (e) => {
    // `ownerDocument` because element may not be in same window as code
    const NODE = e.target.ownerDocument//.getElementById("timeline")
    NODE.addEventListener('mousemove', this.mouseMove, false)
    NODE.addEventListener('mouseup', this.mouseUp, false)

    this.oldX = e.pageX
  }
  mouseMove = (e) => {
    const { layoutState, timelineState, frameWidth, offset, stage } = this.props
    const newFrame = Math.floor((e.clientX - TIMELINE_LABEL_WIDTH) / frameWidth) + offset
    var keyframes = []

    for (let asset of stage.children.slice(0).reverse()) {
      const propList = asset.timelineState.properties || []
      for (let property of propList) {
        let keyframeArray = asset[property + 'Keyframes'] || []
        for (let keyframe of keyframeArray) {
          keyframes.push(keyframe.frame)
        }
      }
    }

    const keyframesSorted = keyframes.sort((a, b) => a - b)
    //layoutState.selections.assets[0]['rotationKeyframes'][3].frame

    if (e.shiftKey) {
      const frameRange = this.oldX < e.pageX ? newFrame + 10 : newFrame - 10
      const range = [newFrame, frameRange].sort((a, b) => a - b)
      const filteredFrames = keyframesSorted.filter((s) => s >= range[0] && s <= range[1])
      if (filteredFrames.length > 0) {
        const frameToSet =
          this.oldX < e.pageX ? filteredFrames[0] : filteredFrames[filteredFrames.length - 1]
        const difference = Math.abs(layoutState.player.frame - frameToSet)
        if (difference <= 10) {
          layoutState.player.setFrame(frameToSet)
        }
      }
    } else {
      layoutState.player.setFrame(newFrame)
    }
    this.oldX = e.pageX
  }
  mouseUp = (e) => {
    // `ownerDocument` because element may not be in same window as code
    const NODE = e.target.ownerDocument // .getElementById("timeline")
    NODE.removeEventListener('mousemove', this.mouseMove, false)
    NODE.removeEventListener('mouseup', this.mouseUp, false)
  }
  componentDidMount() {
    this.ref.current.addEventListener("mousedown", this.mouseDown)
  }
  render() {
    const { layoutState, timelineState, frames, frameWidth, offset, height } = this.props
    const { player } = layoutState
    const { playing, frame } = player

    let wholeFrame = playing ? frame : Math.round(frame)
    let translation =
      wholeFrame * frameWidth - offset * frameWidth + frameWidth / 2 - TIMELINE_PLAYHEAD_WIDTH / 2

    if (translation < 0 && offset > 1) {
      // Playhead is behind the viewable portion of the timeline and we shouldn't draw it.
      return null
    } else {
      return (
        <svg
          width={TIMELINE_PLAYHEAD_WIDTH}
          height={height}
          viewBox={'0 0 ' + TIMELINE_PLAYHEAD_WIDTH + ' ' + height}
          className="playhead"
          style={{
            shapeRendering: 'crispEdges',
            transform: 'translateX(' + translation + 'px)',
            pointerEvents: 'none'
          }}
          >
          <text key={frame} x="0" y="22" fontSize="10px" fill="#007FFF">
            <tspan x={TIMELINE_PLAYHEAD_WIDTH / 2} textAnchor="middle">
              {Math.round(frame)}
            </tspan>
          </text>
          <rect
            x={TIMELINE_PLAYHEAD_WIDTH / 2}
            y="27"
            width="1"
            height={height - 33}
            fill="#007FFF"
            style={{ pointerEvents: 'none' }}
          />
          <rect
            x="0"
            y="11"
            rx="2"
            ry="2"
            width={TIMELINE_PLAYHEAD_WIDTH}
            height="16"
            fill="#007FFF"
            stroke="#007FFF"
            strokeWidth="2px"
            strokeOpacity=".4"
            fillOpacity=".1"
            className="scrubhead"
            ref={this.ref}
          />
          <rect
            x={TIMELINE_PLAYHEAD_WIDTH / 2 - 3}
            y="27"
            width={7}
            height={height - 33}
            fill="#FF00AA"
            fillOpacity="0"
            style={{ pointerEvents: 'all' }}
          />
        </svg>
      )
    }
  }
})

export default Playhead
