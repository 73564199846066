const sortByFrame = function(a, b) {
	return a.frame - b.frame
}

const assetViews = self => ({
	get activeKeyframesSorted() {
		return self.activeKeyframes
			? self.activeKeyframes.sort(sortByFrame)
			: undefined
	},
	get opacityKeyframesSorted() {
		return self.opacityKeyframes
			? self.opacityKeyframes.sort(sortByFrame)
			: undefined
	},
	get positionKeyframesSorted() {
		return self.positionKeyframes
			? self.positionKeyframes.sort(sortByFrame)
			: undefined
	},
	get scaleKeyframesSorted() {
		return self.scaleKeyframes
			? self.scaleKeyframes.sort(sortByFrame)
			: undefined
	},
	get rotationKeyframesSorted() {
		return self.rotationKeyframes
			? self.rotationKeyframes.sort(sortByFrame)
			: undefined
	},
	get frameKeyframesSorted() {
		return self.frameKeyframes
			? self.frameKeyframes.sort(sortByFrame)
			: undefined
	},
	get canvasKeyframesSorted() {
		return self.canvasKeyframes
			? self.canvasKeyframes.sort(sortByFrame)
			: undefined
	},
})

export default assetViews
