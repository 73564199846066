import React from 'react'
import './manager-breadcrumb.scss'

export function ManagerBreadcrumb({ onClick }) {
  const vDom =
    <div className="manager-breadcrumb" >
      <span className="home" data-qa-hook="button.goBackHome" onClick={onClick}>
        ❮ Home
      </span>
    </div>
  return vDom
}
