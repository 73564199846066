import React from 'react'
import { observable } from 'mobx'
import { decorate } from 'mobx'
import { observer } from 'mobx-react'
import Input from '../../components/Input'
import TextArea from '../../components/TextArea'
import ContentEditable from '../../components/Editable'
import ScriptMenu from './Menu'
import { UpdateMedia } from '../Media'
import { contains } from '../../utils'
import { ONDROP_WHITELIST, TEMPLATE_VAR_DEFAULT } from '../../constants'

class RowComponent extends React.Component {
  editableEnter = (e) => {
    this.hoverOver = true
  }
  
  onClick = (e) => {
    const { state, row } = this.props
    const scriptState = state.script
    e.stopPropagation()
    scriptState.activeElement = row.objectId
  }
  onDragStart = (e) => {
    const { state, row } = this.props
    const { global: globalState } = state
    e.stopPropagation()
    e.dataTransfer.setData('text/row', row.objectId)
    e.dataTransfer.setData('text/panel', 'Script')
    globalState.dragging([row])
  }
  onDragOver = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }
  onDrop = (e) => {
    const { state, row, index } = this.props
    const { global: globalState } = state
    e.stopPropagation()
    const droppedItems = globalState.dropped()
    const droppedFrom = e.dataTransfer.getData('text/panel')
    const isValidDrop = ONDROP_WHITELIST['Script'].includes(droppedFrom)
    if (!isValidDrop) return
    // For intra-panel Drag and Drop
    if (contains(e.dataTransfer.types, 'text/row')) {
      e.preventDefault()
      var droppedRow = droppedItems[0]
      row.parent.moveAudioRow(droppedRow, index)
    }
    // For Audio Media Drag and Drop
    if (droppedItems.length > 0 && droppedItems[0].Type === 'RawAudio') {
      const droppedAudio = droppedItems[0]
      // Set new audio media.
      row.setAudioMedia(droppedAudio)
      // Refresh the cache by looping back to the Ready state while maintaining the same activeSequence.
      //if (app.player.state.Type === 'PlayingState') app.player.state.refresh()
    }
  }
  editableOnBlur = (val) => {
    const { state, row } = this.props
    const scriptState = state.script
    this.updateName(val)
    scriptState.activeElement =
      scriptState.activeElement === row.objectId ? null : scriptState.activeElement
      this.hoverOver = false
  }
  editableOnKeyDown = (e) => {
    const { state, scriptObjects, row } = this.props
    const scriptState = state.script
    const sequence = row.parent
    const isTab = e.keyCode === 9
    const isEnter = e.keyCode === 13
    const isShift = e.shiftKey
    // Tab
    if (isTab) {
      e.preventDefault()
      e.stopPropagation()
      const index = scriptObjects.indexOf(row.objectId)
      const mod = isShift ? -1 : 1
      scriptState.activeElement = scriptObjects[index + mod]
    }
    // Enter
    if (isEnter) {
      e.preventDefault()
      e.stopPropagation()
      if (!isShift) {
        if (sequence.audioRows.indexOf(row) === sequence.audioRows.length - 1) {
          const newRow = sequence.insertAudioRow()
          scriptState.activeElement = newRow.objectId
        } else {
          const index = scriptObjects.indexOf(row.objectId)
          scriptState.activeElement = scriptObjects[index + 1]
        }
      }
    }
  }
  updateName = (val) => {
    const { row } = this.props
    row.updateProp('name', val)
  }
  updateStartTime = (val) => {
    const { row } = this.props
    const numVal = parseInt(val)
    if (!isNaN(numVal)) row.updateProp('startTime', numVal)
  }
  toggleAudio = () => {
    this.playing ? this.stopAudio() : this.playAudio()
  }
  playAudio = () => {
    if (!this.refs.audio) return
    this.playing = true
    this.refs.audio.play()
    this.refs.audio.addEventListener('ended', () => {
      this.stopAudio()
    })
  }
  stopAudio = () => {
    if (!this.refs.audio) return
    this.playing = false
    this.refs.audio.pause()
    this.refs.audio.currentTime = 0
  }
  constructor(props) {
    super(props)
    this.playing = false
    this.hoverOver = false
  }

  mediaUpdater = (media, files) => {
    const { state: { media: mediaState } } = this.props
    mediaState.update(media, files[0])
  }

  startDispToolTip = (startTime) => {
    const rowStartSeconds = startTime / 24
    const rowStartDispMin = parseInt(parseInt(rowStartSeconds) / 60)
    const rowStartDispSec = (((rowStartSeconds) - (rowStartDispMin * 60)) - 0.04).toFixed(2)
    return rowStartDispSec >= 10 ? rowStartDispMin + ':' + rowStartDispSec : rowStartDispMin + ':0' + rowStartDispSec
  }

  endDispToolTip = (endTime) => {
    const rowEndSeconds = endTime / 24
    const rowEndDispMin = parseInt(parseInt(rowEndSeconds) / 60)
    const rowEndDispSec = (((rowEndSeconds) - (rowEndDispMin * 60)) - 0.04).toFixed(2)

    return rowEndDispSec >= 10 ? rowEndDispMin + ':' + rowEndDispSec : rowEndDispMin + ':0' + rowEndDispSec
  }

  render() {
    const { state, scriptObjects, row, index } = this.props
    const { script: scriptState, timeline: timelineState, media: mediaState } = state
    const sequence = row.parent

    const isLocked = row.timelineState.locked || timelineState.audioLocked
    const hasFocus = scriptState.activeElement === row.objectId
    const mediaHost = mediaState.mediaHost
    const media = row.audioMedia
    const hasMedia = row.audioMedia ? true : false
    const mediaTitle = hasMedia ? row.audioMedia.fileName : 'Drop Audio Media'
    const templateKeys = sequence.templateKeys ? sequence.templateKeys : []
    const templateFields = templateKeys.map((key) => (
      <TemplateField key={key} name={key} row={row} />
    ))
    const endTime = hasMedia ? (row.startTime + row.audioMedia.durationMs / 1000 * 24).toFixed(0) : ''

    const lockProps = {
      className: 'sprite timeline lock',
      style: {
        backgroundColor: isLocked ? '#CCC' : 'transparent'
      }
    }

    const iconProps = {
      className: 'audio-control ' + (this.playing ? 'playing' : 'paused'),
      onClick: this.toggleAudio,
      title: this.playing ? 'Stop Preview' : 'Preview Audio',
      style: {
        display: hasMedia ? 'block' : 'none'
      }
    }

    const audioProps = {
      src: hasMedia ? mediaHost + row.audioMedia.src : null,
      preload: 'none',
      ref: 'audio',
      style: {
        display: 'none'
      }
    }

    const nameProps = {
      ref: 'editable',
      disabled: !this.hoverOver,
      html: row.name || '',
      placeholder: 'Audio Row',
      onSave: this.updateName,
      onKeyDown: this.editableOnKeyDown,
      onBlur: this.editableOnBlur,
      onMouseEnter: this.editableEnter
    }

    const startProps = {
      className: 'start',
      style: {
        display: hasMedia ? 'flex' : 'none'
      },
      onClick: (e) => e.stopPropagation()
    }


    const startInputProps = {
      type: 'number',
      min: 1,
      value: row.startTime,
      title: this.startDispToolTip(row.startTime),
      onSave: this.updateStartTime
    }

    const endProps = {
      className: 'end',
      title: this.endDispToolTip(endTime),
      style: {
        display: hasMedia ? 'flex' : 'none'
      }
    }

    const props = {
      className: `script-object audio-row ${hasFocus ? 'focus' : ''}`,
      draggable: true,
      onDragStart: this.onDragStart,
      onDragOver: this.onDragOver,
      onDrop: this.onDrop,
      onClick: this.onClick
    }

    return (
      <div {...props}>
        <div className="inner">
          <div className="main">
            <label className="caption-label">Caption</label>
            <div className="input">
              <ContentEditable {...nameProps} />
            </div>
            {hasMedia ? (
              <div className="file-list">
                <span className={`media-relink`}>
                  <UpdateMedia media={media} mediaUpdater={this.mediaUpdater} />
                </span>
                <i {...iconProps} />
                {hasMedia ? <audio {...audioProps} /> : null}
                <span className={'drop-zone has-media'} title={mediaTitle}>
                  {mediaTitle}
                </span>

                <div {...startProps}>
                  <label className="frame-label">Frames: </label>
                  <Input {...startInputProps} />
                </div>
                <div {...endProps}>
                  <label className="frame-label">to </label>
                  <span className="fake-input">{endTime}</span>
                </div>
              </div>
            ) : (
              <div className="file-list">
                <span className={'drop-zone'} title={mediaTitle}>
                  {mediaTitle}
                </span>
              </div>
            )}
            {scriptState.extended ? (
                <div className="file-list ar-seperator">
                <div className="extended-variables">
                  <label>Variables</label>
                  <div className="inputs">
                    {templateFields.length ? (
                        templateFields
                    ) : (
                        <div className="empty">
                          <span>No Template Variables</span>
                        </div>
                    )}
                  </div>
                </div>
                </div>
            ) : null}
          </div>

          <div className="actions">
            <i {...lockProps} />

            <RowMenu object={row} />
          </div>
        </div>
      </div>
    )
  }
}

const RowMenu = observer(({ object: row }) => {
  const { parent: sequence, timelineState } = row
  const insertBefore = (e) => {
    e.stopPropagation()
    let position = sequence.audioRows.indexOf(row)
    sequence.insertAudioRow(position)
  }
  const insertAfter = (e) => {
    e.stopPropagation()
    let position = sequence.audioRows.indexOf(row) + 1
    sequence.insertAudioRow(position)
  }
  const remove = (e) => {
    e.stopPropagation()
    sequence.removeAudioRow(row)
  }
  const duplicate = () => row.duplicate()
  const toggleLock = () => timelineState.toggleProp('locked')
  return (
    <ScriptMenu>
      <ol>
        <li onMouseUp={insertBefore}>New Audio Row Before</li>
        <li onMouseUp={insertAfter}>New Audio Row After</li>
        <li className="divider" />
        <li onMouseUp={toggleLock}>{`${timelineState.locked ? 'Unlock' : 'Lock'} Audio Row`}</li>
        <li onMouseUp={duplicate}>Duplicate Audio Row</li>
        <li className="divider" />
        <li onMouseUp={remove}>Remove Audio Row</li>
      </ol>
    </ScriptMenu>
  )
})

const TemplateField = observer(({ name, row }) => {
  let field = row.variables[name]
  if (!field) {
    field = TEMPLATE_VAR_DEFAULT
  }
  const textProps = {
    value: field.value,
    onSave: (v) => row.setTemplateVariable(name, { ...field, value: v }),
    placeholder: ''
  }
  return (
    <div className="row-field">
      <label>{name}</label>
      <TextArea {...textProps} />
    </div>
  )
})

decorate(RowComponent, {
  playing: observable,
  hoverOver: observable,
})

const Row = observer(RowComponent)

export default Row
