import React from 'react'
import PropTypes from 'prop-types'
import './manager-action.scss'

ManagerAction.propTypes = {
  children: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  title: PropTypes.string.isRequired,
} 

ManagerAction.defaultProps = {
  disabled: false,
  selected: false,
}

export function ManagerAction({ autoName, children: label, disabled, onClick, selected, title, }) {
  let className = "manager-action"
  if (selected) className += " manager-action--selected"
  const vDom =
    <button onClick={onClick} disabled={disabled} title={title} className={className} data-qa-hook={autoName}>
      {label}
    </button>
  return vDom
}
