import { types, getSnapshot } from 'mobx-state-tree'
import { Keyframe } from 'eplayer-core'
import Vec3 from './Vec3'

const model = types
	.model('Keyframe', {
		Type: types.literal('Keyframe'),
		objectId: types.identifier,
		fn: types.string,
		frame: types.number,
		value: types.union(types.number, types.boolean, Vec3),
	})
	.actions(self => {
		function updateEasing(fn) {
			const kf = self
			kf.fn = fn
		}
		function updateFrame(frame) {
			const kf = self
			kf.frame = frame
		}
		function updateValue(value) {
			const kf = self
			kf.value = value
		}
    // TODO: need to test this
		function clone() {
			return Keyframe.clone(getSnapshot(self))
		}
		return {
			updateEasing,
			updateFrame,
			updateValue,
			clone,
		}
	})
export default model
