import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { EDITIONMANAGER_GRAPHICS } from './constants'
import { Visibility } from '../../visibility'
import './manager-table.scss'

export const ManagerTableHeader = observer(function managerTableHeader({ autoHook, canSort, children: column, activeSort, isSortDesc, onToggleSort, style }) {
  const canSortCol = canSort(column)
  const colIsActiveSort = activeSort(column)
  const colAutoHook = `${autoHook}.${column.replace(/\s+/g, "-")}`
  const sortIcon = canSortCol ?
    colIsActiveSort ?
      isSortDesc ?
        <i className={'icon-chevron--xtrasmall manager-table-column__sort-icon manager-table-column__sort-icon--sorting manager-table-column__sort-icon--desc'} /> :
        <i className={'icon-chevron--xtrasmall manager-table-column__sort-icon manager-table-column__sort-icon--sorting manager-table-column__sort-icon--asc'} /> :
      <i className={'icon-chevron--xtrasmall manager-table-column__sort-icon  manager-table-column__sort-icon--desc'} /> :
    null
  const onClick = canSortCol ?
    () => onToggleSort(column) :
    null
  const className = canSortCol ?
    'manager-table-column manager-table-column--sortable' :
    'manager-table-column'
  const title = canSortCol ?
    `${column} table column, click to toggle sort order` :
    `${column} table column`
  const nameClassName = canSortCol ?
    `mananger-table-column__name manager-table-column__name--sortable` :
    `mananger-table-column__name`

  const vDom =
    <th key={colAutoHook} className={className} onClick={onClick} title={title} data-qa-hook={colAutoHook} style={style}>
      <strong className={nameClassName}>{column}</strong>
      {sortIcon}
    </th>

  return vDom
})

ManagerTableHeader.propTypes = {
  activeSort: PropTypes.func.isRequired,
  autoHook: PropTypes.string.isRequired,
  canSort: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
  isSortDesc: PropTypes.bool.isRequired,
  onToggleSort: PropTypes.func.isRequired,
}

export const ManagerTableData = observer(function managerTableData({ children, style, testId }) {
  const vDom =
    <td className="manager-table-data" style={style} data-qa-hook={testId}>
      {children}
    </td>
  return vDom
})

export const ManagerTable = observer(class ManagerTable extends React.Component {
  static propTypes = {
    columns: PropTypes.arrayOf(PropTypes.element).isRequired,
    displayWithSearch: PropTypes.bool,
    isSearching: PropTypes.bool,
    nextPage: PropTypes.func,
    rows: PropTypes.arrayOf(PropTypes.element).isRequired,
    visibility: PropTypes.instanceOf(Visibility),
  }
  onPagerVisible = isVisible => {
    const { nextPage } = this.props
    const { isSearching } = this.props
    if (isVisible && !isSearching && nextPage) {
      nextPage()
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      pagerRef: React.createRef(),
    }
  }

  componentDidMount() {
    const { visibility } = this.props
    const pagerEl = this.state.pagerRef.current
    if (!visibility) return
    if (!pagerEl) throw "Pager element failed to populate ref value"

    visibility.observe(pagerEl, this.onPagerVisible)
  }

  componentWillUnmount() {
    const { onUnmount } = this.props
    const { visibility } = this.props
    const pagerEl = this.state.pagerRef.current

    onUnmount()
    if (!visibility) return
    visibility.dispose(pagerEl)
  }

  render() {
    const { pagerRef } = this.state
    const { props } = this
    const { columns } = props
    const { displayWithSearch } = props
    const { isSearching } = props
    const { rows } = props

    const loaderClassName = displayWithSearch ?
      isSearching ?
        "manager-table-loader manager-table-loader--searching" :
        "manager-table-loader" :
      isSearching ?
        "manager-table-loader manager-table-loader--searching" :
        "manager-table-loader" 

    const tableClassName = displayWithSearch ?
      isSearching ?
        "manager-table manager-table--searching" :
        "manager-table" :
      isSearching ?
        "manager-table manager-table--searching" :
        "manager-table"
    const body = isSearching ?
      <div className={loaderClassName}>
        <img src={EDITIONMANAGER_GRAPHICS.jellyfish} alt="Blinking Jellyfish logo"/>
        Searching...
      </div> :
      <table className={tableClassName}>
        <tbody>
          {rows}
        </tbody>
      </table>

      const vDom =
        <section className="manager-section manager-row-listing">
          <table className={tableClassName}>
            <thead>
              <tr>{columns}</tr>
            </thead>
          </table>
            {body}
            <div className="manager-table-pager" ref={pagerRef}></div>
        </section>

      return vDom
  }
})
