import React from 'react'
import { observer } from 'mobx-react'

// AssetSetListItem
// Generates an option for the list of AssetSets available when creating a version.
const AssetSetListItem = observer(({ set }) => {
  return (
    <option value={set.id}>
      {set.assetSetPath} - {set.dialect.description} | {set.narrator.name} |{' '}
      {set.narrator.gender.description}
    </option>
  )
})

const AssetSetSelect = observer(({ assetSetList, assetSetValue, onChange }) => {
  const AssetSetOptions = assetSetList.map((set) => <AssetSetListItem key={set.id} set={set} />)
  return (
    <div>
      <span className="assetset-label">
        First version: Assign an asset set. <strong>This cannot be changed later.</strong>
      </span>
      <select className="dropdown" value={assetSetValue} onChange={onChange}>
        {AssetSetOptions}
      </select>
    </div>
  )
})

export default class NewVersion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // Can these be converted to props? Look to fix - see notes from new_modals_1810 merge
      displayVersion: this.props.version.nextVersion('working'),
      versionType: 'working',
      assetSetId: 5, // For selection in version creation
      comments: '',
      commentsEntered: true,
      isDisabled: false,
      isPublishable: false,
      agreedToPublishing: false
    }
  }
  checkPublish = (e) => {
    this.setState({ agreedToPublishing: !this.state.isPublishable })
    this.setState({ isPublishable: !this.state.isPublishable })
  }
  setType = (e) => {
    const { value } = e.target
    const displayVersion = value === '' ? '' : this.props.version.nextVersion(value)
    this.setState({
      displayVersion: displayVersion,
      versionType: value
    })
  }
  updateComments = (e) => {
    const { value: comments } = e.target
    this.setState({ comments: comments })
  }
  updateAssetSet = (e) => {
    const { value: id } = e.target
    this.setState({ assetSetId: parseInt(id) })
  }
  submit = (e) => {
    if (
      (this.state.comments !== '' && this.state.versionType === 'working') ||
      (this.state.comments !== '' &&
        this.state.versionType !== 'working' &&
        this.state.isPublishable === true)
    ) {
      // comments and version type selection are required
      this.setState({ fieldsPassValidation: true })
      this.setState({ agreedToPublishing: true })
      this.setState({ isDisabled: true })
      this.props.saveVersion(this.state.comments, this.state.versionType, this.state.assetSetId)
    } else {
      this.setState({ agreedToPublishing: this.state.isPublishable ? true : false })
      this.setState({ commentsEntered: this.state.comments !== '' ? true : false })
    }
  }
  render() {
    const { app } = this.props
    const { state, edition } = app
    const { editionmanager } = state
    return (
      <div className="newversion-wrap">
        <label>What is the purpose of this version?</label>
        <select value={this.state.versionType} onChange={this.setType} data-qa-hook="dropdown.versionType">
          <option value="working" data-qa-hook="dropdownOption.workingVersion">Working Version</option>
          <option value="minor" data-qa-hook="dropdownOption.minorVersion">Minor Version</option>
          <option value="major" data-qa-hook="dropdownOption.majorVersion">Major Version</option>
        </select>
        {this.state.versionType !== 'working' && (
          <div className="publishing">
            <input type="checkbox" onChange={this.checkPublish} data-qa-hook="checkbox.publishVerify"/> I understand that this version
            type may be published.
            <label className="validation">
              <span className={this.state.agreedToPublishing ? `hidden` : ``}>
                You must agree to the above statement to continue.
              </span>
            </label>
          </div>
        )}
        {edition.version.isUnversioned && (
          <AssetSetSelect
            assetSetList={editionmanager.assetSetList}
            assetSetValue={this.state.assetSetId}
            onChange={this.updateAssetSet}
          />
        )}
        <label>Add comments</label>
        <textarea placeholder="*Required" onChange={this.updateComments} data-qa-hook="modalInput.comment"/>
        <label className="validation">
          <span className={this.state.commentsEntered ? `hidden` : ``}>
            Please add comments and select a version type.
          </span>
        </label>
        <button onClick={this.submit} disabled={this.state.isDisabled ? 'disabled' : false} data-qa-hook="modalButton.createVersion">
          Create Version {this.state.displayVersion}
        </button>
      </div>
    )
  }
}
