{
    "dependencies": {
        "@jgoz/esbuild-plugin-html": "^1.0.5",
        "@types/node": "^20.11.4",
        "@types/react": "^16.4.15",
        "adobe-canvas-transform": "git+ssh://git@bitbucket.ce.wolterskluwer.io:7999/ece/adobe-canvas-transform.git#011481d",
        "b64-to-blob": "^1.2.19",
        "base64-js": "^1.5.1",
        "brace": "^0.11.0",
        "date-fns": "^1.29.0",
        "date-now": "^1.0.1",
        "debounce": "^1.1.0",
        "eplayer-core": "git+ssh://git@bitbucket.ce.wolterskluwer.io:7999/ece/eplayer-core.git#2.15.0",
        "esbuild": "^0.19.11",
        "esbuild-plugin-polyfill-node": "^0.3.0",
        "esbuild-sass-plugin": "^2.10.0",
        "esbuild-server": "^0.3.0",
        "gl-mat4": "^1.2.0",
        "gl-vec3": "^1.1.3",
        "jsesc": "^2.2.0",
        "mobx": "^4.3.1",
        "mobx-react": "^5.2.3",
        "mobx-state-tree": "^3.0.0",
        "mobx-utils": "5.0.0",
        "mousetrap": "^1.6.2",
        "node-sass": "9.x.x",
        "polyp": "git+ssh://git@bitbucket.ce.wolterskluwer.io:7999/ece/polyp#6d21aca",
        "prop-types": "^15.6.1",
        "react": "^16.2.0",
        "react-ace": "^6.1.1",
        "react-color": "^2.14.1",
        "react-dom": "^16.2.0",
        "react-maskedinput": "^4.0.1",
        "react-select": "^1.2.1",
        "react-transition-group": "^2.3.1",
        "tslib": "^2.6.1",
        "tsx": "3.x.x",
        "typescript": "5.1.x",
        "uuid": "^3.2.1"
    },
    "description": "",
    "devDependencies": {
        "@playwright/test": "^1.41.0",
        "@types/tape": "^4.2.32",
        "maven-deploy": "^1.5.0",
        "tap-dot": "^2.0.0",
        "tape": "^4.9.1",
        "tape-async": "^2.3.0"
    },
    "engines": {
        "node": "20.x.x"
    },
    "main": "index.html",
    "name": "EmmiAuthoringTool",
    "private": true,
    "scripts": {
        "archive": "cd dist; jar cMf ../authoring-tool-frontend.zip *",
        "build": "tsx build.esbuild.mts 2&> build.log",
        "clean": "rm -rf ./dist",
        "deploy-maven-release": "node ./scripts/maven-deploy-release.js",
        "deploy-maven-snapshot": "node ./scripts/maven-deploy-snapshot.js",
        "maven-deploy": "./scripts/maven-deploy.sh",
        "postbuild": "cp ./app/favicon* ./dist",
        "start": "tsx serve.esbuild.mts",
        "test": "playwright test",
        "test:build": "./node_modules/.bin/tsc --allowJs --esModuleInterop --sourceMap --outdir tests/lib app/scripts/models/{*,**/*,**/**/*}.js",
        "test:models": "node tests/models",
        "test:ui": "playwright test --ui",
        "test:unit": "tsx ./node_modules/.bin/tape app/scripts/*.test.js | tap-dot",
        "version": "bin/version.js dist/version.json"
    },
    "version": "2.32.5"
}