import React from 'react'
import { decorate, observable } from 'mobx'
import { observer } from 'mobx-react'

const MasterAudioTimeline = observer(({ rows, width, ...rest }) => {
  const audioMarkers = rows.map((row, index) => (
    <AudioMarker key={row.objectId} row={row} {...rest} />
  ))
  return (
    <div className="audio-timeline">
      <div className="grid">
        <svg width={width} height="19" style={{ shapeRendering: 'optimizeSpeed' }}>
          <g>{audioMarkers}</g>
        </svg>
      </div>
    </div>
  )
})

const AudioTimelines = observer(({ rows, width, layoutState, timelineState, ...rest }) => {
  const { audioRows: selections } = layoutState.selections
  const children = selections.map((row) => {
    const isSelected = selections.includes(row)
    return (
      <div key={row.objectId} className={`audio-timeline ${isSelected ? 'selected' : ''}`}>
        <div className="grid">
          <svg width={width} height="19" style={{ shapeRendering: 'optimizeSpeed' }}>
            <AudioMarker
              row={row}
              timelineState={timelineState}
              layoutState={layoutState}
              {...rest}
            />
          </svg>
        </div>
      </div>
    )
  })
  return <div className="audio-timelines">{children}</div>
})

class AudioMarkerComponent extends React.Component {
  constructor(props) {
    super(props)
    this.isDragging = false // tracks if this particular view component is being dragged, not just the row object
    this.dragStartX = 0
  }
  mouseDown = (e) => {
    const { row, timelineState, layoutState } = this.props
    const { selections } = layoutState
    const isSelected = selections.audioRows.includes(row)
    const isLocked = timelineState.audioLocked || row.timelineState.locked
    if (isLocked) return
    if (!isSelected && !e.shiftKey) {
      selections.clearAllSelections()
    }
    if (!isSelected || e.shiftKey) selections.setAudioSelection(row, e.shiftKey)
    const NODE = e.target.ownerDocument // document.getElementById("timeline")
    NODE.addEventListener('mousemove', this.mouseMove, false)
    NODE.addEventListener('mouseup', this.mouseUp, false)
    this.dragStartX = e.clientX
    this.isDragging = true
    timelineState.dragTransaction.start(row.objectId)
  }
  mouseMove = (e) => {
    const { frameWidth, timelineState } = this.props
    const diff = e.clientX - this.dragStartX
    const frameDiff = Math.floor(diff / frameWidth)
    timelineState.dragTransaction.move(frameDiff)
  }
  mouseUp = (e) => {
    const { timelineState } = this.props
    const NODE = e.target.ownerDocument // document.getElementById("timeline")
    NODE.removeEventListener('mousemove', this.mouseMove, false)
    NODE.removeEventListener('mouseup', this.mouseUp, false)
    this.isDragging = false
    timelineState.dragTransaction.end()
  }
  render() {
    const { row, timelineState, layoutState, frames, frameWidth, offset } = this.props
    const { audioRows: selections } = layoutState.selections

    const hasMedia = row.audioMedia
    const isSelected = selections.includes(row)
    const isLocked = timelineState.audioLocked || row.timelineState.locked

    const isDragging = this.isDragging
    let rectClass = ''
    if (isSelected) rectClass += ' selected'
    if (isDragging) rectClass += ' dragging'

    let rowDuration = hasMedia ? row.audioMedia.durationMs / 1000 * 24 : frames
    rowDuration = rowDuration < 1 ? 1 : rowDuration

    const isDisplayed = row.startTime <= offset + frames && row.startTime + rowDuration > offset
    const isShowText = timelineState.showText || row.timelineState.showText
    if (isDisplayed) {
      const dragOffset = isSelected ? timelineState.dragTransaction.offset : 0
      const x = (row.startTime + dragOffset - offset) * frameWidth
      const rowWidth = rowDuration * frameWidth
      let displayName = isShowText ? row.name : null
      if (!displayName && isShowText) {
        displayName = 'Empty Audio Row'
      }
      if (!displayName && !isShowText) {
        displayName = row.audioMedia ? row.audioMedia.fileName : 'No Audio Media'
      }
      return (
        <g>
          <clipPath id={row.objectId}>
            <rect x={x} y="0" width={rowWidth} height="15" />
          </clipPath>
          <rect
            className={rectClass}
            x={x}
            y="0"
            width={rowWidth}
            height="19"
            fill={!hasMedia ? '#373a3d' : '#FFFFFF'}
            rx="2"
            ry="2"
            onMouseDown={this.mouseDown}
          />
          <g clipPath={`url(#${row.objectId})`}>
            <text
              x={x + 5}
              y="13"
              fontFamily="Roboto"
              fontSize="10"
              fill={!hasMedia && !isSelected ? '#FFFFFF' : '#333333'}
              style={{ pointerEvents: 'none' }}>
              {displayName}
            </text>
          </g>
        </g>
      )
    } else {
      return null
    }
  }
}

decorate(AudioMarkerComponent, {
  isDragging: observable,
})

var AudioMarker = observer(AudioMarkerComponent)

export { MasterAudioTimeline, AudioTimelines }
