import React from 'react'
import { observer } from 'mobx-react'
import ModalWrapper from '../../containers/ModalWrapper'

const ErrorModal = observer(class ErrorModal extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { hideModal, okText, reason } = this.props
    return (
      <div>
        <ModalWrapper
          title="Error"
          hideModal={hideModal}
          okText={this.props.okText}
          onOk={hideModal}
          width="60%"
          height="60%">
          <div className="error-modal-container">
            <div className="error-modal-wrap">
              <textarea
                className="error-modal-message"
                value={reason}
                spellCheck="false"
                readOnly
              />
            </div>
          </div>
        </ModalWrapper>
      </div>
    )
  }
})

export default ErrorModal
