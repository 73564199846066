import React from 'react'
import { observer } from 'mobx-react'

const LayoutSelections = observer(({ selections }) => {
  const vDom = (
    <div class="selections">
      <div className="first-row">
        <i className="sprite selection-tools vert-dist" title="Distribute Vertically" data-qa-hook="widget.align.distVvertical" onClick={() => selections.distributeY()}/>
        <i className="sprite selection-tools hori-dist" title="Distribute Horizontally" data-qa-hook="widget.align.disthorizontal" onClick={() => selections.distributeX()}/>
        <i className="sprite selection-tools vert-spacer" />
        <i className="sprite selection-tools hori-align-min" title="Align Left" data-qa-hook="widget.align.left" onClick={() => selections.alignXMin()}/>
        <i className="sprite selection-tools hori-align-center" title="Align Horizontal Center" data-qa-hook="widget.align.horizontalcenter" onClick={() => selections.alignXCenter()}/>
        <i className="sprite selection-tools hori-align-max" title="Align Right" data-qa-hook="widget.align.right" onClick={() => selections.alignXMax()}/>
        <i className="sprite selection-tools vert-align-min" title="Align Top" data-qa-hook="widget.align.top" onClick={() => selections.alignYMin()}/>
        <i className="sprite selection-tools vert-align-center" title="Align Vertical Center" data-qa-hook="widget.align.verticalcenter" onClick={() => selections.alignYCenter()}/>
        <i className="sprite selection-tools vert-align-max" title="Align Bottom" data-qa-hook="widget.align.bottom" onClick={() => selections.alignYMax()}/>
      </div>
      <div className="second-row">
        <input type="checkbox" className="toggle-align-target" name="toggle-align-target" checked={selections.targetStage} onChange={() => selections.toggleTargetStage()} title="Align to Stage" data-qa-hook="widget.align.tostagecheckbox" />
        <label htmlFor="toggle-align-target">Align to Stage</label>
      </div>
    </div>
  )

  return vDom
})

export default LayoutSelections
