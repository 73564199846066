import React from 'react'
import { observer } from 'mobx-react'
import DecisionNode from '../models/BaseContent/DecisionNode'
import Chapter from '../models/BaseContent/Chapter'
import Sequence from '../models/BaseContent/Sequence'
import { BaseContent } from 'eplayer-core'

const WidgetPanel = observer(({ globalState }) => {
  const { dragging } = globalState
  const onChapterDrag = (e) => {
    e.dataTransfer.setData('text/panel', 'StructureWidgets')
    dragging([Chapter.create(BaseContent.Chapter())])
  }
  const onDecisionDrag = (e) => {
    e.dataTransfer.setData('text/panel', 'StructureWidgets')
    dragging([DecisionNode.create(BaseContent.DecisionNode())])
  }
  const onSequenceDrag = (e) => {
    e.dataTransfer.setData('text/panel', 'StructureWidgets')
    dragging([Sequence.create(BaseContent.Sequence())])
  }
  return (
    <div id="widgets">
      <div className="widget-lists">
        <ol className="asset-grid">
          <li className="asset" draggable="true" onDragStart={onChapterDrag}>
            <i className="sprite widget chapter" />
            <label className="asset-label">Chapter</label>
          </li>
          <li className="asset" draggable="true" onDragStart={onSequenceDrag}>
            <i className="sprite widget sequence" />
            <label className="asset-label">Sequence</label>
          </li>
          <li className="asset" draggable="true" onDragStart={onDecisionDrag}>
            <i className="sprite widget decision-node" />
            <label className="asset-label">Decision</label>
          </li>
        </ol>
      </div>
    </div>
  )
})

export default WidgetPanel
