import { decorate, observable } from "mobx"

export class Prompt {
  constructor(scheduler) {
    this.scheduler = scheduler
    this.shouldConfirm = false
    this.promptText = ""
    this.handler = null
  }

  static from(scheduler) {
    return new Prompt(scheduler)
  }

  // TODO if already in process of confirming?
  confirm(promptText, handler) {
    this.promptText = promptText
    this.handler = handler
    this.startConfirm()
  }

  startConfirm() {
    this.shouldConfirm = true
  }

  applyConfirm() {
    const { handler } = this
    this.endConfirm()
    handler(true)
  }

  denyConfirm() {
    const { handler } = this
    this.endConfirm()
    handler(false)
  }

  endConfirm() {
    this.shouldConfirm = false
    this.promptText = ""
    this.handler = null
  }

}

decorate(Prompt, {
  shouldConfirm: observable,
})

