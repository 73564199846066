import { types } from 'mobx-state-tree'

const model = types
	.model('Expandable', {
		expanded: types.boolean,
	})
	.actions(self => {
		function toggleProp(prop) {
			self[prop] = !self[prop]
		}
		return {
			toggleProp,
		}
	})
export default model
