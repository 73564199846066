import React from 'react'
import { observable } from 'mobx'
import { decorate } from 'mobx'
import { observer } from 'mobx-react'
import Input from '../components/Input'
import NameEditor from '../components/NameEditor'

function outputSearchDisplayName(displayName) {
  return { __html: displayName }
}

class SearchSequenceComponent extends React.Component {
  constructor(props) {
    super(props)
    this.isEditing = false
  }
  onEditClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setEditing(true)
  }
  setEditing = (flag) => {
    this.isEditing = flag
  }
  render() {
    const { sequence, loadSequence, ui } = this.props
    const isActiveCheck = (el) => {
      sequence.isActive ? ui.scrollControl.setNavigatorSelectedEl(el) : null
    }
    let itemClass = `tree-item tree-item-sequence`
    let itemWrapperClass = 'tree-item-inner'
    if (this.isEditing) itemWrapperClass += ' editing'
    return (
      <li className={itemClass} onClick={() => loadSequence(sequence)} ref={isActiveCheck}>
        <div className={itemWrapperClass}>
          <span className={`sprite hierarchy sequence`} />
          {this.isEditing ? (
            <NameEditor
              className="tree-item-input"
              defaultValue={sequence.name}
              onSave={sequence.setName}
              hide={() => this.setEditing(false)}
            />
          ) : (
            <span
              className="tree-item-title"
              title={sequence.name}
              dangerouslySetInnerHTML={outputSearchDisplayName(sequence.displayName)}
            />
          )}
          <div className="actions">
            <button type="button" className="edit" onClick={this.onEditClick}>
              <i className="icon edit" />
            </button>
          </div>
        </div>
      </li>
    )
  }
}

decorate(SearchSequenceComponent, {
  isEditing: observable,
})

export const SearchSequence = observer(SearchSequenceComponent)

class SearchChapterComponent extends React.Component {
  constructor(props) {
    super(props)
    this.isEditing = false
    this.collapsed = false
  }
  toggleChapter = () => {
    this.collapsed = !this.collapsed
  }
  onEditClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setEditing(true)
  }
  setEditing = (flag) => {
    this.isEditing = flag
  }
  render() {
    const { chapter, loadSequence, ui } = this.props

    const sequences = chapter.sequences.map((sequence) => (
      <SearchSequence key={sequence.objectId} sequence={sequence} loadSequence={loadSequence} ui={ui} />
    ))

    let itemClass = `tree-item tree-item-chapter`
    let itemWrapperClass = 'tree-item-inner'
    if (this.isEditing) itemWrapperClass += ' editing'
    
    if (chapter.sequences.length) {
      return (
        <li className={itemClass}>
          <div className="collapser">
            <button type="button" className="chevron" onClick={this.toggleChapter}>
              <i className={`sprite script chevron ${this.collapsed ? 'collapsed' : 'expanded'}`} />
            </button>
          </div>
          <div className={itemWrapperClass}>
            <span className={`sprite hierarchy chapter`} />
            {this.isEditing ? (
              <NameEditor
                className="tree-item-input"
                defaultValue={chapter.name}
                onSave={chapter.setName}
                hide={() => this.setEditing(false)}
              />
            ) : (
              <span
                className="tree-item-title"
                title={chapter.name}
                dangerouslySetInnerHTML={outputSearchDisplayName(chapter.displayName)}
              />
            )}
            <div className="actions">
              <button type="button" className="edit" onClick={this.onEditClick}>
                <i className="icon edit" />
              </button>
            </div>
          </div>
          {!this.collapsed && <ol className="tree">{sequences}</ol>}
        </li>
      )
    } else {
      return null
    }
  }
}

decorate(SearchChapterComponent, {
  isEditing: observable,
  collapsed: observable,
})

export const SearchChapter = observer(SearchChapterComponent)

export const NavigatorSearch = observer(class NavigatorSearch extends React.Component {
  constructor(props) {
    super(props)
    this.resetSearch()
  }
  onChange = (val) => {
    const { editionNavigator } = this.props
    editionNavigator.editionIndexSearch(val)
  }
  resetSearch = () => {
    const { editionNavigator } = this.props
    editionNavigator.editionIndexSearch('')
  }
  render() {
    const { editionNavigator } = this.props
    return (
      <div className="layout-search-controls">
        <i className="search-icon" />
        <div className="search-input">
          <Input
            type="text"
            onSave={this.onChange}
            placeholder="Search..."
            value={editionNavigator.searchQuery}
          />
        </div>
        <small className="search-reset" onClick={this.resetSearch}>
          <i className="icon times" />
        </small>
      </div>
    )
  }
})

// Main output of search component
const LayoutSearch = observer(class LayoutSearch extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { edition, state, variables, editionNavigator } = this.props
    const { editionOutline, searchQuery, hasQuery, searchResults } = editionNavigator
    const { ui } = state

    const loadSequence = (sequence) => state.layout.setupFromNavigator(variables, sequence.objectId)

    const SearchContent = hasQuery
      ? searchResults.map((chapter) => (
          <SearchChapter key={chapter.objectId} chapter={chapter} loadSequence={loadSequence} ui={ui} />
        ))
      : editionOutline.map((chapter) => (
          <SearchChapter key={chapter.objectId} chapter={chapter} loadSequence={loadSequence} ui={ui} />
        ))

    return (
      <div className="index-search">
        <div
          className={
            ui.hierarchyPanel.showProgramOutline
              ? 'hierarchy-section-header'
              : 'hierarchy-section-header collapsed'
          }>
          <strong className="section-title">Program Outline</strong>
          <i className="toggle" onClick={ui.hierarchyPanel.toggleProgramOutline}>
            X
          </i>
        </div>
        {ui.hierarchyPanel.showProgramOutline === true && (
          <div className="card-subsection">
            <NavigatorSearch editionNavigator={editionNavigator} />
            <div className="index-search-results">
              {hasQuery && editionNavigator.searchResultsCount === 0 ? (
                <span className="no-results">No Matching Sequences</span>
              ) : (
                <ol className="tree">{SearchContent}</ol>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
})

export default LayoutSearch
