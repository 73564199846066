import { action } from 'mobx'
import { autorun } from 'mobx'
import { observable } from 'mobx'
import { TASK_STATE } from './task-state.constants'
import { TASK_SORT_DIR } from './task-search.constants'
import { TASK_SORT_ORDER } from './task-search.constants'
import { TASK_SORT_ORDER_VIEW } from './task-search.constants'

export function taskSearchModel(taskSearchStore, taskStateStore, alert, prompt, app) {
  const EMPTY = observable([])
  const SORTABLE = {
    Position: true,
    Name: true,
    Version: false,
    Status: true,
    Author: true,
    Abort: false,
  }
  const bindings = {
    canSortProp: action.bound,
    confirmAbort: action.bound,
    isActiveSortProp: action.bound,
    nextPage: action.bound,
    reset: action.bound,
    resetSearch: action.bound,
    sortablePropHook: action.bound,
    updateSearch: action.bound,
    updateFilterAll: action.bound,
    updateFilterUser: action.bound,
    updateSort: action.bound,
  }
  const state = {
    get isActiveSortDesc() {
      return taskSearchStore.sortDir === TASK_SORT_DIR.DESC ?
        true :
        false
    },
    get isEmpty() {
      return taskSearchStore.data.length === 0
    },
    get isInputDisabled() {
      const { fetchingQuery } = taskSearchStore
      const { fetchingNextPage } = taskSearchStore
      return  fetchingNextPage || fetchingQuery
    },
    get isLoading() {
      const { fetchingQuery } = taskSearchStore
      return fetchingQuery
    },
    get isAllUserFilterSelected() {
      return taskSearchStore.filterUser === ''
    },
    get isUserFilterSelected() {
      return !this.isAllUserFilterSelected
    },
    get visible() {
      const { data: query } = taskSearchStore
      const { isLoading } = taskSearchStore
      if (isLoading) return EMPTY
      else return query.map(t =>  {
        const isAbortable = 
          t.status === TASK_STATE.QUEUED ||
          t.status === TASK_STATE.INITIALIZING ||
          t.status === TASK_STATE.CAPTURING
        const abort = isAbortable ?
          () => this.confirmAbort(t) :
          null

        return { ...t,
          abort,
          isAbortable,
        }
      })
    },
    canSortProp(viewProp) {
      return SORTABLE[viewProp] ? true : false
    },
    isActiveSortProp(viewProp) {
      const { sortProp } = taskSearchStore
      return TASK_SORT_ORDER_VIEW[sortProp] === viewProp
    },
    sortablePropHook(autoHook, viewProp) {
      `${autoHook}.${viewProp.toLowerCase().replace(/\s+/g, "-")}`
    },
    async confirmAbort({ id, name, }) {
      prompt.confirm(`Abort capture task for ${name}`, confirmed => {
        if (confirmed) taskStateStore.abortTask(id)
      })
    },
    async nextPage() {
      const { isLoading } = taskSearchStore
      const { hasLastPage } = taskSearchStore
      if (isLoading || hasLastPage) return

      await taskSearchStore.queryNextPage()
    },
    reset() {
      taskSearchStore.reset()
    },
    resetSearch() {
      this.updateSearch('')
    },
    async updateFilterAll() {
      await taskSearchStore.queryChangedFilter('')
    },
    async updateFilterUser() {
      await taskSearchStore.queryChangedFilter(app.username)
    },
    async updateSearch(value) {
      await taskSearchStore.queryChangedSearch(value)
    },
    async updateSort(next) {
      await taskSearchStore.queryChangedSort(TASK_SORT_ORDER[next])
    },
  }

  const model = observable(state, bindings)

  autorun(() => {
    const { err } = taskSearchStore
    if (err) alert.show('error', err.message ?? err)
  })

  autorun(() => {
    const t = taskStateStore.changed
    if (!t || t.taskStatus !== TASK_STATE.ABORTED) return
    else taskSearchStore.remove(t.taskId)
  })

  return model
}
