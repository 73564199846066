import React from 'react'
import { observer } from 'mobx-react'
import Viewable from './viewable'
import {
  TIMELINE_DEFAULT_INTERVAL,
  TIMELINE_INTERVAL_THRESHOLD,
  TIMELINE_LABEL_WIDTH
} from '../../constants'

const Ruler = observer(class Ruler extends React.Component {
  mouseDown = (e) => {
    const { layoutState, frameWidth, offset } = this.props
    const newFrame = Math.floor((e.clientX - TIMELINE_LABEL_WIDTH) / frameWidth) + offset
    layoutState.player.setFrame(newFrame)
  }
  render() {
    const { layoutState, timelineState, duration, frames, frameWidth, offset, width } = this.props
    const { player } = layoutState
    const { frame } = player

    // 60 : 5
    // 120 : 10
    // 240 : 20
    // 480 : 40
    // 960 : 80
    // 1920 : 160

    let interval = TIMELINE_DEFAULT_INTERVAL
    if (frames > TIMELINE_INTERVAL_THRESHOLD) {
      interval = Math.ceil(frames * 0.083 / 10) * 10
    }

    // Container for our svg element(s)
    let labels = []
    // Draw frame markers starting with the offset and ending at the frameLimit.
    for (let f = offset; f < offset + frames && f <= duration; f++) {
      // f = frame number
      // Reset the X coordinate so the offset is effectively 0.
      let x = f * frameWidth - offset * frameWidth
      // Don't draw anything where the playhead currently resides
      if (f === frame) {
        continue
      }
      // First frame
      if (f === 1) {
        // Draw f number label
        labels.push(
          <text key={f + '.2'} x={x} y={8} fontSize="10px" fill="#999999">
            <tspan x={`${x + frameWidth / 2 + 10}`} textAnchor="middle">
              {f}
            </tspan>
          </text>
        )
      }
      // If the frame number is divisible by 5, draw the marker slightly taller and add a label.
      if (f % interval === 0 && f !== 1) {
        // Draw the frame number label
        labels.push(
          <text key={f + '.1'} x={`${x}`} y={8} fontSize="10px" fill="#999999">
            <tspan x={`${x + frameWidth / 2 + 10}`} textAnchor="middle">
              {f}
            </tspan>
          </text>
        )
      }
    }

    const rulerProps = {
      id: 'ruler',
      className: 'ruler'
    }

    return (
      <div {...rulerProps}>
        <Viewable {...this.props} frame={frame} />
        <div className="labels" onMouseDown={this.mouseDown}>
          <svg width={`${width}`} height={15} style={{ shapeRendering: 'optimizeSpeed' }}>
            {labels}
          </svg>
        </div>
      </div>
    )
  }
})

export default Ruler
