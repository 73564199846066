import React from 'react'
import ModalWrapper from '../../containers/ModalWrapper'
import { noFirstSpace, isExpirationDateYearLater,isExpirationDateAWeekPast,isExpirationDateFormatValid, isExpirationDateComplete } from '../../utils'
import MaskedInput from 'react-maskedinput'
export default class ShareLinkModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      focused: false,
      advisorName: '',
      comments: '',
      isNameValid: true,
      expirationValidationMessage: '',
    }
  }

  onFocus = () => {
    this.setState({ focused: true })
  }

  onBlur = () => {
    this.setState({ focused: false })
  }

  setName = (e) => {
    this.setState({ advisorName: noFirstSpace(e.target.value) })
  }

  clearName = (e) => {
    this.setState({ advisorName: '' })
  }
  getLink = () => {
    const isNameValid = this.state.advisorName !== ''
    this.setState({ isNameValid: isNameValid })
    if (isNameValid) {
      this.props.continue({ name: this.state.advisorName, notes: this.state.comments,linkInputExpiration : this.state.linkInputExpiration  })
    }
  }

  updateComments = (e) => {
    const { value: comments } = e.target
    this.setState({ comments: comments })
  }

  setLinkInputExpiration = (e) => {
this.setState({
  linkInputExpiration: e.target.value
})
  }

  validateExpiration = ()=> {
    const {linkInputExpiration='', expirationValidationMessage=''} = this.state;
    if(linkInputExpiration !== ''){

      if (!isExpirationDateComplete(linkInputExpiration) || !isExpirationDateFormatValid(linkInputExpiration)) {
       this.setState({
expirationValidationMessage : 'Please enter a valid date.'
       }) 
        return false
      }

      if (!isExpirationDateAWeekPast(linkInputExpiration)) {
        this.setState({
          expirationValidationMessage : ' Cant set date to more than a week in the past '
                 }) 
        return false
      }

      if (!isExpirationDateYearLater(linkInputExpiration)) {
        this.setState({
          expirationValidationMessage : 'Cant set date to more than a year from today'
                 }) 
        return false
      }

    }
    if(expirationValidationMessage != ''){
      this.setState({
        expirationValidationMessage : ''
               }) 
    }

  }

  render() {

    let expirationClass = 'link-expiration'
     if (this.state.expirationValidationMessage != '') {
       expirationClass = 'link-expiration invalid'
     }
  const isSubmitDisabled =  this.state.expirationValidationMessage !== "" ? true : false
    return (
      <div>
        <ModalWrapper
          title="Share Link"
          hideModal={this.props.cancel}
          width="700px"
          height="auto"
          modaltype="popup">
          <div className="newedition-wrap">
            <div className="share-name-block">
              <label className="share-link-label">Advisor Name (required)</label>
              <input
                type="text"
                maxLength={128}
                className="edition-name"
                ref="editionname"
                placeholder="Advisor Name"
                onChange={this.setName}
                value={this.state.advisorName}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
              />
              <input type="button" className="clear-name" onClick={this.clearName} />
              <label className="validation">
                <span className={this.state.isNameValid ? `hidden` : ``}>
                  You must add the Advisor's name before moving on.
                </span>
              </label>
              <div className="link-expiration-container"> 
              <label className="share-link-label">Add an expiration date (optional)</label>
              <MaskedInput
          mask="11/11/1111"
          className={expirationClass}
          placeholder="mm/dd/yyyy"
          type="text"
          value={this.state.linkInputExpiration}
          onChange={this.setLinkInputExpiration}
        
          onBlur={() => {
            this.validateExpiration();
          }}
        />
      
        </div>
        <div className="error">
          {this.state.expirationValidationMessage}</div>
              <label className="share-link-label">Comments</label>
              <textarea
                className="edition-comments"
                placeholder="Add comments"
                onChange={this.updateComments}
              />
            </div>
            <div className="buttons">
            <button
                className="right-option"
                disabled={isSubmitDisabled}
                onClick={this.getLink}
              >
                Get Link
              </button>
            </div>
          </div>
        </ModalWrapper>
      </div>
    )
  }
}
