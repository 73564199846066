import { observable, computed, action } from 'mobx'
import { TIMELINE_DEFAULT_HEIGHT, TIMELINE_LABEL_WIDTH, TIMELINE_PAD_RIGHT } from '../../constants'

export default function TimelineState(app) {
  const easingContextMenuState = observable({
    keyframe: null,
    visible: false,
    clickX: 0,
    clickY: 0,
    show: function(event, kf) {
      this.visible = true
      this.keyframe = kf
      this.clickX = event.clientX
      this.clickY = event.clientY
    },
    hide: function() {
      this.visible = false
      this.keyframe = null
      this.clickX = 0
      this.clickY = 0
    },
    setEasing: function(easingFnStr) {
      this.keyframe.updateEasing(easingFnStr)
      this.hide()
    }
  }, {
    keyframe: observable.ref,
    show: action.bound,
    hide: action.bound
  })
  const dragTransactionState = observable({
    object: '',
    offset: 0,
    minOffset: 0, // lower bound - 1
    //maxOffset: 0, // could be sequence.duration - upper bound
    start: function(id = '') {
      const { selections } = app.state.layout
      const min = selections.timelineShiftOffsetMin
      this.object = id
      this.offset = 0
      this.minOffset = min
    },
    move: function(offset = 0) {
      if (offset >= this.minOffset) {
        this.offset = offset
      }
    },
    end: function() {
      const { selections } = app.state.layout
      const delta = this.offset
      selections.shiftTimelineSelected(delta)
      this.object = ''
      this.offset = 0
      this.minOffset = 0
    }
  }, {
    start: action.bound,
    move: action.bound,
    end: action.bound
  })
  return observable({
    timelineEl: null,
    timelineElSize: {
      width: document.body.clientWidth, // total width of timeline panel
      height: TIMELINE_DEFAULT_HEIGHT,
      labelWidth: TIMELINE_LABEL_WIDTH, // width of left label area (fixed)
      get timelineWidth() {
        // width of right timeline area
        return this.width - this.labelWidth - TIMELINE_PAD_RIGHT
      }
    },
    //actionsCodeEditing: false,
    actionsExpanded: false,
    assetsExpanded: true,
    assetsLocked: false, //lockAssets
    audioLocked: false, //lockAudio
    videoLocked: false, //lockAudio
    showText: false,
    muteAll: false,
    zoom: 1,
    offset: 1,
    easingContextMenu: easingContextMenuState,
    dragTransaction: dragTransactionState,
    // Actions
    reset: function() {
      this.easingContextMenu.hide()
      this.dragTransaction.end()
      this.setFrameOffset(1)
      this.setZoom(1)
    },
    setTimelineEl: function(el) {
      this.timelineEl = el
    },
    setTimelineSize: function(w, h) {
      this.timelineElSize.width = w
      this.timelineElSize.height = h
    },
    resize: function() {
      // Access necessary DOM props and set state
      const { timelineEl, setTimelineSize } = this
      if (!timelineEl) return
      const w = timelineEl.clientWidth
      const h = timelineEl.clientHeight
      setTimelineSize(w, h)
    },
    toggleSection: function(section) {
      this[section] = !this[section]
    },
    toggleAssetsLock: function() {
      this.assetsLocked = !this.assetsLocked
    },
    toggleAudioLock: function() {
      this.audioLocked = !this.audioLocked
    },
    toggleAudioText: function() {
      this.showText = !this.showText
    },
    toggleAudioMute: function() {
      this.muteAll = !this.muteAll
    },
    toggleVideoLock: function() {
      this.videoLocked = !this.videoLocked
    },
    setZoom: function(zoom) {
      let newZoom = parseFloat(zoom)
      newZoom = Math.max(newZoom, 0.1) // floor of 0.10
      newZoom = Math.min(newZoom, 1) // ceiling of 1
      this.zoom = newZoom
    },
    setFrameOffset: function(offset) {
      let newOffset = parseInt(offset)
      newOffset = Math.max(newOffset, 1) // floor of 1
      this.offset = newOffset
    }
  }, {
    timelineEl: observable.ref,
    reset: action.bound,
    setTimelineEl: action.bound,
    setTimelineSize: action.bound,
    resize: action.bound,
    toggleSection: action.bound,
    toggleAssetsLock: action.bound,
    toggleAudioLock: action.bound,
    toggleAudioText: action.bound,
    toggleAudioMute: action.bound,
    toggleVideoLock: action.bound,
    setZoom: action.bound,
    setFrameOffset: action.bound
  })
}
