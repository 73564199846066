import jsesc from 'jsesc'
import addYears from 'date-fns/add_years'
import subDays from 'date-fns/sub_days'

const varReplace = /\[{2}([\w|\.|\_]*)\]{2}/g
const exprReplace = /(?:\s\+\s)?(\$\.(?:custom|patient|runtime)\.[\w|\_]+)(?:\s\+\s)?/g

export const isArray = Array.isArray

// toggleVals function
// Toggles two values against a target variable

export function toggleVals(a, b, target) {
  if (target === a) return b
  else return a
}

// Action helpers
export function updateProp(field, value, ...items) {
  for (let i = 0, l = items.length; i < l; i++) {
    let item = items[i]
    item[field] = value
  }
}

export function updateVec(field, value, ...items) {
  for (let i = 0, l = items.length; i < l; i++) {
    let item = items[i]
    const vector = item[field]
    for (let j = 0; j < vector.length; j++) {
      vector[j] = value[j]
    }
  }
}

export function updateVecElem(field, index, value, ...items) {
  for (let i = 0, l = items.length; i < l; i++) {
    let item = items[i]
    const vector = item[field]
    vector[index] = value
  }
}

export function updateNestedObj(field1, field2, value, ...items) {
  for (let i = 0, l = items.length; i < l; i++) {
    let item = items[i]
    const f1 = item[field1]
    f1[field2] = value
  }
}

// MUTATIVE
export function sortBy(key, ascending, arr) {
  if (ascending) arr.sort((a, b) => a[key] > b[key])
  else arr.sort((a, b) => a[key] < b[key])
}

export function remove(array, el) {
  array.splice(array.indexOf(el), 1)
}

export function last(array) {
  return array[array.length - 1]
}

export function find(predFn, array) {
  for (var i = 0, item; (item = array[i++]); ) {
    if (predFn(item)) return item
  }
  return null
}

export function contains(list, value) {
  for (var i = 0; i < list.length; ++i) {
    if (list[i] === value) return true
  }
  return false
}

export function map(f, xs, ys = []) {
  const l = xs.length
  for (let i = 0; i < l; i++) ys[i] = f(xs[i], i)
  return ys
}

export function compare(xs, ys) {
  const xLen = xs.length
  const yLen = ys.length
  if (xLen !== yLen) return false

  for (let i = 0; i < xLen; i++) {
    if (xs[i] !== ys[i]) return false
  }

  return true
}

export function clone(xs) {
  const len = xs.length
  const ys = new Array(len)
  for (let i = 0; i < len; i++) ys[i] = xs[i]
  return ys
}

export function intersect(a, b) {
  let t
  if (b.length > a.length) (t = b), (b = a), (a = t) // indexOf to loop over shorter
  return a.filter(function(e) {
    if (b.indexOf(e) !== -1) return true
  })
}

export function walkTree(f, node, parent) {
  const { children } = node
  f(node, parent)
  let end = children.length
  for (let i = 0, c; i < end; i++) {
    c = children[i]
    walkTree(f, c, node)
  }
}

export function reduceTree(f, a, rt) {
  walkTree((node) => f(a, node), rt)
}

// radians  between -π and +π
export function radiansBetween(v1, v2) {
  const dTheta = Math.atan2(v2[1], v2[0]) - Math.atan2(v1[1], v1[0])

  if (dTheta < -Math.PI) return dTheta + Math.PI * 2
  else if (dTheta > Math.PI) return dTheta - Math.PI * 2
  else return dTheta
}

export function midpoint(p1, p2) {
  return [(p1[0] + p2[0]) / 2, (p1[1] + p2[1]) / 2]
}

export function rgbaToHex(rgba) {
  // Must perform string padding or React shits a brick
  const r = ('0' + rgba[0].toString(16)).slice(-2)
  const g = ('0' + rgba[1].toString(16)).slice(-2)
  const b = ('0' + rgba[2].toString(16)).slice(-2)
  return `#${r}${g}${b}`
}

export function hexToRGBA(hex) {
  const matches = hex.match(/^\#(\w{2})(\w{2})(\w{2})/)
  const r = parseInt(matches[1], 16)
  const g = parseInt(matches[2], 16)
  const b = parseInt(matches[3], 16)
  return [r, g, b, 1]
}

export function toMMSS(num) {
  var secNum = num.toFixed(2)
  var minutes = Math.floor(secNum / 60)
  var seconds = secNum - minutes * 60

  seconds = seconds.toFixed(2)
  if (minutes < 10) minutes = '0' + minutes
  if (seconds < 10) seconds = '0' + seconds
  return minutes + '.' + seconds
}

export function ago(date) {
  const units = {
    second: 1000,
    minute: 60 * 1000,
    hour: 60 * 1000 * 60,
    day: 24 * 60 * 1000 * 60,
    week: 7 * 24 * 60 * 1000 * 60,
    month: 30 * 24 * 60 * 1000 * 60,
    year: 365 * 24 * 60 * 1000 * 60
  }

  const round = Math.round
  const pluralize = (unit, number) => {
    return number + ' ' + unit + (number > 1 ? 's' : '') + ' ago'
  }
  const timeSince = new Date().getTime() - new Date(date).getTime()

  if (timeSince < 30000) return 'a few seconds ago'
  if (timeSince < 120000) return 'about a minute ago'

  let unit

  for (let key in units) {
    if (round(timeSince) < units[key])
      return pluralize(unit || 'm', round(timeSince / (units[unit] || 1)))
    unit = key
  }

  return pluralize(unit, round(timeSince / units[unit]))
}

export function copyToClipboard(text) {
  var dummy = document.createElement('input')
  document.body.appendChild(dummy)
  dummy.setAttribute('value', text)
  dummy.select()
  document.execCommand('copy')
  document.body.removeChild(dummy)
}

export function getQueryString() {
  let match
  let pl = /\+/g // Regex for replacing addition symbol with a space
  let search = /([^&=]+)=?([^&]*)/g
  let decode = function(s) {
    return decodeURIComponent(s.replace(pl, ' '))
  }
  let query = window.location.search.substring(1)

  let urlParams = {}
  while ((match = search.exec(query)) !== null) {
    urlParams[decode(match[1])] = decode(match[2])
  }
  return urlParams
}

//optimized window resize (https://developer.mozilla.org/en-US/docs/Web/Events/resize)
export function requestAnimationFrameThrottle(type, name, obj) {
  obj = obj || window
  var running = false
  var func = function() {
    if (running) {
      return
    }
    running = true
    requestAnimationFrame(function() {
      obj.dispatchEvent(new CustomEvent(name))
      running = false
    })
  }
  obj.addEventListener(type, func)
}

// getTimeStamp function
// Processes and seperates datestamp and timestamp from compound format 2017-06-16T20:05:25.614+0000
export function getTimeStamp(date) {
  if (!date) return '?'
  let d = new Date(date)
  let month = d.getMonth() + 1
  // Generates Datestamp
  month = month < 10 ? '0' + month : month
  let day = d.getDate()
  day = day < 10 ? '0' + day : day
  let year = d
    .getFullYear()
    .toString()
    .substr(2, 2)
  // Generates timestamp
  let hour = d.getHours()
  hour = hour < 10 ? '0' + hour : hour
  let minutes = d.getMinutes()
  minutes = minutes < 10 ? '0' + minutes : minutes
  // Returns datestamp and timestamp as a two value array
  if (hour > 12) {
    hour = hour - 12
    return [`${month}/${day}/${year}`, `${hour}:${minutes}pm`]
  } else {
    return [`${month}/${day}/${year}`, `${hour}:${minutes}am`]
  }
}

export function getVersionString(version) {
  const { major, minor, working } = version
  return `${major}.${minor}.${working}`
}

export function escapeString(string) {
  return jsesc(string, { quotes: 'double', wrap: true, minimal: true })
}

export function unescapeString(string) {
  if ('string' !== typeof string) return string
  string = string.replace(/\\u([\d\w]{4})/gi, function(match, grp) {
    return String.fromCharCode(parseInt(grp, 16))
  })
  string = string.replace(/\\x([0-9A-Fa-f]{2})/g, function() {
    return String.fromCharCode(parseInt(arguments[1], 16))
  })
  // Fix double quote escaping
  string = string.split('\\"').join('"')
  // Fix newline escaping
  string = string.split('\\n').join('\n')
  return unescape(string)
}

export function formatVarExpr(exprParts, inputStr, index, varExpr) {
  const endOfInput = inputStr[index] === undefined
  const hasContent = exprParts.length > 0

  // "[[foo]]"
  if (endOfInput && !hasContent) return varExpr
  // "...[[foo]]"
  else if (endOfInput && hasContent) return ' + ' + varExpr
  // "[[foo]]..."
  else if (!endOfInput && !hasContent) return varExpr + ' + '
  // "...[[foo]]..."
  else if (!endOfInput && hasContent) return ' + ' + varExpr + ' + '
  // WAT
  else return ''
}

export function expressionTextFrom(inputStr) {
  const exprParts = []
  let processing = true,
    copyFrom = 0,
    nextReplace,
    exprString

  while (processing) {
    nextReplace = varReplace.exec(inputStr)
    if (isArray(nextReplace)) {
      const [, varMatch] = nextReplace
      const { index: bracketStartIndex } = nextReplace
      const bracketEndIndex = varReplace.lastIndex
      const varKeys = varMatch.split('.')
      const keyLen = varKeys.length
      exprParts.push(escapeString(inputStr.slice(copyFrom, bracketStartIndex)))

      if (keyLen === 0) {
      } else if (keyLen === 1)
        exprParts.push(formatVarExpr(exprParts, inputStr, bracketEndIndex, '$.custom.' + varMatch))
      else exprParts.push(formatVarExpr(exprParts, inputStr, bracketEndIndex, '$.' + varMatch))

      copyFrom = bracketEndIndex
    } else {
      const rest = inputStr.slice(copyFrom)
      if (rest !== '') exprParts.push(escapeString(rest))
      processing = false
    }
  }

  return exprParts.length ? exprParts.join('') : inputStr
}

export function inputTextFrom(exprString) {
  const templateParts = []
  let processing = true,
    copyFrom = 0,
    nextReplace

  while (processing) {
    nextReplace = exprReplace.exec(exprString)
    if (isArray(nextReplace)) {
      const [, exprMatch, ,] = nextReplace
      const { index: exprStartIndex } = nextReplace
      const exprEndIndex = exprReplace.lastIndex
      const varKeys = exprMatch.split('.')

      // Modifiers unwrap text from inner quotes
      templateParts.push(unescapeString(exprString.slice(copyFrom + 1, exprStartIndex - 1)))

      if (varKeys[1] === 'custom') templateParts.push('[[' + varKeys[2] + ']]')
      else templateParts.push('[[' + varKeys[1] + '.' + varKeys[2] + ']]')

      copyFrom = exprEndIndex
    } else {
      // Modifiers unwrap text from inner quotes
      const rest = unescapeString(exprString.slice(copyFrom + 1, exprString.length - 1))
      templateParts.push(rest)
      processing = false
    }
  }
  return templateParts.length ? templateParts.join('') : exprString
}

// Checks if a variable string is valid. The alternative to this method is a super complex RegEx
export function isValidVariable(identifierStr) {
  if (typeof identifierStr !== 'string') return false
  try {
    new Function('var ' + identifierStr)()
  } catch (e) {
    return false
  }
  return true
}

export function handleMediaPayload(file, base64Content) {
  const { name: filename, size, type: t } = file
  const [, ext] = t.match(/^\w+\/(?:x\-)?(\w+)(?:\+\w+)?/)
  const type = ext === 'mpeg' ? 'mp3' : ext

  return { filename, size, type, base64Content }
}

// Checks to make sure that the first character in a field entry isn't a n empty space - Ex. search terms, edition name
export function noFirstSpace(value) {
  return value.replace(/\s/g, '').length ? value : ''
}

// Rounds value to x decimals
export function roundTo(value, x) {
  return Number(Math.round(value + 'e' + x) + 'e-' + x)
}

export function roundForDisplay(value, x) {
  return Number(value.toFixed(x))
}

export function secondsToDurationString(seconds) {
  let s = Math.floor(seconds % 60)
  let m = Math.floor(seconds / 60) % 60
  let h = Math.floor(seconds / 60 / 60)
  return `${h} hr, ${m} min, ${s} sec`
}

export function os() {
  var ua = navigator.userAgent
  if (/mac/i.test(ua)) return 'mac'
  if (/win/i.test(ua)) return 'windows'
  if (/linux/i.test(ua)) return 'linux'
}



export function isExpirationDateFormatValid(linkInputExpiration) {
  if (!linkInputExpiration) return false
  const dateParts = linkInputExpiration.split('/')
  const year = parseInt(dateParts[2])
  const month = parseInt(dateParts[0])
  const day = parseInt(dateParts[1])
  if (year <= 0 || month <= 0 || day <= 0 || month > 12 || day > 31) {
    return false
  }
  return true
}

export function  isExpirationDateAWeekPast(linkInputExpiration) {
  if (!linkInputExpiration) return false
  const dateParts = linkInputExpiration.split('/')
  const year = parseInt(dateParts[2])
  const month = parseInt(dateParts[0])
  const day = parseInt(dateParts[1])
  const today = new Date()
  const selectedDate = new Date(year, month - 1, day)
  if (selectedDate < subDays(today, 7)) {
    return false
  }
  return true
}
export function isExpirationDateYearLater(linkInputExpiration) {
  if (!linkInputExpiration) return false
  const dateParts = linkInputExpiration.split('/')
  const year = parseInt(dateParts[2])
  const month = parseInt(dateParts[0])
  const day = parseInt(dateParts[1])
  const today = new Date()
  const selectedDate = new Date(year, month - 1, day)
  const later = addYears(today, 1)
  if (later < selectedDate) {
    return false
  }
  return true
}


export function  isExpirationDateComplete(linkInputExpiration) {
  if (!linkInputExpiration) return false
  return linkInputExpiration.indexOf('_') === -1
}

export function isNavigationValid(edition) {
  const chapters = edition.chapters

  const doesSequenceTerminate =
        (nodes, sequence) =>
          sequence.next === null ?
            [ ...nodes, sequence ]:
            nodes

  const isDecisionUnspecified =
    (nodes, decisionNode) =>
      decisionNode.next === null ||
      decisionNode.connections.reduce((bool, opt) =>
        bool ?
          bool :
          opt.value === null ?
            true :
            bool,
        false) ?
        [...nodes, decisionNode ] :
        nodes

  const sequences = chapters.reduce((nodes, chapter) =>
    chapter.sequences.reduce(doesSequenceTerminate, nodes),
    [])

  const decisions = chapters.reduce((nodes, chapter) =>
    chapter.decisionNodes.reduce(isDecisionUnspecified, nodes),
    [])

  return [ sequences, decisions ]
}

export function assert(condition, msg) {
  if (!condition) throw new Error(msg)
}

export function isTrueObject(a) {
  return a.toString instanceof Function && '[object Object]' === a.toString()
}

export function enumOf(...members) {
  const E = {}
  for (const member of members.map(m => m.toUpperCase())) {
    E[member] = member
  }
  return Object.freeze(E)
}

export function findByObjectId(entities, objectId) {
  return entities.find(a => a.objectId === objectId) 
}
