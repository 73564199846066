import { getParent } from 'mobx-state-tree'
import { getSnapshot } from 'mobx-state-tree'
import { getRoot } from 'mobx-state-tree'
import { isStateTreeNode } from 'mobx-state-tree'
import { Asset } from 'eplayer-core'
import { Keyframe } from 'eplayer-core'
import { setVec } from '../../utils'
import { setProp } from '../../utils'

const TEMPLATE_PROPS = {
  name: true,
  objectId: true,
  updatedAt: true,
  updatedBy: true,
  template: true,
}

export const TEMPLATE_PROPS_EXCLUDE = {
  active: true,
  opacity: true,
  position: true,
  rotation: true,
  scale: true,
  canvasFrame: true,
  template: true,
  htmlFor: true,
  value: true,
  disabled: true,
  // Checkbox
  checked: true,
  // Slider
  min: true,
  max: true,
  step: true,
  sliderTheme: true,
  // Input
  placeholder: true,
  // Button
  title: true,
}

const Kf = Keyframe.Keyframe

const assetActions = (self) => {
  function updateProp(prop, value, applyTemplate = true) {
    if (TEMPLATE_PROPS_EXCLUDE[prop]) {
      return setProp(prop, value, self)
    }

    const template = self.template

    if (applyTemplate && template && !TEMPLATE_PROPS[prop]) {
      return template.updateProp(prop, value)
    } else {
      return setProp(prop, value, self)
    }
  }


  function updateModalProp(mode, prop, value, applyTemplate = true) {
    const template = self.template

    if (applyTemplate && template) {
      return template.updateModalProp(mode, prop, value)
    } else {
      const modeMap = self.states[mode]
      return setProp(prop, value, modeMap)
    }
  }

  function updateVec(prop, value, applyTemplate = true) {
    if (TEMPLATE_PROPS_EXCLUDE[prop]) {
      return setVec(prop, value, self)
    } else if (applyTemplate && self.template) {
      return self.template.updateVec(prop, value)
    } else {
      return setVec(prop, value, self)
    }
  }

  function updateModalVec(mode, prop, value, applyTemplate = true) {
    const template = self.template

    if (applyTemplate && template) {
      return template.updateModalVec(mode, prop, value)
    } else {
      const modeMap = self.states[mode]
      return setVec(prop, value, modeMap)
    }
  }

  function updateExpression(prop, value, applyTemplate = true) {
    if (TEMPLATE_PROPS_EXCLUDE[prop]) {
      self[prop].string = value
      return 
    } else {
      const template = self.template

      if (applyTemplate && template) {
        template.updateExpression(prop, value)
      } else {
        self[prop].string = value
      }
    }
  }

  function addKeyframe(prop, keyframe) {
    keyframe = keyframe || Kf()
    const frame = keyframe.frame
    const kfs = self[`${prop}Keyframes`]
    // Make sure a Keyframe at this frame doesn't already exist
    const existingKeyframe = kfs.find(kf => {
      return kf.frame === frame
    })
    // Replace current frame keyframe value (note: replace the value instead of the Keyframe to prevent dead objects)
    if (existingKeyframe) existingKeyframe.updateValue(keyframe.value)
    else return kfs.push(keyframe)
  }

  // This should be faster than the below action if we know the prop
  function removeKeyframeByProp(prop, keyframe) {
    self[`${prop}Keyframes`].remove(keyframe)
  }

  function removeKeyframe(keyframe) {
    const keyframesArr = getParent(keyframe)
    keyframesArr.remove(keyframe)
  }

  function toggleHierarchyState(prop) {
    self.hierarchyState[prop] = !self.hierarchyState[prop]
  }

  function forceTrueHierarchyState(prop) {
    self.hierarchyState[prop] = true
  }

  function duplicate() {
    const t = self.template
    const p = getParent(self, 2)
    p.addChild(self.clone())

    if (t) {
      p.children.forEach(asset =>
        t.linkOrphan(asset))
    }
  }

  function remove() {
    const p = getParent(self, 2)
    const t = self.template

    if (t) {
      t.unlink(self)
    }

    p.removeChild(self)
  }

  function paste(obj, isSameDomain) {
    // Re-clone on paste for refreshed UUIDs
    if (isSameDomain) {
      self.addChild(Asset.clone(obj))
    } else {
      self.addChild(Asset.cloneWithoutMedia(obj)) // Clear Media from certain asset types if domains do not match
    }

    // `obj` may possess an orphaned template and needs to be linked if so
    const e = getRoot(self)

    e.templateMedia.forEach(t => 
      self.children.forEach(asset =>
        t.linkOrphan(asset)))
  }

  function clone() {
    return Asset.clone(getSnapshot(self))
  }

  function removeChild(asset) {
    self.children.remove(asset)
  }

  function addChild(asset) {
    if (isStateTreeNode(asset)) {
      const edition = getRoot(self)
      edition.detachNode(asset)
    }

    if ("Stage" === self.Type && "Video" === asset.Type) {
      self.children.splice(0, 0, asset)
    } else {
      self.children.push(asset)
    }
  }

  function addChildAfter(asset, prior) {
    if (isStateTreeNode(asset)) {
      const edition = getRoot(self)
      edition.detachNode(asset)
    }
    for (var i = 0, l = self.children.length; i < l; i++) {
      if (self.children[i].objectId === prior.objectId){
        self.children.splice(i, 0, asset);
        return
      }
    }
  }

  function acceptsDrop() {
    return true
  }

  function alignXMinLayout(targetAsset) {
    const selfPos = self.position
    const selfPosKfs = self.positionKeyframes

    const targetPos = targetAsset.position

    const selfX = selfPos[0]
    const targetX = targetPos[0]

    const dlta = targetX - selfX

    const nextSelfPos = [ selfX + dlta, selfPos[1], selfPos[2] ]

    self.updateProp("position", nextSelfPos)

    for (let i = 0, kf, kfVal, nextVal; i < selfPosKfs.length; i++) {
      kf = selfPosKfs[i]
      kfVal = kf.value
      nextVal = [ kfVal[0] + dlta, kfVal[1], kfVal[2] ]

      kf.updateValue(nextVal)
    }
  }

  function alignXMaxLayout(targetAsset) {
    const selfW = self.width
    const selfPos = self.position
    const selfPosKfs = self.positionKeyframes

    const targetW = targetAsset.width
    const targetPos = targetAsset.position

    const selfX = selfPos[0]
    const targetX = targetPos[0] + targetW

    const dlta = (targetX - selfX) - selfW

    const nextSelfPos = [ selfX + dlta, selfPos[1], selfPos[2] ]

    self.updateProp("position", nextSelfPos)

    for (let i = 0, kf, kfVal, nextVal; i < selfPosKfs.length; i++) {
      kf = selfPosKfs[i]
      kfVal = kf.value
      nextVal = [ kfVal[0] + dlta, kfVal[1], kfVal[2] ]

      kf.updateValue(nextVal)
    }
  }
  function alignXCenterLayout(targetAsset) {
    const selfW = self.width
    const selfPos = self.position
    const selfPosKfs = self.positionKeyframes

    const targetW = targetAsset.width
    const targetPos = targetAsset.position

    const selfX = selfPos[0]
    const targetX = targetPos[0] + (targetW / 2)

    const dlta = (targetX - selfX) - (selfW / 2)

    const nextSelfPos = [ selfX + dlta, selfPos[1], selfPos[2] ]

    self.updateProp("position", nextSelfPos)

    for (let i = 0, kf, kfVal, nextVal; i < selfPosKfs.length; i++) {
      kf = selfPosKfs[i]
      kfVal = kf.value
      nextVal = [ kfVal[0] + dlta, kfVal[1], kfVal[2] ]

      kf.updateValue(nextVal)
    }
  }
  function alignYMinLayout(targetAsset) {
    const selfPos = self.position
    const selfPosKfs = self.positionKeyframes

    const targetPos = targetAsset.position

    const selfY = selfPos[1]
    const targetY = targetPos[1]

    const dlta = targetY - selfY

    const nextSelfPos = [ selfPos[0], selfY + dlta, selfPos[2] ]

    self.updateProp("position", nextSelfPos)

    for (let i = 0, kf, kfVal, nextVal; i < selfPosKfs.length; i++) {
      kf = selfPosKfs[i]
      kfVal = kf.value
      nextVal = [ kfVal[0], kfVal[1] + dlta, kfVal[2] ]

      kf.updateValue(nextVal)
    }
  }
  function alignYMaxLayout(targetAsset) {
    const selfH = self.height
    const selfPos = self.position
    const selfPosKfs = self.positionKeyframes

    const targetH = targetAsset.height
    const targetPos = targetAsset.position

    const selfY = selfPos[1]
    const targetY = targetPos[1] + targetH

    const dlta = (targetY - selfY) - selfH

    const nextSelfPos = [ selfPos[0], selfY + dlta, selfPos[2] ]

    self.updateProp("position", nextSelfPos)

    for (let i = 0, kf, kfVal, nextVal; i < selfPosKfs.length; i++) {
      kf = selfPosKfs[i]
      kfVal = kf.value
      nextVal = [ kfVal[0], kfVal[1] + dlta, kfVal[2] ]

      kf.updateValue(nextVal)
    }
  }
  function alignYCenterLayout(targetAsset) {
    const selfH = self.height
    const selfPos = self.position
    const selfPosKfs = self.positionKeyframes

    const targetH = targetAsset.height
    const targetPos = targetAsset.position

    const selfY = selfPos[1]
    const targetY = targetPos[1] + (targetH / 2)

    const dlta = (targetY - selfY) - (selfH / 2)

    const nextSelfPos = [ selfPos[0], selfY + dlta, selfPos[2] ]

    self.updateProp("position", nextSelfPos)

    for (let i = 0, kf, kfVal, nextVal; i < selfPosKfs.length; i++) {
      kf = selfPosKfs[i]
      kfVal = kf.value
      nextVal = [ kfVal[0], kfVal[1] + dlta, kfVal[2] ]

      kf.updateValue(nextVal)
    }
  }
  function alignXMinAnimate(selfRend, targetRend, frame) {
    const selfPos = selfRend.position

    const targetPos = targetRend.position

    const selfX = selfPos[0]
    const targetX = targetPos[0]

    const dlta = targetX - selfX

    const nextSelfPos = [ selfX + dlta, selfPos[1], selfPos[2] ]

    const kf = Kf({ frame, value: nextSelfPos })

    self.addKeyframe("position", kf)
  }
  function alignXMaxAnimate(selfRend, targetRend, frame) {
    const selfW = selfRend.width
    const selfPos = selfRend.position

    const targetW = targetRend.width
    const targetPos = targetRend.position

    const selfX = selfPos[0] + selfW
    const targetX = targetPos[0] + targetW

    const dlta = targetX - selfX

    const nextSelfPos = [ selfX + dlta, selfPos[1], selfPos[2] ]

    const kf = Kf({ frame, value: nextSelfPos })

    self.addKeyframe("position", kf)
  }
  function alignXCenterAnimate(selfRend, targetRend, frame) {
    const selfW = selfRend.width
    const selfPos = selfRend.position

    const targetW = targetRend.width
    const targetPos = targetRend.position

    const selfX = selfPos[0] + (selfW / 2)
    const targetX = targetPos[0] + (targetW / 2)

    const dlta = targetX - selfX

    const nextSelfPos = [ selfX + dlta, selfPos[1], selfPos[2] ]

    const kf = Kf({ frame, value: nextSelfPos })

    self.addKeyframe("position", kf)
  }
  function alignYMinAnimate(selfRend, targetRend, frame) {
    const selfPos = selfRend.position

    const targetPos = targetRend.position

    const selfY = selfPos[1]
    const targetY = targetPos[1]

    const dlta = targetY - selfY

    const nextSelfPos = [ selfPos[1], selfY + dlta, selfPos[2] ]

    const kf = Kf({ frame, value: nextSelfPos })

    self.addKeyframe("position", kf)
  }
  function alignYMaxAnimate(selfRend, targetRend, frame) {
    const selfH = selfRend.height
    const selfPos = selfRend.position

    const targetH = targetRend.height
    const targetPos = targetRend.position

    const selfY = selfPos[1] + selfH
    const targetY = targetPos[1] + targetH

    const dlta = targetY - selfY

    const nextSelfPos = [ selfPos[1], selfY + dlta, selfPos[2] ]

    const kf = Kf({ frame, value: nextSelfPos })

    self.addKeyframe("position", kf)
  }
  function alignYCenterAnimate(selfRend, targetRend, frame) {
    const selfH = selfRend.height
    const selfPos = selfRend.position

    const targetH = targetRend.height
    const targetPos = targetRend.position

    const selfY = selfPos[1] + (selfH / 2)
    const targetY = targetPos[1] + (targetH / 2)

    const dlta = targetY - selfY

    const nextSelfPos = [ selfPos[1], selfY + dlta, selfPos[2] ]

    const kf = Kf({ frame, value: nextSelfPos })

    self.addKeyframe("position", kf)
  }

  return {
    updateProp,
    updateModalProp,
    updateVec,
    updateModalVec,
    updateExpression,
    addKeyframe,
    removeKeyframeByProp,
    removeKeyframe,
    toggleHierarchyState,
    forceTrueHierarchyState,
    duplicate,
    remove,
    paste,
    clone,
    removeChild,
    addChild,
    addChildAfter,
    acceptsDrop,
    alignXMinLayout,
    alignXCenterLayout,
    alignXMaxLayout,
    alignYMinLayout,
    alignYCenterLayout,
    alignYMaxLayout,
    alignXMinAnimate,
    alignXCenterAnimate,
    alignXMaxAnimate,
    alignYMinAnimate,
    alignYCenterAnimate,
    alignYMaxAnimate,
  }
}
export default assetActions
