import React from 'react'
import DOM from 'react-dom'
import { autorun } from 'mobx'
import { toJS } from 'mobx'
import ErrorModal from './components/modals/Error'
import Alerts from './components/Alerts'
import DefaultToolbar from './panels/Toolbar'
import StructureUI from './containers/StructureUI'
import LayoutUI from './containers/LayoutUI'
import ConversionUI from './containers/ConversionUI'
import ScriptExportUI from './containers/ScriptExportUI'
import EditionManagerUI from './containers/EditionManagerUI'
import WarningModal from './components/modals/Warning'
import { Spinner } from './components'
import { checkLogin } from './actions/remote'
import { queueEditionCapture } from './actions/remote'
import { queryEditionCapture } from './actions/remote'
import { cancelEditionCapture } from './actions/remote'
import { queryAllEditionCapture } from './actions/remote'
import { xlsToTranscriptWord } from "./actions/io"
import { xlsToTranscriptHtml } from "./actions/io"
import { editionToTranscriptHtml } from "./actions/io"
import { editionToTranscriptWord } from "./actions/io"
import { editionToTTMLFile } from "./actions/io"

// Stores
import App from './App'
import Conversion from './observables/ConversionProc'
import { taskSearchStore } from './task-search.store'
import { taskSearchModel } from './task-search.model'
import { taskStateModel } from './task-state.model'
import { taskStateStore } from './task-state.store'

// Init stores
const app = App()
const conversion = app.state.conversion = Conversion(app)
const tStateStore = taskStateStore(app.scheduler, app.storage, queueEditionCapture, queryEditionCapture, cancelEditionCapture)
const tSearchStore = taskSearchStore(queryAllEditionCapture)
const taskSearch = taskSearchModel(tSearchStore, tStateStore, app.state.global.alert, app.prompt, app)
const taskState = taskStateModel(tStateStore, app.state.global.alert)

// DOM container
const APP_ROOT = document.getElementById('app-outer')

// Global window conveniences
window.secretApp = app
window.toJS = toJS

const isLocal = window.location.hostname === 'localhost' ? true : false

// These globally-scoped listeners prevent default browser behaviors from being fired
window.addEventListener('keydown', (e) => {
  // All key combinations with Command or Control or Shift
  const hotkeys = [79, 83, 69, 85, 90, 68, 48, 49, 50, 59, 60, 72]
  // NOTE: CMD/CTRL + Q, W, T, N, M are not preventable.
  if (hotkeys.indexOf(e.keyCode) > -1 && (e.metaKey || e.ctrlKey)) {
    e.preventDefault()
  }
  // Backspace/Delete
  // if (e.keyCode === 8 && e.target.tagName.toLowerCase() !== 'input') e.preventDefault()
})

// TODO This temporary, there is a larger refactor in mind which involves all keybindings
window.addEventListener('keyup', e => {
  if ("Escape" === e.key) {
    switch(app.route.current) {
      case "Conversion": {
        app.state.conversion.reset()
        app.route.goBack()
        break
      }
      case "ScriptExport": {
        app.route.goBack()
        break
      }
    }
  }
})

window.addEventListener('error', (e) => {
  let errorString = isLocal && e.error ? e.error.stack : e.message
  if (e.filename && e.filename.includes(app.assetManagerHost)) {
    // P3-436: improve the error reporting when dynamically inserting scripts into the page from the asset manager
    errorString = `Unable to parse: ${
      e.filename
    }.\n\nPlease try again.\n\nIf this issue persists, please contact support.`
  }
  app.throwError(errorString)
})

const keepAlive = () => {
  checkLogin().then(() => {
    sessionTimer = window.setTimeout(keepAlive, 900000)
  }).catch((error) => {
    app.throwError(`Sorry, it appears the application back-end is down or not functioning correctly.\n\nIf the problem persists please contact support.`)
  })
}

let sessionTimer = window.setTimeout(keepAlive, 900000)

const appProps = {
  id: 'app-inner'
}

checkLogin()
  .then((response) => app.setUsername(response.username))
  .then(() => app.getMediaHost())
  .then(() => app.getAdvisorUrl())
  .then(() => app.state.editionmanager.showHomepage())
  .then(() => autorun(() => {
      const nextRoute = app.route.current
      const globalState = app.state.global
      const alertState = globalState.alert
      const errorState = app.error
      let UI,
        Toolbar = null
      if ('Structure' === nextRoute)
        (UI = <StructureUI app={app} />), (Toolbar = <DefaultToolbar app={app} editionToWord={editionToTranscriptWord} editionToHtml={editionToTranscriptHtml} editionToTTML={editionToTTMLFile}/>)
      else if ('Layout' === nextRoute)
        (UI = <LayoutUI app={app} />), (Toolbar = <DefaultToolbar app={app} editionToWord={editionToTranscriptWord} editionToHtml={editionToTranscriptHtml} editionToTTML={editionToTTMLFile}/>)
      else if ('EditionManager' === nextRoute) UI = <EditionManagerUI app={app} taskSearch={taskSearch} taskState={taskState} scheduler={app.scheduler}/>
      else if ('Conversion' === nextRoute) UI = <ConversionUI app={app} conversion={conversion} />
      else if ('ScriptExport' === nextRoute)
        UI = <ScriptExportUI app={app} html={xlsToTranscriptHtml} word={xlsToTranscriptWord}/>
      else throw new Error('No UI matches route: ' + nextRoute)

      DOM.render(
        <div {...appProps}>
          {Toolbar}
          {UI}
          {errorState.show && (
            <ErrorModal reason={errorState.reason} hideModal={app.dismissError} okText={`OK`} />
          )}
          {<Alerts alerts={alertState.items} />}
          { app.prompt.shouldConfirm && <WarningModal warning={app.prompt.promptText} cancel={() => app.prompt.denyConfirm()} continue={() => app.prompt.applyConfirm()}/>}
          {globalState.loading ? <Spinner/> : null}
        </div>,
        APP_ROOT
      )
    }))
  .catch((err) => {
    console.info(err)
    app.throwError(`Sorry, it appears the application back-end is down or not functioning correctly.\n\nIf the problem persists please contact support.`)
    const errorState = app.error
    DOM.render(
      <div {...appProps}>
        {errorState.show && (
          <ErrorModal reason={errorState.reason} hideModal={app.dismissError} okText={`OK`} />
        )}
      </div>,
      APP_ROOT
    )
  })
