import vec3 from 'gl-vec3'
import { reduceTree } from './utils'

export function connectionsWhere (predicate, edition) {
  const nextNodes = []
  // Edition DecisionNodes
  for (let i = 0, l = edition.decisionNodes.length; i < l; i++) {
    const d = edition.decisionNodes[i]
    if (predicate(d)) nextNodes.push(d)
  }
  // Chapters
  for (let i = 0, l = edition.chapters.length; i < l; i++) {
    const c = edition.chapters[i]
    if (predicate(c)) nextNodes.push(c)
    // Sequences
    for (let j = 0; j < c.sequences.length; j++) {
      const s = c.sequences[j]
      if (predicate(s)) nextNodes.push(s)
    }
    // Chapter DecisionNodes
    for (let k = 0; k < c.decisionNodes.length; k++) {
      const d = c.decisionNodes[k]
      if (predicate(d)) nextNodes.push(d)
    }
  }
  return nextNodes
}

export function structureCollision (edition, worldVec3) {
  let dist

  for (let i = 0, l = edition.chapters.length; i < l; i++) {
    const c = edition.chapters[i]
    for (let j = 0; j < c.sequences.length; j++) {
      const s = c.sequences[j]
      if (vec3.distance(worldVec3, s.current.position) < s.current.radius) return s
    }
    for (let k = 0; k < c.decisionNodes.length; k++) {
      const d = c.decisionNodes[k]
      if (vec3.distance(worldVec3, d.current.position) < d.current.radius) return d
    }
    if (vec3.distance(worldVec3, c.current.position) < c.current.radius) return c
  }
  for (let i = 0, l = edition.decisionNodes.length; i < l; i++) {
    const d = edition.decisionNodes[i]
    if (vec3.distance(worldVec3, d.current.position) < d.current.radius) return d
  }

  return null
}

export function selectAllWhereRawAudio (edition, media) {
  const refs = []
  const chapters = edition.chapters
  const defaultField = 'audioMedia'

  for (let i = 0, l = chapters.length; i < l; i++) {
    const chapter = chapters[i]
    const sequences = chapter.sequences
    for (let j = 0; j < sequences.length; j++) {
      const sequence = sequences[j]
      const rows = sequence.audioRows
      for (let k = 0; k < rows.length; k++) {
        const row = rows[k]
        if (row[defaultField] === undefined)  continue
        // Check for insertion to account for visiting the same template twice
        if (row[defaultField] === media)      !refs.includes(row) && refs.push(row)
      }
    }
  }

  return refs
}

export function selectAllWhereRawVideo(edition, media) {
  const refs = []
  const chapters = edition.chapters
  const defaultField = 'videoMedia'

  for (let i = 0, l = chapters.length; i < l; i++) {
    const chapter = chapters[i]
    const sequences = chapter.sequences
    for (let j = 0; j < sequences.length; j++) {
      const sequence = sequences[j]
      const rows = sequence.videoRows
      for (let k = 0; k < rows.length; k++) {
        const row = rows[k]
        if (row[defaultField] === undefined)  continue
        // Check for insertion to account for visiting the same template twice
        // TODO Update queries to take list of queryable objects, walking document = tight coupling
        if (row[defaultField] === media)      !refs.includes(row) && refs.push(row)
      }
    }
  }

  return refs
}

export function selectAllWhereAssetMedia (edition, media) {
  const refs = []
  const { Type } = media
  const chapters = edition.chapters
  let defaultField, optionsField

  if (Type === 'RawSprite') {
    defaultField = 'spriteMedia'
    optionsField = 'spriteMediaOptions'
  } else if (Type === 'RawHTML') {
    defaultField = 'canvasMedia'
    optionsField = 'canvasMediaOptions'
  }

  for (let i = 0, l = chapters.length; i < l; i++) {
    const chapter = chapters[i]
    const sequences = chapter.sequences
    for (let j = 0; j < sequences.length; j++) {
      const sequence = sequences[j]
      reduceTree(captureRef, refs, sequence.stage)
    }
  }

  return refs

  function captureRef (a, b) {
    if (b[defaultField] === undefined)  return
    // Check for insertion to account for visiting the same template twice
    // TODO Update queries to take list of queryable objects,
    // walking document = tight coupling
    if (b[defaultField] === media)      !refs.includes(b) && refs.push(b)
    const options = b[optionsField]
    if (options && options.length) {
      for (let i = 0, l = options.length; i < l; i++) {
        const option = options[i]
        if (option.value === media) !refs.includes(option) && refs.push(option)
      }
    }
  }
}
