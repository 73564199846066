import React from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import Chapter from './Chapter'
import { contains } from '../../utils'

const ScriptPanel = observer(class ScriptPanel extends React.Component {
  addChapter = (e) => {
    e.stopPropagation()
    const edition = this.props.edition
    edition.insertChapter()
  }
  extend = (e) => {
    e.stopPropagation()
    const scriptState = this.props.state.script
    scriptState.toggleExtended()
  }
  toggleSelected = (e) => {
    e.stopPropagation()
    const scriptState = this.props.state.script
    scriptState.showOnlySelectedToggle()

  }
  constructor(props) {
    super(props)
  }
  render() {
    const { state, edition, route } = this.props
    const { ui } = state
    const scriptState = state.script
    const scriptObjects = edition.scriptObjects // this tracks a flat array of chapter/sequence/row IDs for correct focus handling

    const className = `chapter-list ${scriptState.extended ? 'extend' : ''}`

    const chapterEls = edition.chapters.map((chapter, i) => (
      <Chapter
        key={chapter.objectId}
        state={state}
        scriptObjects={scriptObjects}
        chapter={chapter}
        index={i}
        updateResponseMedia={edition.updateResponseMedia}
      />
    ))

    return (
      <div className="content script">
        <div className="script-bar">
          <button
            type="button"
            className="new-chapter"
            title="New Chapter"
            onMouseUp={this.addChapter}>
            <i className="sprite script new-chapter" />
            <span>+ Chapter</span>
          </button>
          {/* <button type="button" className="export" title="Export Script" disabled><i className="sprite script export" /></button> */}
          <button
            type="button"
            className={`showVariables ${scriptState.extended ? 'active' : ''}`}
            title="Show Template Variables"
            onClick={this.extend}>
            <i className="sprite script variables" />
            <span>Show Variables</span>
          </button>

          <button
              type="button"
              className={`showVariables ${scriptState.showOnlySelected ? 'active' : ''}`}
              title={`${scriptState.showOnlySelected ? 'Show All' : 'Show Selected'}`}
              onClick={this.toggleSelected}>
            <i className="sprite script variables" />
            <span>{`${scriptState.showOnlySelected ? 'Show All' : 'Show Selected'}`}</span>
          </button>
        </div>
        <div
          className="script-wrapper"
          id="script-wrapper"
          style={{
            height:
              route.current === 'Layout'
                ? ui.scriptHeightLayoutInner
                : ui.scriptHeightStructureInner
          }}>
          <div className={className}>{chapterEls}</div>
        </div>
      </div>
    )
  }
})

export default ScriptPanel
