import React from 'react'
import { SketchPicker } from 'react-color'
import { hexToRGBA } from '../utils'
import { observer } from 'mobx-react'

class ColorPicker extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      color: this.props.color,
      displayColorPicker: false
    }
  }
  handleClick() {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
    this.props.state.ui.detailsPanel.toggleColorPicker(!this.state.displayColorPicker)
  }
  handleClose() {
    this.setState({ displayColorPicker: false })
    this.props.state.ui.detailsPanel.toggleColorPicker(false)
  }
  handleChange(e) {
    this.setState({ color: `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a})` })
    this.props.onChange(e)
  }
  render() {
    const rgba = this.props.color
    return (
      <div className="color-picker">
        <div className="swatch" onClick={this.handleClick.bind(this)} title={rgba}>
          <div className="color" style={{ backgroundColor: rgba }} />
        </div>
        {this.state.displayColorPicker ? (
          <div className="popover">
            <div className="cover" onClick={this.handleClose.bind(this)} />
            <SketchPicker color={this.props.color} onChange={this.handleChange.bind(this)} />
          </div>
        ) : null}
      </div>
    )
  }
}

export default observer(ColorPicker)
