import { types, getSnapshot, detach } from 'mobx-state-tree'
import { BaseContent, Media } from 'eplayer-core'
import StartNode from './StartNode'
import Chapter from './Chapter'
import DecisionNode from './DecisionNode'
import { ButtonTemplate } from "../Template"
import { CheckboxTemplate } from "../Template"
import { ContainerTemplate } from "../Template"
import { DropdownTemplate } from "../Template"
import { EmbedTemplate } from "../Template"
import { InputTemplate } from "../Template"
import { ListTemplate } from "../Template"
import { ListItemTemplate } from "../Template"
import { RadioTemplate } from "../Template"
import { SliderTemplate } from "../Template"
import { SpriteTemplate } from "../Template"
import { TextTemplate } from "../Template"
import { ParagraphTemplate } from "../Template"
import { TitleTemplate } from "../Template"
import RawSprite from '../Media/RawSprite'
import RawAudio from '../Media/RawAudio'
import RawVideo from '../Media/RawVideo'
import RawHTML from '../Media/RawHTML'
import {
  connectionsWhere,
  selectAllWhereRawAudio,
  selectAllWhereRawVideo,
  selectAllWhereAssetMedia,
} from '../queries'
import { setProp } from '../utils'
import { CHAPTER_OFFSET_X } from '../constants'

const EditionVars = types.model('EditionVars', {
  runtime: types.map(
    types.optional(
      types.union(
        types.string,
        types.number,
        types.boolean,
        types.literal(null)
      ),
      null
    )
  ),
  patient: types.map(
    types.optional(
      types.union(
        types.string,
        types.number,
        types.boolean,
        types.literal(null)
      ),
      null
    )
  ),
  custom: types.map(
    types.optional(
      types.union(
        types.string,
        types.number,
        types.boolean,
        types.literal(null)
      ),
      null
    )
  ),
})

const EditionVersion = types
  .model('EditionVersion', {
    major: types.number,
    minor: types.number,
    working: types.number,
  })
  .views(self => ({
    get isUnversioned() {
      const { major, minor, working } = self
      return major === 0 && minor === 0 && working === 0
    },
    get displayString() {
      const { major, minor, working } = self
      return `${major}.${minor}.${working}`
    },
    nextVersion(versionType) {
      let nextVersion = {}
      if (versionType === 'major')
        nextVersion = { major: self.major + 1, minor: 0, working: 0 }
      if (versionType === 'minor')
        nextVersion = { major: self.major, minor: self.minor + 1, working: 0 }
      if (versionType === 'working')
        nextVersion = {
          major: self.major,
          minor: self.minor,
          working: self.working + 1,
        }
      return `${nextVersion.major}.${nextVersion.minor}.${nextVersion.working}`
    },
  }))

const model = types
  .model('Edition', {
    Type: types.literal('Edition'),
    objectId: types.identifier,
    name: types.string,
    title: types.string,
    description: types.string,
    assetSetId: types.number,
    startNode: types.late(() => StartNode),
    chapters: types.late(() => types.array(Chapter)),
    decisionNodes: types.late(() => types.array(DecisionNode)),
    audioMedia: types.array(RawAudio),
    videoMedia: types.array(RawVideo),
    canvasMedia: types.array(RawHTML),
    spriteMedia: types.array(RawSprite),
    templateMedia: types.array(types.union(
      ButtonTemplate,
      CheckboxTemplate,
      ContainerTemplate,
      DropdownTemplate,
      EmbedTemplate,
      InputTemplate,
      ListTemplate,
      ListItemTemplate,
      RadioTemplate,
      ParagraphTemplate,
      SliderTemplate,
      SpriteTemplate,
      TextTemplate,
      TitleTemplate)),
    variables: EditionVars,
    version: EditionVersion,
  })
  .views(self => ({
    get unversioned() {
      const { version } = self
      return version.major === 0 && version.minor === 0 && version.working === 0
    },
    get duration() {
      let frameSum = self.chapters
        .reduce((a, b) => a.concat(b.sequences.slice()), [])
        .map(a => Number(a.duration))
        .reduce((a, b) => b ? a+b : a, 0)
      return frameSum/24
    },
    get durationWithoutRisksOrIntro() {
      let notIntro = (chapter) => !/^Intro/i.test(chapter.name)
      let notRiskSeq = (sequence) => !/\/ r(end|a|b2|d|e|e2|el|er|f|gh|n|n2|nh|nhb|s|t|v)$/i.test(sequence.name)
      let frameSum = self.chapters
        .filter(notIntro)
        .reduce((a, b) => a.concat(b.sequences.slice()), [])
        .filter(notRiskSeq)
        .map(a => Number(a.duration))
        .reduce((a, b) => b ? a+b : a, 0)
      return frameSum/24
    },
    get sortedAudio() {
      return self.audioMedia.sort((a, b) =>
        a.fileName.localeCompare(b.fileName)
      )
    },
    get sortedSprites() {
      return self.spriteMedia.sort((a, b) =>
        a.fileName.localeCompare(b.fileName)
      )
    },
    get sortedCanvases() {
      return self.canvasMedia.sort((a, b) =>
        a.fileName.localeCompare(b.fileName)
      )
    },
    get sortedVideo() {
      return self.videoMedia.sort((a, b) =>
        a.fileName.localeCompare(b.fileName)
      )
    },
    get sortedTemplate() {
      return self.templateMedia.sort((a, b) => {
        a.name.localeCompare(b.name)
      })
    },
    get sortedAssetTemplate() {
      return self.sortedTemplate.filter(a =>
        "SequenceTemplate" !== a.Type)
    },
    get tocJSON() {
      const chapters = self.chapters.map((c, i) => {
        return { name: c.name, number: i + 1 }
      })
      return JSON.stringify(chapters)
    },
    // This should probably be a transformer
    get scriptObjects() {
      var flattened = []
      for (let c = 0; c < self.chapters.length; c++) {
        var chapter = self.chapters[c]
        flattened.push(chapter.objectId)
        for (let s = 0; s < chapter.sequences.length; s++) {
          var sequence = chapter.sequences[s]
          flattened.push(sequence.objectId)
          for (let r = 0; r < sequence.audioRows.length; r++) {
            var row = sequence.audioRows[r]
            flattened.push(row.objectId)
          }
        }
      }
      return flattened
    },
  }))
  .actions(self => {
    function updateProp(prop, value) {
      const e = self
      setProp(prop, value, e)
    }
    function setVersion(major, minor, working) {
      self.version.major = major
      self.version.minor = minor
      self.version.working = working
    }
    function initPatientData(vars) {
      const e = self
      // Accepts an array of string values that are the variable keys
      vars.forEach(v => {
        if (e.variables.patient.has(v.key)) return // don't initialize already existing vars
        e.updatePatientData(v.key, v.value)
      })
    }
    function updatePatientData(key, value) {
      const e = self
      e.variables.patient.set(key, value)
    }
    function initRuntimeData(vars) {
      const e = self
      // Accepts an array of string values that are the variable keys
      vars.forEach(v => {
        if (e.variables.runtime.has(v.key)) return // don't initialize already existing vars
        else e.updateRuntimeData(v.key, v.value)
      })
    }
    function updateRuntimeData(key, value) {
      const e = self
      e.variables.runtime.set(key, value)
    }
    function addVariable(variableGroup, key, value) {
      if (key) variableGroup.set(key, value || '')
    }
    function removeVariable(variableGroup, key) {
      variableGroup.delete(key)
    }
    function updateVariable(variableGroup, key, value) {
      variableGroup.set(key, value)
    }
    function addAudio(props) {
      const e = self
      const checksum = props.checksum || props.objectId
      if (!checksum) return
      // Prevent duplicates
      const exists = e.audioMedia.find((m) => m.objectId === checksum)
      if (!exists) {
        e.audioMedia.push(
          RawAudio.create({
            ...Media.RawAudio(checksum),
            ...props,
          })
        )
      }
    }
    function addSprite(props) {
      const e = self
      const checksum = props.checksum || props.objectId
      if (!checksum) return
      // Prevent duplicates
      const exists = e.spriteMedia.find((m) => m.objectId === checksum)
      if (!exists) {
        e.spriteMedia.push(
          RawSprite.create({
            ...Media.RawSprite(checksum),
            ...props,
          })
        )
      }
    }
    function addCanvas(props) {
      const e = self
      const checksum = props.checksum || props.objectId
      if (!checksum) return
      // Prevent duplicates
      const exists = e.canvasMedia.find((m) => m.objectId === checksum)
      if (!exists) {
        e.canvasMedia.push(
          RawHTML.create({
            ...Media.RawHTML(checksum),
            ...props,
          })
        )
      }
    }
    function addVideo(props) {
      const e = self
      const checksum = props.checksum || props.objectId
      if (!checksum) return
      // Prevent duplicates
      const exists = e.videoMedia.find((m) => m.objectId === checksum)
      if (!exists) {
        e.videoMedia.push(
          RawVideo.create({
            ...Media.RawVideo(checksum),
            ...props,
          })
        )
      }
    }
    function addTemplate(props) {
      const e = self
      const id = props.objectId
      const exists = e.templateMedia.find(t => id === t.objectId) ? true : false

      if (exists) {
        return
      }

      e.templateMedia.push(props)
    }
    function addMedia(media) {
      const e = self
      const { Type } = media
      if ('RawAudio' === Type) 	return e.addAudio(media)
      if ('RawSprite' === Type) return e.addSprite(media)
      if ('RawVideo' === Type) 	return e.addVideo(media)
      if ('RawHTML' === Type) 	return e.addCanvas(media)
      if (Type.match("Template")) return e.addTemplate(media)
    }
    function removeAudio(audio, newAudio) {
      const e = self
      const refs = selectAllWhereRawAudio(e, audio)
      for (let i = 0, ref; i < refs.length; i++) {
        ref = refs[i]
        if (ref.audioMedia !== undefined && ref.audioMedia === audio) {
          ref.audioMedia = newAudio ? newAudio : null
          continue
        }
        if (ref.value !== undefined && ref.value === audio) {
          ref.value = newAudio ? newAudio : null
          continue
        }
      }
      e.audioMedia.remove(audio)
    }
    function removeSprite(sprite, newSprite) {
      const e = self
      const refs = selectAllWhereAssetMedia(e, sprite)
      for (let i = 0, ref; i < refs.length; i++) {
        ref = refs[i]
        if (ref.spriteMedia !== undefined && ref.spriteMedia === sprite) {
          ref.spriteMedia = newSprite ? newSprite : null
          continue
        }
        if (ref.value !== undefined && ref.value === sprite) {
          ref.value = newSprite ? newSprite : null
          continue
        }
      }
      e.spriteMedia.remove(sprite)
    }
    function removeVideo(video, newVideo) {
      const e = self
      const refs = selectAllWhereRawVideo(e, video)
      for (let i = 0, ref; i < refs.length; i++) {
        ref = refs[i]
        if (ref.videoMedia !== undefined && ref.videoMedia === video) {
          ref.videoMedia = newVideo ? newVideo : null
          continue
        }
        if (ref.value !== undefined && ref.value === video) {
          ref.value = newVideo ? newVideo : null
          continue
        }
      }
      e.videoMedia.remove(video)
    }
    function removeCanvas(canvas, newCanvas) {
      const e = self
      const refs = selectAllWhereAssetMedia(e, canvas)
      for (let i = 0, ref; i < refs.length; i++) {
        ref = refs[i]
        if (ref.canvasMedia !== undefined && ref.canvasMedia === canvas) {
          ref.canvasMedia = newCanvas ? newCanvas : null
          continue
        }
        if (ref.value !== undefined && ref.value === canvas) {
          ref.value = newCanvas ? newCanvas : null
          continue
        }
      }
      e.canvasMedia.remove(canvas)
    }

    // TODO Handle template replace operation
    function removeTemplate(template) {
      const e = self
      template.remove()
      e.templateMedia.remove(template)
    }

    function removeMedia(media, replacementMedia) {
      const e = self
      const { Type } = media
      if ('RawAudio' === Type) 		return e.removeAudio(media, replacementMedia)
      if ('RawSprite' === Type) 	return e.removeSprite(media, replacementMedia)
      if ('RawVideo' === Type) 		return e.removeVideo(media, replacementMedia)
      if ('RawHTML' === Type) 		return e.removeCanvas(media, replacementMedia)
      if (Type.match("Template")) return e.removeTemplate(media)
    }
    function writeResponseMedia(media) {
      const e = self
      for (let i = 0, serverProps, fileExt; i < media.length; i++) {
        serverProps = media[i]
        fileExt = serverProps.fileExt
        if ('mp4' === fileExt) e.addVideo(serverProps)
        else if ('js' === fileExt) e.addCanvas(serverProps)
        else if ('mp3' === fileExt) e.addAudio(serverProps)
        else if ('png' === fileExt || 'svg' === fileExt || 'jpg' === fileExt || 'jpeg') e.addSprite(serverProps)
        else console.warn('[ERROR] Unsupported media type: ' + fileExt)
      }
    }
    // Re-linking Media
    function updateResponseMedia(oldMedia, mediaResponses) {
      const e = self
      if (mediaResponses.length !== 1) return
      const newMediaResponse = mediaResponses[0] // For now, we can only do 1 at a time (mediaResponses should have length === 1)

      // N.B. ED-504: Data integrity issue with Media files wrt checksum property being improperly migrated from, should remove
      // property value check when addressed, until then, fix issue whereby re-linking doesn't happen if checksum property is missing
      if ((newMediaResponse.checksum || newMediaResponse.objectId) === oldMedia.objectId) {
        return oldMedia
      }
      const fileExt = newMediaResponse.fileExt
      // Add the new Media to the associated array just like uploading normally
      e.writeResponseMedia([newMediaResponse])
      // Retrieve the newly created object (TODO: there's probably a better way to do this i.e. returning it from the functions)
      let newMedia
      if ('mp4' === fileExt) 																									newMedia = e.videoMedia[e.videoMedia.length - 1]
      else if ('js' === fileExt) 																							newMedia = e.canvasMedia[e.canvasMedia.length - 1]
      else if ('mp3' === fileExt) 																						newMedia = e.audioMedia[e.audioMedia.length - 1]
      else if ('png' === fileExt || 'svg' === fileExt || 'jpg' === fileExt) 	newMedia = e.spriteMedia[e.spriteMedia.length - 1]
      // Replace and Remove the previous Media
      e.removeMedia(oldMedia, newMedia)
      return newMedia
    }
    function addChapter(c) {
      self.chapters.push(c)
    }
    function addChapterAtIndex(p, c) {
      self.chapters.splice(p, 0, c)
    }
    function addDecisionNode(d) {
      self.decisionNodes.push(d)
    }
    // Script Panel dropdown insert Chapter
    function insertChapter(position) {
      const e = self
      position = position === undefined ? e.chapters.length : position
      var preceedingChapter = e.chapters[position - 1]
      var x = preceedingChapter ? preceedingChapter.current.position[0] : 0
      var y = preceedingChapter ? preceedingChapter.current.position[1] : 0
      var chapter = BaseContent.Chapter()
      chapter.current.emptyPosition[0] = x + CHAPTER_OFFSET_X
      chapter.current.emptyPosition[1] = y
      e.addChapterAtIndex(position, chapter)
    }
    // Script Panel dropdown remove Chapter
    function removeChapter(chapter) {
      const e = self
      const chapters = e.chapters
      const sequences = chapter.sequences
      const decisions = chapter.decisionNodes
      // Remove sequences (we have to do this to clean up connection references)
      for (let i = 0; i < sequences.length; i++) {
        var sequence = sequences[i]
        chapter.removeSequence(sequence)
      }
      // Remove decisionNodes (we have to do this to clean up connection references)
      for (let i = 0; i < decisions.length; i++) {
        var decision = decisions[i]
        chapter.removeDecisionNode(decision)
      }
      // Remove chapter itself
      chapters.remove(chapter)
    }
    // Dragging a Chapter in Script Panel
    function moveChapter(chapter, position) {
      const e = self
      detach(chapter)
      e.chapters.splice(position, 0, chapter)
    }
    // Dragging any widget onto the Viewport
    function addContent(target, nodes, mousePosition) {
      const e = self
      const parentlessTypes = ['Chapter', 'DecisionNode']
      const chapterChildTypes = ['Sequence', 'DecisionNode']
      nodes.forEach(node => {
        const { Type } = node
        if (target === null && parentlessTypes.includes(Type)) {
          if (Type === 'Chapter') {
            node.current.setEmptyPosition(mousePosition)
            e.addChapter(node)
          }
          if (Type === 'DecisionNode') {
            node.translate(mousePosition)
            e.addDecisionNode(node)
          }
        } else if (
          target &&
          target.Type === 'Chapter' &&
          chapterChildTypes.includes(Type)
        ) {
          if (Type === 'Sequence') {
            node.translate(mousePosition)
            target.addSequence(node)
          }
          if (Type === 'DecisionNode') {
            node.translate(mousePosition)
            target.addDecisionNode(node)
          }
        }
      })
    }
    // Dragging a Chapter or DecisionNode onto the Edtion in Structure Hierarchy
    function setContentParent(node) {
      const e = self
      const { Type } = node
      const typeMap = { Chapter: 'chapters', DecisionNode: 'decisionNodes' }
      const childProp = typeMap[Type]
      detach(node)
      e[childProp].push(node)
    }
    // Delete selected DecisionNode from Edition
    function removeDecisionNode(decisionNode) {
      const e = self
      // Find connections
      const refs = connectionsWhere(n => {
        if (n.next && n.next === decisionNode) return true
        if (n.connections) return n.connections.find(conn => conn.value === decisionNode)
        return false
      }, e)
      // Remove connections
      for (let i = 0; i < refs.length; i++) {
        var ref = refs[i]
        if (ref.next && ref.next === decisionNode) ref.setNext(null)
        if (ref.connections) {
          ref.connections.forEach(conn => {
            if (conn.value === decisionNode) conn.updateValue(null)
          })
        }
      }
      // Handle StartNode connection
      if (
        e.startNode.next &&
        e.startNode.next.objectId === decisionNode.objectId
      ) {
        e.startNode.setNext(null)
      }
      // Remove decisionNode itself
      e.decisionNodes.remove(decisionNode)
    }
    function duplicate() {
      const e = self
      let clone = e.clone()
      // TODO: this needs testing
      return model.create(clone)
    }
    function paste(obj, isSameDomain) {
      // Re-clone on paste for refreshed UUIDs
      if (obj.Type === 'DecisionNode')
        addDecisionNode(BaseContent.DecisionNode.clone(obj))
      // Clear Media from certain asset types if domains do not match
      if (obj.Type === 'Chapter') {
        isSameDomain
          ? addChapter(BaseContent.Chapter.clone(obj))
          : addChapter(BaseContent.Chapter.cloneWithoutMedia(obj))
      }
    }
    function clone() {
      // run graph clone to setup new UUIDs and such
      // Note: for this to work with our parent reference
      // prop, we would need to loop through the cloned tree
      // and re-associate all the parents
      return BaseContent.Edition.clone(getSnapshot(self))
    }
    // Dragging any node in Structure Viewport
    function translate(delta, nodes) {
      const e = self
      for (var i = 0; i < nodes.length; i++) {
        var node = nodes[i]
        // make sure we're not double translating nested selections
        if (!nodes.includes(node.parent)) node.translate(delta)
      }
    }
    function detachNode(node) {
      detach(node)
    }
    return {
      updateProp,
      setVersion,
      initPatientData,
      updatePatientData,
      initRuntimeData,
      updateRuntimeData,
      addVariable,
      removeVariable,
      updateVariable,
      addAudio,
      addSprite,
      addCanvas,
      addVideo,
      addTemplate,
      addMedia,
      removeAudio,
      removeSprite,
      removeVideo,
      removeCanvas,
      removeMedia,
      writeResponseMedia,
      updateResponseMedia,
      addChapter,
      addChapterAtIndex,
      addDecisionNode,
      insertChapter,
      removeChapter,
      moveChapter,
      addContent,
      setContentParent,
      removeDecisionNode,
      duplicate,
      paste,
      clone,
      translate,
      detachNode,
      removeTemplate,
    }
  })
export default model
