import type { AssetType } from 'eplayer-core'

export type Immutable<A> =
  A extends Array<infer B> ?
    ImmutableArray<B> :
  A extends Map<infer K, infer V> ?
    ImmutableMap<K,V> :
  A extends Set<infer B> ?
    ImmutableSet<B> :
  A extends undefined | null | boolean | string | number | Function ?
    A :
  Readonly<{ [K in keyof A]: Immutable<A[K]> }>

export type ImmutableArray<A> =
  ReadonlyArray<Immutable<A>>

export type ImmutableMap<K, V> =
  ReadonlyMap<Immutable<K>, Immutable<V>>

export type ImmutableSet<A> =
  ReadonlySet<Immutable<A>>

export function deepFreeze<A>(a: A) {
  const shouldFreeze =
    typeof a === 'object' &&
    a != null
  if (shouldFreeze) {
    for (const key of keysOf(a)) {
      const value = a[key]
      if (shouldFreeze) {
        deepFreeze(value)
      }
    }
    return Object.freeze(a) as Immutable<A>
  } else {
    return a as Immutable<A>
  }
}

export function keysOf<A extends {}>(a: A) {
  return Object.keys(a) as Array<keyof A>
}

export function findByObjectId<A extends { objectId: string, Type: string }>(entities: Array<A>, objectId: string) : A | null {
  return entities.find(a => a.objectId === objectId) ?? null
}

export function isAssetType(str: string) : str is AssetType {
  switch (str) {
    case 'Button':
    case 'ButtonGroup':
    case 'Checkbox':
    case 'Container':
    case 'Dropdown':
    case 'Embed':
    case 'List':
    case 'ListItem':
    case 'Input':
    case 'Radio':
    case 'Slider':
    case 'Sprite':
    case 'Text':
    case 'Title':
    case 'Paragraph':
    case 'Video':
      return true
    default:
      return false
  }
}
