import React from 'react'
import { decorate, observable } from 'mobx'
import { observer } from 'mobx-react'
import brace from 'brace'
import AceEditor from 'react-ace'
import 'brace/mode/javascript'
import 'brace/theme/tomorrow_night_eighties'
import { walkTree } from '../utils'

const SNIPPETS = [
  /*{
    label: 'onTick',
    code: `player.asset('SOME_ASSET_NAME').onTick = function() {\n// Insert your code here\n};\n`
  },
  */
  {
    label: 'onClick',
    code: `function someFunction(evt) {\n// Insert your code here\n}\nplayer.on('click', 'SOME_ASSET_NAME', someFunction);\n`
  },
  {
    label: 'onChange',
    code: `function someFunction(evt) {\n// Insert your code here\n}\nplayer.on('change', 'SOME_ASSET_NAME', someFunction);\n`
  },
  {
    label: 'play',
    code: `player.play();\n`
  },
  {
    label: 'resume',
    code: `player.resume();\n`
  },
  {
    label: 'pause',
    code: `player.pause();\n`
  },
  {
    label: 'suspend',
    code: `player.suspend();\n`
  },
  {
    label: 'jump',
    code: `player.jump(FRAME_NUMBER);\n`
  },
  {
    label: 'asset',
    code: `player.asset('SOME_ASSET_NAME');\n`
  },
  {
    label: 'row',
    code: `player.row('SOME_ROW_NAME');\n`
  },
  {
    label: 'stage',
    code: `player.getStage();\n`
  },
  {
    label: 'getFrame',
    code: `player.getFrame();\n`
  },
  {
    label: 'getDuration',
    code: `player.getDuration();\n`
  },
  {
    label: 'getChapterDuration',
    code: `player.getChapterDuration('NAME OF CHAPTER OR 0-BASED INDEX');\n`
  }
]

class CodeEditorComponent extends React.Component {
  onChange = (value) => {
    this.value = value
    this.props.onChange(value)
  }
  setEl = (el) => {
    this.editorEl = el
  }
  appendSnippet = (snippet) => {
    this.editorEl.editor.insert(snippet)
    this.editorEl.editor.focus()
  }
  appendVariable = (variable) => {
    this.editorEl.editor.insert(`$.custom.${variable}; \n`)
    this.editorEl.editor.focus()
  }
  appendAssets = (asset) => {
    this.editorEl.editor.insert(`player.asset('${asset}'); \n`)
    this.editorEl.editor.focus()
  }
  getValue = () => {
    return this.value
  }
  getAssetList = (assets, assetList) => {
    this.level++
    assets.forEach((asset) => {
      assetList.push(
        <li className={`level_${this.level}`}>
          <button onClick={(_) => this.appendAssets(asset.name)}>{asset.name}</button>
        </li>
      )
      if (asset.children.length) {
        this.getAssetList(asset.children, assetList)
      }
    })
    this.level--
  }
  constructor(props) {
    super(props)
    this.value = props.value
    this.editorEl = null
    this.level = 0
  }
  render() {
    const { placeholder, error, message, variables, assets } = this.props
    let className = this.props.className || 'textarea code-editor'
    if (error) className += ' compile-error'
    let snippets = SNIPPETS.map((snippet) => (
      <li key={`snippet_${snippet.label}`}>
        <button onClick={(_) => this.appendSnippet(snippet.code)}>{snippet.label}</button>
      </li>
    ))
    let vars =
      variables && variables.custom
        ? Object.keys(variables.custom).map((key) => (
            <li key={`var_${key}`}>
              <button onClick={(_) => this.appendVariable(key)}>{key}</button>
            </li>
          ))
        : null
    if (!vars || vars.length === 0) vars = <li>No Variables</li>
    let assetList = []
    this.getAssetList(assets, assetList)

    return (
      <div className="code-editor-container">
        <div className="code-editor-wrap">
          <AceEditor
            mode="javascript"
            theme="tomorrow_night_eighties"
            onChange={this.onChange}
            value={this.value}
            width={'100%'}
            height={'100%'}
            focus={true}
            ref={this.setEl}
          />
          {message.length ? <div className="compile-error-message">{message}</div> : null}
        </div>
        <div className="code-editor-snippets">
          <label>Snippets</label>
          <ul>{snippets}</ul>
        </div>
        <div className="code-editor-variables">
          <label>Variables</label>
          <ul>{vars}</ul>
        </div>
        <div className="code-editor-assets">
          <label>Assets</label>
          <ul className="code-editor-assets-hierarchy">{assetList}</ul>
        </div>
      </div>
    )
  }
}

decorate(CodeEditorComponent, {
  value: observable,
  editorEl: observable.ref,
})

const CodeEditor = observer(CodeEditorComponent)

export default CodeEditor
