import { observable, action, toJS } from 'mobx'

export default function ScriptState() {
  return observable({
    activeElement: null, // handles correct focus of script input
    extended: false,
    showOnlySelected: false,
    reset: function() {
      this.activeElement = null
      // this.extended = false
    },
    toggleExtended: function() {
      this.extended = !this.extended
    },
    showOnlySelectedToggle: function() {
      this.showOnlySelected = !this.showOnlySelected;
    }
  }, {
    reset: action.bound,
    toggleExtended: action.bound,
    showOnlySelectedToggle: action.bound
  })
}
