import { types } from 'mobx-state-tree'
import { Statement as StatementFactory } from 'eplayer-core'
import Statement from './Statement'
import { setProp } from './utils'

const model = types
	.model('Action', {
		Type: types.literal('Action'),
		objectId: types.identifier,
		name: types.string,
		frame: types.number,
		code: Statement,
	})
	.actions(self => {
		function updateProp(prop, value) {
			const a = self
			setProp(prop, value, a)
		}
		function updateCode(value) {
			const a = self
			const props = { args: '', string: value }
			a.code = Statement.create({
				...StatementFactory(),
				...props,
			})
		}
		return {
			updateProp,
			updateCode,
		}
	})
export default model
