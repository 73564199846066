import { toJS } from 'mobx'
import { types, getRoot } from 'mobx-state-tree'
import { TextOption as TextOptionFactory, Typography as TextUtils } from 'eplayer-core'
import RawText from '../RawText'
import TextOption from '../TextOption'
import Option from '../Option'
import Expression from '../Expression'
import { ButtonTemplate } from '../Template'
import { Fill, Layerable, Rectangle, Shadow, Stroke, Transformable, Typography, Visible, Stateful, MenuGroupPseudoStates,  UI } from './shared/props'
import assetActions from './shared/actions'
import assetViews from './shared/views'
import assetChildTypes from './shared/childTypes'

const AssetType = 'MenuGroupItem'

const model = types
  .model(AssetType, {
    // Entity
    Type: types.literal(AssetType),
    objectId: types.identifier,
    // Asset
    name: types.string,
    children: types.array(types.undefined),
    disabled: Expression,
    template: types.maybeNull(types.reference(ButtonTemplate)),
    // TextOptions
    text: RawText,
    textOptions: types.array(TextOption),
    flaggedMedia: Option,
    visitedMedia: Option,
    states: MenuGroupPseudoStates,
    ...Layerable,
    ...Fill,
    ...Stroke,
    ...Typography,
    ...Shadow,
    ...Rectangle,
    ...Visible,
    ...Transformable,
    ...UI,
  })
  .views(assetViews)
  .actions(self => {
    function afterCreate() {
      const a = self
      const txt = a.text
      // [ 1, 1 ] are the default RawText dimensions for existing content yet to be
      // migrated to model version 1.5.1
      const shouldComputeMetrics =
        txt.width === 1 &&
        txt.height === 1

      if (!shouldComputeMetrics) {
        return
      }

      const e = getRoot(a)
      const vars = toJS(e.variables)

      const opts = a.textOptions
      const optsLen = opts.length

      const [ dfltWidth, dfltHeight ] = TextUtils.textDimensions(createjs, a, a.text.value.fn(vars))
      a.text.width = dfltWidth
      a.text.height = dfltHeight

      for (let i = 0, opt, optRawTx, optWidth, optHeight; i < optsLen; i++) {
        opt = opts[i]
        optRawTx = opt.value

        [ optWidth, optHeight ] = TextUtils.textDimensions(createjs, a, opt.value.fn(vars))

        optRawTx.width = optWidth
        optRawTx.height = optHeight
      }
    }
    function addTextOption(option) {
      const a = self
      option = option || TextOption.create(TextOptionFactory())
      option.updateTextValue('')
      a['textOptions'].push(option)
    }
    function removeTextOption(option) {
      const a = self
      a['textOptions'].remove(option)
    }
    return {
      ...assetActions(self),
      addTextOption,
      removeTextOption,
      afterCreate,
      acceptsDrop() {
        return false
      },
    }
  })

export default model

