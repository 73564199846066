import { isTrueObject } from './utils'
import { assert } from './utils'

export class UserStorage {
  constructor(localStorage) {
    this.localStorage = localStorage
  }

  static fromWindow({ localStorage }) {
    return new UserStorage(localStorage)
  }

  clear(key) {
    this.localStorage.removeItem(key)
  }

  get(key, type) {
    const str = this.localStorage.getItem(key)
    assert(str != null, `Storage retreival mismatch, expected type ${type}, actual type null`)
    if (type === 'string') return str
    const result = JSON.parse(str)
    const err = `Storage retrieval mismatch, expected ${type}, was actually: ${typeof result}`
    switch(type) {
      case 'array':
        assert(Array.isArray(result), err)
        return result
      case 'number':
        assert(Number.isFinite(result), err)
        return result
      case 'boolean':
        assert(typeof result === 'boolean', err)
        return result
      case 'object':
        assert(isTrueObject(result), err)
        return result
      default:
        throw new Error(`Invalid Storage retrieval, cannot retrieve expected type ${type}, call *has* method first`)
    }
  }

  has(key) {
    return this.localStorage.getItem(key) != null
  }

  set(key, type, value) {
    let str = ''
    assert(value != null, 'Third parameter to UserStorage#set must be set to value not of null or undefined')
    assert(type.match && type.match(/string|number|boolean|array|object/), 'Second parameter to UserStorage#set must be string of string|number|boolean|array|object')
    if (type === 'string') str = value
    else str = JSON.stringify(value)
    this.localStorage.setItem(key, str)
  }
}
