import { toJS } from 'mobx'
import { types, getRoot } from 'mobx-state-tree'
import { Option, Typography as TextUtils } from 'eplayer-core'
import Expression from './Expression'

const model = types
	.model('RawText', {
		Type: types.literal('RawText'),
		objectId: types.identifier,
    value: Expression,
    width: types.number,
    height: types.number
	})
	.actions(self => {
    function updateValue(typography, value) {
      const t = self
      const e = getRoot(t)
      const vars = toJS(e.variables)

      t.value.string = value

      // Re-compile the expression (some of this should probably just be computed)
      Option.Expression.compile(t.value)

      const [ width, height ] = TextUtils.textDimensions(createjs, typography, t.value.fn(vars))

      t.updateDimensions(width, height)
    }
    function updateDimensions(width, height) {
      const t = self
      t.height = height
      t.width = width
    }
    return {
      updateDimensions,
      updateValue,
    }
	})
export default model
