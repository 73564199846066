import { observable, action, toJS, computed } from 'mobx'
import Card from './Card'
import {
  TIMELINE_DEFAULT_HEIGHT,
  TIMELINE_MINHEIGHT,
  TIMELINE_SNAP_HEIGHT,
  TOOLBAR_ADJUST,
  WIDGETS_PANEL_HEIGHT,
  TIMELINE_PANEL_DEFAULT_HEIGHT,
  TIMELINE_HEADER_HEIGHT,
  TIMELINE_ASSERT_MIN_HEIGHT
} from '../constants'

const scrollControlState = observable(
  {
    hierarchySelectedEl: null,
    navigatorSelectedEl: null,
    scriptPanelSelectedEl: null,
    navigatorSelectedByIndex: false,
    // Actions
    setHierarchySelectedEl: function(el) {
      this.hierarchySelectedEl = el
    },
    setNavigatorSelectedEl: function(el) {
      this.navigatorSelectedEl = el
      if (this.navigatorSelectedByIndex && el !== null) {
        this.navigatorSelectedEl.scrollIntoView({ inline: 'center' })
        this.navigatorSelectedByIndex = false
      }
    },
    setScriptPanelSelectedEl: function(el) {
      this.scriptPanelSelectedEl = el
    }
  },
  {
    hierarchySelectedEl: observable.ref,
    navigatorSelectedEl: observable.ref,
    scriptPanelSelectedEl: observable.ref,
    setHierarchySelectedEl: action.bound,
    setNavigatorSelectedEl: action.bound,
    setScriptPanelSelectedEl: action.bound
  }
)

export default function UIState(app) {
  return observable(
    {
      activeCard: null,
      activeAction: null,
      codeEditing: false,
      leftColumn: {
        cards: [Card('Widgets'), Card('Media'), Card('Variables'), Card('Actions')],
        activeIndex: 0
      },
      scriptColumn: {
        cards: [Card('Script')]
      },
      viewportColumn: {
        cards: [Card('Viewport')]
      },
      rightColumn: {
        cards: [Card('Hierarchy'), Card('Details')]
      },
      scrollControl: scrollControlState,
      windowHeight: window.innerHeight,
      timelineRow: observable(
        {
          cards: [Card('Timeline')],
          height: app.storage.has('timelineRowHeight')
            ? app.storage.get('timelineRowHeight', 'number')
            : TIMELINE_DEFAULT_HEIGHT,
          audioHeight: TIMELINE_PANEL_DEFAULT_HEIGHT,
          assertHeight: TIMELINE_PANEL_DEFAULT_HEIGHT,
          lastExpandedHeight: TIMELINE_DEFAULT_HEIGHT,
          // Note: this cannot be computed unless we pass in windowHeight from the parent
          maxHeight: function() {
            return window.innerHeight > 850 ? 650 : window.innerHeight - 200
          },
          saveHeight: function() {
            app.storage.set('timelineRowHeight', 'number', this.height)
          },
          setHeight: function(val) {
            val = Math.min(val, this.maxHeight()) // ceiling
            val = val <= TIMELINE_SNAP_HEIGHT ? TIMELINE_MINHEIGHT : val // "snap" at TIMELINE_SNAP_HEIGHT or less
            this.height = val
            const contentHeight = (val - TIMELINE_HEADER_HEIGHT) - this.audioHeight // 95 - header height;
            this.setAssertHeight(Math.max(contentHeight,TIMELINE_ASSERT_MIN_HEIGHT))
          },
          setAudioHeight: function(val) {
            this.audioHeight = val
          },
          setAssertHeight: function(val) {
            this.assertHeight = val
          },
          toggleExpand: function() {
            if (this.height <= TIMELINE_MINHEIGHT) {
              this.setHeight(this.lastExpandedHeight)
            } else {
              this.lastExpandedHeight = this.height
              this.setHeight(TIMELINE_MINHEIGHT)
            }
            this.saveHeight()
          },
          toggleFree: function() {
            const bool = this.free
            this.free = !bool
          },
          free: false
        },
        {
          saveHeight: action.bound,
          setHeight: action.bound,
          toggleExpand: action.bound,
          toggleFree: action.bound
        }
      ),
      detailsPanel: observable(
        {
          colorPickerActive: false,
          toggleColorPicker: function(val) {
            this.colorPickerActive = val
          }
        },
        {
          toggleColorPicker: action.bound
        }
      ),
      hierarchyPanel: observable(
        {
          showProgramOutline: false,
          toggleProgramOutline: function() {
            this.showProgramOutline = !this.showProgramOutline
          }
        },
        {
          toggleProgramOutline: action.bound
        }
      ),
      collapseState: observable(
        {
          panels: app.storage.has('panelCollapseState')
            ? app.storage.get('panelCollapseState', 'object')
            : {
                Media: true,
                Variables: true,
                Actions: true,
                Widgets: false,
                Scripting: true,
                Details: false,
                Hierarchy: false
              },
          toggleCollapse: function(name) {
            this.panels[name] = !this.panels[name]
            app.storage.set('panelCollapseState', 'object', this.panels)
          }
        },
        {
          toggleCollapse: action.bound
        }
      ),
      get verticalPanelHeightStructure() {
        return (
          this.windowHeight -
          (this.collapseState.panels.Widgets
            ? TOOLBAR_ADJUST
            : TOOLBAR_ADJUST + WIDGETS_PANEL_HEIGHT)
        )
      },
      get verticalPanelHeightLayout() {
        return (
          this.windowHeight -
          (this.collapseState.panels.Widgets
            ? TOOLBAR_ADJUST
            : TOOLBAR_ADJUST + WIDGETS_PANEL_HEIGHT) -
          (this.timelineRow.free ?
            0 :
            this.timelineRow.height)
        )
      },
      get verticalPanelHeightStructureInner() {
        return this.verticalPanelHeightStructure - 28
      },
      get verticalPanelHeightLayoutInner() {
        return this.verticalPanelHeightLayout - 28
      },
      get navigatorHeightLayoutInner() {
        return this.verticalPanelHeightLayout - 70
      },
      get scriptHeightLayoutInner() {
        return this.verticalPanelHeightLayout - 78
      },
      get scriptHeightStructureInner() {
        return this.verticalPanelHeightStructure - 78
      },
      setActiveCard: function(card) {
        this.activeCard = card
      },
      setActiveTab: function(tabIndex) {
        this.leftColumn.activeIndex = tabIndex
      },
      resizeWindow: function() {
        this.windowHeight = window.innerHeight
        this.timelineRow.setHeight(this.timelineRow.height)
      }
    },
    {
      activeCard: observable.ref,
      setActiveCard: action.bound,
      setActiveTab: action.bound,
      resizeWindow: action.bound
    }
  )
}
