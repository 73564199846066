import React from 'react'
import { observer } from 'mobx-react'
import Keyframe from './keyframe'
import { roundTo as round } from '../../utils'

function getCompareFunction(property) {
  // With ActiveInput we store activeness as Integers,
  // but CMs stores the default as a Boolean so we need to cast...smh
  const compareBool = (v1, v2) => !!v1 === !!v2
  // Because this is used just for display and we round our values in the input display
  // in asset-labels, we should compare against the rounded values; otherwise tween
  // lines will show up (correctly) even if the value change is inperceptable because of rounding
  const compareRounded = (v1, v2) => round(v1, 2) === round(v2, 2)
  const compareDefault = (v1, v2) => v1 === v2
  // Vec values need to have their array contents compared
  const compareVec3 = (v1, v2) => {
    return (
      compareRounded(v1[0], v2[0]) && compareRounded(v1[1], v2[1]) && compareRounded(v1[2], v2[2])
    )
  }
  switch (property) {
    case 'active':
      return compareBool
    case 'opacity':
      return compareRounded
    case 'position':
    case 'scale':
    case 'rotation':
      return compareVec3
    default:
      return compareDefault
  }
}

function compareKeyframeValues(property, v1, v2) {
  const fn = getCompareFunction(property)
  return fn(v1, v2)
}

const PropertyTimelines = observer(({ properties, stage, ...rest }) => {
  const propTimelines = properties.map((prop, i) => (
    <PropertyTimeline key={i} property={prop} stage={stage} {...rest} />
  ))
  return <div className="property-timelines">{propTimelines}</div>
})

const PropertyTimeline = observer(class PropertyTimeline extends React.Component {
  onMouseDown = (e) => {
    const { layoutState } = this.props
    if (!e.shiftKey) layoutState.selections.clearAllSelections()
  }
  selectKeyframe = (keyframe, shiftKey) => {
    const { asset, timelineState, layoutState } = this.props
    const { selections } = layoutState
    const isSelected = selections.keyframes.includes(keyframe)
    if (!isSelected && !shiftKey) {
      selections.clearAllSelections()
    }
    selections.setKeyframeSelection(keyframe, shiftKey)
    // EAT-2551: individual keyframe selection has no impact on asset selection for now
    // selections.setAssetSelection(asset, shiftKey) // make sure asset doesn't deselect
    // layoutState.player.setFrame(keyframe.frame)
  }
  render() {
    const {
      timelineState,
      layoutState,
      asset,
      property,
      frames,
      frameWidth,
      offset,
      width,
      stage
    } = this.props
    const { keyframes: selections } = layoutState.selections

    const defaultValue = property === 'canvas' ? asset['canvasFrame'] : asset[property]
    const keyframes = asset[property + 'Keyframes'] || []
    const sortedKeyframes = asset[property + 'KeyframesSorted'] || []
    const isLocked = asset.timelineState.locked || timelineState.assetsLocked

    // Rendered Elements
    let kfs = []
    let selectedKfs = []
    let lines = []
    let baseKf = null

    for (let i = 0; i < keyframes.length; i++) {
      // Keyframes
      let k = keyframes[i]
      if (k.frame >= offset && k.frame <= offset + frames) {
        const isSelected = selections.includes(k)
        const isDragging = k.objectId === timelineState.dragTransaction.object
        const dragOffset = isSelected ? timelineState.dragTransaction.offset : 0
        const xPos = (k.frame + dragOffset - offset) * frameWidth
        const keyframeProps = {
          key: k.objectId,
          x: xPos,
          keyframe: k,
          selectKeyframe: this.selectKeyframe,
          timelineState,
          frameWidth,
          isLocked,
          isSelected,
          isDragging
        }

        const keyframeComponent = <Keyframe {...keyframeProps} stage={stage} />
        if (isSelected) {
          // Selected keyframes are rendered on top
          selectedKfs.push(keyframeComponent)
        } else {
          kfs.push(keyframeComponent)
        }
      }
    }

    const createMockBaseKeyframe = (defaultValue) => {
      return {
        frame: 1,
        value: defaultValue
      }
    }

    // Draw Lines only when Tweening
    const sortedEnd = sortedKeyframes.length
    for (let i = 0; i < sortedEnd; i++) {
      let k = sortedKeyframes[i]
      let isStep = k.fn === 'step'
      let prevK = i === 0 ? createMockBaseKeyframe(defaultValue) : sortedKeyframes[i - 1]
      let isTweening = !compareKeyframeValues(property, k.value, prevK.value)
      let isSelected = selections.includes(k)
      let dragOffset = isSelected ? timelineState.dragTransaction.offset : 0
      let xStart = (prevK.frame + dragOffset) * frameWidth - frameWidth / 2
      let xEnd = (k.frame + dragOffset) * frameWidth - frameWidth / 2
      if (isTweening && !isStep) {
        // Don't draw tween lines for step easing
        lines.push(
          <line
            x1={xStart}
            y1={10}
            x2={xEnd}
            y2={10}
            stroke="#FFFFFF"
            strokeWidth="1"
            key={`${k.objectId}-tween`}
          />
        )
      }
    }

    // Show base Keyframe if keyframes exist
    if (keyframes.length) {
      baseKf = (
        <g>
          <polygon
            points={'0,0  0,14  7,7'}
            fill={'#FFFFFF'}
            style={{ transform: 'translate(1px, 3px)', pointerEvents: 'none' }}
          />
        </g>
      )
    }

    return (
      <div className="property-timeline" onMouseDown={this.onMouseDown}>
        <div className="grid">
          <svg
            width={width}
            height="20"
            className="row-rect above"
            style={{ shapeRendering: 'optimizeSpeed' }}>
            {baseKf}
            {lines}
            {kfs}
            {selectedKfs}
          </svg>
        </div>
      </div>
    )
  }
})

export { PropertyTimelines }
