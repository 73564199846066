import React from 'react'
import ExportPanel from '../panels/Export'
import { Takeover } from '../components'

export default class ScriptExportUI extends React.Component {
  render() {
    return (
      <Takeover>
        <ExportPanel {...this.props}/>
      </Takeover>
    )
  }
}
