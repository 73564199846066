import { observable, action, computed } from 'mobx'
import { decorate } from 'mobx'

export class Router {
  constructor(){
    this.current = 'EditionManager'
    this.prior = ''
  }
  get toJS() {
    return {
      current: this.current,
      prior: this.prior
    }
  }
  goTo(next) {
    this.prior = this.current
    this.current = next
  }
  goBack() {
    this.current = this.prior
    this.prior = this.current
  }
  fromJS(routeObject) {
    this.current = routeObject.current
    this.prior = routeObject.prior
  }
}

decorate(Router, {
  current: observable,
  prior: observable,
  toJS: computed,
  goTo: action.bound,
  goBack: action.bound,
  fromJS: action.bound,
})

export default Router
