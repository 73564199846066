import React from 'react'
import PropTypes from 'prop-types'
import { Scheduler } from '../../scheduler'
import './manager-section.scss'
import './manager-search.scss'

export class ManagerSearchField extends React.Component {
  static propTypes = {
    onResetSearch: PropTypes.func.isRequired,
    onUpdateSearch: PropTypes.func.isRequired,
    scheduler: PropTypes.instanceOf(Scheduler).isRequired,
  }
  onClickReset = () => {
    const { onResetSearch } = this.props
    onResetSearch()
    this.setState({ searchValue: ""})
  }
  onChangeSearch = ({ target: { value } }) => {
    this.onUpdateSearch(value)
    this.setState({ searchValue: value })
  }
  render() {
    const { children } = this.props
    const { searchValue } = this.state
    const { onClickReset } = this
    const { onChangeSearch } = this
    const vDom =
      <section className="manager-section manager-search">
        <div className="manager-search__query">
          <input
            id="manager-search__input"
            type="text"
            placeholder="Search..."
            data-qa-hook="input.searchBar"
            value={searchValue}
            onChange={onChangeSearch}
          />
          <button className="manager-search__reset" onClick={onClickReset} />
        </div>
        <div className="manager-search__actions">
          <span className="manager-search__actions-label">Filters</span>
          {children}
        </div>
      </section>

    return vDom
  }

  constructor(props) {
    super(props)
    this.state = { searchValue: "" }
    this.onUpdateSearch = props.scheduler.debounce('taskSearch', 500, () =>
      this.props.onUpdateSearch(this.state.searchValue))
  }
}

