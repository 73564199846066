import React from 'react'
import { observable } from 'mobx'
import { decorate } from 'mobx'
import { observer } from 'mobx-react'
import Input from '../components/Input'
import { isValidVariable } from '../utils'
import { VARIABLE_VALUE_MAP } from '../constants'

function getTypedValue(value) {
  var typedVal
  if (value === 'true') typedVal = true
  else if (value === 'false') typedVal = false
  else if (value === '') typedVal = null
  else if (value === null) typedVal = null
  else if (isNaN(value)) typedVal = value
  else typedVal = Number(value)
  return typedVal
}

const VariableValueOption = observer(({ value, label, showValue }) => {
  return (
    <option key={value} value={value}>
      {showValue ? `${label} ('${value}')` : label}
    </option>
  )
})

const VariableValueOptions = observer(({ optionMap, value, onSave }) => {
  const { values, showValue } = optionMap
  return (
    <div className="select-wrap">
      <select className="var-value" onChange={(e) => onSave(e.target.value)} value={value}>
        <option value="">null</option>
        {values.map((option) => (
          <VariableValueOption
            key={option.label}
            value={option.value}
            label={option.label}
            showValue={showValue}
          />
        ))}
      </select>
    </div>
  )
})

const VariableItem = observer(({ edition, group, name, value, removeable, isTyped, player }) => {
  const onSave = (val) => {
    if (isTyped) val = getTypedValue(val)
    edition.updateVariable(group, name, val)
    player.setVariables(edition.variables)
  }
  const remove = () => {
    if (window.confirm(`Really delete ${name}?`)) {
      edition.removeVariable(group, name)
    }
  }
  const valueString = typeof value !== 'undefined' && value !== null ? value.toString() : ''
  let valueComponent = null
  if (VARIABLE_VALUE_MAP.hasOwnProperty(name)) {
    valueComponent = (
      <VariableValueOptions
        optionMap={VARIABLE_VALUE_MAP[name]}
        value={valueString}
        onSave={onSave}
      />
    )
  } else {
    valueComponent = (
      <Input
        type="text"
        className="var-value"
        placeholder="null"
        value={valueString}
        onSave={onSave}
      />
    )
  }
  return (
    <li>
      <label className="var-name" title={name}>
        {name}
      </label>
      {valueComponent}
      {removeable ? (
        <button className="no-pad var-remove" onClick={remove}>
          <i className="icon remove" />
        </button>
      ) : null}
    </li>
  )
})

class NewCustomVariableFormComponent extends React.Component {
  constructor(props) {
    super(props)
    this.isVariableNameValid = true
    this.variableNameInput = null
  }
  setInput = (input) => {
    this.variableNameInput = input
  }
  onKeyUpCustom = (e) => {
    const { onSave } = this.props
    if (e.keyCode === 13) {
      e.preventDefault()
      const value = e.target.value
      this.handleResult(onSave(value))
    }
  }
  onCustomButtonClick = () => {
    const { onSave } = this.props
    const input = this.variableNameInput
    if (input) {
      const value = input.value
      this.handleResult(onSave(value))
    }
  }
  handleResult = (worked) => {
    this.isVariableNameValid = worked
    if (worked) this.variableNameInput.value = ''
  }
  render() {
    return (
      <div className="var-form">
        <input
          type="text"
          className="var-input"
          placeholder="Variable Name"
          ref={this.setInput}
          onKeyUp={this.onKeyUpCustom}
        />
        <button type="button" className="var-submit" onClick={this.onCustomButtonClick}>
          Create
        </button>
        <label className="validation">
          <span className={this.isVariableNameValid ? `hidden` : ``}>
            Please enter a valid variable.
          </span>
        </label>
      </div>
    )
  }
}

decorate(NewCustomVariableFormComponent, {
  isVariableNameValid: observable,
  variableNameInput: observable.ref,
})

const NewCustomVariableForm = observer(NewCustomVariableFormComponent)

const VariablesPanel = observer(class VariablesPanel extends React.Component {
  render() {
    const { edition } = this.props
    const { player } = this.props
    const vars = edition.variables
    const pVars = vars.patient
    const rVars = vars.runtime
    const cVars = vars.custom
    const pKeys = pVars ? Array.from(pVars.keys()) : []
    const rKeys = rVars ? Array.from(rVars.keys()) : []
    const cKeys = cVars ? Array.from(cVars.keys()) : []
    const pItems = pKeys.map((name) => {
      return (
        <VariableItem
          key={name}
          edition={edition}
          player={player}
          group={pVars}
          name={name}
          value={pVars.get(name)}
          removeable={false}
          isTyped={false}
        />
      )
    })
    const rItems = rKeys.map((name) => {
      return (
        <VariableItem
          key={name}
          edition={edition}
          player={player}
          group={rVars}
          name={name}
          value={rVars.get(name)}
          removeable={false}
          isTyped={false}
        />
      )
    })
    const cItems = cKeys.map((name) => {
      return (
        <VariableItem
          key={name}
          edition={edition}
          player={player}
          group={cVars}
          name={name}
          value={cVars.get(name)}
          removeable={true}
          isTyped={true}
        />
      )
    })
    const addCustomVariable = (val) => {
      if (isValidVariable(val)) {
        edition.addVariable(cVars, val)
        player.setVariables(edition.variables)
        return true
      } else {
        return false
      }
    }
    return (
      <div className="variables">
        <h4 className="card-subsection-title">Custom Variables</h4>
        <div className="card-subsection full-width">
          <ul>
            {cItems.length ? (
              <li className="header">
                <label className="var-name">NAME</label>
                <label className="var-value">VALUE</label>
                <label className="var-remove" />
              </li>
            ) : null}
            {cItems.length ? cItems : <li className="empty">No Custom Variables</li>}
          </ul>
          <NewCustomVariableForm onSave={addCustomVariable} />
        </div>
        <h4 className="card-subsection-title">Patient Variables</h4>
        <div className="card-subsection full-width">
          <ul>
            {pItems.length ? (
              <li className="header">
                <label className="var-name">NAME</label>
                <label className="var-value">VALUE</label>
              </li>
            ) : null}
            {pItems.length ? pItems : <li>No Patient Variables</li>}
          </ul>
        </div>
        <h4 className="card-subsection-title">Runtime Variables</h4>
        <div className="card-subsection full-width">
          <ul>
            {rItems.length ? (
              <li className="header">
                <label className="var-name">NAME</label>
                <label className="var-value">VALUE</label>
              </li>
            ) : null}
            {rItems.length ? rItems : <li>No Runtime Variables</li>}
          </ul>
        </div>
      </div>
    )
  }
})

export default VariablesPanel
