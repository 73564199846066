import React from 'react'
import { observer } from 'mobx-react'
import { Asset } from 'eplayer-core'
import Sprite from '../models/Asset/Sprite'
import Container from '../models/Asset/Container'
import Text from '../models/Asset/Text'
import Paragraph from '../models/Asset/Paragraph'
import Title from '../models/Asset/Title'
import Input from '../models/Asset/Input'
import Checkbox from '../models/Asset/Checkbox'
import Radio from '../models/Asset/Radio'
import Slider from '../models/Asset/Slider'
import Button from '../models/Asset/Button'
import Embed from '../models/Asset/Embed'
import List from '../models/Asset/List'
import ButtonGroup from '../models/Asset/ButtonGroup'
import MenuGroup from '../models/Asset/MenuGroup'
// import MenuGroupItem from '../models/Asset/MenuGroupItem'

const WidgetPanel = observer(({ globalState, sequence, player }) => {
  const { dragging } = globalState
  const { stage } = sequence
  // Cannot add non-video assets if video is populated in this sequence
  const canDblClick = (handler) => e => sequence.videoRows.length ?
    false :
    handler(e)

  const onContainerDblClick = canDblClick((e) =>
    stage.addChild(Container.create(Asset.Container.withDefaults({ name: 'Untitled Container', width: 200, height: 200 }))))
  const onSpriteDblClick = canDblClick((e) =>
    stage.addChild(Sprite.create(Asset.Sprite.withDefaults({ name: 'Untitled Sprite' }))))
  const onTextDblClick = canDblClick((e) =>
    stage.addChild(Text.create(Asset.Text.withDefaults({ name: 'Untitled Text' }))))
  const onParagraphDblClick = canDblClick((e) =>
    stage.addChild(Text.create(Asset.Paragraph.withDefaults({ name: 'Untitled Text' }))))
  const onBtnDblClick = canDblClick((e) =>
    stage.addChild(Button.create(Asset.Button.withDefaults({ name: 'Untitled Button' }))))
  const onInputDblClick = canDblClick((e) =>
    stage.addChild(Input.create(Asset.Input.withDefaults({ name: 'Untitled Input' }))))
  const onCheckboxDblClick = canDblClick((e) =>
    stage.addChild(Checkbox.create(Asset.Checkbox.withDefaults({ name: 'Untitled Checkbox' }))))
  const onRadioDblClick = canDblClick((e) =>
    stage.addChild(Radio.create(Asset.Radio.withDefaults({ name: 'Untitled Radio' }))))
  const onEmbedDblClick = canDblClick((e) =>
    stage.addChild(Embed.create(Asset.Embed.withDefaults({ name: 'Untitled Embed' }))))
  const onListDblClick = canDblClick((e) =>
    stage.addChild(List.create(Asset.List.withDefaults({ name: 'Untitled List' }))))
  const onSliderDblClick = canDblClick((e) =>
    stage.addChild(Slider.create(Asset.Slider.withDefaults({ name: 'Untitled Slider' }))))
  const onTitleDblClick = canDblClick((e) =>
    stage.addChild(Title.create(Asset.Title.withDefaults({ name: 'Untitled Title' }))))
  const onButtonGroupDblClick = canDblClick((e) =>
    stage.addChild(ButtonGroup.create(Asset.ButtonGroup.withDefaults({ name: 'Untitled ButtonGroup' }))))
  const onMenuGroupDblClick = canDblClick((e) =>
    stage.addChild(MenuGroup.create(Asset.MenuGroup.withDefaults({
      name: 'Untitled Menu Group',
    }))))

  const onContainerDrag = (e) => {
    e.dataTransfer.setData('text/panel', 'LayoutWidgets')
    dragging([Container.create(Asset.Container.withDefaults({ name: 'Untitled Container', width: 200, height: 200 }))])
  }
  const onSpriteDrag = (e) => {
    e.dataTransfer.setData('text/panel', 'LayoutWidgets')
    dragging([Sprite.create(Asset.Sprite.withDefaults({ name: 'Untitled Sprite' }))])
  }
  const onTextDrag = (e) => {
    e.dataTransfer.setData('text/panel', 'LayoutWidgets')
    dragging([Text.create(Asset.Text.withDefaults({ name: 'Untitled Text' }))])
  }
  const onParagraphDrag = (e) => {
    e.dataTransfer.setData('text/panel', 'LayoutWidgets')
    dragging([Paragraph.create(Asset.Paragraph.withDefaults({ name: 'Untitled Text' }))])
  }
  const onBtnDrag = (e) => {
    e.dataTransfer.setData('text/panel', 'LayoutWidgets')
    dragging([Button.create(Asset.Button.withDefaults({ name: 'Untitled Button' }))])
  }
  const onInputDrag = (e) => {
    e.dataTransfer.setData('text/panel', 'LayoutWidgets')
    dragging([Input.create(Asset.Input.withDefaults({ name: 'Untitled Input' }))])
  }
  const onCheckboxDrag = (e) => {
    e.dataTransfer.setData('text/panel', 'LayoutWidgets')
    dragging([Checkbox.create(Asset.Checkbox.withDefaults({ name: 'Untitled Checkbox' }))])
  }
  const onRadioDrag = (e) => {
    e.dataTransfer.setData('text/panel', 'LayoutWidgets')
    dragging([Radio.create(Asset.Radio.withDefaults({ name: 'Untitled Radio' }))])
  }
  const onEmbedDrag = (e) => {
    e.dataTransfer.setData('text/panel', 'LayoutWidgets')
    dragging([Embed.create(Asset.Embed.withDefaults({ name: 'Untitled Embed' }))])
  }
  const onListDrag = (e) => {
    e.dataTransfer.setData('text/panel', 'LayoutWidgets')
    dragging([List.create(Asset.List.withDefaults({ name: 'Untitled List' }))])
  }
  const onSliderDrag = (e) => {
    e.dataTransfer.setData('text/panel', 'LayoutWidgets')
    dragging([Slider.create(Asset.Slider.withDefaults({ name: 'Untitled Slider' }))])
  }
  const onTitleDrag = (e) => {
    e.dataTransfer.setData('text/panel', 'LayoutWidgets')
    dragging([Title.create(Asset.Title.withDefaults({ name: 'Untitled Title' }))])
  }
  const onButtonGroupDrag = (e) => {
    e.dataTransfer.setData('text/panel', 'LayoutWidgets')
    dragging([ButtonGroup.create(Asset.ButtonGroup.withDefaults({ name: 'Untitled ButtonGroup' }))])
  }
  const onMenuGroupDrag = (e) => {
    e.dataTransfer.setData('text/panel', 'LayoutWidgets')
    dragging([MenuGroup.create(Asset.MenuGroup.withDefaults({name: 'Untitled Menu Group'}))])
  }


  return (
    <div id="widgets" data-qa-hook="ui.layout-widgets">
      <div className="widget-lists">
        <ol className="asset-grid">
          <li
            className="asset"
            draggable="true"
            onDoubleClick={onContainerDblClick}
            onDragStart={onContainerDrag}
            data-qa-hook="widget.draggable.container">
            <i className="sprite widget container" data-qa-hook="layout-widget.icon"/>
            <label className="asset-label" data-qa-hook="layout-widget.type">Container</label>
          </li>
          <li
            className="asset"
            draggable="true"
            onDoubleClick={onSpriteDblClick}
            onDragStart={onSpriteDrag}
            data-qa-hook="widget.draggable.sprite">
            <i className="sprite widget sprite" data-qa-hook="layout-widget.icon" />
            <label className="asset-label" data-qa-hook="layout-widget.type">Sprite</label>
          </li>
          <li
            className="asset"
            draggable="true"
            onDoubleClick={onTitleDblClick}
            onDragStart={onTitleDrag}
            data-qa-hook="widget.draggable.title">
            <i className="sprite widget title" data-qa-hook="layout-widget.icon"/>
            <label className="asset-label" data-qa-hook="layout-widget.type">Title</label>
          </li>
          <li
            className="asset"
            draggable="true"
            onDoubleClick={onTextDblClick}
            onDragStart={onTextDrag}
            data-qa-hook="widget.draggable.text">
            <i className="sprite widget text" data-qa-hook="layout-widget.icon"/>
            <label className="asset-label" data-qa-hook="layout-widget.type">Text</label>
          </li>
          <li
            className="asset"
            draggable="true"
            onDoubleClick={onParagraphDblClick}
            onDragStart={onParagraphDrag}
            data-qa-hook="widget.draggable.paragraph">
            <i className="sprite widget paragraph" data-qa-hook="layout-widget.icon"/>
            <label className="asset-label" data-qa-hook="layout-widget.type">Paragraph</label> 
          </li>
          <li
            className="asset"
            draggable="true"
            onDoubleClick={onBtnDblClick}
            onDragStart={onBtnDrag}
            data-qa-hook="widget.draggable.button">
            <i className="sprite widget button" data-qa-hook="layout-widget.icon"/>
            <label className="asset-label" data-qa-hook="layout-widget.type">Button</label>
          </li>
          <li
            className="asset"
            draggable="true"
            onDoubleClick={onInputDblClick}
            onDragStart={onInputDrag}
            data-qa-hook="widget.draggable.input">
            <i className="sprite widget input" data-qa-hook="layout-widget.icon"/>
            <label className="asset-label" data-qa-hook="layout-widget.type">Input</label>
          </li>
          <li
            className="asset"
            draggable="true"
            onDoubleClick={onCheckboxDblClick}
            onDragStart={onCheckboxDrag}
            data-qa-hook="widget.draggable.checkbox">
            <i className="sprite widget checkbox" data-qa-hook="layout-widget.icon"/>
            <label className="asset-label" data-qa-hook="layout-widget.type">Checkbox</label>
          </li>
          <li
            className="asset"
            draggable="true"
            onDoubleClick={onRadioDblClick}
            onDragStart={onRadioDrag}
            data-qa-hook="widget.draggable.radio">
            <i className="sprite widget radio" data-qa-hook="layout-widget.icon"/>
            <label className="asset-label" data-qa-hook="layout-widget.type">Radio</label>
          </li>
          <li
            className="asset"
            draggable="true"
            onDoubleClick={onEmbedDblClick}
            onDragStart={onEmbedDrag}
            data-qa-hook="widget.draggable.embed">
            <i className="sprite widget embed" data-qa-hook="layout-widget.icon"/>
            <label className="asset-label" data-qa-hook="layout-widget.type">Embed</label>
          </li>
          <li
            className="asset"
            draggable="true"
            onDoubleClick={onSliderDblClick}
            onDragStart={onSliderDrag}
            data-qa-hook="widget.draggable.slider">
            <i className="sprite widget slider" data-qa-hook="layout-widget.icon"/>
            <label className="asset-label" data-qa-hook="layout-widget.type">Slider</label>
          </li>
          <li
            className="asset"
            draggable="true"
            onDoubleClick={onListDblClick}
            onDragStart={onListDrag}
            data-qa-hook="widget.draggable.list">
            <i className="sprite widget list" data-qa-hook="layout-widget.icon"/>
            <label className="asset-label" data-qa-hook="layout-widget.type">List</label>
          </li>
          <li
            className="asset"
            draggable="true"
            onDoubleClick={onButtonGroupDblClick}
            onDragStart={onButtonGroupDrag}
            data-qa-hook="widget.draggable.buttongroup">
            <i className="sprite widget container" data-qa-hook="layout-widget.icon"/>
            <label className="asset-label" data-qa-hook="layout-widget.type">Button Group</label>
          </li>
          <li
            className="asset"
            draggable="true"
            onDoubleClick={onMenuGroupDblClick}
            onDragStart={onMenuGroupDrag}
            data-qa-hook="widget.draggable.menu-group">
            <i className="sprite widget list" />
            <label className="asset-label">Menu Group</label>
          </li>
        </ol>
      </div>
    </div>
  )
})

export default WidgetPanel
