import { version as jellyfishVersion } from '../../package.json'
import {
  observable,
  extendObservable,
  action,
  autorun
} from 'mobx'
import { createTransformer } from 'mobx-utils'
import { BaseContent, migration, deserialize } from 'eplayer-core'
import KeyboardSignal from 'mousetrap'
import GlobalState from './observables/Panels/Global'
import LayoutState from './observables/Panels/Layout'
import StructureState from './observables/Panels/Structure'
import ScriptState from './observables/Panels/Script'
import MediaState from './observables/Panels/Media'
import TimelineState from './observables/Panels/Timeline'
import EditionManagerState from './observables/Panels/EditionManager'
import UIState from './observables/UI'
import Clock from './observables/Clock'
import History from './observables/History'
import Router from './observables/Router'
import EditionNavigator from './observables/EditionNavigator'
import { getVersionString } from './utils'
import { isNavigationValid } from './utils'
import {
  getPatientData,
  getRuntimeData,
  saveEdition,
  readEdition,
  readVersionedEdition,
  saveVersion,
  getAssetManagerHost,
  getAdvisorLinkUrl,
} from './actions/remote'
import { copy, paste } from './actions/clipboard'
import { VARIABLE_VALUE_MAP } from './constants'
import { CURRENT_MODEL_VERSION } from './constants'
import { getSnapshot } from 'mobx-state-tree'
import { UserStorage } from './storage'
import { Scheduler } from './scheduler'
import { Visibility } from './visibility'
import { Prompt } from './prompt'

import Edition from './models/BaseContent/Edition'

function newEdition(name) {
  // Set up a Starter Edition data model / domain state
  let newEdition = BaseContent.Edition()
  let c = BaseContent.Chapter()
  let s1 = BaseContent.Sequence()
  s1.current.position[1] = 600
  c.sequences.push(s1)
  newEdition.chapters.push(c)
  newEdition.name = name
  newEdition.startNode.next = s1.objectId
  return Edition.create(newEdition)
}

function newVersionDetails({ createdBy, created, lastUpdatedBy, lastUpdated, version }) {
  return observable({
    createdBy,
    lastUpdatedBy,
    createdDate: created ? created : new Date().toString(),
    lastUpdatedDate: lastUpdated ? lastUpdated : null,
    latestVersionCreated: (version && version.created) ? version.created : null
  })
}

function hotkeyIgnore(e) {
  // var el = e.target || e.srcElement // We don't necessarily want the event's target element as this can be inconsistent with what the browser's actual focused element is
  var el = document.activeElement
  var name = el.tagName.toLowerCase()
  var editable = el.isContentEditable
  if (el.classList.contains('asset')) return false
  return 'textarea' === name || 'select' === name || 'input' === name || editable
}

function getEditionDetails(edition, assetSetList) {
  // TODO: why isn't this just retrieved on the back-end?
  const matchingSet = assetSetList.find((set) => set.id === edition.assetSetId)
  const attrs = []

  for (const ch of edition.chapters) {
    for (const seq of ch.sequences) {
      if (seq.isMenu && seq.isProgramCompletion) attrs.push({ id: seq.objectId, menu: true, programCompletion: true })
      else if (seq.isProgramCompletion) attrs.push({ id: seq.objectId, programCompletion: true })
      else if (seq.isMenu) attrs.push({ id: seq.objectId, menu: true })
      else continue
    }
  }

  return {
    assetSetId: edition.assetSetId,
    assetSetPath: matchingSet ? matchingSet.assetSetPath : '',
    atVersion: 'Jellyfish ' + jellyfishVersion,
    content: JSON.stringify(getSnapshot(edition)),
    editionId: edition.objectId,
    meta: edition.tocJSON,
    modelVersion: CURRENT_MODEL_VERSION,
    name: edition.name,
    contentAttributes: JSON.stringify(attrs)
  }
}

function getLanguageCode(assetSetId, assetSetList) {
  const matchingSet = assetSetList.find((set) => set.id === assetSetId)
  return matchingSet ? matchingSet.dialect.iso639.toUpperCase() : "EN"
}

const clock = new Clock()

export default function App() {
  const app = observable({
    Type: 'App',
    edition: null, // domain state
    versionDetails: null,
    assetManagerHost: '',
    advisorUrl: '',
    username: '',
    editionNavigator: null,
    route: new Router(),
    history: new History(), // this is not observable as a whole
    error: {
      show: false,
      reason: ''
    },
    signals: {
      keyboard: KeyboardSignal
    },
  }, {
    edition: observable.ref,
    versionDetails: observable.ref
  })

  const scheduler = Scheduler.fromWindow(window)
  const storage = UserStorage.fromWindow(window)
  const visibility = Visibility.fromWindow(window)
  const prompt = Prompt.from(scheduler)
  extendObservable(app, { scheduler, storage, visibility, prompt, }, {}, { deep: false })

  const global = GlobalState(app)
  extendObservable(app, { state: {
    global,
    layout: LayoutState(app, clock, global),
    structure: StructureState(app),
    script: ScriptState(app),
    media: MediaState(app),
    timeline: TimelineState(app),
    editionmanager: EditionManagerState(app),
    ui: UIState(app)
  }}, {}, { deep: false })

  const navigatorTransformer = createTransformer(function(edition) {
    return new EditionNavigator(edition, app.state.layout.player)
  })
  autorun(function() {
    app.editionNavigator = app.edition ? navigatorTransformer(app.edition) : null
  })

  // Add contentEditable to our KeyboardSignal ignore
  app.signals.keyboard.stopCallback = hotkeyIgnore

  extendObservable(app, {
    setUsername: function(username) {
      this.username = username
    },
    cut: function(evt, route) {
      if (hotkeyIgnore && hotkeyIgnore(evt)) return
      copy(evt, app, route, true)
    },
    copy: function(evt, route) {
      if (hotkeyIgnore && hotkeyIgnore(evt)) return
      copy(evt, app, route)
    },
    paste: function(evt, route) {
      if (hotkeyIgnore && hotkeyIgnore(evt)) return
      paste(evt, app, route)
    },
    throwError: function(reason) {
      app.error.show = true
      app.error.reason = reason
    },
    dismissError: function() {
      app.error.show = false
      app.error.reason = ''
    },
    resetState: function() {
      this.state.global.reset()
      this.state.layout.reset()
      this.state.structure.reset()
      this.state.script.reset()
      this.state.media.reset()
      this.state.timeline.reset()
      // Note: don't need to bother resetting UI state
    },
    trackEdition: function() {
      const layoutSelections = app.state.layout.selections
      const structureSelections = app.state.structure.selections
      app.history.snapshot(app.edition, app.route, layoutSelections, structureSelections, () => {
        // Clear selections before replaying
        layoutSelections.clearAllSelections()
        structureSelections.clearSelection()
      })
    },
    readEdition: function(edition) {
      const partialDetails = {
        createdBy: app.username,
        lastUpdatedBy: app.username
      }
      app.edition = Edition.create(edition)
      app.versionDetails = newVersionDetails(partialDetails)
      app.route.goTo('Structure')
      app.getServerStores().then(() => {
        app.state.global.showAlert('success', `New edition has been read`)
      })

    },
    newEdition: async function(name = 'Untitled Edition') {
      const { setLoading } = app.state.global
      const { showAlert } = app.state.global
      const { media } = app.state
      
      setLoading(true)

      app.edition = newEdition(name)
      app.versionDetails = newVersionDetails({
        createdBy: app.username,
        lastUpdatedBy: app.username
      })

      try {
        await app.getServerStores()
        await media.populateTemplateList()

        app.route.goTo('Structure')

        showAlert('success', `New edition created.`)
      } catch(err) {
        if ('string' === typeof error) app.throwError(err)
        else app.throwError(`Sorry, it appears the application back-end is down or not functioning correctly.\n\nIf the problem persists please contact support.`)
      } finally {
        setLoading(false)
      }
    },
    duplicateEdition: async function() {
      const { setLoading, showAlert, setSaveState } = app.state.global
      const { media } = app.state

      const name = `${app.edition.name} (Copy)`

      setLoading(true)

      try {
        // edition.duplicate is synchronous, but can be intense enough that it can lock up the browser;
        // this ensures the loading spinner has time to render before that happens
        const copy = await new Promise((done) => {
          setTimeout(() => {
            const copy = app.edition.duplicate()
            done(copy)
          }, 300)
        })
        copy.updateProp('name', name)
        copy.setVersion(0, 0, 0)
        app.edition = copy
        app.versionDetails = newVersionDetails({
          createdBy: app.username,
          lastUpdatedBy: app.username
        })

        await media.populateTemplateList()
          

        app.route.goTo('Structure')
        app.resetState()
        app.trackEdition()

        media.parentOrphanTemplateRefs(copy)
        media.dialogTemplateUpdates()

        setSaveState('idle', null, null)
        showAlert('success', `Edition duplicated.`)
      } catch (err) {
        if ('string' === typeof err) app.throwError(err)
        else app.throwError(err.message)
      } finally {
        setLoading(false)
      }
    },
    saveEdition: async function() {
      const { setLoading, showAlert, setSaveState } = app.state.global
      const em = app.state.editionmanager

      setLoading(true)

      let editionDetails = getEditionDetails(app.edition, em.assetSetList)
      try {
        let resp = await saveEdition(editionDetails)
        setSaveState('success')
        showAlert('success', `Edition saved successfully.`)
      } catch (err) {
        setSaveState('error', err.message)
        showAlert('error', `Unable to save edition.`)
        // app.throwError(err.message) // TODO: do we want our general error modal here?
      } finally {
        setLoading(false)
      }
    },
    saveEditionForPreview: async function(previewFrom) {
      const { setLoading, showAlert, setSaveState } = app.state.global
      const { edition } = app
      const em = app.state.editionmanager
      setLoading(true)
      // Note: an alternative approach to previewing from a sequence could be to
      // alter the startNode AFTER snapshotting, but MST declares these snapshots to be
      // immutable, so I'm not sure about the side effects.
      const attrs = []
      const savedStartNode = edition.startNode.next // Save the original startNode to set it back after snapshotting
      if (previewFrom) edition.startNode.setNext(previewFrom) // Update startNode to preview from sequence (this should be ignored by undo/redo)

      for (const ch of edition.chapters) {
        for (const seq of ch.sequences) {
          if (seq.isMenu && seq.isProgramCompletion) attrs.push({ id: seq.objectId, menu: true, programCompletion: true })
          else if (seq.isProgramCompletion) attrs.push({ id: seq.objectId, programCompletion: true })
          else if (seq.isMenu) attrs.push({ id: seq.objectId, menu: true })
          else continue
        }
      }
      let language = getLanguageCode(app.edition.assetSetId, em.assetSetList)

      const content = JSON.stringify({
        nodeAttributes: JSON.stringify(attrs),
        edition: JSON.stringify(getSnapshot(edition)),
        modelVersion: CURRENT_MODEL_VERSION,
      })

      if (previewFrom) edition.startNode.setNext(savedStartNode) // Revert startNode to previous value (this should be ignored by undo/redo)

      try {
        window.localStorage.setItem('preview', content)
        const previewWin = window.open(
          `eplayer/?preview=true&edition=${app.edition.objectId}&language=${language}&user=${app.username}&debug=true`,
          '_blank'
        )
        previewWin.onclose = () => window.localStorage.removeItem('preview')
      } catch (err) {
        setSaveState('error', err.message)
        showAlert('error', `Unable to save and preview edition.`)
        // app.throwError(err.message) // TODO: do we want our general error modal here?
      } finally {
        setLoading(false)
      }
    },
    saveVersion: async function(comment, type, assetSetId) {
      const { setLoading, showAlert, setSaveState } = app.state.global
      const [ endSequences, incompleteDecisions ] = isNavigationValid(app.edition)
      const em = app.state.editionmanager

      if (endSequences.length > 1 || incompleteDecisions.length > 0) {
        let msg = 'Cannot save this edition due to errors in navigation connections:\n'

        if (endSequences.length > 1)
          msg += endSequences
                  .map(seq =>
                    `More than one sequence is missing a connection - ${seq.name}\n`)
                  .join('')

        if (incompleteDecisions.length > 0)
          msg += incompleteDecisions
                  .map(dN =>
                    `This decision has a condition with no connection - ${dN.name}\n`)
                  .join('')

        return app.throwError(msg)
      }

      setLoading(true)
      // Set the asset set prop if unversioned
      if (app.edition.version.isUnversioned) {
        app.edition.updateProp('assetSetId', assetSetId)
      }
      let editionDetails = getEditionDetails(app.edition, em.assetSetList)
      editionDetails.comment = comment
      try {
        const resp = await saveVersion(editionDetails, type)
        app.versionDetails = newVersionDetails(resp)
        app.edition.setVersion(resp.version.major, resp.version.minor, resp.version.working)
        // TODO: it probably makes the most sense to move the versioning and other metadata off the domain model so it does not effect undo/redo
        // reset undo/redo (as of now, setting the assetSetId and version get included in the undo/redo stack)
        app.trackEdition()
        setSaveState('success')
        showAlert('success', `New version saved.`)
      } catch (err) {
        setSaveState('error', err.message)
        showAlert('error', `Unable to save version.`)
        // app.throwError(err.message) // TODO: do we want our general error modal here?
      } finally {
        setLoading(false)
      }
    },
    loadEdition: async function(id) {
      if (!id) return
      const { setLoading, setSaveState, showAlert } = app.state.global
      const { media } = app.state
      setLoading(true)
      try {
        const data = await readEdition(id)

        ////////////////////////////////
        // Start Migration
        ////////////////////////////////
        let entities = {} // holds the "normalized" e objects to be mutated later
        const editionData = deserialize(data.version.content, (k, v) =>
          migration.tools.mapObjectTypes(k, v, entities)
        )
        const changeset = migration.getChangesetsFromVersion(
          data.version.modelVersion,
          CURRENT_MODEL_VERSION
        )
        migration.tools.migrate(entities, changeset) // This function will mutate entities
        ////////////////////////////////
        // End Migration
        ////////////////////////////////
        const edition = Edition.create(editionData)

        await media.populateTemplateList()

        app.edition = edition
        app.versionDetails = newVersionDetails(data)
        app.route.goTo('Structure')
        app.resetState()
        app.trackEdition()

        media.parentOrphanTemplateRefs(edition)
        media.dialogTemplateUpdates()

        setSaveState('idle', null, data.lastUpdated)
        showAlert('success', `${edition.name} loaded.`)
      } catch (err) {
        if ('string' === typeof err) app.throwError(err)
        else app.throwError(err.message)
      } finally {
        setLoading(false)
      }
    },
    loadVersionedEdition: async function(id, version, newest) {
      if (!id) return
      const { setLoading, setSaveState, showAlert } = app.state.global
      const { media } = app.state
      setLoading(true)
      try {
        const data = await readVersionedEdition(id, version, newest)
        ////////////////////////////////
        // Start Migration
        ////////////////////////////////
        let entities = {} // holds the "normalized" e objects to be mutated later
        const editionData = deserialize(data.version.content, (k, v) =>
          migration.tools.mapObjectTypes(k, v, entities)
        )
        const changeset = migration.getChangesetsFromVersion(
          data.version.modelVersion,
          CURRENT_MODEL_VERSION
        )
        migration.tools.migrate(entities, changeset) // This function will mutate entities
        ////////////////////////////////
        // End Migration
        ////////////////////////////////

        let edition = Edition.create(editionData)
        let lastUpdated = data.lastUpdated
        // If not latest reset version data
        if (!newest) {
          const name = edition.name + ' (Copy)'
          edition = edition.duplicate()
          edition.setVersion(0, 0, 0)
          edition.updateProp('name', name)
          lastUpdated = null
          // If latest copy/store version info from server
        } else {
          edition.setVersion(data.version.major, data.version.minor, data.version.working)
        }

        await media.populateTemplateList()

        app.edition = edition
        app.versionDetails = newVersionDetails(data)
        app.route.goTo('Structure')
        app.resetState()
        app.trackEdition()

        media.parentOrphanTemplateRefs(edition)
        media.dialogTemplateUpdates()

        setSaveState('idle', null, lastUpdated)
        showAlert('success', `${edition.name} version ${getVersionString(edition.version)} loaded.`)
      } catch (err) {
        if ('string' === typeof err) app.throwError(err)
        else app.throwError(err.message)
      } finally {
        setLoading(false)
      }
    },
    getServerStores: async function() {
      const p1 = getPatientData()
      const p2 = getRuntimeData()
      // P3-200: TODO: eventually we probably want the default value to come from the server as well
      const pVarKeys = await p1
      const rVarKeys = await p2
      const pVars = Array.from(pVarKeys, (k) => ({
        key: k,
        value: VARIABLE_VALUE_MAP.hasOwnProperty(k) ? VARIABLE_VALUE_MAP[k].default : null
      }))
      const rVars = Array.from(rVarKeys, (k) => ({
        key: k,
        value: VARIABLE_VALUE_MAP.hasOwnProperty(k) ? VARIABLE_VALUE_MAP[k].default : null
      }))
      app.edition.initPatientData(pVars)
      app.edition.initRuntimeData(rVars)
      try {
      } catch (err) {
        app.throwError(err.message)
      }
    },
    getMediaHost: async function() {
      const mediaHostUrl = getAssetManagerHost()
      app.assetManagerHost = await mediaHostUrl
      app.state.layout.player.setMediaHost(await mediaHostUrl)
    },
    getAdvisorUrl: async function() {
      const advisorLinkUrl = getAdvisorLinkUrl()
      app.advisorUrl = await advisorLinkUrl
    },
    getLanguageCode: function() {
      const em = app.state.editionmanager
      const assetSetId = app.edition.assetSetId
      const assetSetList = em.assetSetList
      const matchingSet = assetSetList.find((set) => set.id === assetSetId)

      return matchingSet ? matchingSet.dialect.iso639.toUpperCase() : "EN"
    },
    queueCapture: function(editionId, editionVersion, editionAssetPath, editionName) {
      this.state.global.tasks.enqueueTask(editionId, editionVersion, editionAssetPath, editionName)
    },
  }, {
    setUsername: action,
    cut: action,
    copy: action,
    paste: action,
    throwError: action,
    dismissError: action,
    resetState: action,
    trackEdition: action,
    newEdition: action,
    duplicateEdition: action,
    saveEdition: action,
    saveEditionForPreview: action,
    saveVersion: action,
    loadEdition: action,
    loadVersionedEdition: action,
    getServerStores: action,
    getMediaHost: action,
    getAdvisorUrl: action,
    getLanguageCode: action,
    queueCapture: action,
  })


  return app
}

