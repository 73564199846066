import { observable, action } from 'mobx'

// toggleVals function
// Toggles two values against a target variable
function toggleVals(a, b, target) {
  if (target === a) return b
  else return a
}

export default function EditionSearch() {
  return observable({
    results: [],
    value: '',
    // (should searching and loaded be mutually exclusive?)
    searching: false, // bugstatus
    loaded: false, // resultsstatus
    total: 0, // searchresults.totalElements (do we need to track this outside of the API callback?)
    activepage: 0,
    recordsperpage: 20,
    filter: 'lastUpdated', // enum(name, status, lastUpdated)
    filterdirection: 'desc', // for filter direction for API call
    filterdir: true, // for display of filter arrow direction, starts as desc
    filtername: false,
    filterlastupdated: true,
    filterstatus: false,
    //recordsdisplay: 'Load More...',
    get recordsdisplay() {
      // If no results are returned
      if (this.numResults === 0 || this.total === 0)
        return 'No Search Results found for search term ' + this.value
      // if less than 1 page of records records
      if (this.numDisplay >= this.total) return 'Showing all ' + this.total + ' Search Results'
      // At least 2 pages of records
      if (this.numDisplay < this.total)
        return 'Showing records 1 - ' + this.numDisplay + ' of ' + this.total + ' Search Results'
      return 'Load More...'
    },
    get numResults() {
      return this.results.length
    },
    get numDisplay() {
      // records to display based on page size
      return (this.activepage + 1) * this.recordsperpage
    },
    get isIdle() {
      return !this.searching && !this.loaded
    },
    get isSearching() {
      return this.searching && !this.loaded
    },
    get isLoaded() {
      return !this.searching && this.loaded
    },
    // Actions
    setTotal: function(t) {
      this.total = t
    },
    setResults: function(editionList) {
      this.results.replace(editionList)
    },
    addResults: function(editionList) {
      this.results = this.results.concat(editionList)
    },
    clearResults: function() {
      this.results.clear()
    },
    setIdle: function() {
      this.searching = false
      this.loaded = false
    },
    setSearching: function() {
      this.searching = true
      this.loaded = false
    },
    setLoaded: function() {
      this.searching = false
      this.loaded = true
    },
    toggleSearchFilter: function(clickedfilter) {
      if (this.filter == clickedfilter) {
        this.filterdirection = toggleVals('desc', 'asc', this.filterdirection)
        this.filterdir = toggleVals(true, false, this.filterdir)
      } else {
        this.filterdirection = 'desc'
        this.filterdir = true // Starts as desc when switching
      }
      this.filter = clickedfilter
      if (this.filter == 'name') {
        this.filtername = true
        this.filterlastupdated = false
        this.filterstatus = false
      } else if (this.filter == 'lastUpdated') {
        this.filtername = false
        this.filterlastupdated = true
        this.filterstatus = false
      } else if (this.filter == 'status') {
        this.filtername = false
        this.filterlastupdated = false
        this.filterstatus = true
      }
      //console.log(this.filter)
      // TODO: I dont think this exactly accomplishes the same thing the old code did, but I think there's a better way to handle it
    },
    setPage: function(page) {
      this.activepage = page
    },
    nextPage: function() {
      this.setPage(this.activepage++)
    },
    setSearch: function(query) {
      this.value = query
    },
    resetSearch: function() {
      this.value = ''
    }
  }, {
    results: observable.shallow,
    setTotal: action.bound,
    setResults: action.bound,
    addResults: action.bound,
    clearResults: action.bound,
    setIdle: action.bound,
    setSearching: action.bound,
    setLoaded: action.bound,
    toggleSearchFilter: action.bound,
    setPage: action.bound,
    nextPage: action.bound,
    setSearch: action.bound,
    resetSearch: action.bound
  })
}
