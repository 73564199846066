import React from 'react'
import ImportPanel from '../panels/Import'
import { Takeover } from '../components'

export default class ConversionUI extends React.Component {
  render() {
    const { app, conversion } = this.props
    return (
      <Takeover>
        <ImportPanel app={app} conversion={conversion} />
      </Takeover>
    )
  }
}
