import { types } from 'mobx-state-tree'
import HierarchyState from './HierarchyState'
import TimelineState from './TimelineState'
import DetailsState from './DetailsState'

const UI = {
	hierarchyState: HierarchyState,
	timelineState: TimelineState,
	detailsState: DetailsState,
}

export default UI
