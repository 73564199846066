import * as Template from '../../models/Template'
import { observable, action } from 'mobx'
import { getSnapshot } from "mobx-state-tree"
import { walkTree } from "../../utils"
import { httpPostJSON } from '../../actions/remote'
import { httpGetJSON } from '../../actions/remote'
import { updateRemoteMedia } from '../../actions/remote'
import { UUID } from "eplayer-core"
import CONFIG from '../../CONFIG'

const DIALOG = {
  NONE: 0,
  NEW_TEMPLATE: 1,
  UPDATE_TEMPLATE: 2,
  UPLOAD_MEDIA: 3,
  BATCH_DELETE: 4,
  TEMPLATE_UPDATES: 5
}

export default function MediaState(app) {
  return observable({
    uploading: false,
    selections: [],
    failed: [],
    pending: [],
    audioExpanded: false,
    spritesExpanded: false,
    htmlAnimationsExpanded: false,
    videoExpanded: false,
    assetsExpanded: false,
    sequencesExpanded: false,
    searchQuery: '',
    newFiles:[],
    relinkedFiles:[],
    dialog: DIALOG.NONE,
    templateList: [],
    privledgedTemplateUsers: [],
    updatedTemplates: [],

    get assetTemplateList() {
      const { templateList } = this
      const { singleAsset } = app.state.layout.selections

      return templateList
              .filter(({ Type: str }) =>
                "SequenceTemplate" !== str)
              .filter(({ Type: str }) =>
                singleAsset ?
                  str.match(singleAsset.Type) ?
                    // List matches ListItem
                    str.match("ListItem") && singleAsset.Type === "List" ?
                      false :
                      true :
                    false :
                  false)
    },

    get sessionTemplateUpdates() {
      return this.updatedTemplates
        .map(t => {
        return t.links().map(([name, locationId, locationName]) =>
          [ `${name} in Sequence ${locationName}`, locationId ])
        })
        .reduce((flatList, updates) => {
          flatList.push(...updates)
          return flatList
        },
        [])
    },
    get mediaHost() {
      return app.assetManagerHost
    },

    get showBatchDelete() {
      return DIALOG.BATCH_DELETE === this.dialog
    },

    get showUploadPopup() {
      return DIALOG.UPLOAD_MEDIA === this.dialog
    },

    get showNewTemplate() {
      return DIALOG.NEW_TEMPLATE === this.dialog
    },

    get showUpdateTemplate() {
      return DIALOG.UPDATE_TEMPLATE === this.dialog
    },

    get showTemplateUpdates() {
      return DIALOG.TEMPLATE_UPDATES === this.dialog
    },

    endDialog() {
      this.dialog = DIALOG.NONE
    },

    dialogNewTemplate() {
      if (this.dialog === DIALOG.NONE) {
        this.dialog = DIALOG.NEW_TEMPLATE
      }
    },

    dialogUpdateTemplate() {
      if (this.dialog === DIALOG.NONE) {
        this.dialog = DIALOG.UPDATE_TEMPLATE
      }
    },

    dialogUploadedMedia() {
      if (this.dialog === DIALOG.NONE) {
        this.dialog = DIALOG.UPLOAD_MEDIA
      }
    },

    dialogBatchDelete() {
      if (this.dialog === DIALOG.NONE) {
        this.dialog = DIALOG.BATCH_DELETE
      }
    },

    dialogTemplateUpdates() {
      if (this.dialog === DIALOG.NONE) {
        if (this.updatedTemplates.length) {
          this.dialog = DIALOG.TEMPLATE_UPDATES
        }
      }
    },

    async createTemplate(templateName) {
      const { endDialog } = this
      const { setTemplate } = this
      const { templateList } = this


      const origin = app.state.layout.selections.singleAsset
      
      if (!origin) {
        this.endDialog()
        return app.throwError(`MediaState.createTemplate called without valid selection`)
      }

      const maybeTemplate = origin.template
      const templateProps = getSnapshot((maybeTemplate && maybeTemplate.properties) || origin)
      const templateType = `${origin.Type}Template`
      const newTemplate = Template[templateType].create({
        objectId: UUID(),
        Type: templateType,
        updatedAt: Date.now(),
        updatedBy: app.username,
        name: templateName,
        properties: templateProps
      })
      
      app.state.global.setLoading(true)

      try {
        const { updatedAt, updatedBy } = await setTemplate(newTemplate)

        newTemplate.updateProp('updatedAt', updatedAt)
        newTemplate.updateProp('updatedBy', updatedBy)

        app.edition.addMedia(newTemplate)
        templateList.push(newTemplate)

        if (maybeTemplate) {
          maybeTemplate.unlink(origin)
        }

        newTemplate.link(origin)
      } catch(msg) {
        app.throwError(msg)
      } finally {
        app.state.global.setLoading(false)
        endDialog()
      }
    },

    async updateTemplate() {
      const { endDialog } = this
      const { setTemplate } = this

      const origin = app.state.layout.selections.singleAsset
      
      if (!origin) {
        endDialog()
        return app.throwError(`MediaState.updateTemplate called without valid selection`)
      }

      const template = origin.template

      app.state.global.setLoading(true)

      try {
        const { updatedAt, updatedBy } = await setTemplate(template, app.username)
        template.updateProp('updatedAt', updatedAt)
        template.updateProp('updatedBy', updatedBy)
      } catch(msg) {
        app.throwError(msg)
      } finally {
        app.state.global.setLoading(false)
        endDialog()
      }
    },

    assignTemplate(templateId, asset) {
      const { template } = asset
      const { templateList } = this
      const editionTemplates = app.edition.templateMedia

      let nextTemplate
      
      if (template && templateId === template.objectId) {
        return
      }

      if (!templateId && template) {
        template.unlink(asset)
        return
      }

      if (nextTemplate = editionTemplates.find(t => templateId === t.objectId)) {
        template && template.unlink(asset)
        nextTemplate.link(asset)
        return
      } else if (nextTemplate = templateList.find(t => templateId === t.objectId)) {
        nextTemplate = Template[nextTemplate.Type].create({...nextTemplate})

        app.edition.addMedia(nextTemplate)
        template && template.unlink(asset)
        nextTemplate.link(asset)
        return
      } else {
        app.throwError(`Attempted to assign Template to ${asset.name} but couldn't find template`)
        return
      }
    },

    async populateTemplateList() {
      const { getTemplates } = this
      const { templatePermissions } = this
      const { templateList } = this
      const { privledgedTemplateUsers } = this
      try {
        const list = await getTemplates()
        const perms = await templatePermissions()
        const templates = list.map(data => ({
          name: data.name,
          objectId: data.objectId,
          updatedAt: data.updatedAt,
          updatedBy: data.updatedBy,
          Type: data.type,
          properties: JSON.parse(data.template),
        }))

        templateList.replace(templates)
        privledgedTemplateUsers.replace(perms)
      } catch(msg) {
        return Promise.reject(msg)
      }
    },

    validateTemplateName(templateName) {
      const found =
        this.templateList.find(t =>
          templateName === t.name)

      const isValid =
        templateName ?
          found ?
            false  :
            true :
          false

      return isValid
    },

    async setTemplate({ name, objectId, Type, properties }) {
      const body = getSnapshot(properties)
      try {
        const json = JSON.stringify(body)
        const payload = {
          json,
          name,
          objectId,
          type: Type,
        }

        const result = await httpPostJSON(CONFIG.API.templates.persist, payload)      

        return result
      } catch(status) {
        let msg

        switch(status) {
          case 500:
            msg = "We were unable to publish the template remotely for some reason, try again later or open a PRODREQ issue" 
            break
          case 400:
            msg = "The data sent to complete this operation was malformed for some reason, open a PRODREQ issue"
            break
          case 401: case 402: case 407:
            msg = "You were denied network authorization needed to complete this operation, open a PRODREQ issue"
            break
          case 404: case 410:
            msg = "The network location needed to complete this operation has been moved, open a PRODREQ issue if this is unaddressed shortly"
            break
          case 405:
            msg = "The wrong kind of network request was made needed to complete this operation, open a PRODREQ issue"
            break
          case 408:
            msg = "The remote machine needed to complete this operation failed to respond, open a PRODREQ issue"
            break
          default:
            msg = "We were unable to publish the template remotely for some reason, try again later or open a PRODREQ issue" 
        }

        return Promise.reject(msg)
      }
    },

    async getTemplates() {
      try {
        const result = await httpGetJSON(CONFIG.API.templates.list)
        const domain = result.map(data => ({
          ...data,
          properties: JSON.parse(data.template),
          Type: data.type
        }))

        return domain
      } catch(status) {
        let msg

        switch(status) {
          case 500:
            msg = "We were unable to complete setup of the app, the master template list couldn't be fetched, open a PRODREQ issue" 
            break
          case 401: case 402: case 407:
            msg = "You were denied network authorization needed to complete an operation that completes setup of the app, open a PRODREQ issue"
            break
          case 404: case 410:
            msg = "A network location needed to setup the app is missing, open a PRODREQ issue"
            break
          case 405:
            msg = "We sent the wrong kind of network request needed to setup the app, open a PRODREQ issue"
            break
          case 408:
            msg = "The remote machine needed to complete app setup failed to respond, open a PRODREQ issue"
            break
          default:
            msg = "We were unable to complete setup of the app, the master template list couldn't be fetched, open a PRODREQ issue" 
        }

        return Promise.reject(msg)
      }
    },

    async templatePermissions() {
      try {
        return await httpGetJSON(CONFIG.API.templates.permissions)
      } catch(status) {
        let msg

        switch(status) {
          case 500:
            msg = "We were unable to complete setup of the app, the master template list couldn't be fetched, open a PRODREQ issue" 
            break
          case 401: case 402: case 407:
            msg = "You were denied network authorization needed to complete an operation that completes setup of the app, open a PRODREQ issue"
            break
          case 404: case 410:
            msg = "A network location needed to setup the app is missing, open a PRODREQ issue"
            break
          case 405:
            msg = "We sent the wrong kind of network request needed to setup the app, open a PRODREQ issue"
            break
          case 408:
            msg = "The remote machine needed to complete app setup failed to respond, open a PRODREQ issue"
            break
          default:
            msg = "We were unable to complete setup of the app, the master template list couldn't be fetched, open a PRODREQ issue" 
        }

        return Promise.reject(msg)
      }
    },

    parentOrphanTemplateRefs(edition) {
      const { chapters } = edition
      const { templateMedia } = edition
      const { templateList } = this
      const { updatedTemplates }  = this

      if (!templateList.length) return

      templateMedia.forEach(a => {
        const update = templateList.find(b =>
          a.objectId === b.objectId &&
          a.updatedAt !== b.updatedAt)

        if (!update) return

        a.updateFromProps(update)
        updatedTemplates.push(a)
      })

      chapters.forEach(ch =>
        ch.sequences.forEach(seq =>
          walkTree(adoptOrphanTemplateReferences, seq.stage)))


      function adoptOrphanTemplateReferences(asset) {
        templateMedia.forEach(t =>
          t.linkOrphan(asset))
      }
    },

    isTemplateUserPrivledged() {
      return this.privledgedTemplateUsers.includes(app.username)
    },

    reset: function() {
      this.uploading = false
      this.selections.clear()
      this.failed.clear()
      this.pending.clear()
      this.updatedTemplates.clear()
      this.searchQuery = ''
    },
    toggleSection: function(section) {
      this[section] = !this[section]
    },
    doSearch: function(val) {
      if (val.length) {
        this.searchQuery = val
        // Expand all sections on search (would be nice to do this based on filtered matches)
        this['audioExpanded'] = true
        this['spritesExpanded'] = true
        this['htmlAnimationsExpanded'] = true
        this['videoExpanded'] = true
      } else {
        this.searchQuery = val
      }
    },
    clearFailed: function() {
      this.failed.clear()
    },
    selectMedia: function(media) {
      this.deselectAllMedia()
      this.batchSelectMedia(media)
    },
    deselectMedia: function(media) {
      this.selections.remove(media)
    },
    batchSelectMedia: function(media) {
      this.selections.push(media)
    },
    deselectAllMedia: function() {
      this.selections.clear()
    },
    showUploadModal(){
      this.dialog = DIALOG.UPLOAD_MEDIA
    },
    hideUploadModal(){
      this.dialog = DIALOG.NONE
      this.newFiles = [];
      this.relinkedFiles = [];
    },
    isMatch({ fileExt, fileName }){
      const { edition } = app      
      let list, match
        if (isImageExt(fileExt)) list = edition.spriteMedia
          else if (isAudioExt(fileExt)) list = edition.audioMedia
          else if (isScriptExt(fileExt)) list = edition.canvasMedia
          else if (isVideoExt(fileExt)) list = edition.videoMedia
        match = list.find( media =>
          media.fileName === fileName && media.fileExt === fileExt)
          return match;
    },
    async add(...newFiles) {
      const { edition } = app
      const { pending } = this
      const { failed } = this
      const { state: { layout: { player } } } = app
      this.uploading = true
      this.newFiles.clear()
      this.selections.clear()
      this.relinkedFiles.clear()
      pending.replace(newFiles)

      for await (const [ file, result ] of updateRemoteMedia(player.cache, ...newFiles)) {
        if (result instanceof Error) {
          failed.push(file)
          continue
        }

        const { fileExt, fileName } = result

        const priorMedia = this.isMatch(result);

        if (priorMedia){
          edition.updateResponseMedia(priorMedia, [result])
          this.relinkedFiles.push(fileName)
        }
        else{
          edition.writeResponseMedia([result])
          this.newFiles.push(fileName)
        } 

        switch (fileExt) {
          case 'jpg': case 'png': case 'svg': { this['spritesExpanded'] = true; break; }
          case 'mp3': { this['audioExpanded'] = true; break; }
          case 'mp4': { this['videoExpanded'] = true; break; }
          case 'js': { this['htmlAnimationsExpanded'] = true; break; }
        }
      }

      pending.clear()
      this.uploading = false
      this.showUploadModal()
    },
    async update(priorMedia, newFile) {
      const { edition } = app
      const { failed } = this
      const { state: { layout: { player } } } = app
      for await (const [ file, result ] of updateRemoteMedia(player.cache, newFile)) {
        if (result instanceof Error) failed.push(file)
        else edition.updateResponseMedia(priorMedia, [result])
      }
    },
    confirmBatchDelete() {
      this.dialog = DIALOG.BATCH_DELETE
    },
    denyBatchDelete() {
      this.dialog = DIALOG.NONE
    },
    batchDelete() {
      this.dialog = DIALOG.NONE
      this.delete()
    },
    delete() {
      const { edition } = app      
      const { selections } = this
      let media
      // N.B. Editon#removeMedia appears to 'remove' by shift
      while(media = selections[0]) {
        this.deselectMedia(media)
        edition.removeMedia(media) 
      }
    }
  }, {
    selections: observable.shallow,
    failed: observable.shallow,
    pending: observable.shallow,
    templateList: observable.shallow,
    reset: action.bound,
    toggleSection: action.bound,
    doSearch: action.bound,
    clearFailed: action.bound,
    selectMedia: action.bound,
    deselectMedia: action.bound,
    batchSelectMedia: action.bound,
    deselectAllMedia: action.bound,
    add: action.bound,
    update: action.bound,
    confirmBatchDelete: action.bound,
    denyBatchDelete: action.bound,
    batchDelete: action.bound,
    delete: action.bound,
    endDialog: action.bound,
    dialogNewTemplate: action.bound,
    dialogUpdateTemplate: action.bound,
    dialogBatchDelete: action.bound,
    dialogUploadedMedia: action.bound,
    createTemplate: action.bound,
    updateTemplate: action.bound,
    validateTemplateName: action.bound,
    setTemplate: action.bound,
    getTemplates: action.bound,
    populateTemplateList: action.bound,
    assignTemplate: action.bound,
    parentOrphanTemplateRefs: action.bound,
  })
}

function isImageExt (fileExt) {
  const ext = fileExt.toLowerCase()
  return (
  'png' === fileExt ||
  'svg' === fileExt ||
  'jpeg' === fileExt ||
  'jpg' === fileExt ||
  'webp' === fileExt ?
    true :
    false
  )
}

function isAudioExt(fileExt) {
  return 'mp3' === fileExt || 'wav' === fileExt ? true : false
}

function isVideoExt(fileExt) {
  return 'mp4' === fileExt ? true : false
}

function isScriptExt(fileExt) {
  return 'js' === fileExt ? true : false
}
