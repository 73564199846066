import { types, getSnapshot, getParent, getRoot } from 'mobx-state-tree'
import { BaseContent, Vec3, Mat4 } from 'eplayer-core'
import vec3 from 'gl-vec3'
import DecisionNode from './DecisionNode'
import Sequence from './Sequence'
import { connectionsWhere } from '../queries'
import { setProp } from '../utils'
import { CHAPTER_OFFSET_X, SEQUENCE_OFFSET_X } from '../constants'

function squared(x) {
	return x * x
}

const ChapterCurrent = types
	.model('ChapterCurrent', {
		emptyPosition: types.array(types.number),
		localMatrix: types.array(types.number),
		worldMatrix: types.array(types.number),
	})
	.views(self => ({
		get chapter() {
			return getParent(self)
		},
		get bounds() {
			const c = self.chapter
			var minX = Number.MAX_SAFE_INTEGER,
				maxX = Number.MIN_SAFE_INTEGER,
				minY = Number.MAX_SAFE_INTEGER,
				maxY = Number.MIN_SAFE_INTEGER,
				numSequences = c.sequences.length,
				numDecisions = c.decisionNodes.length
			for (var i = 0, l = numSequences; i < l; i++) {
				var s = c.sequences[i]
				minX = Math.min(minX, s.current.position[0] - s.current.radius)
				maxX = Math.max(maxX, s.current.position[0] + s.current.radius)
				minY = Math.min(minY, s.current.position[1] - s.current.radius)
				maxY = Math.max(maxY, s.current.position[1] + s.current.radius)
			}
			for (var i = 0, l = numDecisions; i < l; i++) {
				var d = c.decisionNodes[i]
				minX = Math.min(minX, d.current.position[0] - d.current.radius)
				maxX = Math.max(maxX, d.current.position[0] + d.current.radius)
				minY = Math.min(minY, d.current.position[1] - d.current.radius)
				maxY = Math.max(maxY, d.current.position[1] + d.current.radius)
			}
			return {
				ul: Vec3(minX, minY, 0),
				lr: Vec3(maxX, maxY, 0),
			}
		},
		get position() {
			const c = self.chapter
			var bounds = self.bounds,
				minX = bounds.ul[0],
				maxX = bounds.lr[0],
				minY = bounds.ul[1],
				maxY = bounds.lr[1],
				isEmpty = !c.sequences.length && !c.decisionNodes.length
			if (isEmpty) {
				return self.emptyPosition
			} else {
				var dX = maxX - minX
				var dY = maxY - minY
				return Vec3(dX / 2 + minX, dY / 2 + minY, 0)
			}
		},
		get radius() {
			const c = self.chapter
			var circleBuffer = 150 // diameter of sequence nodes
			var bounds = self.bounds,
				minX = bounds.ul[0],
				maxX = bounds.lr[0],
				minY = bounds.ul[1],
				maxY = bounds.lr[1],
				isEmpty = !c.sequences.length && !c.decisionNodes.length
			if (isEmpty) {
				return 200
			} else {
				var rX = (maxX - minX) / 2
				var rY = (maxY - minY) / 2
				return Math.sqrt(squared(rX) + squared(rY)) + circleBuffer
			}
		},
		get width() {
			var c = self.chapter;
			var widthBuffer = 150; // diameter of sequence nodes
			var bounds = self.bounds,
			minX = bounds.ul[0],
			maxX = bounds.lr[0],
			isEmpty = !c.sequences.length && !c.decisionNodes.length;
			if (isEmpty) {
				return 200;
			} else {
				var rX = (maxX - minX);
				return Math.sqrt(squared(rX)) + widthBuffer;
			}
		},
		get height() {
			var c = self.chapter;
			var heightBuffer = 150; // diameter of sequence nodes
			var bounds = self.bounds,
			minY = bounds.ul[1],
			maxY = bounds.lr[1],
			isEmpty = !c.sequences.length && !c.decisionNodes.length;
			if (isEmpty) {
				return 200;
			} else {
			var rY = (maxY - minY);
			return Math.sqrt(squared(rY)) + heightBuffer;
			}
		},
	}))
	
	.actions(self => {
		function setEmptyPosition(delta) {
			vec3.add(self.emptyPosition, self.emptyPosition, delta)
		}
		return {
			setEmptyPosition,
		}
	})
  
const ChapterScriptState = types
	.model('ChapterScriptState', {
		expanded: types.boolean,
		showInfo: types.boolean,
		showConnections: types.boolean,
		showAlerts: types.boolean,
	})
	.actions(self => {
		function toggleProp(prop) {
			self[prop] = !self[prop]
		}
		return {
			toggleProp,
		}
	})
  
const model = types
	.model('Chapter', {
		Type: types.literal('Chapter'),
		objectId: types.identifier,
		sequences: types.late(() => types.array(Sequence)),
		decisionNodes: types.late(() => types.array(DecisionNode)),
		name: types.string,
		title: types.string,
		description: types.string,
		inToC: types.boolean,
		current: ChapterCurrent,
		scriptState: ChapterScriptState,
	})
	.views(self => ({
		get parent() {
			return getParent(self, 2)
		},
    get duration() {
      const frameSum =
        this.sequences
          .map(s => Number(s.duration))
          .reduce((a, b) => b ? a + b : a, 0)

      return frameSum / 24
    },
    get durationWithoutRisks() {
      // There is a closed set of possible names for sequences that inform on medical risks to patients in legacy programs
      const notRiskSeq = (sequence) => !/\/ r(end|a|b2|d|e|e2|el|er|f|gh|n|n2|nh|nhb|s|t|v)$/i.test(sequence.name)

      const frameSum =
        this.sequences
          .filter(notRiskSeq)
          .map(s => Number(s.duration))
          .reduce((a, b) => b ? a + b : a, 0)

      return frameSum / 24
    },
	}))
	.actions(self => {
		function updateProp(prop, value) {
			const c = self
			setProp(prop, value, c)
		}
		function addSequence(s) {
			self.sequences.push(s)
		}
		function addSequenceAtIndex(p, s) {
			self.sequences.splice(p, 0, s)
		}
		function addDecisionNode(d) {
			self.decisionNodes.push(d)
		}
		function addDecisionNodeAtIndex(p, d) {
			self.decisionNodes.splice(p, 0, d)
		}

		// Script Panel dropdown insert Sequence
		function insertSequence(position) {
			const c = self
			position = position === undefined ? c.sequences.length : position
			var preceedingSequence = c.sequences[position - 1]
			var nextSequence = c.sequences[position]
			var x = c.current.position[0] + (nextSequence ? SEQUENCE_OFFSET_X : 0)
			var y = preceedingSequence
				? preceedingSequence.current.position[1] +
					(nextSequence ? SEQUENCE_OFFSET_X / 2 : SEQUENCE_OFFSET_X)
				: c.current.position[1]
			var sequence = BaseContent.Sequence()
			sequence.current.position[0] = x
			sequence.current.position[1] = y
			sequence = Sequence.create(sequence)
			if (preceedingSequence) preceedingSequence.setNext(sequence)
			if (nextSequence) sequence.setNext(nextSequence)
			c.addSequenceAtIndex(position, sequence)
		}
		// Script Panel dropdown remove Sequence
		function removeSequence(sequence) {
			const c = self
			const e = c.parent
			// Find connections
			const refs = connectionsWhere(n => {
				if (n.next && n.next === sequence) return true
				if (n.connections) return n.connections.find(conn => conn.value === sequence)
				return false
			}, e)
			// Remove connections
			for (let i = 0; i < refs.length; i++) {
				var ref = refs[i]
				if (ref.next && ref.next === sequence) ref.setNext(null)
				if (ref.connections) {
					ref.connections.forEach(conn => {
						if (conn.value === sequence) conn.updateValue(null)
					})
				}
			}
			// Handle StartNode connection
			if (
				e.startNode.next &&
				e.startNode.next.objectId === sequence.objectId
			) {
				e.startNode.setNext(null)
			}
			// Remove sequence itself
			c.sequences.remove(sequence)
		}
		// Dragging a Sequence in Script Panel
		function moveSequence(sequence, position) {
			const edition = getRoot(self)
			const c = self
			edition.detachNode(sequence)
			c.addSequenceAtIndex(position, sequence)
		}
		// Delete selected DecisionNode from Chapter
		function removeDecisionNode(decisionNode) {
			const c = self
			const e = c.parent
			// Find connections
			const refs = connectionsWhere(n => {
				if (n.next && n.next === decisionNode) return true
				if (n.connections) return n.connections.find(conn => conn.value === decisionNode)
				return false
			}, e)
			// Remove connections
			for (let i = 0; i < refs.length; i++) {
				var ref = refs[i]
				if (ref.next && ref.next === decisionNode) ref.setNext(null)
				if (ref.connections) {
					ref.connections.forEach(conn => {
						if (conn.value === decisionNode) conn.updateValue(null)
					})
				}
			}
			// Handle StartNode connection
			if (
				e.startNode.next &&
				e.startNode.next.objectId === decisionNode.objectId
			) {
				e.startNode.setNext(null)
			}
			// Remove decisionNode itself
			c.decisionNodes.remove(decisionNode)
		}
		function duplicate(edition) {
			const c = self
			edition = edition || c.parent
			let clone = c.clone()
			for (let i = 0; i < clone.sequences.length; i++) {
				clone.sequences[i].current.position[0] =
					c.current.position[0] +
					CHAPTER_OFFSET_X +
					SEQUENCE_OFFSET_X * i -
					SEQUENCE_OFFSET_X * c.sequences.length / 2
			}
			edition.addChapter(clone)
		}
		function paste(obj, isSameDomain) {
			// Re-clone on paste for refreshed UUIDs
			if (obj.Type === 'DecisionNode')
				addDecisionNode(BaseContent.DecisionNode.clone(obj))
			// Clear Media from certain asset types if domains do not match
			if (obj.Type === 'Sequence') {
				isSameDomain
					? addSequence(BaseContent.Sequence.clone(obj))
					: addSequence(BaseContent.Sequence.cloneWithoutMedia(obj))
			}
		}
		function clone() {
			const c = self
			let copy = getSnapshot(c)
			// run graph clone to setup new UUIDs and such
			copy = BaseContent.Chapter.clone(copy)
			// Get internal structure references
			let internalRefs = []
			Array.prototype.push.apply(internalRefs, copy.sequences.map((seq) => seq.objectId))
			Array.prototype.push.apply(internalRefs, copy.decisionNodes.map((dN) => dN.objectId))
			// Walk the Chapter structure and disconnect anything external
			for (let i = 0, l = copy.sequences.length; i < l; i++) {
	      const seq = copy.sequences[i]
				if (!internalRefs.includes(seq.next)) seq.next = null
	    }
			for (let i = 0, l = copy.decisionNodes.length; i < l; i++) {
	      const dN = copy.decisionNodes[i]
				if (!internalRefs.includes(dN.next)) dN.next = null
				// Check connections
				for (let j = 0; j < dN.connections.length; j++) {
					const connection = dN.connections[j]
					if (!internalRefs.includes(connection.value)) connection.value = null
				}
	    }
			return copy
		}
		// Dragging Chapter in Structure Viewport
		function translate(delta) {
			const c = self
			var numSequences = c['sequences'].length,
				numDecisions = c['decisionNodes'].length,
				isEmpty = !numSequences && !numDecisions
			for (var j = 0; j < numSequences; j++) {
				var s = c['sequences'][j]
				s.translate(delta)
			}
			for (var k = 0; k < numDecisions; k++) {
				var d = c['decisionNodes'][k]
				d.translate(delta)
			}
			if (isEmpty) {
				c.current.setEmptyPosition(delta)
			}
		}
		// Dragging a Chapter onto another Chapter in Structure Hierarchy
		function moveContent(node) {
			const edition = getRoot(self)
			const c = self
			const newParent = c.parent
			const newPeers = newParent['chapters']
			const i = newPeers.indexOf(c)
			edition.detachNode(node)
			newParent.addChapterAtIndex(i, node)
		}
		// Dragging a Sequence or DecisionNode onto a Chapter in Structure Hierarchy
		function setContentParent(node) {
			const edition = getRoot(self)
			const c = self
			const typeMap = { Sequence: 'addSequence', DecisionNode: 'addDecisionNode' }
			const childFunc = typeMap[node.Type]
			edition.detachNode(node)
			c[childFunc](node)
		}
		return {
			updateProp,
			addSequence,
			addSequenceAtIndex,
			addDecisionNode,
			addDecisionNodeAtIndex,
			insertSequence,
			removeSequence,
			moveSequence,
			removeDecisionNode,
			duplicate,
			paste,
			clone,
			translate,
			moveContent,
			setContentParent,
		}
	})
export default model
