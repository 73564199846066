import { observable, extendObservable, computed, action } from 'mobx'
import { Camera, Vec3, Mat4 } from 'eplayer-core'
import vec3 from 'gl-vec3'
import mat4 from 'gl-mat4'

const createCamera = (props) => {
  let cam = { ...Camera(), ...props }
  cam = observable(cam)
  extendObservable(cam, {
    get scale() {
      return Vec3(this.zoom, this.zoom, 1)
    },
    get halfViewport() {
      return Vec3(-this.viewport[0] / 2, -this.viewport[1] / 2, 0)
    },
    get inverseViewMatrix() {
      let m = Mat4()
      let centeredPosition = Vec3(0, 0, 0)
      vec3.subtract(centeredPosition, this.halfViewport, this.position)
      mat4.scale(m, m, this.scale) // scale it
      mat4.translate(m, m, centeredPosition) // center position it
      return m
    },
    get viewMatrix() {
      return mat4.invert(Mat4(), this.inverseViewMatrix)
    },
    get canZoomIn() {
      return cam.zoom > 0.5
    },
    get canZoomOut() {
      return true
    },
    // Structure panel still uses the SceneGraph Camera,
    // which has reversed zoom values
    zoomIn: function() {
      cam.zoom = Math.max(cam.zoom - 0.1, 0.5)
    },
    zoomOut: function() {
      cam.zoom += 0.1
    },
    zoomTo: function(z) {
      cam.zoom = z
    },
    pan: function(dx, dy) {
      cam.position[0] += dx
      cam.position[1] += dy
    },
    moveTo: function(x, y) {
      cam.position[0] = x
      cam.position[1] = y
    },
    resize: function(w, h) {
      cam.viewport[0] = w
      cam.viewport[1] = h
    }
  }, {
    zoomIn: action,
    zoomOut: action,
    zoomTo: action,
    pan: action,
    moveTo: action,
    resize: action
  })
  return cam
}

export { createCamera }
