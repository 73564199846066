import CONFIG from './CONFIG'

export const TASK_POLL_INTERVAL = CONFIG.pollIntervals.captureTask

export const TASK_STATE = {
  ABORTED: 'ABORTED',
  DONE: 'DONE',
  ERROR: 'ERROR',
  CAPTURING: 'CAPTURING',
  PROCESSING: 'PROCESSING',
  INITIALIZING: 'INITIALIZING',
  QUEUED: 'QUEUED',
}


