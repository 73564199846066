import React from 'react'
import ModalWrapper from '../../containers/ModalWrapper'
import { noFirstSpace } from '../../utils'
export default class NewEditionModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // Can these be converted to props? Look to fix - see notes from new_modals_1810 merge
      editionName: '',
      isNameValid: true,
      isDisabled: false
    }
  }
  setName = (e) => {
    this.setState({ editionName: noFirstSpace(e.target.value) })
  }
  clearName = (e) => {
    this.setState({ editionName: '' })
  }
  createEdition = () => {
    const { app, cancel } = this.props
    const isNameValid = this.state.editionName !== ''
    if (isNameValid) {
      // comments and version type selection are required
      this.setState({ isNameValid: true })
      this.setState({ isDiabled: true })
      app.newEdition(this.state.editionName)
      cancel()
    } else {
      this.setState({ isNameValid: isNameValid })
    }
  }
  render() {
    return (
      <div>
        <ModalWrapper
          title="Create New Edition"
          hideModal={this.props.cancel}
          width="700px"
          height="auto"
          modaltype="popup">
          <div className="newedition-wrap">
            <label>Create a New Edition</label>
            <div className="edition-name-block">
              <input
                type="text"
                maxLength={128}
                className="edition-name"
                placeholder="Name Your edition"
                onChange={this.setName}
                value={this.state.editionName}
                data-qa-hook="modalInput.editionName"
              />
              <input type="button" className="clear-name" onClick={this.clearName} data-qa-hook="modalButton.clearName"/>
            </div>
            <label className="validation">
              <span className={this.state.isNameValid ? `hidden` : ``}>
                Edition name is required. Maximum of 128 characters.
              </span>
            </label>
            <div className="buttons">
              <button
                className="left-option"
                onClick={this.createEdition}
                disabled={this.state.isDisabled ? 'disabled' : false}
                data-qa-hook="modalButton.createEdition">
                Create Edition
              </button>
              <button
                className="right-option"
                onClick={this.props.cancel}
                disabled={this.state.isDisabled ? 'disabled' : false}
                data-qa-hook="modalButton.cancel">
                Cancel
              </button>
            </div>
          </div>
        </ModalWrapper>
      </div>
    )
  }
}
