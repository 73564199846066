import React from 'react'
import { observer } from 'mobx-react'

function initializeFileInput(onChange) {
  const input = document.createElement('input')
  input.type = 'file'
  input.multiple = false
  input.accept = ".xls"
  input.onchange = onChange
  return input
}

class XLSInput extends React.Component {
  componentDidMount() {
    this.input = initializeFileInput(this.read)
  }
  read = (e)  => {
    this.props.onChange(e)
    this.input = initializeFileInput(this.read)
  }
  render() {
    const onClick = (e) => this.input.click()
    return (
      <button type="button" className="choose" onClick={onClick} title="Choose File" data-qa-hook="export.button.chooseXLS">
        Choose File
      </button>
    )
  }
  constructor(props) {
    super(props)
  }
}

const PatientName = observer(class PatientName extends React.Component {
  render() {
    return (
      <input
        type="text"
        id="patientFacingName"
        name="patientFacingName"
        data-qa-hook="export.input.patientfacingname"
        maxLength={128}
        value={this.props.value}
        onChange={this.props.onChange}
      />
    )
  }
})
const SelectedLanguage = observer(class SelectLanguage extends React.Component {
  render() {
    return (
      <select name='languageSelect' onChange={this.props.onChange} >
        <option value=''></option>
        <option value='en'>English</option>
        <option value='es'>Español</option>
      </select>
    )
  }
})
const ExportPanel = observer(class ExportPanel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fileName: '', // name of XLS file
      file: null, // File instance of XLS file
      errors: [], // Validation errors caught while parsing XLS file
      language: '', // The language code ('en' or 'es')
    }
  }
  setLang(lang){
    this.setState({ language: lang})
  }
  setXLSFile(file) {
    this.setState({ file })
  }
  unsetXLS() {
    this.setState({ file: null })
  }
  setFileName(fileName) {
    this.setState({ fileName })
  }
  setErrors(errors) {
    this.setState({ errors })
  }
  unsetFileState() {
    this.setState({
      fileName: '',
      file: null
    })
  }
  unsetAllState() {
    this.setState({
      errors: [],
      file: null,
      fileName: '',
      language: ''
    })
  }
  buildAndDownloadHtml() {
    const { file, fileName, language } = this.state
    this.props.html(file, fileName, language) 
  }
  buildAndDownloadDocX() {
    const { file, fileName } = this.state
    const { word } = this.props
    this.props.word(file, fileName)
  }

  render() {
    const { app, word, xls } = this.props
    const { errors, fileName, file, language } = this.state
    const disabled = !(fileName && file && language)
    const removeXLS = this.unsetXLS
    const errorList = errors.map((error, index) => (
      <li key={index} className="message">
        {error.message}
      </li>
    ))
    const back = () => {
      this.unsetAllState()
      app.route.goBack()
    }
    const nameInputParams = {
      onChange: (e) => {
        const str = e.target.value
        this.setFileName(str)
      },
      value: fileName
    }
    const xlsInputParams = {
      onChange: (e) => {
        const file = e.target.files[0]
        this.setXLSFile(file)
      }
    }
    const conversionButtonParams = {
      type: 'button',
      onClick: e => this.buildAndDownloadHtml(),
      disabled,
      className: 'conversion-button',
    }
    const docButtonParams = {
      type: 'button',
      onClick: e => this.buildAndDownloadDocX(),
      disabled,
      className: 'conversion-button'
    }
    const languageInputParams = {
      onChange: (e) => {
        this.setLang(e.target.value)
      }
    }

    return (
      <div id="import">
        <div className={`header`}>
          <h1>Export Tool</h1>
          <p>
            Use this tool to generate HTML transcripts and Word scripts for an edition.
          </p>
        </div>
        <div className="container">
          <div className="steps">
            <div className="step">
              <p className="step-text">1. Patient-Facing Edition Name:</p>
              <PatientName {...nameInputParams}/>
              <p className={`file-name ${fileName ? 'has-file' : ''}`}>
                {fileName
                  ? ''
                  : 'A patient name is required. Maximum of 128 characters.'}
              </p>
            </div>
            <div className="step">
              <p className="step-text">2. Select Language:</p>
              <SelectedLanguage {...languageInputParams} />
              <p className={`file-name ${language ? 'has-file' : ''}`}>
                {!language ? 'Please select a language.':''}
              </p>
            </div>
            <div className="step">
              <p className="step-text">3. Select an XLS file:</p>
              <XLSInput {...xlsInputParams}/>
              <p className={`file-name ${file ? 'has-file' : ''}`}>
                <i className="sprite conversion document" />
                {file ? file.name: 'No XLS file selected'}
                <button type="button" onClick={removeXLS}>
                  <i className="sprite conversion remove" />
                </button>
              </p>
            </div>
            <div className="step row">
              <button {...conversionButtonParams} data-qa-hook="export.button.htmlTranscript">Export HTML Transcript</button>
              <button {...docButtonParams} data-qa-hook="export.button.wordTranscript">Export Word Doc Transcript</button>
              <button type="button" className="cancel-button" onClick={back} data-qa-hook="export.button.cancel">
                Cancel or <span className="faux-key">ESC</span>
              </button>
            </div>
          </div>
          <div className={`errors ${errorList.length > 0 ? 'visible' : 'hidden'}`}>
            <h4>Unable to convert script due to the following {errorList.length} errors - </h4>
            <ul>{errorList}</ul>
          </div>
        </div>
      </div>
    )
  }
})

export default ExportPanel