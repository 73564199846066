import React from 'react'
import { decorate, observable } from 'mobx'
import { observer } from 'mobx-react'

class EasterEggsComponent extends React.Component {
  recordCode = (e) => {
    if (e.keyCode == 36) {
      this.acceptsCodes = !this.acceptsCodes
    } else if (e.keyCode == 109) {
      this.activeCode = ''
    } else if (e.keyCode != 109 && e.keyCode != 36 && this.acceptsCodes) {
      this.activeCode = this.activeCode + e.keyCode
    }
    this.checkCode(this.activeCode)
  }
  checkCode = (code) => {
    const { app } = this.props
    const globalState = app.state.global
    // Konami Code
    if (code == '3838404037393739666513') {
      new Audio('../images/coin_1.mp3').play()
      globalState.showAlert('success', 'Konami code accepted.')
      // reset code
      this.activeCode = ''
    }
    // Justin Bailey - Metroid
    if (code == '748583847378666573766989') {
      new Audio('../images/getitem.mp3').play()
      globalState.showAlert('success', 'Justin Bailey code accepted.')
      // reset code
      this.activeCode = ''
    }
    // Zelda - Second quest
    if (code == '9069766865') {
      new Audio('../images/zeldaSecret.mp3').play()
      // reset code
      this.activeCode = ''
    }
  }

  constructor(props) {
    super(props)
    this.activeCode = ''
    this.acceptCodes = false
    const self = this
    document.addEventListener('keydown', function(e) {
      self.recordCode(e)
    })
  }

  render() {
    return <div className="top-left-tag" />
  }
}

decorate(EasterEggsComponent, {
  activeCode: observable,
  acceptCodes: observable,
})

const EasterEggs = observer(EasterEggsComponent)

export default EasterEggs
