// Action helpers
export function setProp (key, value, ...items) {
  let [ state, field ] = key.split("/")

  if (field === undefined) {
    field = state
    state = "normal"
  }

  for (let i = 0, l = items.length; i < l; i++) {
    let item = items[i]
    "normal" === state ?
      item[field] = value :
      item.states[state][field] = value
  }
}

export function setVec (key, value, ...items) {
  let [ state, field ] = key.split("/")

  if (field === undefined) {
    field = state
    state = "normal"
  }

  for (let i = 0, l = items.length; i < l; i++) {
    let item = items[i]
    const vector = "normal" === state ?
      item[field] :
      item.states[state][field]
    for (let j = 0; j < vector.length; j++) {
      vector[j] = value[j]
    }
  }
}

export function setVecElem (key, index, value, ...items) {
  let [ state, field ] = key.split("/")

  if (field === undefined) {
    field = state
    state = "normal"
  }

  for (let i = 0, l = items.length; i < l; i++) {
    let item = items[i]
    const vector = "normal" === state ?
      item[field] :
      item.states[state][field]
    vector[index] = value
  }
}

export function setNestedObj (field1, field2, value, ...items) {
  let [ state, field ] = key.split("/")

  if (field === undefined) {
    field = state
    state = "normal"
  }

  for (let i = 0, l = items.length; i < l; i++) {
    let item = items[i]
    const f1 = "normal" === state ?
      item[field1] :
      item.states[state][field]
    f1[field2] = value
  }
}

export function walkTree (f, node, parent) {
  const { children } = node
  f(node, parent)
  let end = children.length
  for(let i = 0, c; i < end; i++) {
    c = children[i]
    walkTree(f, c, node)
  }
}

export function reduceTree (f, a, rt) {
  walkTree(node => f(a, node), rt)
}

export function strCompare (a, b) {
    if (a.toString() < b.toString()) return -1
    if (a.toString() > b.toString()) return 1
    return 0
}
