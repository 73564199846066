export const SEQUENCE_OFFSET_X = 200
export const SEQUENCE_OFFSET_Y = 200
export const CHAPTER_OFFSET_X = 1000
export const MIN_SEQUENCE_DURATION = 1
export const MAX_SEQUENCE_DURATION = 345600
export const KEYFRAME_PROPS = [
	'activeKeyframes',
	'opacityKeyframes',
	'positionKeyframes',
	'scaleKeyframes',
	'rotationKeyframes',
	'frameKeyframes',
	'canvasKeyframes',
]
