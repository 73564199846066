import { types } from 'mobx-state-tree'

export default function() {
  return types.array(
    types.union(
      require('../Button').default,
      require('../Checkbox').default,
      require('../Container').default,
      require('../Dropdown').default,
      require('../Embed').default,
      require('../Input').default,
      require('../List').default,
      require('../ListItem').default,
      require('../MenuGroup').default,
      require('../Paragraph').default,
      require('../Radio').default,
      require('../Slider').default,
      require('../Sprite').default,
      require('../Text').default,
      require('../Title').default,
      require('../Video').default,
      require('../ButtonGroup').default,
    )
  )
}

