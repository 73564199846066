import React from 'react'
import { observable, toJS } from 'mobx'
import { decorate } from 'mobx'
import { observer } from 'mobx-react'
import Input from '../components/Input'
import CodeEditorTakeover from '../components/modals/CodeEditor'

const Actions = observer(({ state, vars, ui }) => {
  const { nodes } = state.structure.selections
  const sequence = nodes.length === 1 && nodes[0].Type === 'Sequence' ? nodes[0] : null
  return (
    <div>
      {sequence ? (
        <ActionsList sequence={sequence} vars={vars} ui={ui} />
      ) : (
        <p className="note">Select a Sequence to add Actions</p>
      )}
    </div>
  )
})

const ActionsList = observer(({ sequence, vars, ui }) => {
  const actions = sequence.sortedActions
  const addAction = () => sequence.insertAction()
  const list = actions.map((action, index) => {
    return (
      <ActionNode key={action.objectId} action={action} sequence={sequence} vars={vars} ui={ui} />
    )
  })
  return (
    <div className="card-subsection">
      <button type="button" className="add-connection no-pad spacer" onClick={addAction}>
        <i className="icon add-circle" />
        Action
      </button>
      <div className="action-list">
        <SequenceEvent event={`onEnter`} sequence={sequence} vars={vars} ui={ui} />
        {list}
        <SequenceEvent event={`onExit`} sequence={sequence} vars={vars} ui={ui} />
      </div>
    </div>
  )
})

class ActionNodeComponent extends React.Component {
  constructor(props) {
    super(props)
    this.expanded = true
  }
  render() {
    const { action, sequence, vars, ui } = this.props
    const saveName = (val) => action.updateProp('name', val)
    const saveFrame = (val) => action.updateProp('frame', parseInt(val))
    const saveCodeStatement = (val) => action.updateCode(val)
    const remove = () => {
      if (window.confirm('Are you sure you want to remove this Action?')) {
        sequence.removeAction(action)
      }
    }
    const toggleCodeEditor = () => {
      ui.codeEditing = !ui.codeEditing
      ui.activeAction = action.objectId
    }
    const toggleCollapse = () => (this.expanded = !this.expanded)
    const getCodeEditorTakeover = () => {
      if (ui.codeEditing && ui.activeAction === action.objectId) {
        return (
          <CodeEditorTakeover
            title={action.name}
            statement={action.code}
            hideTakeover={toggleCodeEditor}
            onOk={saveCodeStatement}
            okText={`Save`}
            variables={toJS(vars)}
            assets={sequence.stage.children}
          />
        )
      } else {
        return null
      }
    }
    return (
      <div className="action flex-row">
        <div className="statement fill">
          <button className="remove-connection" type="button" onClick={remove}>
            <i className="icon remove" />
          </button>
          <button className="collapse-connection" type="button" onClick={toggleCollapse}>
            <i className={`icon ${this.expanded ? 'expand' : 'collapse'}`} />
          </button>
          <div className="form-row inline flex-start">
            <Input
              type="text"
              placeholder="Action Name"
              value={action.name || ''}
              onSave={saveName}
            />
          </div>
          <div style={{ display: this.expanded ? 'block' : 'none' }}>
            <hr />
            <div className="form-row inline flex-start">
              <div className="key">FRAME</div>
              <div className="key-value">
                <Input
                  type="number"
                  min={1}
                  max={sequence.duration}
                  value={action.frame || 1}
                  onSave={saveFrame}
                />
              </div>
            </div>
            <div className="code-block" onClick={toggleCodeEditor} data-qa-hook={'action.' + action.name + '.frame_' + action.frame + '.show'}>
              <div className="code-block-overlay" />
              <pre>
                <code>{action.code.string.length ? action.code.string : 'Add Action Code!'}</code>
              </pre>
            </div>
            {getCodeEditorTakeover()}
          </div>
        </div>
      </div>
    )
  }
}

decorate(ActionNodeComponent, {
  expanded: observable,
})

const ActionNode = observer(ActionNodeComponent)

class SequenceEventComponent extends React.Component {
  constructor(props) {
    super(props)
    this.expanded = true
  }
  render() {
    const { event, sequence, vars, ui } = this.props
    const statement = sequence[event]
    const saveCodeStatement = (val) => sequence.updateEvent(event, val)
    const toggleCodeEditor = () => {
      ui.codeEditing = !ui.codeEditing
      ui.activeAction = event
    }
    const toggleCollapse = () => (this.expanded = !this.expanded)

    const getCodeEditorTakeover = () => {
      if (ui.codeEditing && ui.activeAction === event) {
        return (
          <CodeEditorTakeover
            title={`Sequence ${event}`}
            statement={statement}
            hideTakeover={toggleCodeEditor}
            onOk={saveCodeStatement}
            okText={`Save`}
            variables={toJS(vars)}
            assets={sequence.stage.children}
          />
        )
      } else {
        return null
      }
    }

    return (
      <div className="action flex-row">
        <div className="statement fill">
          <button className="collapse-connection" type="button" onClick={toggleCollapse}>
            <i className={`icon ${this.expanded ? 'expand' : 'collapse'}`} />
          </button>
          <div className="form-row inline flex-start">
            <div className="value">{event}</div>
          </div>
          <div style={{ display: this.expanded ? 'block' : 'none' }}>
            <hr />
            <div className="code-block" onClick={toggleCodeEditor} data-qa-hook={'action.' + event + '.show'}>
              <div className="code-block-overlay" />
              <pre>
                <code>
                  {statement && statement.string.length ? statement.string : 'Add Action Code!'}
                </code>
              </pre>
            </div>
            {getCodeEditorTakeover()}
          </div>
        </div>
      </div>
    )
  }
}

decorate(SequenceEventComponent, {
  expanded: observable,
})

const SequenceEvent = observer(SequenceEventComponent)

export function ActionsTakeover({
  children,
  title,
  hideTakeover,
  okText,
  onOk,
  width,
  height,
  app
}) {
  const saveButtonParams = {
    type: 'button',
    onClick: onOk,
    disabled: false,
    className: 'modal-button'
  }

  return (
    <div id="loader">
      <div className="header">
        <h1>{title}</h1>
      </div>

      <div className="editions">{children}</div>

      <div className="actions">
        <button {...saveButtonParams}>Save</button>
        <button type="button" className="cancel-button" onClick={hideTakeover}>
          Cancel or <span className="faux-key">ESC</span>
        </button>
      </div>
    </div>
  )
}

export default Actions
