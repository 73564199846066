import React from 'react'
import { observer } from 'mobx-react'
import LayoutHierarchy from '../panels/LayoutHierarchy'
import LayoutIndexControl from '../panels/LayoutIndexControl'
import LayoutSearch from '../panels/LayoutSearch'

const LayoutNavigator = observer(({ state, ...rest }) => {
  const { ui } = state
  return (
    <div className="navigator">
      <LayoutIndexControl state={state} {...rest} />
      <div style={{ height: ui.navigatorHeightLayoutInner }} className="navigator-wrap">
        <LayoutSearch state={state} {...rest} />
        <LayoutHierarchy state={state} {...rest} />
      </div>
    </div>
  )
})

export default LayoutNavigator
