import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'

const DEFAULT_HEIGHT = 20
const SIZER_STYLE = {
  minHeight: DEFAULT_HEIGHT,
  overflowY: 'scroll',
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  visibility: 'hidden',
  position: 'absolute',
  top: 0
}

const TextArea = observer(class TextArea extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      focused: false,
      height: DEFAULT_HEIGHT,
      value: props.value
    }

    this.onKeyDown = this.onKeyDown.bind(this)
    this.onKeyUp = this.onKeyUp.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.setTextareaHeight = this.setTextareaHeight.bind(this)
  }
  onKeyDown(e) {
    const { onSave } = this.props
    e.stopPropagation()
    // Save on enter or tab
    if ((e.keyCode === 13 && !e.shiftKey) || e.keyCode === 9) {
      e.preventDefault()
      onSave(this.state.value)
    }
  }
  onKeyUp(e) {
    e.stopPropagation()
    this.setTextareaHeight()
  }
  onChange(e) {
    const { value } = e.target
    this.setState({ value })
  }
  onFocus(e) {
    this.setState({ focused: true })
    this.setTextareaHeight()
  }
  onBlur(e) {
    const { onSave } = this.props
    this.setState({ focused: false })
    // Save on blur
    onSave(this.state.value)
  }
  setTextareaHeight() {
    if (this.mounted) {
      const element = this.ghost
      const newHeight = element.clientHeight
      if (newHeight !== this.state.height) {
        this.setState({
          height: newHeight
        })
      }
    }
  }
  getTextField() {
    const { placeholder, className, autoName } = this.props
    const { focused, height, value } = this.state
    //const isOneLine = this.state.height <= DEFAULT_HEIGHT
    const textareaStyles = {
      height: focused ? height : DEFAULT_HEIGHT,
      overflow: 'hidden'
    }
    return (
      <div>
        <textarea
          className={className}
          title={value}
          placeholder={placeholder}
          value={value}
          style={textareaStyles}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onKeyUp={this.onKeyUp}
          onKeyDown={this.onKeyDown}
          data-qa-hook={autoName}
        />
      </div>
    )
  }
  getGhostField() {
    const { value } = this.state
    return (
      <div style={SIZER_STYLE} ref={(c) => (this.ghost = c)} aria-hidden="true">
        {value}
      </div>
    )
  }
  componentDidMount() {
    this.mounted = true
  }
  componentWillUnmount() {
    this.mounted = false
  }
  componentWillReceiveProps(newProps) {
    const { focused } = this.state
    if (!focused && newProps.value !== this.props.value) {
      this.setState({ value: String(newProps.value) })
    }
  }
  render() {
    return (
      <div className="input-wrap">
        {this.getTextField()}
        {this.getGhostField()}
      </div>
    )
  }
})

TextArea.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onSave: PropTypes.func.isRequired
}

export default TextArea
