import React from 'react'
import { observer } from 'mobx-react'

const LayoutIndexControl = observer(({ editionNavigator, state, sequence, variables }) => {
  const activeSequence = sequence
  const previous = editionNavigator.previousSequence
  const next = editionNavigator.nextSequence
  const goToNext = () => {
    state.ui.scrollControl.navigatorSelectedByIndex = true
    state.layout.setupFromNavigator(variables, next.objectId)
  }
  const goToPrev = () => {
    state.ui.scrollControl.navigatorSelectedByIndex = true
    state.layout.setupFromNavigator(variables, previous.objectId)
  }
  return (
    <div className="index-controls">
      <div className="current-sequence">
        <i className="sequence-icon" />
        <span className="current-sequence-name">
          {activeSequence.name ? activeSequence.name : 'Untitled Sequence'}
        </span>
      </div>
      <div className="switch-controls">
        <i
          className="previous"
          title={`${previous.name ? previous.name : 'Untitled Sequence'} in ${
            previous.parent ? previous.parent : 'Untitled Chapter'
          }`}
          onClick={goToPrev}
        />
        <i
          className="next"
          title={`${next.name ? next.name : 'Untitled Sequence'} in ${
            next.parent ? next.parent : 'Untitled Chapter'
          }`}
          onClick={goToNext}
        />
      </div>
    </div>
  )
})

export default LayoutIndexControl
