import React from 'react'
import { decorate, observable } from 'mobx'
import { observer } from 'mobx-react'
import { UUID } from 'eplayer-core'
import { getTimeStamp, copyToClipboard } from '../../utils'
import { EDITIONMANAGER_GRAPHICS } from './constants'
import WarningModal from '../../components/modals/Warning'
import ShareLinkModal from '../../components/modals/ShareLink'
import { ManagerBreadcrumb } from './manager-breadcrumb'

// --------------------------
// Begin Edition Details Functions and constants
// --------------------------

const ASSET_SET_DEFAULT_VALUE = { path: 'N/A : ', string: 'No Asset Set Assigned' }

function getVersionString(version) {
  const { major, minor, working } = version
  return `${major}.${minor}.${working}`
}

function getDisplayedAssetSet(list, setId) {
  const listValue = list.find((set) => {
    return set['id'] === setId
  })
  if (listValue) {
    return {
      path: listValue.assetSetPath,
      string: ` - ${listValue.dialect.description} | ${listValue.narrator.name} | ${
        listValue.narrator.gender.description
      }`.toLowerCase()
    }
  } else {
    return ASSET_SET_DEFAULT_VALUE
  }
  return assetSetValue
}

// VersionStatus const
// Generates the status bar header on the Edition Details page
const VersionStatus = observer(({ ed, username, toggleVersionStatus }) => {
  if (!ed || !ed.name) return null
  const isCheckedOut = ed.status.status === 'CHECKED_OUT'
  const isCheckedIn = ed.status.status === 'CHECKED_IN'
  const isArchived = ed.status.status === 'ARCHIVED'
  const isCheckedOutByYou = username === ed.status.checkedOutBy
  let editionStatusString = 'Undefined status'
  if (isCheckedIn) editionStatusString = 'Checked In'
  if (isArchived) editionStatusString = 'Archived'
  if (isCheckedOut && isCheckedOutByYou) editionStatusString = 'Checked Out to You'
  if (isCheckedOut && !isCheckedOutByYou)
    editionStatusString = `Checked Out to ${ed.status.checkedOutBy}`
  let colorbarClass
  if (isCheckedIn) colorbarClass = 'colorbar-open'
  if (isArchived) colorbarClass = 'colorbar-archived'
  if (isCheckedOut && isCheckedOutByYou) colorbarClass = 'colorbar-checkedouttoyou'
  if (isCheckedOut && !isCheckedOutByYou) colorbarClass = 'colorbar-checkedouttoother'
  let editionCheckInOutAction = null
  if (isCheckedIn) editionCheckInOutAction = 'Check Out'
  if (isArchived) editionCheckInOutAction = 'Check Out'
  if (isCheckedOut && isCheckedOutByYou) editionCheckInOutAction = 'Check In'
  if (isCheckedOut && !isCheckedOutByYou) editionCheckInOutAction = 'Check Out'
  let checkInDisabled = true
  if (isCheckedIn) checkInDisabled = false
  if (isArchived) checkInDisabled = true
  if (isCheckedOut && isCheckedOutByYou) checkInDisabled = false
  if (isCheckedOut && !isCheckedOutByYou) checkInDisabled = true

  return (
    <header className="editiondetails">
      <div className="edition-status">{editionStatusString}</div>
      <div className="edition-checkin">
        <button onClick={toggleVersionStatus} disabled={checkInDisabled ? 'true' : ''} data-qa-hook="button.checkInCheckOut">
          {editionCheckInOutAction}
        </button>
      </div>
      <div className={`edition-colorbar ${colorbarClass}`} />
    </header>
  )
})

// VersionSummary const
// Generates the Version summary on the Edition Details page
const VersionSummary = observer(({ ed, username, assetSet, loadLatest }) => {
  if (!ed || !ed.name) return null
  const timestamp = getTimeStamp(ed.lastUpdated)
  const createdtimestamp = getTimeStamp(ed.created)
  const date = timestamp[0]
  const time = timestamp[1]
  const createddate = createdtimestamp[0]
  const createdtime = createdtimestamp[1]
  const versionString = getVersionString(ed.version)
  const isCheckedOutByYou = username === ed.status.checkedOutBy
  return (
    <section className="edition-summary">
      <table className="edition-summary-table">
        <tbody>
          <tr>
            <td>
              <span className="edition-current-name">{ed.name}</span>
              <span>Latest Version {versionString}</span>
            </td>
            <td>
              <span>Latest Version Created: {date}</span>
              <span>By: {ed.lastUpdatedBy}</span>
            </td>
            <td>
              <span>Created: {createddate}</span>
              <span>By: {ed.createdBy}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span>Asset Set</span>
              <span className="assetset">
                <strong>{assetSet.path}</strong>
                {assetSet.string}
              </span>
            </td>
            <td>
              <button
                onClick={loadLatest}
                disabled={isCheckedOutByYou ? '' : 'true'}
                className="continue-editing" data-qa-hook="button.continueEditing">
                Continue Editing
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  )
})

// VersionHistoryListItem const
// Generates a row of the Version history table on the Edition Details page
const VersionHistoryListItem = observer(
  ({ ed, version, versionString, shareLink, createCopy, promoteVersion, requestCapture, canCaptureProgram, index }) => {
    const timestamp = getTimeStamp(ed.version.created)
    const date = timestamp[0]
    const time = timestamp[1]
    const canCaptureVersion =
      canCaptureProgram &&
      (version.major > 0 || version.minor > 0)
    const captureVersionAction = canCaptureVersion ?
      <button title="Queue a video capture" onClick={requestCapture} data-qa-hook="button.queueCapture">
        <i className="bts bt-camera" />
      </button> :
      null

    return (
      <tr data-qa-hook={`item.editionhistory.${index}`}>
        <td style={{ width: 310 }}>
          <span className="version-name" title={ed.name} data-qa-hook="item.editionhistory.edition">
            {ed.name}
          </span>
          <div className="version-actions" style={{ width: 96 }} data-qa-hook="item.editionhistory.buttons">
            { captureVersionAction}
            <button title="Create a link" onClick={shareLink} data-qa-hook="button.createALink">
              <i className="bts bt-link" />
            </button>
            <button className="copy" title="Create copy" onClick={createCopy} data-qa-hook="button.copy" />
            <button
              className="promote" data-qa-hook="button.promote"
              title={
                ed.status.status === 'CHECKED_IN'
                  ? 'Promote Version'
                  : 'Editions must be checked in to be promoted'
              }
              onClick={promoteVersion}
              disabled={ed.status.status !== 'CHECKED_IN' ? true : false}
            />
          </div>
        </td>
        <td data-qa-hook="item.editionhistory.version">{versionString}</td>
        <td title={`at ${time}`} data-qa-hook="item.editionhistory.created">{date}</td>
        <td title="" data-qa-hook="item.editionhistory.createdBy">{ed.version.createdBy}</td>
        <td title={ed.version.comment} data-qa-hook="item.editionhistory.comment">{ed.version.comment}</td>
      </tr>
    )
  }
)

// --------------------------
// Begin Edition Details Propgration
// --------------------------
class EditionDetailsComponent extends React.Component {
  closeWarningModal = () => {
    this.warningModal = null
  }

  constructor(props) {
    super(props)
    this.warningModal = null
  }
  render() {
    const { props } = this
    const { app } = props
    const { className } = props
    const { onClickBreadcrumb } = props
    const { taskState } = props

    const username = app.username
    const editionManager = app.state.editionmanager
    const versionSum = editionManager.versionSummary
    const displayAssetSet = getDisplayedAssetSet(editionManager.assetSetList, versionSum.assetSetId)
    const canCaptureProgram = editionManager.eligibleForCapture

    // UI-related toggles
    const isShownClass = className
    const isSearching = editionManager.versionHistory.isSearching
    const isSearchLoaded = editionManager.versionHistory.isLoaded

    // Action handlers
    const toggleVersionStatus = () => {
      this.closeWarningModal()
      editionManager.toggleVersionStatus(versionSum)
    }
    const toggleCheckInWarningModal = () => {
      const isCheckedOutByYou = username === versionSum.status.checkedOutBy
      if (isCheckedOutByYou) {
        const warning = 'Checking in an edition will delete any personal versions of it.'
        this.warningModal = (
          <WarningModal
            cancel={this.closeWarningModal}
            continue={toggleVersionStatus}
            warning={warning}
          />
        )
      } else {
        this.closeWarningModal()
        toggleVersionStatus()
      }
    }
    const loadLatest = () => {
      this.closeWarningModal()
      app.loadVersionedEdition(versionSum.editionId, getVersionString(versionSum.version), true)
    }
    const loadVersion = (editionVersion) => {
      this.closeWarningModal()
      app.loadVersionedEdition(
        editionVersion.editionId,
        getVersionString(editionVersion.version),
        false
      )
    }
    const promoteVersion = (editionVersion) => {
      this.closeWarningModal()
      editionManager.promoteVersion(
        editionVersion,
        getVersionString(editionVersion.version),
        'working'
      )
    }
    const createCopy = (editionVersion) => {
      const warning =
        'This will open an unsaved copy of ' +
        editionVersion.name +
        ' version ' +
        getVersionString(editionVersion.version) +
        '.'
      this.warningModal = (
        <WarningModal
          cancel={this.closeWarningModal}
          continue={() => loadVersion(editionVersion)}
          warning={warning}
        />
      )
    }
    const shareLink = (editionVersion) => {
      this.warningModal = (
        <ShareLinkModal
          cancel={this.closeWarningModal}
          continue={(data) => {
            continueShareLinkModal(data, editionVersion)
          }}
        />
      )
    }
    const promoteThisVersion = (editionVersion) => {
      const warning =
        'This will promote ' +
        editionVersion.name +
        ' version ' +
        getVersionString(editionVersion.version) +
        ' to become the head version.'
      this.warningModal = (
        <WarningModal
          cancel={this.closeWarningModal}
          continue={() => promoteVersion(editionVersion)}
          warning={warning}
        />
      )
    }
    const continueShareLinkModal = (data, editionVersion) => {
      let shareData = {
        advisor: data.name,
        createdBy: username,
        editionId: editionVersion.editionId,
        editionName: editionVersion.name,
        editionVersion: getVersionString(editionVersion.version),
        lastUpdatedBy: username,
        link: '',
        linkExpiration: data.linkInputExpiration,
        notes: data.notes,
        token: UUID()
      }
      const lang = 'EN'
      const link = editionManager.advisorLinks.getShareLinkUrl(shareData.token, app.advisorUrl, lang)
      copyToClipboard(link)
      editionManager.saveShareLink(shareData).then(() => {
        this.closeWarningModal()

        app.state.global.showAlert('success', ` Link has been copied to your clipboard.`)
      })
    }
    // Warning Modal
    const getWarningModal = () => this.warningModal

    // VersionHistoryList const
    // Generates version history table from observables
    const VersionHistoryList = editionManager.versionHistory.results.map((version, index) => {
      const versionString = getVersionString(version.version)
      return (
        <VersionHistoryListItem
          key={versionString}
          index={index}
          ed={version}
          canCaptureProgram={canCaptureProgram}
          requestCapture = {() => taskState.enqueueCaptureFromEditionVersion(version)}
          version={version.version}
          versionString={versionString}
          createCopy={() => createCopy(version)}
          shareLink={() => shareLink(version)}
          promoteVersion={() => promoteThisVersion(version)}
        />
      )
    })

    return (
      <editiondetails-wrapper class={isShownClass}>
        <editiondetails-container>
          {getWarningModal()}
          <ManagerBreadcrumb onClick={onClickBreadcrumb}/>
          <VersionStatus
            ed={versionSum}
            username={username}
            toggleVersionStatus={toggleCheckInWarningModal}
          />
          <VersionSummary
            ed={versionSum}
            username={username}
            assetSet={displayAssetSet}
            loadLatest={loadLatest}
          />
          <section
            className={isSearching ? `search-results-loading` : `search-results-loading hide`}>
            <img src={EDITIONMANAGER_GRAPHICS.jellyfish} /> Searching...
          </section>
          <section className={isSearchLoaded ? `search-results` : `search-results hide`}>
            <table className="editionhistory">
              <thead>
                <tr>
                  <th style={{ width: 310 }}>Edition Name</th>
                  <th>Version</th>
                  <th>Created</th>
                  <th>Created By</th>
                  <th>Version Notes</th>
                </tr>
              </thead>
            </table>
            <div className="search-results-body overflow">
              <table className="search-results-table editionhistory">
                <tbody>{VersionHistoryList}</tbody>
              </table>
            </div>
          </section>
        </editiondetails-container>
      </editiondetails-wrapper>
    )
  }
}

decorate(EditionDetailsComponent, {
  warningModal: observable.ref,
})

const EditionDetails = observer(EditionDetailsComponent)

export default EditionDetails
