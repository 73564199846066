import { observable, extendObservable, computed, action } from 'mobx'
import { DOMCamera, Vec3, Mat4 } from 'eplayer-core'
import vec3 from 'gl-vec3'
import mat4 from 'gl-mat4'

const createDOMCamera = (props) => {
  let cam = { ...DOMCamera(), ...props }
  cam = observable(cam)
  extendObservable(cam, {
    get scale() {
      return Vec3(this.zoom, this.zoom, 1)
    },
    get viewMatrix() {
      const m = Mat4()
      const transformOrigin = Vec3(
        this.viewport[0] * this.origin[0],
        this.viewport[1] * this.origin[1],
        0
      )
      const translationOrigin = Vec3(0, 0, 0)
      vec3.negate(translationOrigin, transformOrigin)
      mat4.translate(m, m, this.position)
      mat4.translate(m, m, transformOrigin)
      mat4.scale(m, m, this.scale)
      mat4.translate(m, m, translationOrigin)
      return m
    },
    get inverseViewMatrix() {
      return mat4.invert(Mat4(), this.viewMatrix)
    },
    get canZoomIn() {
      return cam.zoom < 3
    },
    get canZoomOut() {
      return cam.zoom > 0.25
    },
    zoomIn: function() {
      cam.zoom = Math.min(cam.zoom + 0.05, 3)
    },
    zoomOut: function() {
      cam.zoom = Math.max(cam.zoom - 0.05, 0.25)
    },
    zoomTo: function(z) {
      cam.zoom = z
    },
    pan: function(dx, dy) {
      cam.position[0] += dx
      cam.position[1] += dy
    },
    moveTo: function(x, y) {
      cam.position[0] = x
      cam.position[1] = y
    },
    resize: function(w, h) {
      cam.viewport[0] = w
      cam.viewport[1] = h
    }
  }, {
    zoomIn: action,
    zoomOut: action,
    zoomTo: action,
    pan: action,
    moveTo: action,
    resize: action
  })
  return cam
}

export { createDOMCamera }
