import { observable, action, reaction, toJS, computed } from 'mobx'
import {
  getLegacyEditionList,
  getEditionList,
  getCheckedOutList,
  saveLinkExpiration,
  getAdvisorLinks,
  saveSharableLink,
  getAssetSets,
  getEditionSearch,
  getVersionHistoryList,
  getVersionSummary,
  checkInEdition,
  checkOutEdition,
  discardEdition,
  promoteVersion,
  getCaptureCandidancy
} from '../../../actions/remote'
import EditionSearch from './EditionSearch'
import EditionVersionHistory from './EditionVersionHistory'
import AdvisorLinkSearch from './AdvisorLinkSearch'
import AdvisorLink from './AdvisorLink'
import FileList from '../../FileList'
import { getVersionString } from '../../../utils'
import { MANAGER_ROUTES } from '../../../constants'
import format from 'date-fns/format'

export default function EditionManagerState(app) {
  return observable({
    subroute: {
      current: MANAGER_ROUTES.QUEUE,
    },
    assetSetList: [],
    // Homepage (TODO: move to sub-object)
    recentExpanded: true,
    legacyFileList: FileList(app),
    personalFileList: FileList(app),
    currentlyCheckedOut: [], // FileList?
    search: EditionSearch(),
    searchMoreEl: null,
    //////////////////////////////////////
    // Details (TODO: move to sub-object)
    versionEditionId: null,
    versionSummary: {}, // if done this way Mobx won't monitor anything within
    versionHistory: EditionVersionHistory(),
    eligibleForCapture: false,
    ///////////////////////////////////////
    // Advisor links
    advisorLinks: AdvisorLinkSearch(),
    //////////////////////////////////////
    // TODO: this isn't really an action per se (and should probably be moved to the Homepage component itself)
    onScroll: function(e) {
      if (
        this.subroute.current !== 'Homepage' ||
        !this.searchMoreEl ||
        e.target.id !== 'editionmanager'
      ) {
        if (this.subroute.current === 'AdvisorLink' && this.advisorLinks.searchMoreEl) {
          let currentposition =
            window.innerHeight - this.advisorLinks.searchMoreEl.getBoundingClientRect().top
          if (currentposition >= 24) {
            this.advisorLinks.getMoreSearchResults()
          }
        }
        return
      }
      let currentposition = window.innerHeight - this.searchMoreEl.getBoundingClientRect().top
      if (currentposition >= 24) {
        this.getMoreSearchResults()
      }
    },
    // Actions
    showHomepage: async function() {
      const { setLoading } = app.state.global
      setLoading(true)
      this.personalFileList.resetState()
      this.subroute.current = MANAGER_ROUTES.HOMEPAGE
      await this.getEditionList()
      await this.getAssetSetList()
      setLoading(false)
    },
    showTaskQueue: async function() {
      const { setLoading } = app.state.global
      setLoading(true)
      this.subroute.current = MANAGER_ROUTES.QUEUE
      setLoading(false)
    },
    showEditionDetails: async function(id = this.versionEditionId) {
      const { setLoading } = app.state.global
      setLoading(true)
      this.versionEditionId = id
      this.versionSummary = {} // TODO: handle this better
      this.versionHistory.clearResults()
      this.eligibleForCapture = false
      await this.getVersionSummary(id)
      await this.getVersionHistoryList(id)
      await this.getCaptureCandidancy(this.versionEditionId, this.versionSummary.assetSetPath)
      setLoading(false)
      this.subroute.current = 'Details'
    },
    showAdvisorDetails: async function() {
      const { setLoading } = app.state.global
      setLoading(true)
      this.advisorLinks.clearResults()
      await this.getAdvisorLinks()
      this.subroute.current = 'AdvisorLink'
      setLoading(false)
    },
    saveShareLink: async function(data) {
      const { setLoading } = app.state.global
      setLoading(true)
      await saveSharableLink(data)
      setLoading(false)
    },
    showLegacyLoader: async function() {
      const { setLoading } = app.state.global
      setLoading(true)
      this.legacyFileList.resetState()
      this.subroute.current = 'Loader'
      await this.getLegacyEditionList()
      setLoading(false)
    },
    setSearchMoreEl: function(el) {
      this.searchMoreEl = el
    },
    toggleRecentExpand: function() {
      this.recentExpanded = !this.recentExpanded
    },
    setCheckedOutList: function(editionList) {
      this.currentlyCheckedOut.replace(editionList)
    },
    clearCheckedOutList: function(editionList) {
      this.currentlyCheckedOut.clear()
    },
    setAssetSetList: function(assetSetList) {
      this.assetSetList.replace(assetSetList)
    },
    toggleVersionStatus: function(ed) {
      const status = ed.status.status
      if (status === 'CHECKED_OUT') {
        this.checkInEdition(ed.editionId)
      } else if (status === 'CHECKED_IN') {
        this.checkOutEdition(ed.editionId)
      }
    },
    // Remote Actions moved from App.js
    getLegacyEditionList: async function() {
      const em = this
      try {
        const editionList = await getLegacyEditionList()
        em.legacyFileList.clearSelection()
        em.legacyFileList.setEditionList(editionList)
      } catch (err) {
        app.throwError(err.message)
      }
    },
    getEditionList: async function() {
      const em = this
      try {
        const editionList = await getEditionList()
        const checkedOutList = await getCheckedOutList()
        em.personalFileList.clearSelection()
        em.personalFileList.setEditionList(editionList)
        if (checkedOutList.content) em.setCheckedOutList(checkedOutList.content)
        else em.clearCheckedOutList()
      } catch (err) {
        app.throwError(err.message)
      }
    },
    getAssetSetList: async function() {
      const em = this
      const { showAlert } = app.state.global
      try {
        const list = getAssetSets()
        em.setAssetSetList(await list)
      } catch (err) {
        showAlert('error', `Unable to retrieve asset sets.`) // TODO: which of these should we use?
        app.throwError(err.message)
      }
    },
    checkOutEdition: async function(id) {
      const em = this
      const { showAlert } = app.state.global
      try {
        const checkOut = await checkOutEdition(id)
        em.versionSummary = await getVersionSummary(id)
        em.getVersionHistoryList(id)
        showAlert('success', `Checked Out.`)
      } catch (err) {
        showAlert('error', `Unable to check out.`) // TODO: which of these should we use?
        app.throwError(err.message)
      }
    },
    checkInEdition: async function(id) {
      const em = this
      const { showAlert } = app.state.global
      try {
        const checkIn = await checkInEdition(id)
        em.versionSummary = await getVersionSummary(id)
        em.getVersionHistoryList(id)
        showAlert('success', `Checked In.`)
      } catch (err) {
        showAlert('error', `Unable to check in.`) // TODO: which of these should we use?
        app.throwError(err.message)
      }
    },
    getVersionHistoryList: async function(edition) {
      const em = this
      em.versionHistory.setSearching()
      try {
        const versionList = await getVersionHistoryList(edition)
        if (versionList.content) em.versionHistory.setResults(versionList.content)
        else em.versionHistory.clearResults()
      } catch (err) {
        app.throwError(err.message)
      }
      em.versionHistory.setLoaded()
    },
    getVersionSummary: async function(edition) {
      const em = this
      try {
        em.versionSummary = await getVersionSummary(edition)
      } catch (err) {
        app.throwError(err.message)
      }
    },
    getCaptureCandidancy: async function(editionId, editionAssetSetPath) {
      this.eligibleForCapture = await getCaptureCandidancy(editionId, editionAssetSetPath)
    },
    getAdvisorLinks: async function() {
      const em = this
      // Gets Edition Search results for Edition Manager Homepage
      em.advisorLinks.setPage(0)

      try {
        em.advisorLinks.setSearching()
        const editionSearch = await getAdvisorLinks(em.advisorLinks)
        em.advisorLinks.setLoaded()
        em.advisorLinks.setTotal(editionSearch.totalElements)
        if (editionSearch.content) {
          // set editionsearch results to observable
          const content = editionSearch.content.map((s) => {
            return new AdvisorLink(s)
          })
          em.advisorLinks.setResults(content)
        } else {
          // If no records returned and content is empty, clear the results array
          em.advisorLinks.clearResults()
        }
      } catch (err) {
        app.throwError(err.message)
      }
    },
    clearLinkExpiration: async function(ed) {
      const { showAlert } = app.state.global
      const em = this
      try {
        await saveLinkExpiration({ id: ed.id, linkExpiration: '' })
        ed.setLinkExpiration('')
        showAlert('success', ' Date successfully changed! ')
      } catch (err) {
        showAlert('error', ' Could not update the expiration date')
      }
    },
    saveLinkExpiration: async function(ed) {
      const { showAlert } = app.state.global
      const em = this

      try {
        if (!ed.linkInputExpiration) {
          return false
        }
        

        if (!ed.isExpirationDateComplete || !ed.isExpirationDateFormatValid) {
          showAlert('error', 'Please enter a valid date.')
          return false
        }

        if (!ed.isExpirationDateAWeekPast) {
          showAlert('error', ' Cant set date to more than a week in the past ')
          return false
        }

        if (!ed.isExpirationDateYearLater) {
          showAlert('error', 'Cant set date to more than a year from today')
          return false
        }

        await saveLinkExpiration({ id: ed.id, linkExpiration: ed.linkInputExpiration })
        // TODO: what does `saveLinkExpiration` above return? Should we use that to set the value here?
        ed.setLinkExpiration(ed.linkInputExpiration)
        showAlert('success', ' Date successfully changed! ')
      } catch (err) {
        showAlert('error', ' Could not update the expiration date')
      }
    },
    // TODO: this can probably just be moved to the sub-object
    getEditionSearch: async function() {
      const em = this
      // Gets Edition Search results for Edition Manager Homepage
      em.search.setPage(0)
      if (em.search.value === '') {
        em.search.setIdle()
      } else {
        try {
          em.search.setSearching()
          const editionSearch = await getEditionSearch(em.search)
          em.search.setLoaded()
          em.search.setTotal(editionSearch.totalElements)
          if (editionSearch.content) {
            // set editionsearch results to observable
            em.search.setResults(editionSearch.content)
          } else {
            // If no records returned and content is empty, clear the results array
            em.search.clearResults()
          }
        } catch (err) {
          app.throwError(err.message)
        }
      }
    },
    // TODO: this can probably just be moved to the sub-object
    getMoreSearchResults: async function() {
      const em = this
      // Gets Edition Search results for Edition Manager Homepage
      try {
        em.search.activepage++
        const editionSearch = await getEditionSearch(em.search)
        if (editionSearch.content) {
          em.search.addResults(editionSearch.content)
        } else {
          // What should happen here? Display "This is everything" or whatever?
        }
      } catch (err) {
        app.throwError(err.message)
      }
    },
    promoteVersion: async function(edition, version, type) {
      const { setLoading, showAlert } = app.state.global
      const em = this
      setLoading(true)
      try {
        const resp = await promoteVersion(edition.editionId, version, type)
        const getV = await em.getVersionSummary(edition.editionId)
        const getVHist = await em.getVersionHistoryList(edition.editionId)
        showAlert(
          'success',
          `${edition.name} version ${getVersionString(edition.version)} promoted.`
        )
      } catch (err) {
        showAlert('error', `unable to promote ${edition.name}.`)
      }
      setLoading(false)
    },
    discardEdition: async function(edition) {
      const { setLoading, showAlert } = app.state.global
      const em = this
      setLoading(true)
      try {
        const resp = await discardEdition(edition.objectId)
        const edList = await em.getEditionList()
        showAlert('success', `${edition.name} has been discarded.`)
      } catch (err) {
        showAlert('error', `unable to discard personal versions for ${edition.name}.`)
      }
      setLoading(false)
    },
    revertEdition: async function(edition) {
      const { setLoading, showAlert } = app.state.global
      setLoading(true)
      try {
        const resp = await discardEdition(edition.objectId)
        app.loadVersionedEdition(edition.objectId, '0.0.0', true) // Version number doesn't matter since we're using getLatest
        // Don't need an alert here since the loadVersionedEdition alert will now show version number
      } catch (err) {
        showAlert('error', `unable to revert this edition.`)
      }
      setLoading(false)
    }
  }, {
    assetSetList: observable.shallow,
    currentlyCheckedOut: observable.shallow,
    searchMoreEl: observable.ref,
    versionEditionId: observable.ref,
    onScroll: action.bound,
    showHomepage: action.bound,
    showEditionDetails: action.bound,
    showAdvisorDetails: action.bound,
    saveShareLink: action.bound,
    showLegacyLoader: action.bound,
    setSearchMoreEl: action.bound,
    toggleRecentExpand: action.bound,
    setCheckedOutList: action.bound,
    clearCheckedOutList: action.bound,
    setAssetSetList: action.bound,
    toggleVersionStatus: action.bound,
    getLegacyEditionList: action,
    getEditionList: action,
    getAssetSetList: action,
    checkOutEdition: action,
    checkInEdition: action,
    getVersionHistoryList: action,
    getVersionSummary: action,
    getAdvisorLinks: action,
    clearLinkExpiration: action,
    saveLinkExpiration: action,
    getEditionSearch: action,
    getMoreSearchResults: action,
    promoteVersion: action,
    discardEdition: action,
    revertEdition: action
  })
}
