import { types } from 'mobx-state-tree'
import { Asset } from 'eplayer-core'
import { RGBA } from 'eplayer-core'
import { ButtonGroupTemplate } from '../Template'
import { Fill } from './shared/props'
import { Layerable } from './shared/props'
import { Rectangle } from './shared/props'
import { Stroke } from './shared/props'
import { Transformable } from './shared/props'
import { Visible } from './shared/props'
import { UI } from './shared/props'
import assetActions from './shared/actions'
import assetViews from './shared/views'

const AssetType = 'ButtonGroup'

const childDefaults = {
  backgroundColor: RGBA(0, 122, 195, 1), // #007ac3
  borderRaidus: 4,
  boxShadowColor: RGBA(0, 0, 0, 0.8),
  boxShadowX: 6,
  boxShadowY: 6,
  fontColor: RGBA(255, 255, 255, 1), // #FFFFFF
  fontSize: 50,
  fontWeight: 500,
  height: 120,
  textAlign: 'center',
  width: 411,
}

const childHoverDefaults = {
  backgroundColor: RGBA(64, 155, 210, 1), // #409BD2
  boxShadowColor: RGBA(0, 0, 0, 0.8),
  boxShadowX: 8,
  boxShadowY: 8,
  boxShadowBlur: 10,
}

const childActiveDefaults = {
  backgroundColor: RGBA(0, 91, 146, 1),
  boxShadowColor: RGBA(0, 0, 0, 0),
  boxShadowX: 8,
  boxShadowY: 8,
  boxShadowBlur: 10,
}

const childDisabledDefaults = {
  fontColor: RGBA(128, 189, 225, 1), // #80BDE1
}

export default types
  .model(AssetType, {
    Type: types.literal(AssetType),
    objectId: types.identifier,
    name: types.string,
    children: types.late(() => types.array(require('./Button').default)),
    gutterSize: types.integer,
    labeledBy: types.string,
    ...Layerable,
    ...Fill,
    ...Stroke,
    ...Rectangle,
    ...Visible,
    ...Transformable,
    ...UI,
  })
  .views(assetViews)
  .actions(self => {
    return {
      ...assetActions(self),
      addChild(nextChild) {
        const gapWidth = self.gutterSize
        const nowNumChild = self.children.length 

        if (nextChild == null) {
          const Button = require('./Button')
          nextChild = Button.default.create(Asset.Button.withDefaults({ name: 'Untitled Button' }))
        }

        if (nextChild.Type !== 'Button') return
        else if (nowNumChild === 3) return

        for (const key of Object.keys(childDefaults)) {
          nextChild.updateProp(key, childDefaults[key])
          nextChild.updateModalProp('active', key, childDefaults[key])
          nextChild.updateModalProp('disabled', key, childDefaults[key])
          nextChild.updateModalProp('hover', key, childDefaults[key])
        }
        for (const key of Object.keys(childActiveDefaults)) {
          nextChild.updateModalProp('active', key, childActiveDefaults[key])
        }
        for (const key of Object.keys(childDisabledDefaults)) {
          nextChild.updateModalProp('disabled', key, childDisabledDefaults[key])
        }
        for (const key of Object.keys(childHoverDefaults)) {
          nextChild.updateModalProp('hover', key, childHoverDefaults[key])
        }

        const width = nextChild.width
        const height = nextChild.height
        const groupWidth = self.children.reduceRight(
          (gW, child) => gW += child.width + gapWidth,
          width)

        if (height > self.height) self.updateProp('height', height)
        self.updateProp('width', groupWidth)
        self.children.push(nextChild)
      },
      removeChild(child) {
        const { children } = self
        const { gutterSize: gapWidth } = self
        const nextNumChild = children.length - 1

        children.remove(child)
        if (nextNumChild === 0) self.updateProp('width', 245)
        else if (nextNumChild === 1) self.updateProp('width', children[0].width)
        else self.updateProp('width', children.slice(1).reduceRight(
          (w, child) => w += child.width + gapWidth,
          children[0].width))
      },
      acceptsDrop() {
        return false
      }
    }
  })
