import { observable, extendObservable, computed, action, observe, reaction, toJS } from 'mobx'
import ViewportState from './Viewport'
import { Vec3 } from 'eplayer-core'
import vec3 from 'gl-vec3'
import { createCamera } from '../../Camera'
import { STRUCTURE_MOUSE_MODES } from '../../../constants'
import { connectionsWhere } from '../../../models/queries'

export default function StructureState(app) {
  // Camera
  const camera = createCamera({ zoom: 3 })
  // Selections
  const selections = observable({
    nodes: [],
    get toJS() {
      return {
        nodes: this.nodes.map((node) => node.objectId)
      }
    },
    get hasAny() {
      return this.nodes.length
    },
    get hasOne() {
      return this.nodes.length === 1
    },
    get first() {
      return this.nodes[0]
    },
    get isSingleSequence() {
      return this.hasOne && this.first.Type === 'Sequence'
    },
    get isSingleDecisionNode() {
      return this.hasOne && this.first.Type === 'Sequence'
    },
    get singleSequence() {
      const { isSingleSequence } = this
      return isSingleSequence && this.first || null
    },
    get singleDecisionNode() {
      const { hasOne } = this
      const { first } = this
      const { isSingleDecisionNode } = this  
      return (hasOne && isSingleDecisionNode && first) || null
    },
    get decisionNodes() {
      const { hasAny } = this
      const { nodes } = this
      return hasAny && nodes.filter(node => "DecisionNode" === node.Type)
    },
    get canDuplicate() {
      if (!this.hasAny) return false
      this.nodes.forEach((selection) => {
        // Refactored from the old code; can we not duplicate DecisionNodes?
        if (selection.Type !== 'Chapter' && selection.Type !== 'Sequence') {
          return false
        }
      })
      return true
    },
    get sequences() {
      return this.nodes.filter(node => "Sequence" === node.Type)
    },
    fromJS: function(selectionsObject) {
      const edition = app.edition
      const restoredNodes = connectionsWhere((n) => {
        if (selectionsObject.nodes.includes(n.objectId)) return true
        return false
      }, edition)
      this.nodes.replace(restoredNodes)
    },
    setSelection: function(node, shiftKey) {
      const { ui } = app.state
      if (!node) {
        this.clearSelection()
        return
      }
      if (shiftKey) {
        this.nodes.includes(node) ? this.nodes.remove(node) : this.nodes.push(node)
      } else this.nodes.replace([node])
      if (this.nodes.length === 1 && (node.Type === 'Sequence' || node.Type === 'Chapter')) {
        window.requestAnimationFrame(function() {
          if (ui.scrollControl.scriptPanelSelectedEl) {
            ui.scrollControl.scriptPanelSelectedEl.scrollIntoView({ inline: 'center' })
          } else {
            /* No behavior for other scenarios */
          }
        })
      }
    },
    removeSelection: function(node) {
      if (!node) return
      this.nodes.remove(node)
    },
    clearSelection: function() {
      this.nodes.clear()
    },
    duplicateSelected: function() {
      this.nodes.forEach((selection) => {
        if (
          selection.Type === 'Chapter' ||
          selection.Type === 'Sequence' ||
          selection.Type === 'DecisionNode'
        ) {
          selection.duplicate()
        }
      })
    },
    deleteSelected: function() {
      this.nodes.forEach((node) => {
        const { Type } = node
        if (Type === 'Chapter') node.parent.removeChapter(node)
        if (Type === 'Sequence') node.parent.removeSequence(node)
        if (Type === 'DecisionNode') node.parent.removeDecisionNode(node) // Note: decisionNodes can be on Chapters or Editions
      })
      this.clearSelection()
    }
  }, {
    nodes: observable.shallow,
    fromJS: action.bound,
    setSelection: action.bound,
    removeSelection: action.bound,
    clearSelection: action.bound,
    duplicateSelected: action.bound,
    deleteSelected: action.bound
  })
  // Viewport State
  const viewport = ViewportState(app.history, camera, selections)

  // Reactions
  const reactions = observable({
    disposers: [],
    addReaction: function(reaction) {
      this.disposers.push(reaction)
    },
    disposeReactions: function() {
      this.disposers.forEach((disposer) => disposer())
    },
    handlePanelSizing: function() {
      const widgetsPanel = observe(app.state.ui.collapseState.panels, 'Widgets', (change) => {
        requestAnimationFrame(viewport.resize)
        //requestAnimationFrame(app.state.ui.resizeWindow)
      })
    }
  }, {
    addReaction: action.bound,
    disposeReactions: action.bound,
    handlePanelSizing: action.bound
  })
  const handleLayoutEntry = function(e) {
    const activeCard = app.state.ui.activeCard
    const viewportFocused = activeCard && 'Viewport' === activeCard.title
    const hasOne = selections.hasOne
    if (hasOne && viewportFocused && selections.first.Type === 'Sequence') {
      const node = selections.first
      const vars = app.edition.variables
      app.state.layout.setup(vars, node)
      app.route.goTo('Layout')
    }
  }
  // Hotkeys
  const hotKeyMap = [
    ['mod+d', 'keydown', selections.duplicateSelected],
    ['backspace', 'keyup', selections.deleteSelected],
    ['del', 'keyup', selections.deleteSelected],
    ['esc', 'keyup', selections.clearSelection],
    ['=', 'keydown', viewport.zoomIn],
    ['-', 'keydown', viewport.zoomOut],
    ['mod+0', 'keydown', action(() => camera.zoomTo(3))],
    ['mod+1', 'keydown', action(() => camera.moveTo(0, 0))],
    ['enter', 'keydown', handleLayoutEntry]
  ]
  const handleHotkeys = action((keyboard) => {
    for (let i = 0, binding; (binding = hotKeyMap[i]); i++) {
      const [hotkey, evt, fn] = binding
      keyboard.bind(hotkey, fn, evt)
    }
  })
  const disposeHotkeys = action((keyboard) => {
    for (let i = 0, binding; (binding = hotKeyMap[i]); i++) {
      const [hotkey, evt, fn] = binding
      keyboard.unbind(hotkey, evt)
    }
  })
  const reset = action(() => {
    // Note: for now nothing from camera or viewport needs to be reset
    selections.clearSelection()
  })
  const enter = action(() => {
    reactions.handlePanelSizing()
    handleHotkeys(app.signals.keyboard)
  })
  const exit = action(() => {
    reactions.disposeReactions()
    disposeHotkeys(app.signals.keyboard)
  })
  return { camera, selections, viewport, reset, enter, exit }
}
