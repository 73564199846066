import React from 'react'
import { observer } from 'mobx-react'
import { ManagerAction } from './manager-action'
import { ManagerBreadcrumb } from './manager-breadcrumb'
import { ManagerHeader, HEADER_GRADIENT } from './manager-header'
import { ManagerSearchField } from './manager-search'
import { ManagerTableHeader } from './manager-table'
import { ManagerTableData } from './manager-table'
import { ManagerTable } from './manager-table'

export const RemoteTaskQueue = observer(function remoteTaskQueue({ className: visibleState, onClickBreadcrumb, scheduler, taskSearch, visibility }) {
  const isFilterDisabled = taskSearch.isInputDisabled
  const isFilterValAll = taskSearch.isAllUserFilterSelected
  const isFilterValUser = taskSearch.isUserFilterSelected
  const isSearching = taskSearch.isLoading
  const nextPage = taskSearch.nextPage
  const onClickFilterAll = isFilterValAll ? undefined : taskSearch.updateFilterAll
  const onClickFilterUser = isFilterValUser ? undefined : taskSearch.updateFilterUser
  const titleFilterAll = "Show all tasks"
  const titleFilterUser = "Only show tasks you created"
  const rowData = taskSearch.visible
  const columns  = [
    <ManagerTableHeader activeSort={taskSearch.isActiveSortProp} canSort={taskSearch.canSortProp} isSortDesc={taskSearch.isActiveSortDesc} onToggleSort={taskSearch.updateSort} style={{ width: 80 }} key='manager.tasks.column.position' autoHook='manager.tasks.column' >
      Position
    </ManagerTableHeader>,
    <ManagerTableHeader activeSort={taskSearch.isActiveSortProp} canSort={taskSearch.canSortProp} isSortDesc={taskSearch.isActiveSortDesc} onToggleSort={taskSearch.updateSort} style={{ width: 200, textOverflow: 'ellipsis' }} key='manager.tasks.column.name' autoHook='manager.tasks.column'>
      Name
    </ManagerTableHeader>,
    <ManagerTableHeader activeSort={taskSearch.isActiveSortProp} canSort={taskSearch.canSortProp} isSortDesc={taskSearch.isActiveSortDesc} onToggleSort={taskSearch.updateSort} style={{ width: 100 }} key='manager.tasks.column.version' autoHook='manager.tasks.column'>
      Version
    </ManagerTableHeader>,
    <ManagerTableHeader activeSort={taskSearch.isActiveSortProp} canSort={taskSearch.canSortProp} isSortDesc={taskSearch.isActiveSortDesc} onToggleSort={taskSearch.updateSort} style={{ width: 180 }} key='manager.tasks.column.status' autoHook='manager.tasks.column'>
      Status
    </ManagerTableHeader>,
    <ManagerTableHeader activeSort={taskSearch.isActiveSortProp} canSort={taskSearch.canSortProp} isSortDesc={taskSearch.isActiveSortDesc} onToggleSort={taskSearch.updateSort} style={{ width: 180 }} key='manager.tasks.column.author' autoHook='manager.tasks.column'>
      Author
    </ManagerTableHeader>,
    <ManagerTableHeader activeSort={taskSearch.isActiveSortProp} canSort={taskSearch.canSortProp} isSortDesc={taskSearch.isActiveSortDesc} onToggleSort={taskSearch.updateSort} style={{ width: 180 }} key='manager.tasks.column.abort' autoHook='manager.tasks.column'>
      Abort
    </ManagerTableHeader>,
  ]
  const rows = rowData.map((data, index) => {
    const abortable = data.isAbortable
    const confirmAbort = abortable ? data.abort: null
    const abortEntry = abortable ?
      <button onClick={confirmAbort} className="manager-table-data__abort" data-qa-hook="capture-task.button.abort">Abort</button> :
      null
    const vDom =
      <tr key={data.id} className="manager-table-row" data-qa-hook={`item.capture-task.${index}`}>
        <ManagerTableData style={{ paddingRight: 5, width: 80 }} testId="capture-task.position">
          {data.position}
        </ManagerTableData>
        <ManagerTableData style={{ width: 200 }} testId="capture-task.program">
          {data.name}
        </ManagerTableData>
        <ManagerTableData style={{ width: 100 }} testId="capture-task.version">
          {`${data.version.major}.${data.version.minor}.${data.version.working}`}
        </ManagerTableData>
        <ManagerTableData style={{ width: 180 }} testId="capture-task.status">
          {data.status}
        </ManagerTableData>
        <ManagerTableData style={{ width: 180 }} testId="capture-task.creator">
          {data.creator}
        </ManagerTableData>
        <ManagerTableData style={{ width: 180 }}>
          {abortEntry}
        </ManagerTableData>
      </tr>

    return vDom
  })
  
  const tableProps = {
    columns,
    displayWithSearch: false,
    isSearching,
    nextPage,
    rows,
    visibility,
    onUnmount: taskSearch.reset,
  }

  const vDom =
    <editiondetails-wrapper class={visibleState}>
      <editiondetails-container>

        <ManagerBreadcrumb onClick={onClickBreadcrumb} />

        <ManagerHeader title="Video Capture Queue" gradient={HEADER_GRADIENT.LIGHTBLUE_BLUE}></ManagerHeader>

        <ManagerSearchField scheduler={scheduler} searchValue={taskSearch.updateSearch} onResetSearch={taskSearch.resetSearch} onUpdateSearch={taskSearch.updateSearch}>
          <ManagerAction selected={isFilterValAll} onClick={onClickFilterAll} disabled={isFilterDisabled} title={titleFilterAll}>
            All Tasks
          </ManagerAction>
          <ManagerAction selected={isFilterValUser} onClick={onClickFilterUser} disabled={isFilterDisabled} title={titleFilterUser}>
            Your Tasks
          </ManagerAction>
        </ManagerSearchField>

        <ManagerTable {...tableProps}></ManagerTable>

      </editiondetails-container>
    </editiondetails-wrapper>

  return vDom
})
