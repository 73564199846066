import React from 'react'
import { observer } from 'mobx-react'
import ScriptInspector from '../panels/Script'
import Media, { BatchDeleteModal, UploadFileModal } from '../panels/Media'
import { NewTemplateModal, UpdateTemplateModal } from '../panels/Media'
import Widgets from '../panels/LayoutWidgets'
import Variables from '../panels/Variables'
import Actions from '../panels/Actions'
import LayoutNavigator from '../panels/LayoutNavigator'
import LayoutDetails from '../panels/LayoutDetails'
import LayoutViewport from '../panels/LayoutViewport'
import LayoutSelections from '../panels/LayoutSelections'
import Timeline from '../panels/Timeline'
import { Card, TimelineCard, WidgetsCard } from '../components'
import { requestAnimationFrameThrottle } from '../utils'
import NewWindow from "../components/NewWindow"

const LayoutUI = observer(class LayoutUI extends React.Component {
  resize = () => {
    const { app } = this.props
    const { state } = app
    state.ui.resizeWindow()
    state.layout.viewport.resize()
    state.timeline.resize()
  }
  setFocus = (el) => {
    if (!el) return
    // Focus the UI for keyboard control
    el.focus()
  }
  collapsePanel = (name) => {
    const { collapseState } = this.props.app.state.ui
    collapseState.toggleCollapse(name)
  }
  closeUploadModal = () => {
    const { app } = this.props
    const { state } = app
    const {  media: mediaState } = state
      mediaState.hideUploadModal();
  }

  componentDidMount() {
    const { app } = this.props
    const { state } = app
    // Handle window/viewport sizing
    requestAnimationFrameThrottle('resize', 'optimizedResize')
    window.addEventListener('optimizedResize', this.resize)
    state.layout.enter()
  }
  componentWillUnmount() {
    const { app } = this.props
    const { state } = app
    window.removeEventListener('optimizedResize', this.resize)
    state.layout.exit()
  }
  render() {
    const { app } = this.props
    const { signals, edition, state, route } = app
    const { variables } = edition
    const {
      ui,
      global: globalState,
      media: mediaState,
      script: scriptState,
      layout: layoutState,
      timeline: timelineState
    } = state
    const { spriteMedia, audioMedia, videoMedia, canvasMedia } = edition
    const { activeSequence: sequence } = layoutState.player
    const { activeCard } = ui
    const { timelineRow: { free: freeTimeline } } = ui
    // columns
    const { leftColumn, scriptColumn, viewportColumn, rightColumn, timelineRow, detailsPanel } = ui
    const { activeIndex } = leftColumn

    // cards
    const [widgetsCard, mediaCard, variablesCard, actionsCard] = leftColumn.cards
    const [scriptCard] = scriptColumn.cards
    const [viewportCard] = viewportColumn.cards
    const [hierarchyCard, detailsCard] = rightColumn.cards
    const [timelineCard] = timelineRow.cards
    const { setActiveCard, setActiveTab } = ui
    const onClickCard = (card) => (e) => setActiveCard(card)
    let leftColumnContent = null
    let rightColumnContent = null
    let scriptColumnContent = null

    // for expand/collapse and panel resize
    const { collapseState } = state.ui

    const goToSequenceId = id => {
      layoutState.setupFromNavigator(variables, id)
    }

    const onGrabberDown = (e, row) => {
      document.body.addEventListener('mousemove', onGrabberMove, false)
      document.body.addEventListener('mouseup', onGrabberUp, false)
      this.startY = e.clientY
      this.startHeight = timelineRow.height
    }
    const onGrabberMove = (e) => {
      const diff = e.clientY - this.startY
      const currentHeight = this.startHeight - diff
      timelineRow.setHeight(currentHeight)
    }
    const onGrabberUp = (e) => {
      document.body.removeEventListener('mousemove', onGrabberMove, false)
      document.body.removeEventListener('mouseup', onGrabberUp, false)
      this.startY = null
      this.startHeight = null
      timelineRow.saveHeight()
    }
    const variableCardProps = {
      activeCard: activeCard,
      card: variablesCard,
      onClick: onClickCard(variablesCard),
      name: 'Variables',
      collapsed: collapseState.panels.Variables,
      height: ui.verticalPanelHeightLayout,
      innerHeight: ui.verticalPanelHeightLayoutInner,
      collapsePanel: this.collapsePanel
    }
    const actionsCardProps = {
      activeCard: activeCard,
      card: actionsCard,
      onClick: onClickCard(actionsCard),
      name: 'Actions',
      collapsed: collapseState.panels.Actions,
      height: ui.verticalPanelHeightLayout,
      innerHeight: ui.verticalPanelHeightLayoutInner,
      collapsePanel: this.collapsePanel
    }
    const detailsCardProps = {
      activeCard: activeCard,
      card: detailsCard,
      onClick: onClickCard(detailsCard),
      name: 'Details',
      collapsed: collapseState.panels.Details,
      height: ui.verticalPanelHeightLayout,
      innerHeight: ui.verticalPanelHeightLayoutInner,
      collapsePanel: this.collapsePanel
    }
    const mediaCardProps = {
      activeCard: activeCard,
      card: mediaCard,
      onClick: onClickCard(mediaCard),
      name: 'Media',
      collapsed: collapseState.panels.Media,
      height: ui.verticalPanelHeightLayout,
      innerHeight: ui.verticalPanelHeightLayoutInner,
      collapsePanel: this.collapsePanel
    }
    const widgetsCardProps = {
      activeCard: activeCard,
      card: widgetsCard,
      onClick: onClickCard(widgetsCard),
      name: 'Widgets',
      collapsed: collapseState.panels.Widgets,
      collapsePanel: this.collapsePanel
    }
    const hierarchyCardProps = {
      activeCard: activeCard,
      card: hierarchyCard,
      onClick: onClickCard(hierarchyCard),
      name: 'Hierarchy',
      collapsed: collapseState.panels.Hierarchy,
      height: ui.verticalPanelHeightLayout,
      innerHeight: ui.verticalPanelHeightLayoutInner,
      collapsePanel: this.collapsePanel,
      layoutView: true
    }
    const viewportCardProps = {
      activeCard: activeCard,
      card: viewportCard,
      onClick: onClickCard(viewportCard),
      height: ui.verticalPanelHeightLayout,
      innerHeight: ui.verticalPanelHeightLayoutInner
    }
    const scriptCardProps = {
      activeCard: activeCard,
      card: scriptCard,
      onClick: onClickCard(scriptCard),
      name: 'Scripting',
      collapsed: collapseState.panels.Scripting,
      height: ui.verticalPanelHeightLayout,
      innerHeight: ui.verticalPanelHeightLayoutInner,
      collapsePanel: this.collapsePanel
    }
    const timelineCardProps = {
      activeCard: activeCard,
      card: timelineCard,
      onClick: onClickCard(timelineCard),
      height: timelineRow.free ? "100%" : timelineRow.height,
      innerHeight: ui.verticalPanelHeightLayoutInner,
      global: globalState,
      free: timelineRow.free
    }
    return (
      <div id="interface" ref={this.setFocus} tabIndex="0" className="column">
        <div
          className="top-row"
          style={{ ...collapseState.panels.Widgets
              ? { display: 'none' }
              : { width: '100%' }
          }}>
          <WidgetsCard {...widgetsCardProps}>
            <Widgets globalState={globalState} sequence={sequence} />
            <LayoutSelections selections={layoutState.selections} />
          </WidgetsCard>
        </div>
        <div
          className="column left"
          style={
            collapseState.panels.Media ? { display: 'none' } : { width: '220px', display: 'block' }
          }>
          <Card {...mediaCardProps}>
            <Media globalState={globalState} previewTemplateOnClick={goToSequenceId} mediaState={mediaState} edition={edition} />
          </Card>
        </div>
        { mediaState.showUploadPopup && <UploadFileModal cancel={this.closeUploadModal} mediaState={mediaState} /> }
        { mediaState.showBatchDelete && <BatchDeleteModal mediaState={mediaState}/> }
        { mediaState.showNewTemplate && <NewTemplateModal onCancel={mediaState.endDialog} onInput={mediaState.validateTemplateName} onSubmit={mediaState.createTemplate} /> }
        { mediaState.showUpdateTemplate && <UpdateTemplateModal onCancel={mediaState.endDialog} onSubmit={mediaState.updateTemplate}/> }
        <div
          className="column left"
          style={
            collapseState.panels.Variables
              ? { display: 'none' }
              : { width: '220px', display: 'block' }
          }>
          <Card {...variableCardProps}>
            <Variables edition={edition} player={layoutState.player}/>
          </Card>
        </div>
        <div
          className={ui.codeEditing ? `column left zpush` : `column left`}
          style={
            collapseState.panels.Actions
              ? { display: 'none' }
              : { width: '221px', display: 'block' }
          }>
          <Card {...actionsCardProps}>
            <Actions state={state} vars={edition.variables} ui={ui} />
          </Card>
        </div>
        <div
          className={`column script left`}
          style={
            collapseState.panels.Scripting
              ? { display: 'none' }
              : { width: scriptState.extended ? 400 : 400, display: 'block' }
          }>
          <Card {...scriptCardProps}>
            <ScriptInspector state={state} edition={edition} route={route} />
          </Card>
        </div>
        <div className="column free">
          <div className="row">
            <Card {...viewportCardProps}>
                { globalState.loading ? null : <LayoutViewport route={route} state={state} signals={signals} /> }
            </Card>
          </div>
        </div>
        <div
          className="column right"
          style={
            collapseState.panels.Details
              ? { display: 'none' }
              : {
                  width: '220px',
                  display: 'block',
                  zIndex: detailsPanel.colorPickerActive ? '12' : '10'
                }
          }>
          <Card {...detailsCardProps}>
            <LayoutDetails
              state={state}
              sequence={sequence}
              spriteMedia={spriteMedia}
              videoMedia={videoMedia}
              canvasMedia={canvasMedia}
            />
          </Card>
        </div>
        <div
          className="column right"
          style={
            collapseState.panels.Hierarchy
              ? { display: 'none' }
              : { width: '220px', display: 'block' }
          }>
          <Card {...hierarchyCardProps}>
            <LayoutNavigator
              editionNavigator={app.editionNavigator}
              state={state}
              edition={edition}
              sequence={sequence}
              variables={variables}
              route={route}
            />
          </Card>
        </div>
      {freeTimeline ?
          <NewWindow onClose={() => timelineRow.toggleFree() }>
            <div className="timeline-wrap free" style={{ height: "100%" }}>
              <TimelineCard {...timelineCardProps}>
                <Timeline state={state} sequence={sequence} signals={signals} />
              </TimelineCard>
            </div>
          </NewWindow> :
          <div className="timeline-wrap" style={{ height: timelineRow.height }}>
            <button type="button" className="grabber horizontal" onMouseDown={onGrabberDown} />
            <TimelineCard {...timelineCardProps}>
              <Timeline state={state} sequence={sequence} signals={signals} />
            </TimelineCard>
          </div>
      }
      </div>
    )
  }
})

export default LayoutUI
