import { observable, action } from 'mobx'
import CONFIG from '../../../CONFIG'
import { toggleVals } from '../../../utils'
import { getAdvisorLinks } from '../../../actions/remote'

export default function AdvisorLinkSearch() {
  return observable({
    results: [],
    value: '',
    searchMoreEl: null,
    // (should searching and loaded be mutually exclusive?)
    searching: false, // bugstatus
    loaded: false, // resultsstatus
    total: 0, // searchresults.totalElements (do we need to track this outside of the API callback?)
    activepage: 0,
    recordsperpage: 20,
    filter: 'lastUpdated', // enum(name, status, lastUpdated)
    filterdirection: 'desc', // for filter direction for API call
    filterdir: true, // for display of filter arrow direction, starts as desc
    isMyLinks: false,
    isActiveLinks: false,
    get isIdle() {
      return !this.searching && !this.loaded
    },
    get isSearching() {
      return this.searching && !this.loaded
    },
    get isLoaded() {
      return !this.searching && this.loaded
    },
    get recordsDisplay() {
      // If no results are returned
      if (this.numResults === 0 || this.total === 0)
        return 'No Search Results found for search term ' + this.value
      // if less than 1 page of records records
      if (this.numDisplay >= this.total) return 'Showing all ' + this.total + ' Search Results'
      // At least 2 pages of records
      if (this.numDisplay < this.total)
        return 'Showing records 1 - ' + this.numDisplay + ' of ' + this.total + ' Search Results'
      return 'Load More...'
    },
    get numResults() {
      return this.results.length
    },
    get numDisplay() {
      // records to display based on page size
      return (this.activepage + 1) * this.recordsperpage
    },
    // Actions
    setSearchMoreEl: function(el) {
      this.searchMoreEl = el
    },
    getMoreSearchResults: async function() {
      const als = this
      // Gets Advisor link Search results for Advisor links page
      try {
        als.activepage++
        const advisorLinkPagination = await getAdvisorLinks(als)
        if (advisorLinkPagination.content) {
          als.addResults(advisorLinkPagination.content)
        } else {
          // What should happen here? Display "This is everything" or whatever?
        }
      } catch (err) {
        app.throwError(err.message)
      }
    },
    setTotal: function(t) {
      this.total = t
    },
    setResults: function(advisorLinks) {
      this.results.replace(advisorLinks)
    },
    addResults: function(advisorLinks) {
      this.results = this.results.concat(advisorLinks)
    },
    clearResults: function() {
      this.results.clear()
    },
    setIdle: function() {
      this.searching = false
      this.loaded = false
    },
    setSearching: function() {
      this.searching = true
      this.loaded = false
    },
    setLoaded: function() {
      this.searching = false
      this.loaded = true
    },
    getShareLinkUrl: function(token, advisorUrl, language) {
      return `${advisorUrl}?advisorToken=${token}&language=${language}`
    },
    toggleMyLinks: function() {
      this.isMyLinks = !this.isMyLinks
    },
    toogleActiveLinks: function() {
      this.isActiveLinks = !this.isActiveLinks
    },
    toggleSearchFilter: function(clickedfilter) {
      if (this.filter == clickedfilter) {
        this.filterdirection = toggleVals('desc', 'asc', this.filterdirection)
        this.filterdir = toggleVals(true, false, this.filterdir)
      } else {
        this.filterdirection = 'desc'
        this.filterdir = true // Starts as desc when switching
      }
      this.filter = clickedfilter
    },
    setPage: function(page) {
      this.activepage = page
    },
    nextPage: function() {
      this.setPage(this.activepage++)
    },
    setSearch: function(query) {
      this.value = query
    },
    resetSearch: function() {
      this.value = ''
    }
  }, {
    results: observable.shallow,
    searchMoreEl: observable.ref,
    setSearchMoreEl: action.bound,
    getMoreSearchResults: action.bound,
    setTotal: action.bound,
    setResults: action.bound,
    addResults: action.bound,
    clearResults: action.bound,
    setIdle: action.bound,
    setSearching: action.bound,
    setLoaded: action.bound,
    getShareLinkUrl: action.bound,
    toggleMyLinks: action.bound,
    toogleActiveLinks: action.bound,
    toggleSearchFilter: action.bound,
    setPage: action.bound,
    nextPage: action.bound,
    setSearch: action.bound,
    resetSearch: action.bound
  })
}
