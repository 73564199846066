class State {
  constructor(player) {
    this.player = player
  }
  suspend() {}
  resume() {}
  update(dT) {}
  notify(event) {}
  next() {}
  enter() {}
  exit() {}
}

export class ReadyState extends State {
  constructor(player) {
    super(player)
    this.Type = 'ReadyState'
    player.setReadiness(false)
  }
  next() {
    const { player } = this
    const { activeSequence } = player
    const { objectId: sequenceId } = activeSequence
    const count = player.cache.total(sequenceId)
    const resolved = player.cache.completed(sequenceId)
    if (count && count === resolved) player.changeState(new PlayingState(player))
    else player.changeState(new LoadingState(player))
  }
  update(dT) {
    if (this.player.activeSequence) {
      return this.player.next()
    }
  }
}

export class LoadingState extends State {
  constructor(player) {
    super(player)
    this.Type = 'LoadingState'
    this.failed = false
    this.sequence = player.activeSequence
    this.sequenceId = player.activeSequence.objectId
  }
  next() {
    const { player } = this
    if (this.failed) this.player.changeState(new ErrorState(player))
    else this.player.changeState(new PlayingState(player))
  }
  enter() {
    const { player } = this
    const { sequence } = this
    const { sequenceId } = this
    player.queueSequenceMedia(sequence)
    player.cache.start(sequenceId)
  }
  update(dT) {
    const { sequenceId } = this
    const count = this.player.cache.total(sequenceId)
    const failed = this.player.cache.failed(sequenceId)
    const completed = this.player.cache.completed(sequenceId)
    if (count && completed === count) {
      return this.next()
    }
    else if (failed) {
      this.failed = true
      return this.next()
    }
    else if (!count) {
      return this.next()
    } else {
      return
    }
  }
}

export class PlayingState extends State {
  constructor(player) {
    super(player)
    this.Type = 'PlayingState'
    this.sequence = player.activeSequence
  }
  next() {
    const readyState = new ReadyState(this.player)
    this.player.changeState(readyState)
  }
  enter() {
    let { systems } = this.player
    const { sequence } = this
    var i = systems.length
    while (i--) {
      systems[i].init(sequence)
    }
    this.player.setReadiness(true)
  }
  update(dT) {
    let { systems, playing, isSeeking, frame, fps, playbackRate } = this.player
    // Run the AudioManager and VideoManagerin AT
    systems[0].run()
    systems[1].run()
    if (isSeeking) this.player.isSeeking = false
    // Advance the frame
    if (playing) {
      let nextFrame = dT / 1000 * (fps * playbackRate) + frame
      if (nextFrame > this.sequence.duration) {
        this.player.isSeeking = true
        nextFrame = 1
      }
      this.player.frame = nextFrame
    }
    if (this.player.activeSequence !== this.sequence) {
      this.next()
    }
  }
  exit() {
    let { systems, cache } = this.player
    // Make sure to shutdown your systems (i.e. Audio)
    var i = systems.length
    while (i--) {
      systems[i].shutdown()
    }
  }
}

export class ErrorState extends State {
  constructor(player) {
    super(player)
  }
}
