import React from 'react'
import { computed } from 'mobx'
import { decorate } from 'mobx'
import { observer } from 'mobx-react'
import { TIMELINE_LABEL_WIDTH } from '../../constants'

class ViewableComponent extends React.Component {
  constructor(props) {
    super(props)
    this.prevOffset = 0
    this.dragStartX = 0
  }
  get start() {
    const { offset, duration, width } = this.props
    return (offset - 1) / duration * width
  }
  get end() {
    const { frames, offset, duration, width } = this.props
    return (offset - 1 + frames) / duration * width
  }
  rightHandleMouseDown = (e) => {
    const NODE = document //.getElementById("timeline")
    NODE.addEventListener('mousemove', this.rightHandleMouseMove, false)
    NODE.addEventListener('mouseup', this.rightHandleMouseUp, false)
  }
  rightHandleMouseMove = (e) => {
    const { timelineState, width } = this.props
    const zoom = (e.clientX - this.start - TIMELINE_LABEL_WIDTH) / width
    timelineState.setZoom(zoom)
  }
  rightHandleMouseUp = (e) => {
    const NODE = document // .getElementById("timeline")
    NODE.removeEventListener('mousemove', this.rightHandleMouseMove, false)
    NODE.removeEventListener('mouseup', this.rightHandleMouseUp, false)
  }
  viewableMouseDown = (e) => {
    const { offset } = this.props
    e.target.style.cursor = '-webkit-grabbing'
    const NODE = document //.getElementById("timeline")
    NODE.addEventListener('mousemove', this.viewableMouseMove, false)
    NODE.addEventListener('mouseup', this.viewableMouseUp, false)
    this.dragStartX = e.clientX
    this.prevOffset = offset
  }
  viewableMouseMove = (e) => {
    const { timelineState, frames, frameWidth, duration, width } = this.props
    const diff = (e.clientX - this.dragStartX) * (width / (this.end - this.start))
    let newOffset = this.prevOffset + Math.floor(diff / frameWidth)
    if (newOffset > duration - frames) newOffset = Math.round(duration - frames) // set offset max
    timelineState.setFrameOffset(newOffset)
  }
  viewableMouseUp = (e) => {
    e.target.style.cursor = '-webkit-grab'
    const NODE = document // .getElementById("timeline")
    NODE.removeEventListener('mousemove', this.viewableMouseMove, false)
    NODE.removeEventListener('mouseup', this.viewableMouseUp, false)
  }
  render() {
    const { frame, duration, width } = this.props
    const start = this.start
    const end = this.end
    return (
      <div className="viewable">
        <svg width={`${width}`} height="8" style={{ shapeRendering: 'geometricPrecision' }}>
          <rect x="0" y="3" width={width} height="3" fill="#202326" />
          <rect
            x={start}
            y="3"
            width={end - start}
            height="3"
            fill="#AAAEB3"
            onMouseDown={this.viewableMouseDown}
            style={{ cursor: '-webkit-grab' }}
          />
          <rect
            className="viewable-frame"
            x={(frame - 1) / duration * width + 10}
            y="3"
            width={width / duration}
            height="3"
            fill="#007FFF"
          />
          <circle cx={start + 4} cy="4" r="4" fill="#AAAEB3" />
          <circle
            cx={end - 4}
            cy="4"
            r="4"
            fill="#AAAEB3"
            onMouseDown={this.rightHandleMouseDown}
            style={{ cursor: 'ew-resize' }}
          />
        </svg>
      </div>
    )
  }
}

decorate(ViewableComponent, {
  start: computed,
  end: computed,
})

const Viewable = observer(ViewableComponent)

export default Viewable
