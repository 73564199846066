import React from 'react'
import { observable } from 'mobx'
import { decorate } from 'mobx'
import MaskedInput from 'react-maskedinput'
import { observer } from 'mobx-react'
import debounce from 'debounce'
import { getTimeStamp, noFirstSpace, getVersionString, copyToClipboard } from '../../utils'
import { EDITIONMANAGER_GRAPHICS } from './constants'
import { ManagerBreadcrumb } from './manager-breadcrumb'

class EditionAdvisorLinkExpirationComponent extends React.Component {
  constructor(props) {
    super(props)
    this.focused = false
  }
  render() {
    const { ed, saveExpiration, discardExpiration } = this.props
    let expirationClass = 'link-expiration'
    if (!this.focused && !ed.isValid && ed.linkExpiration !== ed.initialExpiration) {
      expirationClass = 'link-expiration invalid'
    } else if (ed.isExpirationDateComplete && ed.isExpired) {
      expirationClass = 'link-expiration expired'
    }
    return (
      <div className="expiration">
        <MaskedInput
          mask="11/11/1111"
          className={expirationClass}
          placeholder="mm/dd/yyyy"
          type="text"
          value={ed.linkInputExpiration}
          onChange={(e) => {
            ed.setLinkInputExpiration(e.target.value)
          }}
          onFocus={() => {
            this.focused = true
          }}
          onBlur={() => {
            this.focused = false
            if (ed.linkInputExpiration !== ed.linkExpiration) {
              saveExpiration(ed)
            }
          }}
        />
        <span onClick={discardExpiration} className="expiration-discard" title="Discard Expiration">
          <i className="icon remove" />
        </span>
      </div>
    )
  }
}

decorate(EditionAdvisorLinkExpirationComponent, {
  focused: observable,
})

const EditionAdvisorLinkExpiration = observer(EditionAdvisorLinkExpirationComponent)

// Generates a row of the advisor link item table on the advisor links page
const EditionAdvisorLinkItem = observer(
  ({ ed, link, copyFn, saveExpiration, discardExpiration }) => {
    const timestamp = getTimeStamp(ed.created)
    const date = timestamp[0]
    const time = timestamp[1]
    return (
      <tr>
        <td>
          <span className="version-name" title={ed.editionName}>
            {ed.editionName}
          </span>
        </td>
        <td>{ed.editionVersion}</td>
        <td>
          <div title={link} onClick={copyFn} className="link-icon-container">
            <i className="bts bt-link" />
          </div>
        </td>
        <td>
          {ed.notes && (
            <div title={ed.notes} className="comment-icon-container">
              <i className="bts bt-comment" />
            </div>
          )}
        </td>
        <td>{ed.advisor}</td>
        <td title="">{ed.createdBy}</td>
        <td title={`at ${time}`}>{date}</td>
        <td>
          <EditionAdvisorLinkExpiration
            ed={ed}
            saveExpiration={saveExpiration}
            discardExpiration={discardExpiration}
          />
        </td>
      </tr>
    )
  }
)

// --------------------------
// Begin Edition Details Propgration
// --------------------------
const EditionAdvisorLinkDetails = observer(class EditionAdvisorLinkDetails extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { app } = this.props
    const editionManager = app.state.editionmanager

    editionManager.getAdvisorLinks()
  }
  render() {
    const { props } = this
    const { app } = props
    const { className } = props
    const { onClickBreadcrumb } = props

    const editionManager = app.state.editionmanager

    // UI-related toggles
    const isShownClass = className
    const isSearching = editionManager.advisorLinks.isSearching
    const isSearchLoaded = editionManager.advisorLinks.isLoaded

    // Action handlers
    const saveExpiration = (ed) => editionManager.saveLinkExpiration(ed)

    const AdvisorLinkList = editionManager.advisorLinks.results.map((version) => {
      const language = 'EN'
      const link = editionManager.advisorLinks.getShareLinkUrl(version.token, app.advisorUrl, language)
      const copyFn = () => {
        copyToClipboard(link)
        app.state.global.showAlert('success', `Link copied to clipboard.`)
      }
      const discardExpiration = () => {
        editionManager.clearLinkExpiration(version)
      }
      return (
        <EditionAdvisorLinkItem
          key={version.id}
          ed={version}
          link={link}
          copyFn={copyFn}
          saveExpiration={saveExpiration}
          discardExpiration={discardExpiration}
        />
      )
    })

    const searchOutput = (e) => {
      editionManager.advisorLinks.setSearch(noFirstSpace(e.target.value))
      // Sets half second delay on search when typing to avoid API calls every keypress
      const searchCountdown = debounce(function(searchValue) {
        if (searchValue === editionManager.advisorLinks.value) editionManager.getAdvisorLinks()
      }, 500)
      searchCountdown(e.target.value)
    }

    const clearSearchForm = () => {
      editionManager.advisorLinks.resetSearch()
      editionManager.getAdvisorLinks()
    }

    const sortByName = () => {
      editionManager.advisorLinks.toggleSearchFilter('editionName')
      editionManager.getAdvisorLinks()
    }

    const sortByCreatedBy = () => {
      editionManager.advisorLinks.toggleSearchFilter('createdBy')
      editionManager.getAdvisorLinks()
    }

    const sortByAdvisorName = () => {
      editionManager.advisorLinks.toggleSearchFilter('advisor')
      editionManager.getAdvisorLinks()
    }

    const sortByCreatedDate = () => {
      editionManager.advisorLinks.toggleSearchFilter('created')
      editionManager.getAdvisorLinks()
    }
    const moreResults = () => {
      editionManager.advisorLinks.getMoreSearchResults()
    }

    const sortByExipreDate = () => {
      editionManager.advisorLinks.toggleSearchFilter('linkExpiration')
      editionManager.getAdvisorLinks()
    }

    const getMyEditionLinks = () => {
      editionManager.advisorLinks.toggleMyLinks()
      editionManager.getAdvisorLinks()
    }
    const getActiveLinks = () => {
      editionManager.advisorLinks.toogleActiveLinks()
      editionManager.getAdvisorLinks()
    }

    return (
      <editiondetails-wrapper class={isShownClass}>
        <editiondetails-container>
          <ManagerBreadcrumb onClick={onClickBreadcrumb}/>
          <header className="editiondetails">
            <div className="edition-status"> Advisor Links</div>
            <div className={`edition-colorbar colorbar-open`} />
          </header>

          <section className="edition-search">
            <div className="search-bar">
              <input
                className="search-bar-input"
                id="search-bar-input"
                type="text"
                placeholder="Search..."
                value={editionManager.advisorLinks.value}
                onChange={searchOutput}
              />
              <button className="search-clear" onClick={clearSearchForm} />
            </div>
            <div className="buttons advisor-search-button">
              <span className="filter-text"> Quick Filters </span>
              <button
                className={editionManager.advisorLinks.isMyLinks ? 'selected' : ''}
                onClick={getMyEditionLinks}>
                My Links Only
              </button>
              <button
                className={editionManager.advisorLinks.isActiveLinks ? 'selected' : ''}
                onClick={getActiveLinks}>
                Active Links Only
              </button>
            </div>
          </section>

          <section
            className={isSearching ? `search-results-loading` : `search-results-loading hide`}>
            <img src={EDITIONMANAGER_GRAPHICS.jellyfish} /> Searching...
          </section>
          <section className={isSearchLoaded ? `search-results` : `search-results hide`}>
            <div className={'search-results-body overflow'}>
              <table className="search-results-table editionhistory editionadvisorlink">
                <thead>
                  <tr>
                    <th
                      className={
                        editionManager.advisorLinks.filter === 'editionName'
                          ? 'filter active'
                          : 'filter'
                      }
                      onClick={sortByName}>
                      <strong>Edition Name</strong>
                      <i
                        className={
                          editionManager.advisorLinks.filterdir
                            ? 'chevron-xtrasmall desc'
                            : 'chevron-xtrasmall asc'
                        }
                      />
                    </th>
                    <th>Version</th>
                    <th>Links</th>
                    <th>Comment</th>

                    <th
                      className={
                        editionManager.advisorLinks.filter === 'advisor'
                          ? 'filter active'
                          : 'filter'
                      }
                      onClick={sortByAdvisorName}>
                      <strong>Advisor</strong>
                      <i
                        className={
                          editionManager.advisorLinks.filterdir
                            ? 'chevron-xtrasmall desc'
                            : 'chevron-xtrasmall asc'
                        }
                      />
                    </th>
                    <th
                      className={
                        editionManager.advisorLinks.filter === 'createdBy'
                          ? 'filter active'
                          : 'filter'
                      }
                      onClick={sortByCreatedBy}>
                      <strong>Created By</strong>
                      <i
                        className={
                          editionManager.advisorLinks.filterdir
                            ? 'chevron-xtrasmall desc'
                            : 'chevron-xtrasmall asc'
                        }
                      />
                    </th>

                    <th
                      className={
                        editionManager.advisorLinks.filter === 'created'
                          ? 'filter active'
                          : 'filter'
                      }
                      onClick={sortByCreatedDate}>
                      <strong>Created</strong>
                      <i
                        className={
                          editionManager.advisorLinks.filterdir
                            ? 'chevron-xtrasmall desc'
                            : 'chevron-xtrasmall asc'
                        }
                      />
                    </th>
                    <th
                      className={
                        editionManager.advisorLinks.filter === 'linkExpiration'
                          ? 'filter active'
                          : 'filter'
                      }
                      onClick={sortByExipreDate}>
                      <strong>Expires</strong>
                      <i
                        className={
                          editionManager.advisorLinks.filterdir
                            ? 'chevron-xtrasmall desc'
                            : 'chevron-xtrasmall asc'
                        }
                      />
                    </th>
                  </tr>
                </thead>
                {AdvisorLinkList}
              </table>
              <div
                className="search-load-more"
                ref={editionManager.advisorLinks.setSearchMoreEl}
                id="search-load-more"
                onClick={moreResults}>
                <em>{editionManager.advisorLinks.recordsDisplay}</em>
              </div>
            </div>
          </section>
        </editiondetails-container>
      </editiondetails-wrapper>
    )
  }
})

export default EditionAdvisorLinkDetails
