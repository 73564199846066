import { types, getParent, getSnapshot } from 'mobx-state-tree'
import Expandable from './Expandable'
import RawVideo from './Media/RawVideo'
import { setProp } from './utils'

// TODO: is this even used any more?
const VideoRowCurrent = types.model('VideoRowCurrent', {
	active: types.boolean,
	progress: types.number,
})

const VideoRowTimelineState = types
	.model('VideoRowTimelineState', {
		muted: types.boolean,
    showText: types.boolean,
		locked: types.boolean,
	})
	.actions(self => {
		function toggleProp(prop) {
			self[prop] = !self[prop]
		}
		return {
			toggleProp,
		}
	})
  
const VideoRowDetailsState = types.model('VideoRowDetailsState', {
	properties: Expandable,
	media: Expandable,
})

const model = types
	.model('VideoRow', {
		Type: types.literal('VideoRow'),
		objectId: types.identifier,
		name: types.string,
		// MediaOptions
		videoMedia: types.maybeNull(types.reference(RawVideo)),
		startTime: types.number,
		current: VideoRowCurrent,
		timelineState: VideoRowTimelineState,
		detailsState: VideoRowDetailsState,
	})
	.views(self => ({
		get parent() {
			return getParent(self, 2)
		},
	}))
	.actions(self => {
		function updateProp(prop, value) {
			const r = self
			setProp(prop, value, r)
		}
		function setVideoMedia(media) {
			const r = self
			r.videoMedia = media
		}
		function duplicate(sequence) {
			const r = self
			sequence = sequence || r.parent
			sequence.addVideoRow(clone())
		}
		function clone() {
			return VideoRow.clone(getSnapshot(self))
		}
		return {
			updateProp,
			setVideoMedia,
			duplicate,
			clone,
		}
	})

export default model

